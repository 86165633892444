import React from "react";
import { Button, Modal } from "@mui/material";
import { ReactComponent as CloseCross } from '../../assets/Icons/closeCross.svg';
import './Help.css';
import { helpModalText } from "../../constants/helpModal";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const HelpClientStaff = (props) => {
    const { t } = useTranslation(['customerStaff']);
    const lng = useSelector(store => store.auth.lenguageId);
    const languageCode = lng ? (parseInt(lng) === 1 ? 'en' : 'es') : 'es';
    const { open = false, closeModal } = props;
    const handleClose = () => closeModal();

    return (
        <Modal
            open={open}
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                zIndex: 10000
            }}
        >
            <div style={{ width: 500, height: 'min-content', padding: '15px 30px', background: '#F8F9FA', borderRadius: 20 }}>
                <Button className='button-close-cross' variant="text" style={{ float: 'right', marginTop: 10 }}
                    onClick={handleClose} startIcon={<CloseCross />}></Button>
                <div style={{
                    width: '100%', height: 'calc(100% - 45px)', display: 'flex',
                    justifyContent: 'space-between', alignItems: 'center',
                    paddingLeft: 8
                }}>
                    <div style={{
                        display: 'flex', flexDirection: 'column',
                        justifyContent: 'flex-start', height: '100%',
                        padding: '0 40px', marginTop: 25, marginBottom: 50,
                        alignItems: 'center'
                    }}>
                        {helpModalText[14][languageCode][0].map((text, index) => (
                            <p key={index} style={{
                                fontStyle: 'normal',
                                fontWeight: '400',
                                fontSize: '22px',
                                lineHeight: '27px',
                                color: '#231E60'
                            }}>
                                {text}
                            </p>
                        ))}
                    </div>
                </div>
                <div style={{
                    width: '100%', display: 'flex', justifyContent: 'center',
                    height: 60
                }}>
                    <Button variant="contained" className="button-app-save"
                        onClick={() => closeModal()}>
                        {t("acceptLbl")}
                    </Button>
                </div>
            </div>
        </Modal >
    );
}
export default HelpClientStaff;