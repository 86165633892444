import React from "react";
import { Container, Grid, Typography, Card, CardMedia } from "@mui/material";
import helpImage17 from '../../assets/Images/help17b.png';
import helpImage18 from '../../assets/Images/help18.png';
import helpImage19 from '../../assets/Images/help19.png';
import helpImage20 from '../../assets/Images/help20.png';
import { useTranslation } from "react-i18next";
import './Help.css';

const HelpCustomerStaff = () => {

    const { t } = useTranslation(['help']);

    return (
        <Container>
         <Typography className="admin-business-text" style={{ marginBottom: '20px' }}>
                            {t('help:customerStaff:category')+"|"+t('help:customerStaff:title')}
                        </Typography>
            <Grid item xs={12}>
                <div className="container-app-div help-screen header" style={{ marginTop: 10 }}>
                    <div style={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
                        
                        <p className="paragraph-text strong" style={{ marginBottom: '20px' }} >
                            {t('help:thisPageLbl')}
                        </p>
                        <label className="help-label-text">
                        <span className="enum">1</span> {t('help:customerStaff:lbl1')}
                        </label>
                        <label className="help-label-text">
                        <span className="enum">2</span> {t('help:customerStaff:lbl2')}
                        </label>
                        </div>
                        <div style={{width:'50%'}}>
                        <label className="help-label-text">
                            {t('help:customerStaff:lbl3')}
                        </label>
                        <label className="help-label-text">
                            {t('help:customerStaff:lbl4')}
                        </label>
                        <label className="help-label-text">
                            {t('help:customerStaff:lbl5')}
                        </label>
                        <label className="help-label-text">
                            {t('help:customerStaff:lbl6')}
                        </label>
                        <label className="help-label-text">
                            {t('help:customerStaff:lbl7')}
                        </label>
                    </div>
                    </div>

                    <div className="container-app-div" style={{ marginTop: 10 }}>
                
                        <div style={{ display: 'flex', marginBottom: 30 }}>
                            <div style={{ width: '50%', display: 'flex', flexDirection: 'column' }}>
                                <p className="paragraph-text strong">
                                    {t('help:step1')}<span className="enum">1</span>:&nbsp;    {t('help:customerStaff:lbl8')}
                                </p>
                                <label className="help-label-text p">
                                    {t('help:customerStaff:lbl9')}
                                </label>
                                <label className="help-label-text p">
                                    {t('help:customerStaff:lbl10')}
                                </label>
                                <label className="help-label-text p">
                                    {t('help:customerStaff:lbl11')}
                                </label>
                            </div>
                            <div style={{ width: '50%', display: 'flex', flexDirection: 'column',justifyContent: 'center', paddingTop:'50px',alignItems: 'center' }}>
                                <Card sx={{ maxWidth: 275, height: 80, boxShadow: 'none',padding:'0' }}>
                                    <CardMedia
                                        component="img"
                                        image={helpImage17}
                                        alt="img"
                                        style={{ objectFit: 'contain' }}
                                    />
                                </Card>
                                <Card sx={{ width: 377, height: 306, boxShadow: 'none',padding:'0' }}>
                                <CardMedia
                                    component="img"
                                    image={helpImage18}
                                    alt="img"
                                    style={{ objectFit: 'contain' }}
                                />
                            </Card>
                            </div>
                        </div>
                        </div>
                        <div className="container-app-div" style={{ marginTop: 10 }}>
                
                        <div style={{ display: 'flex', marginBottom: 30 }}>
                            <div style={{ width: '50%', display: 'flex', flexDirection: 'column' }}>
                                <p className="paragraph-text strong">
                                    {t('help:step2')}<span className="enum">2</span>:&nbsp;   {t('help:customerStaff:lbl12')}
                                </p>
                                <label className="help-label-text">
                                    {t('help:customerStaff:lbl13')}
                                </label>
                                <label className="help-label-text">
                                    {t('help:customerStaff:lbl14')}
                                </label>
                                <label className="help-label-text">
                                    {t('help:customerStaff:lbl15')}
                                </label>
                                <label className="help-label-text">
                                    {t('help:customerStaff:lbl16')}
                                </label>
                                <label className="help-label-text">
                                    {t('help:customerStaff:lbl17')}
                                </label>
                                <br />
                                <label className="help-label-text">
                                    {t('help:customerStaff:lbl18')}
                                </label>
                                <label className="help-label-text">
                                    {t('help:customerStaff:lbl19')}
                                </label>
                                <br />
                                <label className="help-label-text">
                                    {t('help:customerStaff:lbl20')}
                                </label>
                            </div>
                            <div style={{
                                width: '50%', display: 'flex', flexDirection: 'column',margin: '40px 0 20px 0',
                                justifyContent: 'center', alignItems: 'center'
                            }}>
                                <Card sx={{ maxWidth: 302, height: 68, boxShadow: 'none' }}>
                                    <CardMedia
                                        component="img"
                                        image={helpImage19}
                                        alt="img"
                                        style={{ objectFit: 'contain' }}
                                    />
                                </Card>
                                <Card sx={{ width: 372, height: 281, boxShadow: 'none' }}>
                                <CardMedia
                                    component="img"
                                    image={helpImage20}
                                    alt="img"
                                    style={{ objectFit: 'contain' }}
                                />
                            </Card>
                            </div>
                        </div>
                            
                        </div>
                        
                    
                
            </Grid>
        </Container>
    );
}
export default HelpCustomerStaff;