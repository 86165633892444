import React, { useEffect, useState } from "react";
import { withRouter } from "react-router";
import { Link } from 'react-router-dom';
import {
    Container, Grid, Typography, TableContainer,
    TableRow, TableCell, Table, TableBody, TableHead, Button
} from "@mui/material";
import Title from "../../../components/Title";
import { ReactComponent as ArrowBack } from '../../../assets/Icons/arrowBack.svg';
import * as ROUTES from '../../../constants/routes';
import './Details.css';
import { useDispatch, useSelector } from "react-redux";
import { getNotificationById } from "../../../redux/NotificationDuck";
import Moment from "react-moment";
import { getUsers, changeUserPage } from "../../../redux/UserDuck";
import PaginationCustom from "../../../components/Pagination";
import { useTranslation } from 'react-i18next';
const NotificationDetail = props => {
    const { t } = useTranslation(['notification', 'common', 'label']);
    const users = useSelector(store => store.user.users);
    const activePageUser = useSelector(store => store.user.activePage);
    const pagesUser = useSelector(store => store.user.pages);

    const [notificationId, setNotificationId] = useState('');
    const notification = useSelector(store => store.notification.notification);
    const dispatch = useDispatch();

    //componentDidMount
    useEffect(() => {
        dispatch(changeUserPage(1));
        //component will unmount
        return () => {
            dispatch(changeUserPage(1));
        }
    }, [dispatch]);

    const getStaffClientName = (clientsList) => {
        const list = [];
        clientsList.forEach(item => {
            const clientName = item.client.name;
            (clientName && !list.includes(clientName)) && list.push(clientName);
        });
        return list.toString().replace(',', ', ');
    };

    useEffect(() => {
        const { id } = props.match.params;
        setNotificationId(id);
        dispatch(getNotificationById(id));
    }, [dispatch, props.match.params]);

    useEffect(() => {
        if (notificationId) {
            const page = activePageUser;
            dispatch(getUsers(
                [{ filterBy: 'notification', search: String(notificationId) }],
                page, 10, false, 'notification'
            ));
        }
        // eslint-disable-next-line
    }, [dispatch, notificationId, activePageUser]);

    return notification && (
        <Container>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <Button className="arrow-back-icon-svg" style={{ marginBottom: '2%' }} variant="text"
                    component={Link}
                    to={ROUTES.NOTIFICATIONS} startIcon={<ArrowBack />}>
                </Button>
                <Title title={notification.title} />
            </div>
            <Grid item xs={12}>
                <div className="container-app-div">
                    <Typography className="app-form-title">{t("subtitle4")}</Typography>
                    <TableContainer>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow className="header-cell-matters">
                                    <TableCell className="detail-table-cell-notifications" align="right">{t("headerTbl8")}</TableCell>
                                    <TableCell sx={{ width: '78%' }} className="detail-table-cell-notifications">{t("headerTbl9")}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow key={'id1'}>
                                    <TableCell component="th" scope="row" className="description-table-cell-notifications" >
                                        {t("headerTbl10")}
                                    </TableCell>
                                    <TableCell className="detail-table-cell-notifications">
                                        {notification.title}
                                    </TableCell>
                                </TableRow>
                                <TableRow key={'id2'}>
                                    <TableCell component="th" scope="row" className="description-table-cell-notifications">
                                        {t("headerTbl11")}
                                    </TableCell>
                                    <TableCell className="detail-table-cell-notifications">
                                        {notification.description}
                                    </TableCell>
                                </TableRow>
                                <TableRow key={'id3'}>
                                    <TableCell component="th" scope="row" className="description-table-cell-notifications">
                                        {t("headerTbl12")}
                                    </TableCell>
                                    <TableCell className="detail-table-cell-notifications">
                                        {notification.company.name}
                                    </TableCell>
                                </TableRow>
                                <TableRow key={'id4'}>
                                    <TableCell component="th" scope="row" className="description-table-cell-notifications">
                                        {t("headerTbl13")}
                                    </TableCell>
                                    <TableCell className="detail-table-cell-notifications">
                                        {notification.office.name}
                                    </TableCell>
                                </TableRow>
                                <TableRow key={'id5'}>
                                    <TableCell component="th" scope="row" className="description-table-cell-notifications">
                                        {t("headerTbl14")}
                                    </TableCell>
                                    <TableCell className="detail-table-cell-notifications">
                                        {notification.client ? notification.client.name : t('label:allsLbl')}
                                    </TableCell>
                                </TableRow>
                                <TableRow key={'id6'}>
                                    <TableCell component="th" scope="row" className="description-table-cell-notifications">
                                        {t("headerTbl15")}
                                    </TableCell>
                                    <TableCell className="detail-table-cell-notifications">
                                        <Moment style={{ marginTop: '4px' }}
                                            format="DD/MM/YYYY">
                                            {notification.created}
                                        </Moment>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
                <div className="container-app-div">
                    <Typography className="app-form-title">{t("subtitle6")}</Typography>
                    <TableContainer>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell className='table-app-title-header'>{t("headerTbl5")}</TableCell>
                                    <TableCell className='table-app-title-header'>{t("headerTbl6")}</TableCell>
                                    <TableCell className='table-app-title-header'>{t("headerTbl7")}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {users.length === 0 &&
                                    <TableRow key='empty'>
                                        <TableCell colSpan={3} style={{ textAlign: 'center' }}>
                                            {t("common:commonNoResultsLbl")}
                                        </TableCell>
                                    </TableRow>
                                }
                                {users.map(item => (
                                    <TableRow key={item.id}>
                                        <TableCell component="th" scope="row" className='app-form-subtitle'>
                                            {item.firstName + ' ' + item.lastName}
                                        </TableCell>
                                        <TableCell component="th" scope="row" className='app-form-subtitle'>
                                            {item.email}
                                        </TableCell>
                                        <TableCell component="th" scope="row" className='app-form-subtitle'>
                                            {item && getStaffClientName(item.clientUsers)}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <br />
                    <PaginationCustom pages={pagesUser} activePage={activePageUser}
                        onChange={page => dispatch(changeUserPage(page))} />
                </div>
            </Grid>
        </Container>
    );

}

export default withRouter(NotificationDetail);