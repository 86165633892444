export const userValidation = (user, imgRef, t) => {
    var error = {};
    const allowedExtensions = /(.jpg|.jpeg|.png)$/i;
    const maxSizeKB = 5000;
   // const onlyLetters = /^[a-zñáéíóúüñ]*$/i;
   const onlyLetters = /^[a-zA-Z0-9!@#$%^&*()_+\-=[\]{};':"\\|,íúóéáñ./\n/\r/\t<>/?]*$/;
   
    if (user.firstName === null || user.firstName.length === 0 ||
        /^\s+$/.test(user.firstName))
        error.firstName = t("subscription:errorName");
    else
        if (!user.firstName.replaceAll(" ", "").match(onlyLetters))
            error.firstName = t("validations:errorAlphabet");
    if (user.lastName === null || user.lastName.length === 0 ||
        /^\s+$/.test(user.lastName))
        error.lastName = t("subscription:errorLastName");
    else
        if (!user.lastName.replaceAll(" ", "").match(onlyLetters))
            error.lastName =t("validations:errorAlphabet");
    if (user.email === null || user.email.length === 0 ||
        /^\s+$/.test(user.email))
        error.email = t("validations:errorEmail");
    else if (!validateUserEmail(user.email))
        error.email = t("validations:errorEmailFormat");
    if (user.countryCode === null || user.countryCode.length === 0 ||
        /^\s+$/.test(user.countryCode))
        error.countryCode = t("validations:errorCountryCode");
    if (user.phoneNumber === null || user.phoneNumber.length === 0 ||
        /^\s+$/.test(user.phoneNumber))
        error.phoneNumber = t("validations:errorPhoneNumber");
    else if (!user.phoneNumber.replaceAll(" ","").match(onlyLetters))
        error.phoneNumber = t("validations:errorPhoneNumberFormat");
    if (user.position === null || user.position.length === 0 ||
        /^\s+$/.test(user.position))
        error.position = t("subscription:errorPosition");
    if (user.profileDescription === null || user.profileDescription.length === 0 ||
        /^\s+$/.test(user.profileDescription))
        error.profileDescription = t("subscription:errorProfile");
    // if (!user.imageUrl && (user.image === null || user.image.length === 0))
    //     error.image = t("subscription:errorProfilePic");
    if (!!user.image) {
        const size = user.image.size;
        const sizeKB = parseInt(size / 1024);
        let Width = user.image.clientWidth;
        let Height = user.image.clientHeight;
        var ratio = 1;
            if(Height>Width)
            {
            ratio = (Height / Width);
            }else{
            ratio = (Width / Height);
            }
            var ThresHoldVal = 0.15; // 10% out.

            if((ratio-1) > ThresHoldVal)
            {
                error.image = t("subscription:validations:errorDimensions")+` (${imgRef.current.clientWidth} x ${imgRef.current.clientHeight} px)`;

            }
        //if (imgRef.current.clientWidth > 400 || imgRef.current.clientHeight > 400)
        if (user.image.clientWidth > 400 || user.image.clientHeight > 400)
            error.image = t("subscription:validations:errorDimensions")+` (${imgRef.current.clientWidth} x ${imgRef.current.clientHeight} px)`;

        if (!allowedExtensions.exec(user.image.name))
            error.image = t("subscription:validations:errorProfileImgExt");
        else if (sizeKB > maxSizeKB)
            error.image = t("subscription:validations:errorProfileImgSize");
    }
    // if (offices.length === 0)
    //     error.office = t("subscription:errorOffice");

    return error;
};

const validateUserEmail = ((email) => {
    const regex = /^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    return regex.test(email) ? true : false;
});
export const cardChangeValidation = (subscription, t) => {

    var error = {};

    if (subscription.cardHolderName === null || subscription.cardHolderName.length === 0 || 
        /^\s+$/.test(subscription.cardHolderName))
        error.cardHolderName = t("subscription:errorCardHolderName");
    if (subscription.cardHolderName.length > 0 && subscription.cardHolderName.length <= 5 )
        error.cardHolderName = t("subscription:errorCardHolderNameInvalid");

    if (subscription.cardN === null || subscription.cardN.length === 0 ||
        /^\s+$/.test(subscription.cardN))
        error.cardN = t("subscription:errorCardN");

    if (subscription.cvv === null ||  subscription.cvv.length === 0 ||
        /^\s+$/.test(subscription.cvv))
        error.cvv = t("subscription:errorCVV");
      
    if (subscription.expYear === null || subscription.expYear.length === 0 ||
            /^\s+$/.test(subscription.expYear))
            error.expYear = "Invalid or empty Expiration Year.";
    if (subscription.expYear === null || subscription.expYear.length === 0 ||
            /^\s+$/.test(subscription.expYear)|| subscription.expYear<2023)
            //.expYear = "Invalid or empty Expiration Year.";
            error.expYear = t("subscription:errorExpYear");
    if (subscription.expMonth === null || subscription.expMonth.length === 0 ||
            /^\s+$/.test(subscription.expMonth)|| subscription.expMonth<1 || subscription.expMonth>12)
            error.expMonth = error.expYear = t("subscription:errorExpMonth"); 



    return error;
};



