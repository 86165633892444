import React, { useState, useEffect, createRef } from 'react';
import { withRouter } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
    Grid, Typography,
    InputLabel, Box, CardMedia,
    Button, Container, Tab
} from "@mui/material";
import { TabContext, TabList, TabPanel ,Alert} from '@mui/lab';
import { ReactComponent as Search } from '../../../assets/Icons/search.svg';
import { ReactComponent as Trash } from '../../../assets/Icons/trash.svg';
import { ReactComponent as Archive } from '../../../assets/Icons/archive.svg';
import TextFieldComponent from '../../../components/TextField';
import SelectFieldComponent from '../../../components/SelectField';
import Title from '../../../components/Title';
import TableUsers from '../TableUsers';
import TableClients from '../TableClients';
import { getCountries } from '../../../redux/CountryDuck';
import { saveBusiness, updateBusiness, changeStatusCompany, changeStatusOffice, getOfficesFilterWithStatus } from '../../../redux/BusinessDuck';
import { getClients, changeClientPage } from '../../../redux/ClientDuck';
import { getUsers, changeUserPage } from '../../../redux/UserDuck';
import { ReactComponent as Filter } from '../../../assets/Icons/filter.svg';


import * as ROUTES from '../../../constants/routes';
import './Form.css';
import { getFileURL } from '../../../constants/apiUrls';
import { businessValidation, validateOffice } from './FormValidation';
import PaginationCustom from '../../../components/Pagination';
import DeleteModalOffice from '../../../components/DeleteMessage';
import DeleteModalBusiness from '../../../components/DeleteMessage';
import { useTranslation } from 'react-i18next';
const FormBusiness = props => {
    const { t } = useTranslation(['business', 'common', 'error', 'validations', 'label']);
    const activePageClient = useSelector(store => store.client.activePage);
    const pagesClient = useSelector(store => store.client.pages);
    const activePageUser = useSelector(store => store.user.activePage);
    const pagesUser = useSelector(store => store.user.pages);
    const countries = useSelector(store => store.country.countries);
    const clients = useSelector(store => store.client.clients);
    const users = useSelector(store => store.user.users);
    const businessRedux = useSelector(store => store.business.business);
    const officeRedux = useSelector(store => store.business.offices);
    const isCreating = useSelector(store => store.business.isCreating);
    const isCreated = useSelector(store => store.business.isCreated);

    const [businessTabValue, setBusinessTabValue] = useState('1');
    const [clientUserTabValue, setClientUserTabValue] = useState('client');
    const [business, setBusiness] = useState({
        name: '', image: '', imageUrl: '', offices: [], disabled: '',
    });
    const [searchClient, setSearchClient] = useState('');
    const [searchUser, setSearchUser] = useState('');
    const [businessError, setBusinessError] = useState({});
    const imgRef = createRef();
    const [resetPageUser, setResetPageUser] = useState(false);
    const [resetPageClient, setResetPageClient] = useState(false);

    const [filter, setFilters] = useState('false');

    const [openDeleteOffice, setOpenDeleteOffice] = useState(false);
    const [openDeleteBusiness, setOpenDeleteBusiness] = useState(false);

    const dispatch = useDispatch();

    const [office, setOffice] = useState({
        name: '', email: '', countryCode: '',
        phoneNumber: '', webSite: '',
        address: '', GPSCoordinates: '',
    });
    const handleChange = (event) => {
        setFilters(event.target.value);
    };

    //componentDidMount
    useEffect(() => {
        dispatch(changeClientPage(1));
        dispatch(changeUserPage(1));
        //component will unmount
        return () => {
            dispatch(changeClientPage(1));
            dispatch(changeUserPage(1));
        }
    }, [dispatch]);

    useEffect(() => {
        const filterBy = [];
        if (businessRedux) {
            filterBy.push({ search: String(businessRedux.id), filterBy: 'company' });
            filter !== 'none' ? filterBy.push({ search: filter, filterBy: 'disabled' }) : filterBy.push({ search: 'false', filterBy: 'disabled' });
            dispatch(getOfficesFilterWithStatus(filterBy));

        }

    }, [dispatch, filter, businessRedux]);

    const onImageChange = event => {
        if (!!event.target.files[0]) {
            const { name } = event.target;
            const value = event.target.files[0];
            let img = new Image();
            img.src = window.URL.createObjectURL(value);
            img.onload = () => {
                if (img.width <= 400 && img.height <= 200) {
                    setBusiness({
                        ...business, [name]: value,
                        imageUrl: URL.createObjectURL(value)
                    });
                    setBusinessError({ ...businessError, image: '' });
                } else {
                    setBusinessError({ ...businessError, image: t("common:commonNoValidDimensions") + ` (${img.width} x ${img.height})` });
                }
            }
        }
    };

    const onSubmit = () => {
        const error = businessValidation(business, imgRef, t);
        setBusinessError(error);
        if (!error.errors) {
            if (isCreating) dispatch(saveBusiness(business));
            else dispatch(updateBusiness(business));
        }
    };

    const onChangeClientTab = (value, newValue) => {
        setClientUserTabValue(newValue);
    };

    const onChangeTab = (value, newValue) => {
        if (newValue !== 'add') setBusinessTabValue(newValue);
    };

    const onChange = e => {
        const { name, value } = e.target;
        setBusiness({ ...business, [name]: value });
    };

    const onChangeOficce = e => {
        const { name, value } = e.target;
        setOffice({ ...office, [name]: value });
    };

    const onChangeBusinessOficce = (id, e) => {
        const { name, value } = e.target;
        var offices = business.offices;
        var officeObj = business.offices.find(item => item.id === id);
        const index = business.offices.indexOf(officeObj);
        officeObj[name] = value;
        offices[index] = officeObj;
        setBusiness({ ...business, offices: offices });
    };

    const addOffice = () => {
        const error = validateOffice(office, business, t);
        setBusinessError(error);
        if (!error.officeList.find(item => item.id === 'new')) {
            const index = business.offices.length;
            const offices = business.offices;
            const id = 'new-' + index;
            offices.push({ id: id, ...office });
            setBusiness({ ...business, offices });
            setBusinessTabValue(String(index + 2));
            clearOffice();
        }
    };

    const clearOffice = () => {
        setOffice({
            name: '', email: '', countryCode: '',
            phoneNumber: '', webSite: '',
            address: '', GPSCoordinates: '',
        });
    };

    const onChangeClient = (event) => {
        setSearchClient(event.target.value);
        setResetPageClient(true);
    };

    const onChangeUser = (event) => {
        setSearchUser(event.target.value);
        setResetPageUser(true);
    };

    useEffect(() => {
        dispatch(getCountries());
    }, [dispatch]);

    useEffect(() => {
        if (!isCreating && businessRedux) {
            //const businessId=businessRedux.id;
            const page = resetPageClient ? 0 : activePageClient;
            const timer = setTimeout(() => dispatch(getClients(
                [{ search: searchClient, filterBy: 'name' },
                { search: String(businessRedux.id), filterBy: "company" }], page, 3)
            ), 1000);
            setResetPageClient(false);
            return () => clearTimeout(timer);
        }
        // eslint-disable-next-line
    }, [dispatch, searchClient, businessRedux]);

    useEffect(() => {
        if (!isCreating && businessRedux) {
            //const businessId=businessRedux.id;
            const page = resetPageClient ? 0 : activePageClient;
            dispatch(getClients(
                [{ search: searchClient, filterBy: 'name' },
                { search: String(businessRedux.id), filterBy: "company" }], page, 3)
            );
            setResetPageClient(false);
        }
        // eslint-disable-next-line
    }, [dispatch, activePageClient]);

    useEffect(() => {
        if (!isCreating && businessRedux) {
            const page = resetPageUser ? 0 : activePageUser;
            const timer = setTimeout(() => dispatch(getUsers(
                [{ search: searchUser, filterBy: 'search-bar' },
                { search: String(businessRedux.id), filterBy: "company" }], page, 3, false, "company")
            ), 1000);
            setResetPageUser(false);
            return () => clearTimeout(timer);
        }
        // eslint-disable-next-line
    }, [dispatch, searchUser, businessRedux]);

    useEffect(() => {
        if (!isCreating && businessRedux) {
            const page = resetPageUser ? 0 : activePageUser;
            dispatch(getUsers(
                [{ search: searchUser, filterBy: 'search-bar' },
                { search: String(businessRedux.id), filterBy: "company" }], page, 3, false, "company")
            );
            setResetPageUser(false);
        }
        // eslint-disable-next-line
    }, [dispatch, activePageUser]);

    useEffect(() => {
        if (businessRedux) {
            const officeList = [];
            if (officeRedux) {
                officeRedux.forEach(item => {
                    officeList.push({
                        id: item.id,
                        companyId: item.companyId,
                        name: item.name,
                        email: item.email,
                        countryCode: item.countryCodeId,
                        phoneNumber: item.phone,
                        webSite: item.webSite,
                        address: item.direction,
                        GPSCoordinates: item.coordinatesGps,
                        disabled: item.disabled
                    });
                });
                setBusiness({
                    name: businessRedux.name,
                    imageUrl: businessRedux.fileId ? getFileURL + businessRedux.fileId : '',
                    offices: officeList,
                    disabled: businessRedux.disabled,
                });
            }
        }
    }, [businessRedux, officeRedux]);

    useEffect(() => {
        (isCreated) && props.history.push(ROUTES.BUSINESS);
    }, [isCreated, props.history]);

    return (
        <Container>
            {(!isCreating && businessRedux) &&
                <DeleteModalBusiness
                    open={openDeleteBusiness}
                    closeModal={() => setOpenDeleteBusiness(false)}
                    handleClick={() => dispatch(changeStatusCompany(businessRedux.id))}
                    buttonMessage={!businessRedux.disabled ? t("archiveLbl") : t("enableLbl")}
                    textMessage={`${t("textConfirmationA")} ${!businessRedux.disabled ? t("archiveLbl") : t("enableLbl")} ${t("textCompanySure")} `}
                />
            }
            <Title title={t("title2")} style={{ marginLeft: 5 }} />
            <Grid item xs={12}>
                <div className="container-app-div">
                    <Typography className="app-form-title">{t("subtitle2")}</Typography>
                    <Typography className="app-form-subtitle">
                        {t("subtitle3")}
                    </Typography>
                    <InputLabel className="label-app">{t("nameLbl")}</InputLabel>
                    <div style={{ width: '45%' }}>
                        <TextFieldComponent name="name" callback={onChange.bind(this)}
                            valueInput={business.name} placeholder={t("namePlh")}
                            errorInput={businessError.name}
                        />
                    </div>

                </div>
                <div className="container-app-div">
                    <Typography className="app-form-title">{t("logoLbl")} ({t("label:optionalLbl")})</Typography>
                    <div className='image-div'>
                        {!!business.imageUrl &&
                            <>
                                <CardMedia component="img"
                                    image={business.imageUrl}
                                    className="card-img-form"
                                    ref={imgRef}
                                />
                                <br />
                            </>
                        }
                        <div>
                            <Button component="label"
                                className='button-add-image'
                            >
                                <input type="file" accept="image/*"
                                    name="image" onChange={onImageChange}
                                    style={{ display: "none" }}
                                />
                                {t("addImgLbl")}
                            </Button>
                            <br></br>
                            <Typography className="add-image-title">{t("dimensionsLbl")}: 400 x 200 px</Typography>
                        </div>
                    </div>
                    <p className='app-error-text'>{businessError.image}</p>
                </div>
                <div className="container-app-div">

                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div>
                            <Typography className="app-form-title">{t("subtitle4")}</Typography>
                            <Typography className="app-form-subtitle">
                                {t("subtitle5")}
                            </Typography>
                        </div>
                        <div>
                            <SelectFieldComponent name="filter"
                                placeholder={t("filterByStatusPlh")}
                                valueInput={filter}
                                onChange={handleChange}
                                options={[
                                    { key: 'false', value: t("activeStatusLbl") },
                                    { key: 'true', value: t("archivedStatusLbl") }
                                ]}
                                Icon={Filter}
                            />
                        </div>


                    </div>

                    <Box sx={{ width: '100%' }}>
                        <TabContext value={businessTabValue}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <TabList onChange={onChangeTab} variant="scrollable" scrollButtons="auto">
                                    {business.offices.map((item, index) => (
                                        <Tab key={item.id} label={item.name} value={String(index + 2)} />
                                    ))}
                                    <Tab label={t("newOfficeLbl")} value='1' />
                                </TabList>
                            </Box>
                            <div>

                            </div>
                            <TabPanel value='1' className='tab-select-page'>
                                <div>
                                    <div style={{
                                        display: 'flex', flexDirection: 'row', marginBottom: 10,
                                        width: '100%', justifyContent: 'space-between'
                                    }}>
                                        <div style={{ width: '48%' }}>
                                            <InputLabel className="label-app">{t("headerTbl4")}</InputLabel>
                                            <TextFieldComponent name="name"
                                                callback={onChangeOficce.bind(this)}
                                                valueInput={office.name}
                                                errorInput={(businessError.officeList && businessError.officeList.find(error => error.id === 'new'))
                                                    && businessError.officeList.find(error => error.id === 'new').name}
                                            />
                                        </div>
                                        <div style={{ width: '48%' }}>
                                            <InputLabel className="label-app">Email</InputLabel>
                                            <TextFieldComponent name="email"
                                                callback={onChangeOficce.bind(this)}
                                                valueInput={office.email}
                                                errorInput={(businessError.officeList && businessError.officeList.find(error => error.id === 'new'))
                                                    && businessError.officeList.find(error => error.id === 'new').email}
                                            />
                                        </div>
                                    </div>
                                    <div style={{
                                        display: 'flex', flexDirection: 'row', marginBottom: 10,
                                        width: '100%', justifyContent: 'space-between'
                                    }}>
                                        <div style={{ width: '16%' }}>
                                            <InputLabel className="label-app">{t("label:countyCodeLbl")}</InputLabel>
                                            <SelectFieldComponent name="countryCode"
                                                onChange={onChangeOficce.bind(this)}
                                                valueInput={office.countryCode}
                                                options={countries}
                                                errorInput={(businessError.officeList && businessError.officeList.find(error => error.id === 'new'))
                                                    && businessError.officeList.find(error => error.id === 'new').countryCode}
                                            />
                                        </div>
                                        <div style={{ width: '30%' }}>
                                            <InputLabel className="label-app">{t("label:phoneNumberLbl")}</InputLabel>
                                            <TextFieldComponent name="phoneNumber"
                                                maxLength={30}
                                                placeholder='88888888'
                                                callback={onChangeOficce.bind(this)}
                                                valueInput={office.phoneNumber}
                                                errorInput={(businessError.officeList && businessError.officeList.find(error => error.id === 'new'))
                                                    && businessError.officeList.find(error => error.id === 'new').phoneNumber}
                                            />
                                        </div>
                                        <div style={{ width: '48%' }}>
                                            <InputLabel className="label-app">Website</InputLabel>
                                            <TextFieldComponent name="webSite"
                                                placeholder='https://www.fos.com'
                                                callback={onChangeOficce.bind(this)}
                                                valueInput={office.webSite}
                                                errorInput={(businessError.officeList && businessError.officeList.find(error => error.id === 'new'))
                                                    && businessError.officeList.find(error => error.id === 'new').webSite}
                                            />
                                        </div>
                                    </div>
                                    <div style={{ marginBottom: 10 }}>
                                        <InputLabel className="label-app">{t("addressLbl")}</InputLabel>
                                        <TextFieldComponent name="address"
                                            maxLength={150}
                                            callback={onChangeOficce.bind(this)}
                                            valueInput={office.address}
                                            errorInput={(businessError.officeList && businessError.officeList.find(error => error.id === 'new'))
                                                && businessError.officeList.find(error => error.id === 'new').address}
                                        />
                                    </div>
                                    {/*

                                    <div style={{ marginBottom: 10 }}>
                                        <InputLabel className="label-app">{t("gpsLbl")}</InputLabel>
                                        <TextFieldComponent name="GPSCoordinates"
                                            callback={onChangeOficce.bind(this)}
                                            valueInput={office.GPSCoordinates}
                                            errorInput={(businessError.officeList && businessError.officeList.find(error => error.id === 'new'))
                                                && businessError.officeList.find(error => error.id === 'new').GPSCoordinates}
                                        />
                                    </div>
                                    */}
                                    <Button className='button-archive' variant="text" onClick={() => addOffice()}>
                                        {"+" + t("addOfficeLbl")}
                                    </Button>
                                    <Alert severity="error" className='change-pass-alert'>
                                                        {t("createOfficeWarning")}
                                                    </Alert>
                                </div>
                            </TabPanel>
                            {business.offices.map((item, index) => (
                                <TabPanel key={item.id} value={String(index + 2)} className='tab-select-page'>
                                    <div>
                                        <div style={{
                                            display: 'flex', flexDirection: 'row', marginBottom: 10,
                                            width: '100%', justifyContent: 'space-between'
                                        }}>
                                            <div style={{ width: '48%' }}>
                                                <InputLabel className="label-app">{t("headerTbl4")}</InputLabel>
                                                <TextFieldComponent name="name"
                                                    callback={onChangeBusinessOficce.bind(this, item.id)}
                                                    valueInput={item.name}
                                                    errorInput={(businessError.officeList && businessError.officeList.find(error => error.id !== 'new'))
                                                        && businessError.officeList.find(error => error.id === item.id).name}
                                                />
                                            </div>
                                            <div style={{ width: '48%' }}>
                                                <InputLabel className="label-app">Email</InputLabel>
                                                <TextFieldComponent name="email"
                                                    callback={onChangeBusinessOficce.bind(this, item.id)}
                                                    valueInput={item.email}
                                                    errorInput={(businessError.officeList && businessError.officeList.find(error => error.id !== 'new'))
                                                        && businessError.officeList.find(error => error.id === item.id).email}
                                                />
                                            </div>
                                        </div>
                                        <div style={{
                                            display: 'flex', flexDirection: 'row', marginBottom: 10,
                                            width: '100%', justifyContent: 'space-between'
                                        }}>
                                            <div style={{ width: '16%' }}>
                                                <InputLabel className="label-app">{t("label:countyCodeLbl")}</InputLabel>
                                                <SelectFieldComponent name="countryCode"
                                                    onChange={onChangeBusinessOficce.bind(this, item.id)}
                                                    valueInput={item.countryCode}
                                                    options={countries}
                                                    errorInput={(businessError.officeList && businessError.officeList.find(error => error.id !== 'new'))
                                                        && businessError.officeList.find(error => error.id === item.id).countryCode}
                                                />
                                            </div>
                                            <div style={{ width: '30%' }}>
                                                <InputLabel className="label-app">{t("label:phoneNumberLbl")}</InputLabel>
                                                <TextFieldComponent name="phoneNumber"
                                                    maxLength={30}
                                                    callback={onChangeBusinessOficce.bind(this, item.id)}
                                                    valueInput={item.phoneNumber}
                                                    errorInput={(businessError.officeList && businessError.officeList.find(error => error.id !== 'new'))
                                                        && businessError.officeList.find(error => error.id === item.id).phoneNumber}
                                                />
                                            </div>
                                            <div style={{ width: '48%' }}>
                                                <InputLabel className="label-app">Website</InputLabel>
                                                <TextFieldComponent name="webSite"
                                                    callback={onChangeBusinessOficce.bind(this, item.id)}
                                                    valueInput={item.webSite}
                                                    errorInput={(businessError.officeList && businessError.officeList.find(error => error.id !== 'new'))
                                                        && businessError.officeList.find(error => error.id === item.id).webSite}
                                                />
                                            </div>
                                        </div>
                                        <div style={{ marginBottom: 10 }}>
                                            <InputLabel className="label-app">{t("addressLbl")}</InputLabel>
                                            <TextFieldComponent name="address"
                                                maxLength={150}
                                                callback={onChangeBusinessOficce.bind(this, item.id)}
                                                valueInput={item.address}
                                                errorInput={(businessError.officeList && businessError.officeList.find(error => error.id !== 'new'))
                                                    && businessError.officeList.find(error => error.id === item.id).address}
                                            />
                                        </div>
                                        {/*  <div style={{ marginBottom: 10 }}>
                                            <InputLabel className="label-app">{t("gpsLbl")}</InputLabel>
                                            <TextFieldComponent name="GPSCoordinates"
                                                callback={onChangeBusinessOficce.bind(this, item.id)}
                                                valueInput={item.GPSCoordinates}
                                                errorInput={(businessError.officeList && businessError.officeList.find(error => error.id !== 'new'))
                                                    && businessError.officeList.find(error => error.id === item.id).GPSCoordinates}
                                            />
                                        </div>*/}
                                        {(!isCreating && !String(item.id).includes('new-') && !business.disabled) &&
                                            <>
                                                <DeleteModalOffice
                                                    open={openDeleteOffice}
                                                    closeModal={() => setOpenDeleteOffice(false)}
                                                    handleClick={() => dispatch(changeStatusOffice(item.id))}
                                                    buttonMessage={!item.disabled ? t("archiveLbl") : t("enableLbl")}
                                                    textMessage={!item.disabled ? t("textConfirmationA") + t("archiveLbl") + t("textTheOfficeSure") : t("textConfirmationA") + t("enableLbl") + t("textTheOfficeSure")}
                                                />
                                                <Button className='button-archive' variant="text"
                                                    startIcon={<Trash />}
                                                    onClick={() => setOpenDeleteOffice(true)}
                                                >

                                                    {!item.disabled ? t("archiveOfficeLbl") : t("enableOfficeLbl")}


                                                </Button>
                                            </>
                                        }
                                    </div>
                                </TabPanel>
                            ))}
                        </TabContext>
                    </Box>
                    <p className='app-error-text'>{businessError.offices}</p>
                    {(businessError.officeList && businessError.officeList.find(item => item.errors === true)) &&
                        <p className='app-error-text'>
                            {t("textTheOffice") +
                                `${business.offices.find(itemOff =>
                                    itemOff.id === businessError.officeList.find(item => item.errors === true).id).name} ` + t("errorIncorrectData")}
                        </p>
                    }
                </div>
                {!isCreating &&
                    <div className="container-app-div">
                        <Typography className="app-form-title">{t("subtitle6")}</Typography>
                        <TabContext value={clientUserTabValue}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <TabList onChange={onChangeClientTab}>
                                    <Tab label={t("clientLbl")} value="client" />
                                    <Tab label={t("userLbl")} value="user" />
                                </TabList>
                            </Box>
                            <TabPanel value="client" className='tab-select-page-clients'>
                                <div style={{ width: '35%', marginLeft: 30 }}>
                                    <TextFieldComponent icon={<Search />}
                                        name='searchClient' callback={onChangeClient}
                                        position='start' placeholder={t("common:commonSearchPlh")} valueInput={searchClient}
                                    />
                                </div>
                                <TableClients clientList={clients} />
                                <br />
                                <PaginationCustom pages={pagesClient} activePage={activePageClient}
                                    onChange={page => dispatch(changeClientPage(page))} />
                            </TabPanel>
                            <TabPanel value="user" className='tab-select-page-clients'>
                                <div style={{ width: '35%', marginLeft: 30 }}>
                                    <TextFieldComponent icon={<Search />}
                                        name='searchUser' callback={onChangeUser}
                                        position='start' placeholder={t("common:commonSearchPlh")} valueInput={searchUser}
                                    />
                                </div>
                                <TableUsers userList={users} />
                                <br />
                                <PaginationCustom pages={pagesUser} activePage={activePageUser}
                                    onChange={page => dispatch(changeUserPage(page))} />
                            </TabPanel>
                        </TabContext>
                    </div>
                }
                {(!isCreating && businessRedux) &&
                    <div className="container-app-div">
                        <Typography className="app-form-title">{t("optionsLbl")}</Typography>
                        <Button className='button-archive' variant="text"
                            startIcon={<Archive />}
                            onClick={() => setOpenDeleteBusiness(true)}
                        >
                            {!businessRedux.disabled ? t("archiveLbl") : t("enableLbl")}
                        </Button>
                    </div>
                }
                <div style={{ display: 'flex' }}>
                    <Button variant="contained" className="button-app-save" style={{ marginRight: 18 }}
                        onClick={() => onSubmit()}>
                        {t("common:commonSubmitBtn")}
                    </Button>
                    <Button variant="contained" className="button-app-cancel"
                        onClick={() => props.history.push(ROUTES.BUSINESS)}>
                        {t("common:commonCancelBtn")}
                    </Button>
                </div>
            </Grid>
        </Container>
    );
}
export default withRouter(FormBusiness);