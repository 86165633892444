import React from "react";
import { Typography } from "@mui/material";
import IconSvg from '../../../../components/Icon';
import './AccessRowAut.css';

const AccessRowAut = ({ title, Icon = null }) => {

    return (
        <div style={{ display: 'flex', alignItems: 'center', }}>
            <IconSvg Icon={Icon} className="access-icon-svg-row access-icon-row-doc" />
            <Typography className="app-form-subtitle'"
                style={{
                    width: 250,
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden'
                }}>
                {title}
            </Typography>
        </div>
    );
}
export default (AccessRowAut);