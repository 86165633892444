import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import UserForm from '../Form';
import { changeIsCreating, getUserById } from '../../../redux/UserDuck';

const UserUpdate = props => {

    const isCreating = useSelector(store => store.user.isCreating);
    const dispatch = useDispatch();

    useEffect(() => {
        const { id } = props.match.params;
        if (id) {
            dispatch(getUserById(id));
            dispatch(changeIsCreating(false));
        }
    }, [dispatch, props.match.params]);

    return !isCreating && (
        <UserForm />
    )
};

export default withRouter(UserUpdate);