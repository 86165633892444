import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import {
    Modal, Typography, Button,
    TableContainer, TableHead, TableRow,
    TableCell, TableBody, Table, Grid
} from "@mui/material";
import { ReactComponent as CloseCross } from '../../../assets/Icons/closeCross.svg';
import Preview from '../Preview';
import { getHistory, getContentById, publish, changeHistoryPage } from '../../../redux/MobileContentDuck';
import { useDispatch, useSelector } from 'react-redux';
import Moment from 'react-moment';
import * as ROUTES from '../../../constants/routes';
import './Record.css';
import { getFileURL } from '../../../constants/apiUrls';
import { useTranslation } from 'react-i18next';
import PaginationCustom from "../../../components/Pagination";

const Record = (props) => {
    const { t } = useTranslation(['mobileContent', 'common']);


    const { open = false, closeRecord } = props;
    const history = useSelector(store => store.mobile.history);
    const content = useSelector(store => store.mobile.content);
    const activePage = useSelector(store => store.mobile.activePage);
    const pagesHistory = useSelector(store => store.mobile.pages);
    const [recordId, setRecordId] = useState('');
    const [pages, setPages] = useState([]);
    const dispatch = useDispatch();

    const contentType = [
        'TEXT',
        'IMAGE',
        'GALLERY',
        'FORM',
        'MAP',
        'USER_CARD',
        'VIDEO'
    ];

    const editMobileContent = (id) => {
        props.history.push(ROUTES.MOBILE_CONTENT_UPDATE.replace(':id', id));
        closeRecord();
    };

    /*useEffect(() => {
        console.log(history);
    }, [history]);*/

    useEffect(() => {
        dispatch(changeHistoryPage(1));
    }, [dispatch, open]);

    useEffect(() => {
        if (open) {
            setRecordId('');
            dispatch(getHistory([], activePage, 10, true));
        }
    }, [dispatch, open, activePage]);

    useEffect(() => {
        if (recordId !== '') {
            dispatch(getContentById(recordId));
            setPages([]);
        }
    }, [dispatch, recordId]);


    useEffect(() => {
        if (!!content && pages.length === 0) {
            const list = [];
            content.pages.forEach((page, pageIndex) => {
                const pageObject = {
                    id: page.id,
                    pageIndex: String(pageIndex + 2),
                    page: page.name,
                    content: [],
                    default: false
                };
                page.objects.forEach(object => {
                    pageObject.content.push(getContentValue(object.position, object.objectLanguages));
                });
                list.push(pageObject);
            });
            setPages(prev => ([...list]));
        }
        // eslint-disable-next-line
    }, [content]);

    const convertURL = (value) => {
        const embedRegex = new RegExp(/^<iframe[^>]+src=["']([^"']+)["'][^>]*>(?:<\/iframe>)?$/);
        const isIframe = embedRegex.test(value);
        let src = isIframe ? value.match(/src=["']([^"']+)["']/)[1] : value;
        const linkview = /watch\?v=/;
        const replacement = "embed/";
        const isWatchLink = value.match(linkview) ? true : false;
        src = isWatchLink ? src.replace(linkview, replacement) : src;
        const catchId = /embed\/([^?]+)/;
        const youtubeId = src.match(catchId);

        return {
            url: youtubeId ? value : "https://www.youtube.com/embed/" + value,
            ytid: value,
        };
    };

    const getContentValue = (position, objectLanguages) => {
        const contentObject = {
            id: null,
            contentIndex: position,
            contentType: null,
            value: {}
        };
        objectLanguages.forEach(objectLanguage => {
            contentObject.id = objectLanguage.objectId;
            contentObject.contentType = contentType[objectLanguage.type];
            if (contentObject.contentType === 'TEXT') {
                contentObject.value[objectLanguage.languageId] = {
                    id: objectLanguage.textId,
                    text: objectLanguage.text.text1
                };
            } else if (contentObject.contentType === 'IMAGE') {
                contentObject.value[objectLanguage.languageId] = {
                    id: objectLanguage.imageId,
                    file: null,
                    imageUrl: getFileURL + objectLanguage.image.fileId,
                    fileId: objectLanguage.image.fileId,
                    width: objectLanguage.image.file.width,
                    height: objectLanguage.image.file.height,
                };
            } else if (contentObject.contentType === 'GALLERY') {
                const imageList = [];
                objectLanguage.imageGallery.folder.files.forEach(item => {
                    imageList.push({
                        id: objectLanguage.imageGalleryId,
                        fileId: item.id,
                        file: null,
                        imageUrl: getFileURL + item.id,
                        folderId: objectLanguage.imageGallery.folderId,
                        width: item.width,
                        height: item.height,
                    })
                });
                contentObject.value[objectLanguage.languageId] = imageList;
            } else if (contentObject.contentType === 'FORM') {
                const formList = [];
                objectLanguage.form.fields.forEach(item => {
                    formList.push({
                        id: item.id,
                        type: item.type,
                        // placeholder: item.placeholder,
                        placeholder: "",
                        label: item.label,
                        required: item.required,
                        formId: item.formId
                    })
                });
                contentObject.value[objectLanguage.languageId] = formList;
            } else if (contentObject.contentType === 'MAP') {
                contentObject.value[objectLanguage.languageId] = {
                    id: objectLanguage.mapId,
                    map: objectLanguage.map.coordinatesGps
                };
            } else if (contentObject.contentType === 'USER_CARD') {
                contentObject.value[objectLanguage.languageId] = {
                    id: objectLanguage.userCardId,
                    userId: objectLanguage.userCard.userId,
                    fullName: objectLanguage.userCard.fullName,
                    email: objectLanguage.userCard.email,
                    phone: objectLanguage.userCard.phone,
                    position: objectLanguage.userCard.position,
                    downloadable: String(objectLanguage.userCard.downloadable)
                };
            } else if (contentObject.contentType === 'VIDEO') {
                contentObject.value[objectLanguage.languageId] = {
                    id: objectLanguage.videoId,
                    ...convertURL(objectLanguage.video.url),
                };
            }
        });
        return contentObject;
    };

    return (
        <Modal
            open={open}
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }}
        >
            <div className="container-div-modal-record">
                <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 20 }}>
                    <Typography className="record-title">{t("historyLbl")}</Typography>
                    <Button className='button-close-cross' variant="text" onClick={closeRecord} startIcon={<CloseCross />}></Button>
                </div>
                <div style={{
                    maxHeight: 550,
                    overflowY: 'auto'
                }} className='scroll'>
                    <Grid container justifyContent='space-between' style={{ paddingRight: 0 }}>
                        <Grid item xs={8} style={{ paddingRight: 40 }}>
                            <TableContainer>
                                <Table aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell className='table-app-title-header'>{t("headerTbl1")}</TableCell>
                                            <TableCell className='table-app-title-header'>{t("headerTbl2")} - UTC</TableCell>
                                            <TableCell className='table-app-title-header'>Version</TableCell>
                                            <TableCell className='table-app-title-header'>{t("headerTbl3")}</TableCell>
                                            <TableCell className='table-app-title-header'>{t("headerTbl4")}</TableCell>
                                            <TableCell className='table-app-title-header' colSpan={2}></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {history.map(item => (
                                            <TableRow key={item.id} onClick={() => setRecordId(item.id)}
                                                style={{ cursor: 'pointer', background: recordId === item.id ? '#5AB6DF1A' : '' }}>
                                                <TableCell className='table-app-title-header'>
                                                    <Moment style={{ marginTop: '4px' }}
                                                        format="DD/MM/YYYY">
                                                        {item.date}
                                                    </Moment>
                                                </TableCell>
                                                <TableCell className='table-app-title-header'>
                                                    <Moment style={{ marginTop: '4px' }}
                                                        format="h:mm A" utc>
                                                        {item.date}
                                                    </Moment>
                                                </TableCell>
                                                <TableCell className='table-app-title-header'>
                                                    {item.name}
                                                </TableCell>
                                                <TableCell className='table-app-title-header'>
                                                    <span style={{ color: item.status ? '#28A745' : '#8976B6' }}>
                                                        {item.status ? t("publishedLbl") : t("draftLbl")}
                                                    </span>
                                                </TableCell>
                                                <TableCell className='table-app-title-header'>
                                                    {item.user.firstName + ' ' + item.user.lastName}
                                                </TableCell>
                                                {recordId === item.id ?
                                                    <>
                                                        <TableCell className='table-app-title-header'
                                                            style={{ minWidth: 100 }}>
                                                            <Button variant='text' className='button-app-create'
                                                                onClick={() => editMobileContent(item.id)}
                                                            >
                                                                Editar
                                                            </Button>
                                                        </TableCell>
                                                        <TableCell className='table-app-title-header'
                                                            style={{ minWidth: 200 }}>
                                                            {!item.status &&
                                                                <Button variant='text' className='button-app-create'
                                                                    onClick={() => dispatch(publish(item.id))}>
                                                                    {history.some(item => item.status === true)
                                                                        ? t("restoreLbl")
                                                                        : t("publishBtn")
                                                                    }
                                                                </Button>
                                                            }
                                                        </TableCell>
                                                    </>
                                                    :
                                                    <>
                                                        <TableCell className='table-app-title-header'></TableCell>
                                                        <TableCell className='table-app-title-header'></TableCell>
                                                    </>
                                                }
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <br />
                            <PaginationCustom pages={pagesHistory} activePage={activePage}
                                onChange={page => dispatch(changeHistoryPage(page))} />
                        </Grid>
                        <Grid item xs={4}>
                            <Preview pages={pages} />
                        </Grid>
                    </Grid>
                </div>
            </div>
        </Modal>
    );
};

export default withRouter(Record);