import React, { useEffect, useState } from "react";
import {
    Typography, TableContainer, TableRow,
    TableCell, Table, TableHead, TableBody
} from "@mui/material";
import SwitchComponent from "../../../../components/Switch";
import TextFieldComponent from "../../../../components/TextField";
import { ReactComponent as Search } from '../../../../assets/Icons/search.svg';
import './ThirdStep.css';
import { useDispatch, useSelector } from "react-redux";
import { getAssignedClientStaff, changeUserAssignedPage } from "../../../../redux/UserAssignDuck";
import PaginationCustom from "../../../../components/Pagination";
import { useTranslation } from 'react-i18next';
import { getClientsName } from '../../../../utils/functions'

const ThirdStep = ({ setValues, authorization, error }) => {
    const { t } = useTranslation(['authorization', 'common']);

    const assignedClientStaff = useSelector(store => store.assign.assignedClientStaff);
    const activePageAssigned = useSelector(store => store.assign.activePageAssigned);
    const pagesAssigned = useSelector(store => store.assign.pagesAssigned);
    const [resetPageClientAssigned, setResetPageClientAssigned] = useState(false);
    const [searchAssigned, setSearchAssigned] = useState('');
    const dispatch = useDispatch();

    //componentDidMount
    useEffect(() => {
        dispatch(changeUserAssignedPage(1));
        //component will unmount
        return () => {
            dispatch(changeUserAssignedPage(1));
        }
    }, [dispatch]);

    const onSwitch = (id, event) => {
        const { checked } = event.target;
        const list = authorization.receivers;
        if (!checked) {
            const receiver = list.find(d => String(d.id) === String(id));
            const index = list.indexOf(receiver);
            index > -1 && list.splice(index, 1);
        } else {
            const receiver = assignedClientStaff.find(d => String(d.id) === String(id));
            list.push(receiver);
        }
        setValues({ ...authorization, receivers: list });
    };

    const onChangeSearchAssigned = (e) => {
        setSearchAssigned(e.target.value);
        setResetPageClientAssigned(true);
    };

    useEffect(() => {
        const page = resetPageClientAssigned ? 0 : activePageAssigned;
        const filterBy = [
            { search: String(authorization.clientId), filterBy: 'client' },
            { search: '1', filterBy: 'status' }
        ];
        searchAssigned !== '' && filterBy.push({ search: searchAssigned, filterBy: 'search-bar' });

        const timer = setTimeout(() =>
            dispatch(getAssignedClientStaff(filterBy, page, 10, true, 'authorization')), 1000);
        setResetPageClientAssigned(false);
        return () => clearTimeout(timer);
        // eslint-disable-next-line
    }, [dispatch, searchAssigned]);

    useEffect(() => {
        const page = resetPageClientAssigned ? 0 : activePageAssigned;
        const filterBy = [
            { search: String(authorization.clientId), filterBy: 'client' },
            { search: '1', filterBy: 'status' }
        ];
        searchAssigned !== '' && filterBy.push({ search: searchAssigned, filterBy: 'search-bar' });

        dispatch(getAssignedClientStaff(filterBy, page, 10, true, 'authorization'));
        setResetPageClientAssigned(false);
        // eslint-disable-next-line
    }, [dispatch, activePageAssigned]);

    return (
        <div>
            <div className="container-app-div">
                <Typography className="app-form-title">{t("subtitle10")}</Typography>
                <Typography className="app-form-subtitle">{t("subtitle11")}</Typography>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div style={{ width: '35%' }}>
                        <TextFieldComponent Icon={<Search />} position='start'
                            valueInput={searchAssigned}
                            callback={onChangeSearchAssigned}
                            placeholder={t("common:commonSearchPlh")}
                        />
                    </div>
                </div>
                <TableContainer>
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell className='table-app-title-header'>{t("headerTbl6")}</TableCell>
                                <TableCell className='table-app-title-header'>{t("headerTbl7")}</TableCell>
                                <TableCell className='table-app-title-header'>{t("headerTbl8")}</TableCell>
                                <TableCell className='table-app-title-header' align="right"></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {assignedClientStaff.length === 0 &&
                                <TableRow key='empty'>
                                    <TableCell colSpan={4} style={{ textAlign: 'center' }}>
                                        {t("common:commonNoResultsLbl")}
                                    </TableCell>
                                </TableRow>
                            }
                            {assignedClientStaff.map(item => (
                                <TableRow key={item.id}>
                                    <TableCell component="th" scope="row" className={!!authorization.receivers.find(r => String(r.id) === String(item.id)) ? 'authorization-text-active-clients' : 'authorization-text-clients'}>
                                        {item.firstName} {item.lastName}
                                    </TableCell>
                                    <TableCell component="th" scope="row" className={!!authorization.receivers.find(r => String(r.id) === String(item.id)) ? 'authorization-text-active-clients' : 'authorization-text-clients'}>
                                        {item.email}
                                    </TableCell>
                                    <TableCell component="th" scope="row" className={!!authorization.receivers.find(r => String(r.id) === String(item.id)) ? 'authorization-text-active-clients' : 'authorization-text-clients'}>
                                        {item.clients ? getClientsName(item.clients) : ''}
                                    </TableCell>
                                    <TableCell align="right">
                                        <SwitchComponent
                                            callback={onSwitch.bind(this, item.id)}
                                            checked={!!authorization.receivers.find(r => String(r.id) === String(item.id))}
                                            name='receivers'
                                        />
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <br />
                <PaginationCustom pages={pagesAssigned} activePage={activePageAssigned}
                    onChange={page => dispatch(changeUserAssignedPage(page))} />
                {error.receivers &&
                    <p className='app-error-text'>{error.receivers}</p>
                }
            </div>
        </div>
    );
}

export default ThirdStep;