import React, { useState, useEffect } from "react";
import {
    TableCell, TableHead, TableContainer, TableBody,
    Table, TableRow, Typography
} from "@mui/material";
import SwitchComponent from "../../../../components/Switch";
import { useSelector } from "react-redux";
import { useTranslation } from 'react-i18next';
const TableClientAssigment = ({ isCreating = false, activatedClients }) => {
    const { t } = useTranslation(['matter','common']);
    const clientsRedux = useSelector(store => store.assign.clients);
    const clientStaff = useSelector(store => store.assign.clientStaff);
    const [activeClients, setActiveClients] = useState([]);

    const onSwitch = event => {
        if (isCreating) {
            // const { name, checked } = event.target;
            // const list = checked ? [] : activeClients;
            // if (checked) {
            //     list.push(String(name));
            // } else {
            //     const index = list.indexOf(String(name));
            //     index > -1 && list.splice(index, 1);
            // }
            // activatedClients(list);
            // setActiveClients(prev => ([...list]));

            const { name, checked } = event.target;
            const list = activeClients;
            if (checked) {
                list.push(parseInt(name));
            } else {
                const index = list.indexOf(parseInt(name));
                index > -1 && list.splice(index, 1);
            }
            activatedClients(list);
            setActiveClients(prev => ([...list]));
        };
    }

    useEffect(() => {
        if (clientStaff) {
            const clientList = [];
            const clientUsers = clientStaff.clientUsers ? clientStaff.clientUsers : [];
            clientUsers.forEach(item => {
                clientList.push(parseInt(item.clientId));
            });
            setActiveClients(clientList);
        }
    }, [clientStaff]);

    useEffect(() => {
        if (isCreating) {
            setActiveClients([]);
        }
    }, [isCreating]);

    return (
        <>
            <TableContainer>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell className='table-row-info'>{t("common:commonNameLbl")}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {clientsRedux.length === 0 &&
                            <TableRow key='empty'>
                                <TableCell colSpan={1} style={{ textAlign: 'center' }}>
                                {t("common:commonNoResultsLbl")}
                                    </TableCell>
                            </TableRow>
                        }
                        {clientsRedux.map((option) => (
                            <TableRow key={option.name}>
                                <TableCell className="table-row-clients-cell">
                                    <div className='align-divs-clients' >
                                        <Typography className={`activeClients.includes(parseInt(option.id) && "access-text-active-clients"}`} >
                                            {option.name}
                                        </Typography>
                                        <div style={{ marginRight: 18 }}>
                                            <SwitchComponent
                                                callback={onSwitch}
                                                name={option.id}
                                                checked={activeClients.includes(parseInt(option.id))}
                                            />
                                        </div>
                                    </div>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
}
export default (TableClientAssigment);