import axios from 'axios';

const getUserToken = async () => {
    const token = await localStorage.getItem('token')
        ? localStorage.getItem('token') : '';
    return {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
    }
};
const getDownloadToken = async () => {
    // const token = await localStorage.getItem('token')
    //     ? localStorage.getItem('token') : '';
    return {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Accept': 'accept: */*',
            // 'Authorization': `Bearer ${token}`
        },
        responseType: 'arraybuffer'
    }
};

const getPaymentGatewayToken = async () => {
    const token = await localStorage.getItem('token')
        ? localStorage.getItem('token') : '';
         return {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Accept': 'accept: */*',
            'Authorization': `Bearer ${token}`
        },
        responseType:'arraybuffer'
    }
};
export const getAxios = async (url, params) => {
    return await axios.get(url, { params, ...await getUserToken() });
};
export const getBlobAxios = async (url, params) => {
    return await axios.get(url, { params, ...await getDownloadToken() });
};

export const postAxios = async (url, data) => {
    return await axios.post(url, data, await getUserToken());
};
export const postgpAxios = async (url, data,headers) => {
    return await axios.post(url, data,headers);
};

export const postPaymentAxios = async (url, data) => {
    return await axios.post(url, data, await getUserToken());
};

export const patchAxios = async (url, data) => {
    return await axios.patch(url, data, await getUserToken());
};

export const putAxios = async (url, data) => {
    return await axios.put(url, data, await getUserToken());
};

export const deleteAxios = async (url) => {
    return await axios.delete(url, await getUserToken());
};