export const stepOneValidation = (authorization,t) => {
    var error = {};

    if (authorization.title === null || authorization.title.length === 0 ||
        /^\s+$/.test(authorization.title))
        error.title = t("errorTitle");
    if (authorization.title.length > 50)
        error.title = t("errorMax50");

    if (authorization.description === null || authorization.description.length === 0 ||
        /^\s+$/.test(authorization.description))
        error.description = t("errorDescription");
    if (authorization.description.length > 500)
        error.description = t("errorMax500");

    if (authorization.companyId === null || authorization.companyId.length === 0 ||
        /^\s+$/.test(authorization.companyId))
        error.companyId = t("errorCompany");

    if (authorization.officeId === null || authorization.officeId.length === 0 ||
        /^\s+$/.test(authorization.officeId))
        error.officeId = t("errorOffice");

    if (authorization.clientId === null || authorization.clientId.length === 0 ||
        /^\s+$/.test(authorization.clientId))
        error.clientId = t("errorClient");

    return error;
};

export const stepSecondValidation = (authorization,t) => {
    var error = {};
    if (authorization.selectedFiles.length === 0)
        error.selectedFiles = t("errorDoc");
    return error;
};

export const stepThirdValidation = (authorization,t) => {
    var error = {};
    if (authorization.receivers.length === 0)
        error.receivers = t("errorDestination");
    return error;
};

