import { React, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Grid, Button } from "@mui/material";
import Title from "../../../components/Title";
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import FirstStep from "../Steps/FirstStep";
import SecondStep from "../Steps/SecondStep/SecondStep";
import ThirdStep from "../Steps/ThirdStep/ThirdStep";
import FourthStep from "../Steps/FourthStep/FourthStep";
import { saveAuthorization } from "../../../redux/AuthorizationDuck";
import * as ROUTES from '../../../constants/routes';
import './Create.css';
import { withRouter } from "react-router-dom";
import { stepOneValidation, stepThirdValidation } from "./CreateValidation";
import { useTranslation } from 'react-i18next';
const CreateAuthorization = props => {
    const { t } = useTranslation(['authorization', 'common']);
    const isCreated = useSelector(store => store.authorization.isCreated);
    const [step, setStep] = useState(0);
    const [authorization, setAuthorization] = useState({
        title: '', description: '', companyId: '', officeId: '', clientId: '',
        selectedFiles: [], receivers: []
    });

    useEffect(() => {
        setAuthorization({ ...authorization, selectedFiles: [], receivers: [] });
        // eslint-disable-next-line
    }, [authorization.clientId]);

    const [error, setError] = useState({});
    const dispatch = useDispatch();

    const onSubmit = () => {
        dispatch(saveAuthorization(authorization));
    };

    const changeStep = (newStep) => {
        if (step < newStep) {
            if (step === 0) {
                const errorOne = stepOneValidation(authorization, t);
                setError(errorOne);
                if (Object.entries(errorOne).length === 0) {
                    setStep(newStep);
                }
            } else if (step === 1) {
                setStep(newStep);
            } else if (step === 2) {
                const errorThird = stepThirdValidation(authorization, t);
                setError(errorThird);
                if (Object.entries(errorThird).length === 0) {
                    setStep(newStep);
                }
            }
        } else {
            setStep(newStep);
        }
    };

    useEffect(() => {
        (isCreated) && props.history.push(ROUTES.AUTHORIZATIONS);
    }, [isCreated, props.history]);

    return (
        <Container>
            <Title title={t("title")} style={{ marginLeft: 5 }} />
            <Grid item xs={12}>
                <div className="step-container-authorization">
                    <Stepper activeStep={step} alternativeLabel>
                        <Step key={'1'}>
                            <StepLabel>{t("subtitle1")}</StepLabel>
                        </Step>
                        <Step key={'2'}>
                            <StepLabel>{t("subtitle2")}</StepLabel>
                        </Step>
                        <Step key={'3'}>
                            <StepLabel>{t("subtitle3")}</StepLabel>
                        </Step>
                        <Step key={'4'}>
                            <StepLabel>{t("subtitle4")}</StepLabel>
                        </Step>
                    </Stepper>
                </div>
                {
                    step === 0 &&
                    <FirstStep
                        error={error}
                        authorization={authorization}
                        setValues={setAuthorization}
                    />
                }
                {
                    step === 1 &&
                    <SecondStep
                        error={error}
                        authorization={authorization}
                        setValues={setAuthorization}
                    />
                }
                {
                    step === 2 &&
                    <ThirdStep
                        error={error}
                        authorization={authorization}
                        setValues={setAuthorization}
                    />
                }
                {
                    step === 3 &&
                    <FourthStep
                        authorization={authorization}
                        setStep={changeStep}
                    />
                }
                <div style={{ display: 'flex' }}>
                    {step > 0 &&
                        <Button variant="contained" className="button-app-previous" style={{ marginRight: 24 }}
                            onClick={() => changeStep(step - 1)}>
                            {t("common:commonPreviousLbl")}
                        </Button>
                    }
                    {step < 3
                        ?
                        <Button variant="contained" className="button-app-save"
                            onClick={() => changeStep(step + 1)}>
                            {t("common:commonNextLbl")}
                        </Button>
                        :
                        <Button variant="contained" className="button-app-save"
                            onClick={() => onSubmit()}>
                            {t("sendBtn")}
                        </Button>
                    }
                </div>
            </Grid>
        </Container>
    );
}

export default withRouter(CreateAuthorization);