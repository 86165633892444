import React, { useState, useEffect } from 'react';
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';
import './GoogleMap.css';
import { GoogleApiKey } from '../../../../utils/functions'

const googleMapsAPI = GoogleApiKey;

const mapStyles = {
    height: '257px',
    position: 'relative'
};

const GoogleMap = (props) => {
    const zoom = 15;

    const [markerPosition, setMarkerPosition] = useState({
        lat: 9.93593727277584,
        lng: -84.10095092339176
    });

    useEffect(() => {
        const value = props.value;
        const position = value.split(',');
        if (position[0] && position[1])
            setMarkerPosition({ lat: position[0], lng: position[1] })
    }, [props.value]);

    const mapTrack =
        <Map google={props.google} zoom={zoom} style={mapStyles}
            center={{ lat: markerPosition.lat, lng: markerPosition.lng }}
            initialCenter={{ lat: markerPosition.lat, lng: markerPosition.lng }}
        >
            <Marker name='Ubicación' title='Marcador'
                position={{ lat: markerPosition.lat, lng: markerPosition.lng }}
            />
        </Map>

    return (
        <div className='container-google-map'>
            {mapTrack}
        </div>
    );
};

const LoadingContainer = () => (
    <div>Loading...</div>
);

const GoogleWrapper = GoogleApiWrapper({
    apiKey: (googleMapsAPI),
    LoadingContainer: LoadingContainer,
    language: localStorage.getItem('lng-fos') ? (localStorage.getItem('lng-fos') === "1" ? "en" : "es") : "en",
    region: localStorage.getItem('lng-fos') ? (localStorage.getItem('lng-fos') === "1" ? "US" : "CR") : "US"
})(GoogleMap);

export default GoogleWrapper;