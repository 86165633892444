import React from "react";
import {
    TableContainer, TableRow,
    TableCell, Table, TableHead, TableBody
} from "@mui/material";
import { useTranslation } from 'react-i18next';
const TableUsers = ({ userList }) => {
    const { t } = useTranslation(['business','common']);
    return (
        <TableContainer>
            <Table aria-label="simple table" style={{ padding: '0px !important' }}>
                <TableHead>
                    <TableRow>
                        <TableCell className='table-row-info' style={{ paddingLeft: 30 }}>{t("common:commonNameLbl")}</TableCell>
                        <TableCell className='table-row-info'>{t("headerTbl5")}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {userList.length === 0 &&
                        <TableRow key='empty'>
                            <TableCell colSpan={2} style={{ textAlign: 'center' }}>
                            {t("common:commonNoResultsLbl")}
                                </TableCell>
                        </TableRow>
                    }
                    {userList.map(item => (
                        <TableRow key={item.id}>
                            <TableCell component="th" scope="row" className='table-row-name' style={{ paddingLeft: 30 }}>
                                {item.firstName + ' ' + item.lastName}
                            </TableCell>
                            <TableCell component="th" scope="row" className='table-row-name'>
                                {item.organizationUser.position}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

export default TableUsers