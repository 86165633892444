import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { changeIsCreating } from '../../../redux/ClientDuck';
import Form from '../Form';

const CreateCustomer = () => {

    const isCreating = useSelector(store => store.client.isCreating);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(changeIsCreating(true));
    }, [dispatch]);

    return isCreating && (
        <Form />
    );
};

export default CreateCustomer;