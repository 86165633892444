import React, { useState, useEffect } from 'react';
import {
    Typography, TableContainer,
    TableRow, TableCell, Table, TableBody, TableHead, CardMedia
} from "@mui/material";
import { ReactComponent as Image } from '../../../assets/Icons/image.svg';
import { ReactComponent as Document } from '../../../assets/Icons/document.svg';
import { ReactComponent as Folder } from '../../../assets/Icons/folder.svg';
import Title from '../../../components/Title';
import AccessRow from "./AccessRow";
import CircleIcon from '@mui/icons-material/Circle';
import { withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getAuthorizationById } from '../../../redux/AuthorizationDuck';
import { getUsers, changeUserPage } from '../../../redux/UserDuck';
import PaginationCustom from '../../../components/Pagination';
import { getDocuments, changeDocPage } from '../../../redux/DocumentDuck';
import Moment from 'react-moment';
import { getFileURL } from "../../../constants/apiUrls";
import './Detail.css';
import { useTranslation } from 'react-i18next';
const DetailAuthorization = props => {
    const { t } = useTranslation(['authorization', 'common']);
    const authorization = useSelector(store => store.authorization.authorization);

    const users = useSelector(store => store.user.users);
    const activePageUser = useSelector(store => store.user.activePage);
    const pagesUser = useSelector(store => store.user.pages);

    const activePageDoc = useSelector(store => store.doc.activePageDoc);
    const pagesDoc = useSelector(store => store.doc.pagesDoc);
    const documents = useSelector(store => store.doc.documents);

    const [authorizationId, setAuthorizationId] = useState('');
    const dispatch = useDispatch();

    const imageExtensions = [".JPG", ".JPEG", ".GIF", ".PNG", ".TIFF",
        ".TIF", ".RAW", ".BMP", ".PSD", ".EPS", ".PIC",
        ".MOV", ".MKV", ".MP4", ".WMV", ".FLV"];

    //componentDidMount
    useEffect(() => {
        dispatch(changeUserPage(1));
        dispatch(changeDocPage(1));
        //component will unmount
        return () => {
            dispatch(changeUserPage(1));
            dispatch(changeDocPage(1));
        }
    }, [dispatch]);

    const getStaffClientName = (clientsList) => {
        const list = [];
        clientsList.forEach(item => {
            const clientName = item.client.name;
            (clientName && !list.includes(clientName)) && list.push(clientName);
        });
        return list.toString().replace(',', ', ');
    };

    useEffect(() => {
        const { id } = props.match.params;
        setAuthorizationId(id);
        dispatch(getAuthorizationById(id));
    }, [dispatch, props.match.params]);

    useEffect(() => {
        if (authorizationId) {
            const page = activePageUser;
            dispatch(getUsers(
                [{ filterBy: 'authorization', search: String(authorizationId) }],
                page, 10, false, 'authorization'
            ));
        }
        // eslint-disable-next-line
    }, [dispatch, authorizationId, activePageUser]);

    useEffect(() => {
        if (authorizationId) {
            const page = activePageDoc;
            dispatch(getDocuments(
                [{ filterBy: 'authorization', search: String(authorizationId) }],
                '', page, 10, false, 'authorization'
            ));
        }
        // eslint-disable-next-line
    }, [dispatch, authorizationId, activePageDoc]);

    return authorization && (
        <div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <div style={{ marginRight: 21 }}>
                    <Title title={authorization.title} style={{ marginLeft: 5 }} />
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <CircleIcon className="access-icon-svg-active access-icon-active" style={{ marginRight: 7 }} />
                    <Typography style={{ color: authorization.disabled ? '#6C757D' : '#28A745' }}>
                        {authorization.disabled ? t("common:commonStatusArchived") : t("common:commonStatusActive")}
                    </Typography>
                </div>
            </div>
            <div className="container-app-div extra-padding-for-div-business">
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div style={{ display: 'flex' }}>
                        {authorization.client.fileId &&
                            <CardMedia component="img"
                                image={getFileURL + authorization.client.fileId}
                                sx={{
                                    width: 195, height: 67
                                }}
                            />
                        }
                        <div style={{ marginLeft: 37 }}>
                            <div style={{ display: 'flex' }}>
                                <Typography className="big-text-for-business-name-matter"
                                    style={{
                                        color: '#152536', maxWidth: "70%",
                                        whiteSpace: 'nowrap',
                                        textOverflow: 'ellipsis',
                                        overflow: 'hidden'
                                    }}>
                                    {authorization.client.name}
                                </Typography>
                                <Typography className="big-text-for-business-name-matter"
                                    style={{ color: '#6C757D', marginLeft: '7px' }}>
                                    ({authorization.client.clientType ? t("common:commonCompanyLbl") : t("common:commonPersonLbl")})
                                </Typography>
                            </div>
                            <div className='status-row-client-matter'
                                style={{ color: authorization.client.disabled ? '#6C757D' : '#28A745', margin: '9px 0px' }}>
                                <CircleIcon style={{ fontSize: 7, marginRight: 7 }} />
                                <Typography>
                                    {authorization.client.disabled ? t("common:commonStatusArchived") : t("common:commonStatusActive")}
                                </Typography>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-app-div">
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Typography className="app-form-title">{t("subtitle6")}</Typography>
                </div>
                <TableContainer>
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow className="header-cell-matters">
                                <TableCell className="detail-table-cell-notifications" align="right">{t("headerTbl9")}</TableCell>
                                <TableCell sx={{ width: '78%' }} className="detail-table-cell-notifications">
                                    {t("headerTbl10")}
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow key={'id'}>
                                <TableCell component="th" scope="row" className="description-table-cell-notifications" >
                                    {t("headerTbl11")}
                                </TableCell>
                                <TableCell className="detail-table-cell-notifications">
                                    {authorization.title}
                                </TableCell>
                            </TableRow>
                            <TableRow key={'id2'}>
                                <TableCell component="th" scope="row" className="description-table-cell-notifications">
                                    {t("headerTbl12")}
                                </TableCell>
                                <TableCell className="detail-table-cell-notifications">
                                    {authorization.description}
                                </TableCell>
                            </TableRow>
                            <TableRow key={'id3'}>
                                <TableCell component="th" scope="row" className="description-table-cell-notifications">
                                    {t("headerTbl13")}
                                </TableCell>
                                <TableCell className="detail-table-cell-notifications">
                                    {authorization.company && authorization.company.name}
                                </TableCell>
                            </TableRow>
                            <TableRow key={'id4'}>
                                <TableCell component="th" scope="row" className="description-table-cell-notifications">
                                    {t("headerTbl14")}
                                </TableCell>
                                <TableCell className="detail-table-cell-notifications">
                                    {authorization.office && authorization.office.name}
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
            <div className="container-app-div">
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Typography className="app-form-title">{t("subtitle10")}</Typography>
                </div>
                <TableContainer>
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell className='table-app-title-header'>{t("headerTbl6")}</TableCell>
                                <TableCell className='table-app-title-header'>{t("headerTbl7")}</TableCell>
                                <TableCell className='table-app-title-header'>{t("headerTbl8")}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {users.length === 0 &&
                                <TableRow key='empty'>
                                    <TableCell colSpan={3} style={{ textAlign: 'center' }}>
                                        {t("common:commonNoResultsLbl")}
                                    </TableCell>
                                </TableRow>
                            }
                            {users.map(item => (
                                <TableRow key={item.id}>
                                    <TableCell component="th" scope="row" className='app-form-subtitle'>
                                        {item.firstName + ' ' + item.lastName}
                                    </TableCell>
                                    <TableCell component="th" scope="row" className='app-form-subtitle'>
                                        {item.email}
                                    </TableCell>
                                    <TableCell component="th" scope="row" className='app-form-subtitle'>
                                        {item && getStaffClientName(item.clientUsers)}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <br />
                <PaginationCustom pages={pagesUser} activePage={activePageUser}
                    onChange={page => dispatch(changeUserPage(page))} />
            </div>
            <div className="container-app-div">
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Typography className="app-form-title">{t("subtitle12")}</Typography>
                </div>
                <TableContainer>
                    <Table aria-label="simple table" id="files-table">
                        <TableHead>
                            <TableRow>
                                <TableCell className='table-app-title-header'>{t("headerTbl1")}</TableCell>
                                <TableCell className='table-app-title-header'>{t("headerTbl2")}</TableCell>
                                <TableCell className='table-app-title-header'>{t("headerTbl3")}</TableCell>
                                <TableCell className='table-app-title-header'>{t("headerTbl4")}</TableCell>
                                <TableCell className='table-app-title-header'>{t("headerTbl5")}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {documents.length === 0 &&
                                <TableRow key='empty'>
                                    <TableCell colSpan={5} style={{ textAlign: 'center' }}>
                                        {t("common:commonNoResultsLbl")}
                                    </TableCell>
                                </TableRow>
                            }
                            {documents.map(item => (

                                <TableRow class="document-list-row" key={item.id}>
                                    <TableCell component="th" scope="row" className='app-form-subtitle'>
                                        <AccessRow
                                            title={item.fileName}
                                            Icon={imageExtensions.includes(item.fileExtension.toUpperCase().trim())
                                                ?
                                                Image
                                                :
                                                Document
                                            }
                                        />
                                    </TableCell>
                                    <TableCell component="th" scope="row"  >
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <Folder style={{ marginRight: '10.14px' }} />
                                            <Typography className='folder-title-cel-text'
                                                style={{
                                                    width: 180,
                                                    whiteSpace: 'nowrap',
                                                    textOverflow: 'ellipsis',
                                                    overflow: 'hidden'
                                                }}>
                                                {item.folder.name}
                                            </Typography>
                                        </div>
                                    </TableCell>

                                    <TableCell component="th" scope="row" className='app-form-subtitle'>
                                        <div style={{ width: "160px", textOverflow: "ellipsis", overflow: "hidden" }}>
                                            {item.uploadedByUser.firstName + ' ' + item.uploadedByUser.lastName}

                                        </div>
                                    </TableCell>
                                    <TableCell component="th" scope="row" className='app-form-subtitle' >
                                        {item.fileExtension.toUpperCase().trim()}
                                    </TableCell>
                                    <TableCell component="th" scope="row" className='app-form-subtitle'>
                                        <Moment style={{ marginTop: '4px' }}
                                            format="DD/MM/YYYY">
                                            {item.created}
                                        </Moment>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <br />
                <PaginationCustom pages={pagesDoc} activePage={activePageDoc}
                    onChange={page => dispatch(changeDocPage(page))} />
            </div>
        </div>
    );
}

export default withRouter(DetailAuthorization);