import React from 'react';
import TextFieldComponent from '../../../components/TextField';
import { InputLabel } from '@mui/material';
import { useTranslation } from "react-i18next";
import "./PaymentForm.css";

const PaymentForm = ({ form, onChangeForm, error  }) => {
    const { t } = useTranslation(['userProfile', 'common', 'subscription', 'validations', 'userProfile']);
   
    const minDate = () => {
        const actualDate = new Date().toISOString().split("T")[0];
        const splitDate = actualDate.split("-", 2)
        return splitDate[0] + "-" + splitDate[1];
    };

    return (
        <div style={{display: "flex", flexDirection: "column", width: "100%" }}>
            <InputLabel className="label-app">
            {t("subscription:CardHolder")}
            </InputLabel>
            <TextFieldComponent name="cardHolderName"
                type="text"
                valueInput={form.cardHolderName}
                callback={onChangeForm}
                errorInput={error.cardHolderName}
            />
            <InputLabel className="label-app">
               {t("subscription:CardNum")}
            </InputLabel>
            <TextFieldComponent name="cardN"
            className="noarrows"
                type="number"
                inputProps = {{
                    "min":"0",
                    
                    "maxLength":"16"
                 }}
                valueInput={form.cardN}
                callback={onChangeForm}
                errorInput={error.cardN}
            />
            <InputLabel className="label-app">
            {t("subscription:CardCVV")}
            </InputLabel>
            <TextFieldComponent name="cvv"
                type="number"
                valueInput={form.cvv}
                className="noarrows"
            
                callback={onChangeForm}
                errorInput={error.cvv}
                inputmode="numeric" 
                inputProps = {{
                    "min":"00",
                    "max":"9999",
                    "maxLength":"4"
                 }}
            />
            <div className="subscriptionformField" style={{display: 'flex',
alignItems: 'flex-start',
marginTop: '34px'}}>
                                    
                                    <label className=" subscription-form label"> {t("subscription:CardExpiration")}:</label>
                                    <div className="expiration-selectors">
                                        <div>

                                    <label className=" subscription-form label"> {t("subscription:CardMonth")}</label>
                                    <TextFieldComponent name="expMonth"
                                        className="subsText noarrows"
                                        type="number"
                                        valueInput={form.expMonth}
                                        inputProps = {{
                                            "min":"01",
                                            "max":"12",
                                            "maxLength":"2"
                                         }}
                                        placeholder="MM"
                                        inputmode="numeric" 
                                        callback={onChangeForm}
                                        errorInput={error.expMonth}
                                    />  
                                        </div>
                                        <div>

                                     <label className=" subscription-form label"> {t("subscription:CardYear")}</label>
                                    <TextFieldComponent name="expYear"
                                        className="subsText noarrows"
                                        type="number"
                                        valueInput={form.expYear}
                                        placeholder="YYYY"
                                        inputProps = {{
                                           "min":"2023",
                                           "max":"2039",
                                           "maxLength":"4"
                                        }}
                                       
                                        inputmode="numeric" 
                                        maxLength={4}
                                        callback={onChangeForm}
                                        errorInput={error.expYear}
                                    />    
                                        </div>
                                      
                                    </div>
                                </div>
        </div>
    )
};

export default PaymentForm;