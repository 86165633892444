import React, { useEffect, Suspense } from 'react';
import {
  useSelector,
  useDispatch
} from 'react-redux';
import {
  BrowserRouter as Router, Route, Switch,
  useLocation
} from 'react-router-dom';
import { ProtectedRoute } from './services/Middleware';
import Loading from './components/Loading';
import Sidebar from './components/Sidebar';
import Login from './views/Login';
import ForgetPassword from './views/ForgetPassword';
import ResetPassword from './views/ResetPassword';
import Dashboard from './views/Dashboard';
import SystemConfiguration from './views/SystemConfiguration';
import AppConfiguration from './views/AppConfiguration';
import Business from './views/Business';
import BusinessCreate from './views/Business/Create';
import BusinessUpdate from './views/Business/Update';
import Permission from './views/Permission';
import User from './views/User';
import UserUpdate from './views/User/Update';
import UserCreate from './views/User/Create';
import DataDeletion from './views/DataDeletion';
import Logs from './views/Logs';
import Customer from './views/Customer';
import ClientDetail from './views/Customer/Detail';
import ClientCreate from './views/Customer/Create';
import ClientUpdate from './views/Customer/Update';
import UserAssigment from './views/UserAssigment';
import UserProfile from './views/UserProfile';
import CustomerStaff from './views/CustomerStaff';
import Matters from './views/Matters';
import MattersCreate from './views/Matters/Create';
import MattersDetail from './views/Matters/Details';
import Notifications from './views/Notifications';
import NotificationDetail from './views/Notifications/Details';
import NotificationCreate from './views/Notifications/Create';
import Authorizations from './views/Authorizations';
import AuthorizationsCreate from './views/Authorizations/Create';
import AuthorizationsDetail from './views/Authorizations/Detail';
import MobileContent from './views/MobileContent';
import Documents from './views/Documents';
import { logoutAction } from './redux/AuthDuck'
import SnackbarResponse from './components/SnackbarResponse';
import * as ROUTES from './constants/routes';
import i18n from 'i18next';
import HelpModal from './views/Help/HelpModal';
import './App.css';
import HelpSetting from './views/Help/HelpSetting';
import HelpAppSetting from './views/Help/HelpAppSetting';
import HelpBusiness from './views/Help/HelpBusiness';
import HelpUser from './views/Help/HelpUser';
import HelpPermission from './views/Help/HelpPermission';
import HelpLog from './views/Help/HelpLog';
import HelpCustomer from './views/Help/HelpCustomer';
import HelpUserAssignment from './views/Help/HelpUserAssignment';
import HelpCustomerStaff from './views/Help/HelpCustomerStaff';
import HelpMatter from './views/Help/HelpMatter';
import HelpNotification from './views/Help/HelpNotification';
import HelpAuthorization from './views/Help/HelpAuthorization';
import HelpDocument from './views/Help/HelpDocument';
import HelpMobileContent from './views/Help/HelpMobileContent';
import $ from 'jquery';

const NoMatch = () => {
  const location = useLocation();

  return (
    <div>
      <h3>
        No match for <code>{location.pathname}</code>
      </h3>
    </div>
  );
}

const App = () => {

  const isLogged = useSelector(store => store.auth.isLogged);
  const expiration = useSelector(store => store.auth.expiration);
  const lng = useSelector(store => store.auth.lenguageId);
  const dispatch = useDispatch();

  const tokenExpiration = () => {
    if (isLogged) {
      const expirationDate = new Date(expiration);
      expirationDate.setTime(expirationDate.getTime() + expirationDate.getTimezoneOffset() * 60 * 1000);
      const actualDate = new Date();
      actualDate.setTime(actualDate.getTime() + actualDate.getTimezoneOffset() * 60 * 1000);
      if (expirationDate.getTime() < actualDate.getTime()) {
        dispatch(logoutAction());
      }
    }
  };

  useEffect(() => {
    const timer = setInterval(tokenExpiration, 6000);
    return () => clearTimeout(timer);
  });

  useEffect(() => {
    i18n.changeLanguage(lng ? (parseInt(lng) === 1 ? 'en' : 'es') : 'en');
  }, [lng]);

  $(document).ready(function () {
    $("button").click(function () {
      $("button").prop("disabled", false);
    });
  });

  return (
    <div>
      <Suspense fallback=''>
        <Loading />
        <SnackbarResponse />
        <Router>
          <Switch>
            <Route exact path={ROUTES.LOGIN} component={Login} />
            <Route exact path={ROUTES.DATA_DELETION} component={DataDeletion} />
            <Route exact path={ROUTES.FORGET_PASSWORD} component={ForgetPassword} />
            <Route exact path={ROUTES.RESET_PASSWORD} component={ResetPassword} />
            <Sidebar>
              <ProtectedRoute exact path={ROUTES.DASHBOARD} component={Dashboard} />
              <ProtectedRoute exact path={ROUTES.HOME} component={Dashboard} />

              <ProtectedRoute exact path={ROUTES.HELP_SYSTEM_CONFIGURATION} component={HelpSetting} />
              <ProtectedRoute exact path={ROUTES.HELP_APP_CONFIGURATION} component={HelpAppSetting} />
              <ProtectedRoute exact path={ROUTES.HELP_BUSINESS} component={HelpBusiness} />
              <ProtectedRoute exact path={ROUTES.HELP_USERS} component={HelpUser} />
              <ProtectedRoute exact path={ROUTES.HELP_PERMITS} component={HelpPermission} />
              <ProtectedRoute exact path={ROUTES.HELP_LOGS} component={HelpLog} />
              <ProtectedRoute exact path={ROUTES.HELP_CLIENTS} component={HelpCustomer} />
              <ProtectedRoute exact path={ROUTES.HELP_USER_ASSIGNMENT} component={HelpUserAssignment} />
              <ProtectedRoute exact path={ROUTES.HELP_CUSTOMER_STAFF} component={HelpCustomerStaff} />
              <ProtectedRoute exact path={ROUTES.HELP_MATTERS} component={HelpMatter} />
              <ProtectedRoute exact path={ROUTES.HELP_NOTIFICATIONS} component={HelpNotification} />
              <ProtectedRoute exact path={ROUTES.HELP_AUTHORIZATIONS} component={HelpAuthorization} />
              <ProtectedRoute exact path={ROUTES.HELP_DOCUMENTS} component={HelpDocument} />
              <ProtectedRoute exact path={ROUTES.HELP_MOBILE_CONTENT} component={HelpMobileContent} />

              <ProtectedRoute exact path={ROUTES.SYSTEM_CONFIGURATION} component={SystemConfiguration} />
              <ProtectedRoute exact path={ROUTES.APP_CONFIGURATION} component={AppConfiguration} />
              <ProtectedRoute exact path={ROUTES.BUSINESS} component={Business} />
              <ProtectedRoute exact path={ROUTES.BUSINESS_UPDATE} component={BusinessUpdate} />
              <ProtectedRoute exact path={ROUTES.BUSINESS_CREATE} component={BusinessCreate} />
              <ProtectedRoute exact path={ROUTES.USERS} component={User} />
              <ProtectedRoute exact path={ROUTES.USER_PROFILE} component={UserProfile} />
              <ProtectedRoute exact path={ROUTES.USERS_CREATE} component={UserCreate} />
              <ProtectedRoute exact path={ROUTES.USERS_UPDATE} component={UserUpdate} />
              <ProtectedRoute exact path={ROUTES.PERMITS} component={Permission} />
              <ProtectedRoute exact path={ROUTES.LOGS} component={Logs} />

              <ProtectedRoute exact path={ROUTES.CLIENTS} component={Customer} />
              <ProtectedRoute exact path={ROUTES.CLIENT_DETAIL} component={ClientDetail} />
              <ProtectedRoute exact path={ROUTES.CLIENT_CREATE} component={ClientCreate} />
              <ProtectedRoute exact path={ROUTES.CLIENT_UPDATE} component={ClientUpdate} />

              <ProtectedRoute exact path={ROUTES.USER_ASSIGNMENT} component={UserAssigment} />
              <ProtectedRoute exact path={ROUTES.CUSTOMER_STAFF} component={CustomerStaff} />

              <ProtectedRoute exact path={ROUTES.MATTERS} component={Matters} />
              <ProtectedRoute exact path={ROUTES.MATTERS_CREATE} component={MattersCreate} />
              <ProtectedRoute exact path={ROUTES.MATTERS_DETAIL} component={MattersDetail} />

              <ProtectedRoute exact path={ROUTES.NOTIFICATIONS} component={Notifications} />
              <ProtectedRoute exact path={ROUTES.NOTIFICATIONS_CREATE} component={NotificationCreate} />
              <ProtectedRoute exact path={ROUTES.NOTIFICATIONS_DETAIL} component={NotificationDetail} />

              <ProtectedRoute exact path={ROUTES.AUTHORIZATIONS} component={Authorizations} />
              <ProtectedRoute exact path={ROUTES.AUTHORIZATIONS_CREATE} component={AuthorizationsCreate} />
              <ProtectedRoute exact path={ROUTES.AUTHORIZATIONS_DETAIL} component={AuthorizationsDetail} />
              <ProtectedRoute exact path={ROUTES.MOBILE_CONTENT} component={MobileContent} />
              <ProtectedRoute exact path={ROUTES.MOBILE_CONTENT_UPDATE} component={MobileContent} />
              <ProtectedRoute exact path={ROUTES.DOCUMENTS} component={Documents} />
              <HelpModal />
            </Sidebar>
            <Route path="*">
              <NoMatch />
            </Route>
          </Switch>
        </Router>
      </Suspense>
    </div>
  )
};

export default App;
