import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { changeIsCreating, getClientById } from '../../../redux/ClientDuck';
import Form from '../Form';

const UpdateCustomer = props => {

    const isCreating = useSelector(store => store.client.isCreating);
    const dispatch = useDispatch();

    useEffect(() => {
        const { id } = props.match.params;
        if (id) {
            dispatch(changeIsCreating(false));
            dispatch(getClientById(id));
        }
    }, [dispatch, props.match.params]);

    return !isCreating && (
        <Form />
    )
};

export default withRouter(UpdateCustomer);