export const custStaffValidation = (cust, clients, lastStep, t) => {
    var error = {};
    const onlyLetters = /^[a-zA-Z0-9!@#$%^&*()_+\-=[\]{};':"\\|,íúóéáñ./\n/\r/\t<>/?]*$/;

    if (cust.firstName === null || cust.firstName.length === 0 ||
        /^\s+$/.test(cust.firstName))
        error.firstName = t("error:requiredName");
    if (cust.lastName === null || cust.lastName.length === 0 ||
        /^\s+$/.test(cust.lastName))
        error.lastName = t("error:requiredlastname");
    if (cust.email === null || cust.email.length === 0 ||
        /^\s+$/.test(cust.email))
        error.email = t("error:requiredEmail");
    else if (!validateCustEmail(cust.email))
        error.email = t("error:formatEmail");


    if (cust.countryCodeId || cust.phoneNumber) {
        if (cust.countryCodeId === null || cust.countryCodeId.length === 0 ||
            /^\s+$/.test(cust.countryCodeId))
            error.countryCodeId = t("error:requiredCountryCode");
        if (cust.phoneNumber === null || cust.phoneNumber.length === 0 ||
            /^\s+$/.test(cust.phoneNumber))
            error.phoneNumber = t("error:requiredPhoneNumber");
        else if (!cust.phoneNumber.replaceAll(" ", "").match(onlyLetters))
            error.phoneNumber = t("error:formatPhoneNumber");

    }

    if (lastStep) {
        if (clients.length === 0) {
            error.activeClients = t("errorClientsRequired");
        }
    }
    return error;
};

const validateCustEmail = ((email) => {
    const regex = /^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    return regex.test(email) ? true : false;
});