import React from "react";
import { Container, Grid, Typography, Card, CardMedia } from "@mui/material";
import helpImage29 from '../../assets/Images/help29.png';
import helpImage30 from '../../assets/Images/help30.png';
import { useTranslation } from "react-i18next";
import './Help.css';

const HelpDocument = () => {

    const { t } = useTranslation(['help']);

    return (
        <Container>
        <Typography className="admin-business-text" style={{ marginBottom: '20px' }}>
                            {t('help:document:category')+"|"+t('help:document:title')}
                        </Typography>

            <Grid item xs={12}>
                <div className="container-app-div help-screen header" style={{ marginTop: 10 }}>
                    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                        <p className="paragraph-text strong" style={{ marginBottom: '20px' }} >
                            {t('help:thisPageLbl')}
                        </p>
                        <label className="help-label-text">
                        <span className="enum">1</span>  {t('help:document:lbl1')}
                        </label>
                        <label className="help-label-text">
                        <span className="enum">2</span>  {t('help:document:lbl2')}
                        </label>
                        </div><div>
                        <label className="help-label-text">
                            {t('help:document:lbl3')}
                        </label>
                        <label className="help-label-text">
                            {t('help:document:lbl4')}
                        </label>
                        <br />
                    </div>
                    </div>
                    <div className="container-app-div" style={{ marginTop: 10,display: 'flex', flexDirection: 'row'  }}>
                            <div style={{ width: '60%', display: 'flex', flexDirection: 'column' }}>
                                <p className="paragraph-text">
                                {t('help:step1')}<span className="enum">1</span>:&nbsp;{t('help:document:lbl5')}
                                </p>
                                  <label className="help-label-text">
                                    {t('help:document:lbl6')}
                                </label>
                                <label className="help-label-text p">
                                    {t('help:document:lbl7')}
                                </label>
                                <label className="help-label-text p">
                                    {t('help:document:lbl8')}
                                </label>
                            </div>
                            <div style={{
                                width: '40%', display: 'flex',
                                justifyContent: 'center', alignItems: 'center'
                            }}>
                                <Card sx={{ maxWidth: 230, height: 90, boxShadow: 'none' }}>
                                    <CardMedia
                                        component="img"
                                        image={helpImage29}
                                        alt="img"
                                        style={{ objectFit: 'contain' }}
                                    />
                                </Card>
                            </div>
                        
                        </div>
                        <div className="container-app-div" style={{ marginTop: 10,display: 'flex', flexDirection: 'row'  }}>
                            <div style={{ width: '60%', display: 'flex', flexDirection: 'column' }}>
                                <p className="paragraph-text">
                                {t('help:step1')}<span className="enum">2</span>:&nbsp;{t('help:document:lbl9')}
                                </p>
                                <label className="help-label-text">
                                    {t('help:document:lbl10')}
                                </label>
                                <label className="help-label-text ">
                                    {t('help:document:lbl11')}
                                </label>
                            </div>
                            <div style={{
                                width: '40%', display: 'flex',
                                justifyContent: 'center', alignItems: 'center'
                            }}>
                                <Card sx={{ maxWidth: 230, height: 90, boxShadow: 'none' }}>
                                    <CardMedia
                                        component="img"
                                        image={helpImage30}
                                        alt="img"
                                        style={{ objectFit: 'contain' }}
                                    />
                                </Card>
                            </div>
                        </div>
                    
            </Grid>
        </Container>
    );
}
export default HelpDocument;