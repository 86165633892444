import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from 'react-redux';
import {
    Typography, TableRow, TableCell, Table,
    TableBody, TableHead, Button, Divider,
    Modal
} from "@mui/material";
import { ReactComponent as Image } from '../../../assets/Icons/image.svg';
import { ReactComponent as Document } from '../../../assets/Icons/document.svg';
import { ReactComponent as Folder } from '../../../assets/Icons/folder.svg';
import { ReactComponent as Trash } from '../../../assets/Icons/trash.svg';
import { ReactComponent as Search } from '../../../assets/Icons/search.svg';
import { ReactComponent as CloseCross } from '../../../assets/Icons/closeCross.svg';
import TextFieldComponent from "../../../components/TextField";
import Moment from 'react-moment';
import Checkbox from "../../../components/Checkbox";
import PaginationCustom from "../../../components/Pagination";
import { changeDocPage, getDocuments } from "../../../redux/DocumentDuck";
import { useTranslation } from 'react-i18next';
import AccessRowAut from '../Common/AccessRowAut'
import { subjectAddFile } from "../../../redux/SubjectDuck";

const AddFiles = (props) => {

    const { open = false, closeModal, clientId, subjectId } = props;
    const handleClose = () => {
        setSelectedDocs([]);
        closeModal();
    }


    const { t } = useTranslation(['matter', 'common']);
    const pagesDoc = useSelector(store => store.doc.pagesDoc);
    const activePageDoc = useSelector(store => store.doc.activePageDoc);
    const documents = useSelector(store => store.doc.documents);

    const [resetPageDoc, setResetPageDoc] = useState(false);
    const [search, setSearch] = useState('');
    const [selectedDocs, setSelectedDocs] = useState([]);
    const [activePageSelectedDoc, setActivePageSelectedDoc] = useState(0);
    const dispatch = useDispatch();

    const obj_per_page = 10;
    const imageExtensions = [".JPG", ".JPEG", ".GIF", ".PNG", ".TIFF",
        ".TIF", ".RAW", ".BMP", ".PSD", ".EPS", ".PIC",
        ".MOV", ".MKV", ".MP4", ".WMV", ".FLV"];

    //componentDidMount
    useEffect(() => {
        dispatch(changeDocPage(1));
        //component will unmount
        return () => {
            dispatch(changeDocPage(1));
        }
    }, [dispatch]);

    const onChangeSearch = e => {
        setSearch(e.target.value);
        setResetPageDoc(true);
    };

    const onSwitch = (value, event) => {
        const { checked } = event.target;
        const list = selectedDocs;
        if (!checked) {
            const doc = list.find(d => String(d.id) === String(value));
            const index = list.indexOf(doc);
            index > -1 && list.splice(index, 1);
            if (!list[activePageSelectedDoc * obj_per_page] && activePageSelectedDoc > 0) {
                setActivePageSelectedDoc(activePageSelectedDoc - 1);
            }
        }
        else {
            const doc = documents.find(d => String(d.id) === String(value));
            list.push(doc);
        }
        setSelectedDocs(prev => ([...list]));
    };

    const removeFile = (id) => {
        const list = selectedDocs;
        const doc = list.find(d => String(d.id) === String(id));
        const index = list.indexOf(doc);
        index > -1 && list.splice(index, 1);
        setSelectedDocs(prev => ([...list]));

        if (!list[activePageSelectedDoc * obj_per_page] && activePageSelectedDoc > 0) {
            setActivePageSelectedDoc(activePageSelectedDoc - 1);
        }
    };

    const onSearchDoc = useCallback((searchDoc) => {
        const timer = setTimeout(() => {
            const page = resetPageDoc ? 0 : activePageDoc;
            const filterBy = [{ search: String(clientId), filterBy: 'client' }];
            searchDoc !== '' && filterBy.push({ search: searchDoc, filterBy: 'search-bar-subject' });
            dispatch(getDocuments(filterBy, "recent-files", page, obj_per_page, true, 'subject'));
            setResetPageDoc(false);
        }, 1000);
        return () => clearTimeout(timer);
        // eslint-disable-next-line
    }, [dispatch]);

    useEffect(() => {
        const page = resetPageDoc ? 0 : activePageDoc;
        const filterBy = [{ search: String(clientId), filterBy: 'client' }];
        search !== '' && filterBy.push({ search: search, filterBy: 'search-bar-subject' });
        dispatch(getDocuments(filterBy, "recent-files", page, obj_per_page, true, 'subject'));
        setResetPageDoc(false);
        // eslint-disable-next-line
    }, [dispatch, activePageDoc]);

    useEffect(() => {
        if (!!open) {
            onSearchDoc(search);
        }
        // eslint-disable-next-line
    }, [onSearchDoc, open]);

    const totalNumPages = () => {
        return Math.ceil(selectedDocs.length / obj_per_page);
    };

    const changeSelectedDocPage = (page) => {
        setActivePageSelectedDoc(page - 1);
    };

    const drawRowSelectedDocs = useCallback((page, selectedFiles) => {
        const list = [];
        for (let index = page * obj_per_page; index < (page * obj_per_page) + obj_per_page; index++) {
            if (selectedFiles.length > index) {
                list.push(selectedFiles[index]);
            }
        }
        setSelectedDocs(prev => ([...list]));
    }, []);

    useEffect(() => {
        drawRowSelectedDocs(activePageSelectedDoc, selectedDocs);
        // eslint-disable-next-line 
    }, [drawRowSelectedDocs, activePageSelectedDoc, selectedDocs.length]);

    const onSubmit = () => {
        dispatch(subjectAddFile(subjectId, selectedDocs));
        setSelectedDocs([]);
        setSearch('');
        closeModal();
    };

    return (
        <Modal
            open={open}
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                zIndex: 10000
            }}
        >
            <div style={{
                width: '80%',
                height: 'calc(100vh - 10%)', padding: '15px 30px',
                background: '#F8F9FA', borderRadius: 20,
            }}>
                <Button className='button-close-cross' variant="text"
                    style={{ float: 'right', marginTop: 10 }}
                    onClick={handleClose} startIcon={<CloseCross />}></Button>
                <div style={{
                    maxHeight: 'calc(100vh - 15%)',
                    overflowY: 'auto', marginTop: 40
                }} className='scroll'>
                    <div className="container-app-div" style={{ marginRight: 10, marginTop: 0 }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 42 }}>
                            <Typography className="app-form-title">{t("selectFilesLbl")}</Typography>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <div style={{ width: '85%', marginRight: 20 }}>
                                <TextFieldComponent Icon={<Search />} position='start'
                                    placeholder={t("searchFileOrFolderLbl")}
                                    valueInput={search}
                                    callback={onChangeSearch.bind(this)}
                                />
                            </div>
                            <div>
                                <Button variant="contained" className="button-app-save"
                                    onClick={() => onSearchDoc(search)}>
                                    {t("common:commonSearchPlh")}
                                </Button>
                            </div>
                        </div>
                        <Divider className="divider-line" style={{ marginTop: 26, marginBottom: 29 }} />
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <div style={{ width: '35%' }}>
                                <Typography className="doc-title-text">{t("common:commonFileLbl") + "s"}</Typography>
                            </div>
                        </div>
                        <Divider className="divider-line" style={{ marginTop: 29 }} />
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell className='table-app-title-header'>{t("common:commonNameLbl")}</TableCell>
                                    <TableCell className='table-app-title-header'>{t("common:commonFolderLbl")}</TableCell>
                                    <TableCell className='table-app-title-header'>{t("common:commonCreatedByLbl")}</TableCell>
                                    <TableCell className='table-app-title-header'>{t("common:commonTypeLbl")}</TableCell>
                                    <TableCell className='table-app-title-header'>{t("common:commonDateLbl")}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {documents.length === 0 &&
                                    <TableRow key='empty'>
                                        <TableCell colSpan={5} style={{ textAlign: 'center' }}>
                                            {t("common:commonNoResultsLbl")}
                                        </TableCell>
                                    </TableRow>
                                }
                                {documents.map(item => (
                                    <TableRow key={item.id}>
                                        <TableCell component="th" scope="row" className='app-form-subtitle'>
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <Checkbox
                                                    name='documents'
                                                    onChange={onSwitch.bind(this, item.id)}
                                                    value={!!selectedDocs.find(f => String(f.id) === String(item.id))}
                                                    checkboxCheckedValue={true}
                                                />
                                                <AccessRowAut
                                                    title={item.fileName}
                                                    Icon={imageExtensions.includes(item.fileExtension.toUpperCase().trim())
                                                        ? Image : Document}
                                                />
                                            </div>
                                        </TableCell>
                                        <TableCell component="th" scope="row"  >
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <Folder style={{ marginRight: '10.14px' }} />
                                                <Typography className='folder-title-cel-text'>
                                                    {item.folder.name}
                                                </Typography>
                                            </div>
                                        </TableCell>
                                        <TableCell component="th" scope="row" className='app-form-subtitle'>
                                            {item.uploadedByUser.firstName + ' ' + item.uploadedByUser.lastName}
                                        </TableCell>
                                        <TableCell component="th" scope="row" className='app-form-subtitle'>
                                            {item.fileExtension.replace(".", "").toUpperCase()}
                                        </TableCell>
                                        <TableCell component="th" scope="row" className='app-form-subtitle'>
                                            <Moment className="app-form-subtitle" style={{ marginTop: '4px' }}
                                                format="DD/MM/YYYY">
                                                {item.created}
                                            </Moment>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                        <div style={{ marginTop: 24 }}>
                            <PaginationCustom pages={pagesDoc} activePage={activePageDoc}
                                onChange={page => dispatch(changeDocPage(page))} />
                        </div>
                    </div>
                    <div className="container-app-div" style={{ marginRight: 10 }}>
                        <Typography className="app-form-title">{t("selectedFilesLbl")}</Typography>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell className='table-app-title-header'>{t("common:commonNameLbl")}</TableCell>
                                    <TableCell className='table-app-title-header'>{t("common:commonFolderLbl")}</TableCell>
                                    <TableCell className='table-app-title-header'>{t("common:commonCreatedByLbl")}</TableCell>
                                    <TableCell className='table-app-title-header'>{t("common:commonTypeLbl")}</TableCell>
                                    <TableCell className='table-app-title-header'>{t("common:commonDateLbl")}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {selectedDocs.length === 0 &&
                                    <TableRow key='empty'>
                                        <TableCell colSpan={5} style={{ textAlign: 'center' }}>
                                            {t("subtitle6")}
                                        </TableCell>
                                    </TableRow>
                                }
                                {selectedDocs.map(item => (
                                    <TableRow key={item.id}>
                                        <TableCell component="th" scope="row" className='app-form-subtitle'>
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <AccessRowAut
                                                    title={item.fileName}
                                                    Icon={imageExtensions.includes(item.fileExtension.toUpperCase().trim())
                                                        ? Image : Document}
                                                />
                                            </div>
                                        </TableCell>
                                        <TableCell component="th" scope="row"  >
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <Folder style={{ marginRight: '10.14px' }} />
                                                <Typography className='folder-title-cel-text'>
                                                    {item.folder.name}
                                                </Typography>
                                            </div>
                                        </TableCell>
                                        <TableCell component="th" scope="row" className='app-form-subtitle'>
                                            {item.uploadedByUser.firstName + ' ' + item.uploadedByUser.lastName}
                                        </TableCell>
                                        <TableCell component="th" scope="row" className='app-form-subtitle'>
                                            {item.fileExtension.replace(".", "").toUpperCase()}
                                        </TableCell>
                                        <TableCell component="th" scope="row" className='app-form-subtitle'>
                                            <div>
                                                <Moment className="app-form-subtitle" style={{ marginTop: '4px' }}
                                                    format="DD/MM/YYYY">
                                                    {item.created}
                                                </Moment>
                                                <Trash style={{ marginLeft: '20.14px', cursor: 'pointer' }}
                                                    className="subjects-icon-svg-delete subjects-icon-delete"
                                                    onClick={() => removeFile(item.id)} />
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                        <div style={{ marginTop: 24 }}>
                            <PaginationCustom pages={totalNumPages()} activePage={activePageSelectedDoc}
                                onChange={page => changeSelectedDocPage(page)} />
                        </div>
                    </div>
                    <div style={{ display: 'flex', marginBottom: 40 }}>
                        <Button variant="contained" className="button-app-save" style={{ marginRight: 18 }}
                            onClick={() => onSubmit()}>
                            {t("common:commonSubmitBtn")}
                        </Button>
                        <Button variant="contained" className="button-app-cancel"
                            onClick={handleClose}>
                            {t("common:commonCancelBtn")}
                        </Button>
                    </div>
                </div>
            </div>
        </Modal>
    );
}

export default AddFiles;
