import { postAxios, getAxios } from "../services/Axios";
import { getLogsURL, getNotificationByIdURL, postNotificationURL } from "../constants/apiUrls";
import { logoutAction, renewTokenAction } from './AuthDuck'

// constantes
const initialState = {
    logs: [],
    log: null,
    pages: 1,
    activePage: 0,
    totalFilter: 0,
    isCreated: false,
    response: null,
    loading: false,
    error: null,
};

// types
const LOG_SUCCESS = 'LOG_SUCCESS';
const CREATE_LOG_SUCCESS = 'CREATE_LOG_SUCCESS';
const LOG_GET_BY_ID = 'LOG_GET_BY_ID';
const LOG_ERROR = 'LOG_ERROR';
const LOADING_LOG = 'LOADING_LOG';
const CLEAR_RESPONSE = 'CLEAR_RESPONSE';
const LOG_CHANGE_PAGE = 'LOG_CHANGE_PAGE';
const LOG_OUT = 'LOG_OUT';
const INITIAL_STATE = 'INITIAL_STATE';

// reducer
export default function reducer(state = initialState, action) {
    switch (action.type) {
        case LOADING_LOG:
            return { ...state, loading: true, error: null };
        case LOG_SUCCESS:
            return {
                ...state, logs: action.payload, isCreated: false,
                totalFilter: action.totalFilter,
                pages: action.pages, error: null, loading: false
            };
        case CREATE_LOG_SUCCESS:
            return {
                ...state, response: action.response, isCreated: true,
                activePage: 0, loading: false, error: null
            };
        case LOG_GET_BY_ID:
            return {
                ...state, notification: action.payload,
                isCreated: false, error: null, loading: false
            };
        case LOG_CHANGE_PAGE:
            return { ...state, activePage: action.activePage };
        case CLEAR_RESPONSE:
            return { ...state, response: null };
        case LOG_ERROR:
            return {
                ...state, error: action.payload,
                response: action.payload, loading: false
            };
        case LOG_OUT:
            return { ...initialState };
        case INITIAL_STATE:
            return {
                ...state,
                logs: [],
                log: null,
                pages: 1,
                activePage: 0,
                totalFilter: 0,
            };
        default:
            return state;
    }
};

export const getLogs = (filterBy = [], page = 0, pageSize = 10000, loading = false, service = '') => async (dispatch, getState) => {
    loading && dispatch({ type: LOADING_LOG });
    try {
        const { user } = getState().auth;
        const res = await postAxios(getLogsURL, {
            "organizationId": user.organizationId,
            "page": page,
            "pageSize": pageSize,
            "filterBy": filterBy,
            "service": service,
            "orderBy": "",
            "orderDesc": true
        });
        if (res.status === 200) {
            const token = res.headers.refreshtoken;
            const expiration = res.headers.expirationToken;
            (token && expiration) && dispatch(renewTokenAction(token, expiration));
            return dispatch({
                type: LOG_SUCCESS,
                payload: res.data.response,
                pages: res.data.totalPages,
                totalFilter: res.data.totalFilter
            });
        }
        throw new Error();
    } catch (error) {
        if (error.response && error.response.status === 401)
            dispatch(logoutAction());
        dispatch({
            type: LOG_ERROR,
            payload: error.response ? error.response.data.message : "common:errorLoadingLogs"
        });
    }
};

export const getNotificationById = (id) => async (dispatch) => {
    dispatch({ type: LOADING_LOG });
    try {
        const res = await getAxios(getNotificationByIdURL + id);
        if (res.status === 200) {
            const token = res.headers.refreshtoken;
            const expiration = res.headers.expirationToken;
            (token && expiration) && dispatch(renewTokenAction(token, expiration));
            return dispatch({
                type: LOG_GET_BY_ID,
                payload: res.data.response
            });
        }
        throw new Error();
    } catch (error) {
        if (error.response && error.response.status === 401)
            dispatch(logoutAction());
        dispatch({
            type: LOG_ERROR,
            payload: error.response ? error.response.data.message : "common:errorLoadingAuth"
        });
    }
};

export const saveNotification = (notification) => async (dispatch, getState) => {
    dispatch({ type: LOADING_LOG });
    try {
        const userRedux = getState().auth.user;
        const receiverIds = [];

        notification.receivers.forEach(rec => {
            receiverIds.push(rec.id);
        });

        const res = await postAxios(postNotificationURL, {
            'userId': userRedux.id,
            'companyId': notification.companyId,
            'officeId': notification.officeId,
            'clientId': notification.clientId,
            'title': notification.title,
            'description': notification.description,
            'receivers': receiverIds
        });

        if (res.status === 200) {
            const token = res.headers.refreshtoken;
            const expiration = res.headers.expirationToken;
            (token && expiration) && dispatch(renewTokenAction(token, expiration));
            return dispatch({
                type: CREATE_LOG_SUCCESS,
                response: res.data.response
            });
        }
        throw new Error();
    } catch (error) {
        if (error.response && error.response.status === 401)
            dispatch(logoutAction());
        dispatch({
            type: LOG_ERROR,
            payload: error.response ? error.response.data.message : "common:errorSavingNotif"
        });
    }
};

export const changeNotificationPage = (page) => (dispatch) => {
    dispatch({
        type: LOG_CHANGE_PAGE,
        activePage: page - 1
    });
};
