import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal, Button, MobileStepper, FormControlLabel, Checkbox } from '@mui/material'
import { ReactComponent as CloseCross } from '../../assets/Icons/closeCross.svg';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { withRouter } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';
import { helpModalText } from '../../constants/helpModal';
import { getHelps, updateHelp, updateFirstTime } from '../../redux/HelpDuck';
import { useTranslation } from 'react-i18next';

const HelpModal = (props) => {

    const lng = useSelector(store => store.auth.lenguageId);
    const helps = useSelector(store => store.help.helps);
    const firstTime = useSelector(store => store.help.firstTime);
    const languageCode = lng ? (parseInt(lng) === 1 ? 'en' : 'es') : 'es';
    const [step, setStep] = useState(0);
    const [open, setOpen] = useState(false);
    const [noShowAgain, setNoShowAgain] = useState(false);
    const { t } = useTranslation(['help']);
    const dispatch = useDispatch();
    const { location: { pathname } } = props;

    const modalItems = helps ? [
        {
            index: 0,
            selected: pathname.includes(ROUTES.SYSTEM_CONFIGURATION)
                && firstTime.configuration && !helps.configuration,
            value: 'configuration'
        },
        {
            index: 1,
            selected: pathname.includes(ROUTES.APP_CONFIGURATION)
                && firstTime.appConfiguration && !helps.appConfiguration,
            value: 'appConfiguration'
        },
        {
            index: 2,
            selected: pathname.includes(ROUTES.BUSINESS)
                && firstTime.companiesAndOffices && !helps.companiesAndOffices,
            value: 'companiesAndOffices'
        },
        {
            index: 3,
            selected: pathname.includes(ROUTES.MOBILE_CONTENT)
                && firstTime.mobile && !helps.mobile,
            value: 'mobile'
        },
        {
            index: 4,
            selected: pathname.includes(ROUTES.USERS)
                && firstTime.users && !helps.users,
            value: 'users'
        },
        {
            index: 5,
            selected: pathname.includes(ROUTES.PERMITS)
                && firstTime.permissions && !helps.permissions,
            value: 'permissions'
        },
        {
            index: 6,
            selected: pathname.includes(ROUTES.USER_ASSIGNMENT)
                && firstTime.assignUsers && !helps.assignUsers,
            value: 'assignUsers'
        },
        {
            index: 7,
            selected: pathname.includes(ROUTES.LOGS)
                && firstTime.logbook && !helps.logbook,
            value: 'logbook'
        },
        {
            index: 8,
            selected: pathname.includes(ROUTES.CLIENTS)
                && firstTime.clients && !helps.clients,
            value: 'clients'
        },
        {
            index: 9,
            selected: pathname.includes(ROUTES.CUSTOMER_STAFF)
                && firstTime.clientStaff && !helps.clientStaff,
            value: 'clientStaff'
        },
        {
            index: 10,
            selected: pathname.includes(ROUTES.MATTERS)
                && firstTime.subject && !helps.subject,
            value: 'subject'
        },
        {
            index: 11,
            selected: pathname.includes(ROUTES.NOTIFICATIONS)
                && firstTime.notifications && !helps.notifications,
            value: 'notifications'
        },
        {
            index: 12,
            selected: pathname.includes(ROUTES.AUTHORIZATIONS)
                && firstTime.authorizations && !helps.authorizations,
            value: 'authorizations'
        },
        {
            index: 13,
            selected: pathname.includes(ROUTES.DOCUMENTS)
                && firstTime.documents && !helps.documents,
            value: 'documents'
        },
    ] : [];

    const index = modalItems.find(item => item.selected === true);

    const maxStep = index ? helpModalText[index.index][languageCode].length : 1;

    const nextStep = () => {
        step + 1 === maxStep ? setStep(0) : setStep(step + 1);
    };

    const backStep = () => {
        step === 0 ? setStep(maxStep - 1) : setStep(step - 1);
    };

    useEffect(() => {
        if (maxStep)
            setStep(0);
    }, [maxStep]);

    useEffect(() => {
        if (pathname) {
            if (index) setOpen(true);
            else setOpen(false);
        }
    }, [pathname, index]);

    const handleClose = () => {
        if (noShowAgain) {
            dispatch(updateHelp(index.value));
        } else {
            dispatch(updateFirstTime(index.value));
        }
        setNoShowAgain(false);
        setOpen(false);
    };

    useEffect(() => {
        dispatch(getHelps());
    }, [dispatch]);

    return (index && maxStep > step) ? (
        <Modal
            open={open}
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                zIndex: 10000
            }}
        >
            <div style={{ width: 500, height: 'min-content', padding: '15px 30px', background: '#F8F9FA', borderRadius: 20 }}>
                <Button className='button-close-cross' variant="text" style={{ float: 'right', marginTop: 10 }}
                    onClick={handleClose} startIcon={<CloseCross />}></Button>
                <div style={{
                    width: '100%', height: 'calc(100% - 45px)', display: 'flex',
                    justifyContent: 'space-between', alignItems: 'center',
                    paddingLeft: 8
                }}>
                    {maxStep !== 1 &&
                        <Button className='button-close-cross' variant="text"
                            startIcon={<ArrowBackIosIcon style={{ color: '#000' }} />}
                            onClick={() => backStep()}></Button>
                    }
                    <div style={{
                        display: 'flex', flexDirection: 'column',
                        justifyContent: 'flex-start', height: '100%',
                        padding: '0 40px', marginTop: 25, marginBottom: 50,
                        alignItems: 'center', position: 'relative'
                    }}>
                        {helpModalText[index.index][languageCode][step].map((text, index) => (
                            <p key={index} style={{
                                fontStyle: 'normal',
                                fontWeight: '400',
                                fontSize: '22px',
                                lineHeight: '27px',
                                color: '#231E60'
                            }}>
                                {text}
                            </p>
                        ))}
                        {maxStep !== 1 &&
                            <MobileStepper
                                variant="dots"
                                steps={maxStep}
                                position="static"
                                activeStep={step}
                                style={{
                                    marginRight: 8,
                                    marginTop: 10,
                                    background: 'transparent'
                                }}
                            />
                        }
                    </div>
                    {maxStep !== 1 &&
                        <Button className='button-close-cross' variant="text"
                            startIcon={<ArrowForwardIosIcon style={{ color: '#000' }} />}
                            onClick={() => nextStep()}></Button>
                    }
                </div>
                <FormControlLabel control={<Checkbox
                    checked={noShowAgain}
                    onChange={e => setNoShowAgain(e.target.checked)}
                />} label={t('help:noShowAgain')} />
            </div>
        </Modal>
    ) : null
};

export default withRouter(HelpModal);