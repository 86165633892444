import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { changeIsCreating } from '../../../redux/UserDuck';
import FormUser from '../Form';

const CreateUser = () => {

    const isCreating = useSelector(store => store.user.isCreating);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(changeIsCreating(true));
    }, [dispatch]);

    return isCreating && (
        <FormUser />
    )
};

export default CreateUser;