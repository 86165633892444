import React from 'react';
import { Typography, CardContent } from '@mui/material';
import './Folder.css';

function FolderContainer(props) {

    return (
        <CardContent className='card-content-item-folder-mobile'
            onClick={() => props.addContent(props.type, props.pageIndex)}>
            {props.icono}
            <Typography className='card-text-folder-mobile'>
                {props.texto}
            </Typography>
        </CardContent>
    );
}

export default FolderContainer;