import thunk from 'redux-thunk';
import { createStore, combineReducers, compose, applyMiddleware } from 'redux';
import authReducer, { readActiveUserAction } from './AuthDuck';
import systemReducer from './SystemConfigurationDuck';
import subscriptionReducer from './SubscriptionDuck';
import appSettingReducer from './AppConfigurationDuck';
import businessReducer from './BusinessDuck';
import clientReducer from './ClientDuck';
import userReducer from './UserDuck';
import lenguageReducer from './LenguageDuck';
import countryReducer from './CountryDuck';
import permissionReducer from './PermissionDuck';
import assignReducer from './UserAssignDuck';
import subjectReducer from './SubjectDuck';
import notificationReducer from './NotificationDuck';
import documentReducer from './DocumentDuck';
import authorizationReducer from './AuthorizationDuck';
import logReducer from './LogDuck';
import helpReducer from './HelpDuck';
import mobileReducer from './MobileContentDuck';

const rootReducer = combineReducers({
    auth: authReducer,
    system: systemReducer,
    appSetting: appSettingReducer,
    business: businessReducer,
    subscription: subscriptionReducer,
    client: clientReducer,
    user: userReducer,
    lenguage: lenguageReducer,
    country: countryReducer,
    permission: permissionReducer,
    assign: assignReducer,
    subject: subjectReducer,
    notification: notificationReducer,
    doc: documentReducer,
    authorization: authorizationReducer,
    log: logReducer,
    help: helpReducer,
    mobile: mobileReducer
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default function generateStore() {
    const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));
    readActiveUserAction()(store.dispatch);
    return store;
};