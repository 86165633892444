import React, { useEffect, useState } from "react";
import { withRouter, Link } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import {
    Container, Grid, Typography, CardMedia, TableContainer,
    TableRow, TableCell, Table, TableBody, TableHead, Button,
    IconButton
} from "@mui/material";
import Title from "../../../components/Title";
import CircleIcon from '@mui/icons-material/Circle';
import { ReactComponent as SettingsOutline } from '../../../assets/Icons/settingsOutline.svg';
import { ReactComponent as AttachFile } from '../../../assets/Icons/attachFile.svg';
import { ReactComponent as Archive } from '../../../assets/Icons/archive.svg';
import { ReactComponent as Document } from '../../../assets/Icons/document.svg';
//import { ReactComponent as Trash } from '../../../assets/Icons/trash.svg';
import { ReactComponent as Image } from '../../../assets/Icons/image.svg';
import { ReactComponent as Folder } from '../../../assets/Icons/folder.svg';
import { ReactComponent as Trash } from '../../../assets/Icons/trash.svg';



import PaginationCustom from "../../../components/Pagination";
import AccessRow from "./AccessRow";
import { getSubjectById, changeStatusSubject, deleteFile, changeIsDeleted } from "../../../redux/SubjectDuck";
import { getDocuments, changeDocPage } from '../../../redux/DocumentDuck';
import TableUserAssigned from "./TableUserAssigned";
import { getFileURL } from "../../../constants/apiUrls";
import { getAssignedClientStaff, changeUserAssignedPage } from "../../../redux/UserAssignDuck";
import * as ROUTES from '../../../constants/routes';
import './Details.css';
import Moment from 'react-moment';
import { useTranslation } from 'react-i18next';
import AddFiles from "./AddFiles";
import { getCountries } from "../../../redux/CountryDuck";

const MattersDetails = props => {
    const permission = useSelector(store => store.auth.permission);
    const { t } = useTranslation(['matter', 'common']);

    const subject = useSelector(store => store.subject.subject);
    const isDeletedFile = useSelector(store => store.subject.isDeletedFile);

    const assignedClientStaff = useSelector(store => store.assign.assignedClientStaff);
    const activePageAssigned = useSelector(store => store.assign.activePageAssigned);
    const pagesAssigned = useSelector(store => store.assign.pagesAssigned);

    const activePageDoc = useSelector(store => store.doc.activePageDoc);
    const pagesDoc = useSelector(store => store.doc.pagesDoc);
    const documents = useSelector(store => store.doc.documents);

    const [subjectId, setSubjectId] = useState('');
    const [openAddFile, setOpenAddFile] = useState(false);
    const dispatch = useDispatch();

    const imageExtensions = [".JPG", ".JPEG", ".GIF", ".PNG", ".TIFF",
        ".TIF", ".RAW", ".BMP", ".PSD", ".EPS", ".PIC",
        ".MOV", ".MKV", ".MP4", ".WMV", ".FLV"];

    //componentDidMount
    useEffect(() => {
        dispatch(changeDocPage(1));
        dispatch(changeUserAssignedPage(1));
        //component will unmount
        return () => {
            dispatch(changeDocPage(1));
            dispatch(changeUserAssignedPage(1));
        }
    }, [dispatch]);

    useEffect(() => {
        const { id } = props.match.params;
        setSubjectId(id);
        dispatch(getSubjectById(id));
    }, [dispatch, props.match.params]);

    useEffect(() => {
        if (subject) {
            dispatch(getAssignedClientStaff(
                [
                    {
                        search: String(subject.companyId), filterBy: 'company',
                    },
                    {
                        search: String(subject.client.id), filterBy: 'client',
                    }
                ], activePageAssigned, 3));
        }
        // eslint-disable-next-line
    }, [dispatch, activePageAssigned, subject]);

    useEffect(() => {
        if (!!(isDeletedFile || !openAddFile) && subject) {
            const page = activePageDoc;
            dispatch(getDocuments(
                [{ filterBy: 'subject', search: String(subject.id) }],
                '', page, 10, false, 'subject'
            ));
            if (isDeletedFile)
                dispatch(changeIsDeleted());
        }
        // eslint-disable-next-line
    }, [dispatch, subject, activePageDoc, isDeletedFile, openAddFile]);

    useEffect(() => {
        dispatch(getCountries());
    }, [dispatch]);

    return subject && (
        <Container>
            <AddFiles
                open={openAddFile}
                closeModal={() => setOpenAddFile(false)}
                subjectId={subject.id}
                clientId={subject.clientId}
            />
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <div style={{ marginRight: 21 }}>
                    <Title title={subject.title} style={{ marginLeft: 5 }} />
                </div>
                <div style={{ display: 'flex', alignItems: 'center', color: subject.disabled ? '#6C757D' : '#28A745' }}>

                    <CircleIcon style={{ fontSize: 7, marginRight: 7 }} />
                    <Typography >
                        {subject.disabled ? t("common:commonStatusArchived") : t("common:commonStatusActive")}
                    </Typography>

                </div>
            </div>
            <Grid item xs={12}>
                <div className="container-app-div extra-padding-for-div-business">
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <div style={{ display: 'flex' }}>
                            {subject.client.fileId &&
                                <CardMedia component="img"
                                    image={getFileURL + subject.client.fileId}
                                    sx={{
                                        width: 150, height: 150
                                    }}
                                    style={{ objectFit: 'none' }}
                                />
                            }
                            <div style={{ marginLeft: 37 }}>
                                <div style={{ display: 'flex' }}>
                                    <Typography className="big-text-for-business-name-matter"
                                        style={{ color: '#152536', maxWidth: "70%" }}>
                                        {subject.client.name}
                                    </Typography>
                                    <Typography className="big-text-for-business-name-matter"
                                        style={{ color: '#6C757D', marginLeft: '7px' }}>
                                        ({subject.client.clientType ? t("common:commonCompanyLbl") : t("common:commonPersonLbl")})
                                    </Typography>
                                </div>
                                <div className='status-row-client-matter'
                                    style={{ color: subject.client.disabled ? '#6C757D' : '#28A745', margin: '9px 0px' }}>
                                    <CircleIcon style={{ fontSize: 7, marginRight: 7 }} />
                                    <Typography>
                                        {subject.client.disabled ? t("common:commonStatusArchived") : t("common:commonStatusActive")}
                                    </Typography>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container-app-div">
                    <Typography className="app-form-title" >{t("subtitle1")}</Typography>
                    <TableContainer>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow className="header-cell-matters">
                                    <TableCell className="detail-table-cell-matters" align="right">
                                        {t("headerTbl8")}
                                    </TableCell>
                                    <TableCell sx={{ width: '78%' }} className="detail-table-cell-matters">
                                        {t("headerTbl4")}
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow key={'id'}>
                                    <TableCell component="th" scope="row" className="description-table-cell-matters" >
                                        {t("headerTbl7")}
                                    </TableCell>
                                    <TableCell className="detail-table-cell-matters">
                                        {subject.title}
                                    </TableCell>
                                </TableRow>
                                <TableRow key={'id2'}>
                                    <TableCell component="th" scope="row" className="description-table-cell-matters">
                                        {t("headerTbl8")}
                                    </TableCell>
                                    <TableCell className="detail-table-cell-matters">
                                        {subject.description}
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
                <div className="container-app-div">
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Typography className="app-form-title"> {t("subtitle2")}</Typography>
                        <Button className='button-app-create' variant="text" startIcon={<AttachFile />}
                            onClick={() => setOpenAddFile(true)}>
                            {t("uploadFilesLbl")}
                        </Button>
                    </div>
                    <TableContainer>
                        <Table aria-label="simple table" style={{
                            overflowX: 'auto'
                        }}>
                            <TableHead>
                                <TableRow>
                                    <TableCell className='table-app-title-header'> {t("common:commonNameLbl")}</TableCell>
                                    <TableCell className='table-app-title-header'>{t("common:commonFolderLbl")}</TableCell>
                                    <TableCell className='table-app-title-header'>{t("common:commonCreatedByLbl")}</TableCell>
                                    <TableCell className='table-app-title-header'>{t("common:commonTypeLbl")}</TableCell>
                                    <TableCell className='table-app-title-header'>{t("common:commonDateLbl")}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {documents.length === 0 &&
                                    <TableRow key='empty'>
                                        <TableCell colSpan={5} style={{ textAlign: 'center' }}>
                                            {t("common:commonNoResultsLbl")}
                                        </TableCell>
                                    </TableRow>
                                }
                                {documents.map(item => (
                                    <TableRow key={item.id}>
                                        <TableCell component="th" scope="row" className='app-form-subtitle'>
                                            <AccessRow
                                                title={item.fileName}
                                                Icon={imageExtensions.includes(item.fileExtension.toUpperCase().trim())
                                                    ?
                                                    Image
                                                    :
                                                    Document
                                                }
                                            />
                                        </TableCell>
                                        <TableCell component="th" scope="row"  >
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <Folder style={{ marginRight: '10.14px' }} />
                                                <Typography className='folder-title-cel-text'
                                                    style={{
                                                        width: 120,
                                                        whiteSpace: 'nowrap',
                                                        textOverflow: 'ellipsis',
                                                        overflow: 'hidden'
                                                    }}>
                                                    {item.folder.name}
                                                </Typography>
                                            </div>
                                        </TableCell>
                                        <TableCell component="th" scope="row" className='app-form-subtitle'>
                                            <div style={{
                                                width: 120,
                                                whiteSpace: 'nowrap',
                                                textOverflow: "ellipsis",
                                                overflow: "hidden"
                                            }}>
                                                {item.uploadedByUser.firstName + ' ' + item.uploadedByUser.lastName}
                                            </div>
                                        </TableCell>
                                        <TableCell component="th" scope="row" className='app-form-subtitle'>
                                            {item.fileExtension.toUpperCase().trim()}
                                        </TableCell>
                                        <TableCell component="th" scope="row" className='app-form-subtitle'
                                            style={{
                                                minWidth: 145,
                                            }}>
                                            <div style={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                alignItems: "center"
                                            }}>

                                                <Moment style={{ marginRight: 8 }}
                                                    format="DD/MM/YYYY">
                                                    {item.created}
                                                </Moment>
                                                <IconButton
                                                    onClick={() => dispatch(deleteFile(subjectId, item.id))}
                                                >
                                                    <Trash className="documents-icon-svg-delete-results documents-icon-delete-results" />
                                                </IconButton>
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <br />
                    <PaginationCustom pages={pagesDoc} activePage={activePageDoc}
                        onChange={page => dispatch(changeDocPage(page))} />
                </div>

                <div className="container-app-div">
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Typography className="app-form-title">{t("subtitle3")}</Typography>
                        {permission && permission.permissions.find(p => p.permissionName === 'Permissions.ClientStaff')
                            &&
                            <Button className='button-app-create' variant="text"
                                startIcon={<SettingsOutline />} component={Link}
                                to={ROUTES.CUSTOMER_STAFF}>
                                {t("adminCustomerStaffBtn")}
                            </Button>
                        }
                    </div>
                    <div>
                        <TableUserAssigned userList={assignedClientStaff} />
                        <br />
                        <PaginationCustom pages={pagesAssigned} activePage={activePageAssigned}
                            onChange={page => dispatch(changeUserAssignedPage(page))} />
                    </div>
                </div>


                <div className="container-app-div">
                    <Typography className="app-form-title">{t("common:commonOptionsLbl")}</Typography>
                    <Button className='button-archive' variant="text"
                        startIcon={<Archive />}
                        onClick={() => dispatch(changeStatusSubject(subject.id))}>
                        {!subject.disabled ? t("common:commonArchiveLbl") : t("common:commonEnableLbl")}
                    </Button>
                </div>
                <div style={{ display: 'none' }}>
                    <Button variant="contained" className="button-app-save" style={{ marginRight: 18 }}>
                        {t("common:commonSubmitBtn")}
                    </Button>
                    <Button variant="contained" className="button-app-cancel">
                        {t("common:commonCancelBtn")}
                    </Button>
                </div>
            </Grid>
        </Container>
    );
}

export default withRouter(MattersDetails);