 import { getHelpURL, putHelpURL } from '../constants/apiUrls';
import { getAxios, putAxios } from '../services/Axios';
import { logoutAction, renewTokenAction } from './AuthDuck'

// constantes
const initialState = {
    helps: null,
    firstTime: {
        "configuration": true,
        "appConfiguration": true,
        "companiesAndOffices": true,
        "mobile": true,
        "users": true,
        "permissions": true,
        "assignUsers": true,
        "logbook": true,
        "clients": true,
        "clientStaff": true,
        "subject": true,
        "notifications": true,
        "authorizations": true,
        "documents": true
    },
    loading: false,
    error: null,
};

// types
const HELP_SUCCESS = 'HELP_SUCCESS';
const HELP_ERROR = 'HELP_ERROR';
const LOADING_HELP = 'LOADING_HELP';
const FIRST_TIME_SUCCESS = 'FIRST_TIME_SUCCESS';
const CLEAR_RESPONSE = 'CLEAR_RESPONSE';
const LOG_OUT = 'LOG_OUT';

// reducer
export default function reducer(state = initialState, action) {
    switch (action.type) {
        case LOADING_HELP:
            return { ...state, loading: true, error: null };
        case HELP_SUCCESS:
            return {
                ...state, helps: action.payload,
                firstTime: action.firstTime, error: null, loading: false
            };
        case FIRST_TIME_SUCCESS:
            return { ...state, firstTime: action.payload, error: null, loading: false };
        case HELP_ERROR:
            return {
                ...state, error: action.payload, loading: false,
                response: action.payload
            };
        case CLEAR_RESPONSE:
            return { ...state, response: null };
        case LOG_OUT:
            return { ...initialState };
        default:
            return state;
    }
};

export const getHelps = () => async (dispatch, getState) => {
    try {
        const res = await getAxios(getHelpURL);
        if (res.status === 200) {
            const token = res.headers.refreshtoken;
            const expiration = res.headers.expirationToken;
            (token && expiration) && dispatch(renewTokenAction(token, expiration));
            return dispatch({
                type: HELP_SUCCESS,
                payload: res.data.response,
                firstTime: {
                    "configuration": true,
                    "appConfiguration": true,
                    "companiesAndOffices": true,
                    "mobile": true,
                    "users": true,
                    "permissions": true,
                    "assignUsers": true,
                    "logbook": true,
                    "clients": true,
                    "clientStaff": true,
                    "subject": true,
                    "notifications": true,
                    "authorizations": true,
                    "documents": true
                }
            });
        }
        throw new Error();
    } catch (error) {
        if (error.response && error.response.status === 401)
            dispatch(logoutAction());
        dispatch({
            type: HELP_ERROR,
            payload: error.response ? error.response.data.message :"common:errorLoadingModals"
    });
}
};
export const updateHelp = (key) => async (dispatch, getState) => {
    try {
        var help = getState().help.helps;
        const { firstTime } = getState().help;
        help[key] = true;
        const res = await putAxios(putHelpURL, help);
        if (res.status === 200) {
            const token = res.headers.refreshtoken;
            const expiration = res.headers.expirationToken;
            (token && expiration) && dispatch(renewTokenAction(token, expiration));
            return dispatch({
                type: HELP_SUCCESS,
                payload: help,
                firstTime: firstTime
            });
        }
        throw new Error();
    } catch (error) {
        if (error.response && error.response.status === 401)
            dispatch(logoutAction());
        dispatch({
            type: HELP_ERROR,
            payload: error.response ? error.response.data.message : "common:errorLoadingModals"
        });
    }
};

export const updateFirstTime = (key) => async (dispatch, getState) => {
    try {
        var help = getState().help.firstTime;
        help[key] = false;
        return dispatch({
            type: FIRST_TIME_SUCCESS,
            payload: help
        });
    } catch (error) {
        if (error.response && error.response.status === 401)
            dispatch(logoutAction());
        dispatch({
            type: HELP_ERROR,
            payload: error.response ? error.response.data.message : "common:errorLoadingModals"
        });
    }
};