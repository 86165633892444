import React, { useState, useEffect } from 'react';
import {
    useSelector, useDispatch
} from 'react-redux';
import {
    Typography, InputLabel, Button, Modal, Divider, Box, LinearProgress
} from "@mui/material";
import { ReactComponent as CloseCross } from '../../assets/Icons/closeCross.svg';
import SelectFieldComponent from "../SelectField";
import { ReactComponent as BlueImage } from '../../assets/Icons/blueImage.svg';
import { ReactComponent as BlueFile } from '../../assets/Icons/blueFile.svg';
import { ReactComponent as Folder } from '../../assets/Icons/folderGray.svg';
import { ReactComponent as FolderPlus } from '../../assets/Icons/folderPlus.svg';
import { ReactComponent as CloudUpload } from '../../assets/Icons/cloudUpload.svg';
import uuid from 'react-uuid';
import FolderComponent from '../Folder';
import { withRouter } from 'react-router-dom';
import { closeModal, createFiles, setSelectedFolderId } from '../../redux/DocumentDuck';
import { useTranslation } from "react-i18next";
import './File.css';

const File = (props) => {

    const { open = false, closeFile, clientId } = props;
    const { t } = useTranslation(['file', 'validations', 'error']);
    const clients = useSelector(store => store.client.clients);
    const isCreated = useSelector(store => store.doc.isCreatedFile);
    const folders = useSelector(store => store.doc.foldersFilters);
    const selectedFolderId = useSelector(store => store.doc.selectedFolderId);

    const [folderId, setFolderId] = useState('');
    const [error, setError] = useState({});
    const [folderList, setFolderList] = useState([]);
    const [openFolder, setOpenFolder] = useState(false);
    const [files, setFiles] = useState([]);
    const [filesProgress, setFilesProgress] = useState([]);
    const dispatch = useDispatch();

    const imageExtensions = ["JPG", "JPEG", "PNG"];

    const validExtensions = ['jpeg', 'jpg', 'png', 'pdf', 'docx', 'vnd.openxmlformats-officedocument.wordprocessingml.document'];

    const onSubmit = () => {
        var error = {};
        if (folderId === null || folderId.length === 0 ||
            /^\s+$/.test(folderId))
            error.folderId = t("error:requiredFolder");
        if (files.length <= 0) {
            error.files = t("error:requiredFile");
        }
        setError(error);
        if (!error.folderId && !error.files) {
            dispatch(createFiles(folderId, files, clientId));
        }
    };

    const onChange = e => {
        setFolderId(e.target.value);
    };

    const onChangeFiles = e => {
        if (!!e.target.files[0]) {
            const filesTemp = files;
            const filesProTemp = filesProgress;
            const noValid = [];
            Array.from(e.target.files).forEach(file => {
                if (validExtensions.includes(file.type.split('/')[1])) {
                    const sizeInMB = (file.size / (1024 * 1024)).toFixed(5);
                    if (sizeInMB <= 20) {
                        filesTemp.push({
                            id: uuid(),
                            file,
                            url: URL.createObjectURL(file),
                            fileExtension: file.type.split('/')[1],
                        });
                        filesProTemp.push(0);
                    } else {
                        noValid.push(t("subtitle4a") + file.name + t("subtitle4b"));
                    }
                } else {
                    noValid.push(t("subtitle5a") + file.name + t("subtitle5b"));
                }
            });
            setError({ noValid: noValid });
            setFiles(prev => ([...filesTemp]));
        }
    };

    useEffect(() => {
        if (isCreated) {
            closeFile();
            setFolderId('');
            setError('');
            setFiles([]);
            dispatch(closeModal());
        }
    }, [dispatch, isCreated, closeFile]);

    useEffect(() => {
        setFiles([]);
    }, [closeFile]);

    useEffect(() => {
        const list = [];
        folders.forEach(item => {
            list.push({ key: item.id, value: item.name });
        });
        setFolderList(list);
    }, [folders]);

    useEffect(() => {
        if (folders.find(f => f.id === selectedFolderId)) {
            setFolderId(selectedFolderId);
        }
    }, [selectedFolderId, folders]);

    useEffect(() => {
        if (!open) {
            dispatch(setSelectedFolderId());
            setFolderId('');
        }
    }, [dispatch, open]);

    useEffect(() => {
        if (filesProgress.find(p => p < 100) < 100) {
            let list = filesProgress;
            list.forEach((progress, index) => {
                const newProgress = progress + Math.floor(
                    Math.random() * (30 - 15 + 1) + 15
                );
                list[index] = (newProgress > 100) ? 100 : newProgress;
            });
            const timer = setInterval(() => {
                setFilesProgress(prev => ([...list]));
            }, 500);
            return () => {
                clearInterval(timer);
            };
        }
    }, [filesProgress, filesProgress.length]);

    function LinearProgressWithLabel(props) {

        return (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box sx={{ width: '100%', mr: 1 }}>
                    <LinearProgress variant="determinate" value={filesProgress[props.index]} />
                </Box>
                <Box sx={{ minWidth: 35 }}>
                    <Typography variant="body2" color="text.secondary">
                        {`${Math.round(
                            props.value,
                        )}%`}
                    </Typography>
                </Box>
            </Box>
        );
    };

    return (
        <>
            <FolderComponent open={openFolder} closeFolder={() => setOpenFolder(false)} clientId={clientId} />
            <Modal
                open={open}
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}
            >
                <div className="container-div-modal-file">
                    <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 20 }}>
                        <Typography className="file-title">{t("title") + " " + t("for")} {(clients.length > 0 && clientId) && clients.find(c => c.id === clientId).name}</Typography>
                        <Button className='button-close-cross' variant="text" onClick={closeFile} startIcon={<CloseCross />}></Button>
                    </div>
                    <div style={{
                        maxHeight: 550,
                        overflowY: 'auto'
                    }} className='scroll'>
                        <div style={{ marginRight: 10 }}>
                            <Typography className='file-subtitle'>
                                {t("subtitle1")}
                            </Typography>
                            <InputLabel className="label-app"> {t("selectFolderLbl")} </InputLabel>
                            <div style={{ display: 'flex', width: '100%', alignItems: 'center' }}>
                                <SelectFieldComponent name="folderId"
                                    valueInput={folderId}
                                    onChange={onChange}
                                    options={folderList}
                                    Icon={Folder}
                                    errorInput={error.folderId}
                                />
                                <Button className="button-create-folder-file" variant="contained"
                                    onClick={() => setOpenFolder(true)} style={{ marginLeft: 15 }}
                                    startIcon={<FolderPlus className="file-icon-svg-folder file-icon-folder" />}>
                                    {t("createFolderBtn")}
                                </Button>
                            </div>
                            <div className="upload-files-div">
                                <div className="upload-files-text">
                                    <CloudUpload className="file-icon-svg-cloud" />
                                    <label className="title">{t("subtitle2")}</label>
                                    <label>{t("subtitle3")}</label>
                                    <label>{t("subtitle4")}</label>
                                </div>
                                <input
                                    className="upload-files-input"
                                    type="file"
                                    accept=".jpg, image/png, application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                    // accept="/*"
                                    multiple
                                    onChange={onChangeFiles}
                                />
                            </div>
                            <p className='app-error-text' >{error.files}</p>
                            {error.noValid && error.noValid.map((err, index) => (
                                <p className='app-error-text' key={index}>
                                    {err}
                                </p>
                            ))
                            }
                            <div className='scroll' style={{ marginTop: 30, maxHeight: 180, overflowY: 'auto', overflowX: 'hidden' }}>
                                <p className="count-files">{files.length} {t("filesLbl")}</p>
                                <br />
                                {files.map((item, index) => (
                                    <div style={{ display: "flex" }} key={item.id}>
                                        {imageExtensions.includes(item.fileExtension.toUpperCase().trim())
                                            ?
                                            <BlueImage className="files-icon" />
                                            :
                                            <BlueFile className="files-icon" />
                                        }
                                        <p className="files-name">{item.file.name}</p>
                                        <Box sx={{ width: '100%', marginRight: 2 }}>
                                            <LinearProgressWithLabel
                                                value={filesProgress[index]}
                                                index={index}
                                            />
                                        </Box>
                                    </div>
                                ))}
                            </div>
                            <Divider />
                            <div style={{ marginTop: 30 }}>
                                <Button className='button-app-save' variant="contained"
                                    disabled={filesProgress.find(p => p < 100) < 100}
                                    onClick={() => onSubmit()}>
                                    {t("addBtn")}
                                </Button>
                            </div>
                            <br />
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    );
}

export default withRouter(File);