import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from 'react-router-dom';
import { Typography, InputLabel, Button, Alert } from "@mui/material";
import TextFieldComponent from '../../components/TextField';
import { changePasswordAction, clearError, getUser, logoutAction } from "../../redux/AuthDuck";
import * as ROUTES from '../../constants/routes';
import { useTranslation } from 'react-i18next';
import './ChangePassword.css';

const ChangePassword = props => {

    const { t } = useTranslation(['changePassword', 'resetPassword', 'button', 'auth']);
    const user = useSelector(store => store.auth.user);
    const alertMessage = useSelector(store => store.auth.alertMessage);
    const resetPassword = useSelector(store => store.auth.resetPassword);

    const error = useSelector(store => store.auth.error);
    const [password, setPassword] = useState({
        currentPassword: '', password: '', confirmPassword: ''
    });
    const [passwordError, setPasswordError] = useState({});
    const dispatch = useDispatch();

    const onChange = e => {
        const { name, value } = e.target;
        setPassword({ ...password, [name]: value });
    };

    const onSubmit = () => {
        if (validationPassword()) {
            dispatch(changePasswordAction(password));
            setPassword({
                currentPassword: '', password: '', confirmPassword: ''
            });
        }
    };

    const validationPassword = () => {
        const passwordLength = 8;
        var error = { password: '', confirmPassword: '', currentPassword: '' };

        if (password.currentPassword === null || password.currentPassword.length === 0
            || /^\s+$/.test(password.currentPassword))
            error.currentPassword = t("changePassword:errorCurrentPass");
        if (password.password === null || password.password.length === 0
            || /^\s+$/.test(password.password))
            error.password = t("changePassword:errorPassRequired");
        else if (password.password.length < passwordLength ||
            !/[^a-zA-Z0-9]/.test(password.password))
            error.password = t("changePassword:errorPassFormat");
        if (password.confirmPassword === null || password.confirmPassword.length === 0
            || /^\s+$/.test(password.confirmPassword))
            error.confirmPassword = t("changePassword:errorPassRequired");
        else if (password.confirmPassword.length < passwordLength ||
            !/[^a-zA-Z0-9]/.test(password.confirmPassword))
            error.confirmPassword = t("changePassword:errorPassFormat");
        if ((!error.password && !error.confirmPassword) &&
            password.password !== password.confirmPassword)
            error.confirmPassword = t("changePassword:errorPassMismatch");

        setPasswordError({ ...passwordError, ...error });
        return !error.password && !error.confirmPassword && !error.currentPassword;
    };

    useEffect(() => {
        dispatch(clearError());
    }, [dispatch]);

    useEffect(() => {
        if (resetPassword) {
            user.temporaryPasswordLogin
                ? dispatch(logoutAction())
                : dispatch(getUser());
        }
    }, [resetPassword, user, props.history, dispatch]);

    return (
        <div style={{ width: "50%" }}>
            {props.children}
            <Typography className="label-generate-password" style={{ marginBottom: '20px' }}>
                {t('changePassword:subtitle')}
            </Typography>
            {alertMessage &&
                <Alert severity="error" className='change-pass-alert'> {t("auth:" + alertMessage)}</Alert>
            }
            <div style={{ display: 'flex', flexDirection: 'column', width: '80%' }}>
                <form id="changePass">
                    <InputLabel className="label-app">{t('changePassword:originalPass')}</InputLabel>
                    <TextFieldComponent name="currentPassword"
                        valueInput={password.currentPassword} type="password"
                        callback={onChange.bind(this)}
                        errorInput={passwordError.currentPassword || t(error)}
                        activeError={false}
                    />
                    <InputLabel className="label-app">{t('changePassword:newPass')}</InputLabel>
                    <TextFieldComponent name="password"
                        valueInput={password.password} type="password"
                        callback={onChange.bind(this)}
                        errorInput={passwordError.password}
                        activeError={false}
                    />
                    <InputLabel className="label-app">{t('changePassword:confirmPass')}</InputLabel>
                    <TextFieldComponent name="confirmPassword"
                        valueInput={password.confirmPassword} type="password"
                        callback={onChange.bind(this)}
                        errorInput={passwordError.confirmPassword}
                        activeError={false}
                    />
                </form>
            </div>
            <div style={{ display: 'flex' }}>
                <Button variant="contained" className="button-app-save" style={{ marginRight: 18 }}
                    onClick={() => onSubmit()}>
                    {t('button:changeBtn')}
                </Button>
                {(user && !user.temporaryPasswordLogin) &&
                    <Button variant="contained" className="button-app-cancel"
                        onClick={() => props.history.push(ROUTES.DASHBOARD)}>
                        {t('button:cancelBtn')}
                    </Button>
                }
            </div>
        </div>
    );
}

export default withRouter(ChangePassword);