export const clientValidation = (client, businesses, imgRef, users, t) => {
    var error = {};
    const allowedExtensions = /(.jpg|.jpeg|.png)$/i;
    const maxSizeKB = 5000;
    var officeActive = false;
    const onlyLetters = /^[a-zA-Z0-9!@#$%^&*()_+\-=[\]{};':"\\|,íúóéáñ./\n/\r/\t<>/?]*$/;

    if (client.name === null || client.name.length === 0 ||
        /^\s+$/.test(client.name))
        error.name = t("customer:errorClientNameRequired");
    if (client.typeOfClient === null || client.typeOfClient.length === 0 ||
        /^\s+$/.test(client.typeOfClient))
        error.typeOfClient = t("customer:errorClientTypeRequired");
    if (client.email && !validateEmail(client.email))
        error.email = t("error:formatEmail");
    if (client.phoneNumber || client.countryCodeId) {
        if (client.countryCodeId === null || client.countryCodeId.length === 0 ||
            /^\s+$/.test(client.countryCodeId))
            error.countryCodeId = t("error:requiredCountryCode");
        if (client.phoneNumber === null || client.phoneNumber.length === 0 ||
            /^\s+$/.test(client.phoneNumber))
            error.phoneNumber = t("error:requiredPhoneNumber");
        else if (!client.phoneNumber.replaceAll(" ", "").match(onlyLetters))
            error.phoneNumber = t("error:formatPhoneNumber");
    }
    if (client.image) {
        const size = client.image.size;
        const sizeKB = parseInt(size / 1024);
        if (imgRef.current.clientWidth > 400 || imgRef.current.clientHeight > 200)
            error.image = t("validations:errorDimensions") + ` (${imgRef.current.clientWidth} x ${imgRef.current.clientHeight} px)`;
        if (!allowedExtensions.exec(client.image.name))
            error.image = t("validations:errorImgExt");
        else if (sizeKB > maxSizeKB)
            error.image = t("validations:errorImgSize");
    }

    if (users.length === 0) {
        error.users = t("validations:errorUsers");
    }

    businesses.forEach(business => {
        business.offices.forEach((item, index) => {
            if (item.active) {
                officeActive = true;
                return;
            }
        });
    });
    if (!officeActive)
        error.office = t("userProfile:errorOfficeRequired");

    return error;
}


const validateEmail = ((email) => {
    const regex = /^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    return regex.test(email) ? true : false;
});