import React from "react";
import {
    InputLabel, Typography, Button,
    Grid
} from "@mui/material";
import { ReactComponent as Trash } from '../../../assets/Icons/trash.svg';
import { ReactComponent as Plus } from '../../../assets/Icons/plus.svg';
import TextFieldComponent from "../../../components/TextField";
import Folders from "../../MobileContent/Folders/Folder";
import DragDropItem from "../DragDropItem/DragDropItem";
import Preview from "../Preview";
import { useTranslation } from 'react-i18next';
import './Form.css';

const Form = ({ onChangePageName, addContent, onChangeContentOrder,
    deleteContent, page = {}, onContentValueChange, deletePage,
    deleteFields, deleteImageToGallery, errorPage, contentPageError,
    pageSelected, addPage, pages, onChangePage }) => {

    const { t } = useTranslation(['mobileContent', 'common']);

    return (
        <div>
            <InputLabel className="title-page-label">{t("pageNameLbl")}</InputLabel>
            <div style={{ marginBottom: 10, display: 'flex', alignItems: 'flex-start' }}>
                <TextFieldComponent name="page" placeholder={t("pageNameLbl")}
                    valueInput={page.page}
                    maxLength={30}
                    callback={onChangePageName.bind(this, page.pageIndex)}
                    errorInput={errorPage}
                />
                {page.default &&
                    <Button className='button-app-save' variant='contained'
                        onClick={() => addPage()} style={{
                            width: 200,
                            marginLeft: 20,
                            marginTop: 13
                        }}>
                        {t("addPageBtn")}
                    </Button>
                }
            </div>
            <div style={{
                border: '1px solid #CED4DA',
                boxSizing: 'border-box', marginBottom: 40,
                borderRadius: 20, padding: 30
            }}>
                <Typography className="elements-title" style={{ marginBottom: 10 }}>
                    <Plus style={{ marginRight: 9 }} /> {t("subtitle1")}
                </Typography>
                <Typography className="elements-subtitle" style={{ marginBottom: 25 }}>
                    {t("subtitle2")}
                </Typography>
                <Folders
                    addContent={addContent}
                    pageIndex={page.pageIndex}
                />
            </div>
            {page.content.length > 0 &&
                <Grid container justifyContent='space-between' style={{ paddingRight: 0 }}>
                    <Grid item xs={6} className="div-content-info">
                        <Typography className="elements-title" style={{ marginBottom: 26 }}>
                            {t("subtitle3")}
                        </Typography>
                        <DragDropItem
                            page={page}
                            onChangeContentOrder={onChangeContentOrder}
                            deleteContent={deleteContent}
                            onContentValueChange={onContentValueChange}
                            deleteImageToGallery={deleteImageToGallery}
                            deleteFields={deleteFields}
                            contentPageError={contentPageError}
                            pageSelected={pageSelected}
                            t={t}
                        />
                    </Grid>
                    <Grid item xs={6} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Preview
                            page={page}
                            pages={pages}
                            onChangePage={onChangePage}
                            record={false}
                        />
                    </Grid>
                </Grid>
            }
            {!page.default &&
                <div style={{
                    width: '100%', display: 'flex',
                    justifyContent: 'flex-end', marginTop: 40
                }}>
                    <Button className='button-delete-page'
                        onClick={() => deletePage(page.pageIndex)}
                        variant="text" startIcon={<Trash />}
                    >
                        {t("deletePageBtn")}
                    </Button>
                </div>
            }
        </div>
    );

}

export default Form;