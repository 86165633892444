import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { changeIsCreating } from '../../../redux/BusinessDuck';
import Form from '../Form';

const CreateBusiness = () => {

    const isCreating = useSelector(store => store.business.isCreating);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(changeIsCreating(true));
    }, [dispatch]);

    return isCreating && (
        <Form />
    )
};

export default CreateBusiness;