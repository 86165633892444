import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from 'react-redux';
import {
    Typography, TableRow, TableCell, Table,
    TableBody, TableHead, Button, Divider
} from "@mui/material";
import { ReactComponent as Image } from '../../../assets/Icons/image.svg';
import { ReactComponent as Document } from '../../../assets/Icons/document.svg';
import { ReactComponent as Folder } from '../../../assets/Icons/folder.svg';
import { ReactComponent as Trash } from '../../../assets/Icons/trash.svg';
import { ReactComponent as CloudUpload } from '../../../assets/Icons/cloudUpload.svg';
import { ReactComponent as Search } from '../../../assets/Icons/search.svg';
import TextFieldComponent from "../../../components/TextField";
import Moment from 'react-moment';
import Checkbox from "../../../components/Checkbox";
import AccessRowAut from "./AccessRowAut";
import PaginationCustom from "../../../components/Pagination";
import { changeDocPage, getDocuments } from "../../../redux/DocumentDuck";
import File from "../../../components/File";
import { useTranslation } from 'react-i18next';
import './RecentDocuments.css';

const RecentDocuments = ({ setValues, subject, error }) => {
    const { t } = useTranslation(['matter', 'common']);
    const pagesDoc = useSelector(store => store.doc.pagesDoc);
    const activePageDoc = useSelector(store => store.doc.activePageDoc);
    const documents = useSelector(store => store.doc.documents);
    const isCreatedFile = useSelector(store => store.doc.isCreatedFile);

    const [resetPageDoc, setResetPageDoc] = useState(false);
    const [search, setSearch] = useState('');
    const [openFile, setOpenFile] = useState(false);
    const [selectedDocs, setSelectedDocs] = useState([]);
    const [activePageSelectedDoc, setActivePageSelectedDoc] = useState(0);
    // const [validationErrors, setValidationErrors] = useState(0);
    const dispatch = useDispatch();

    const obj_per_page = 10;
    const imageExtensions = [".JPG", ".JPEG", ".GIF", ".PNG", ".TIFF",
        ".TIF", ".RAW", ".BMP", ".PSD", ".EPS", ".PIC",
        ".MOV", ".MKV", ".MP4", ".WMV", ".FLV"];

    //componentDidMount
    useEffect(() => {
        dispatch(changeDocPage(1));
        //component will unmount
        return () => {
            dispatch(changeDocPage(1));
        }
    }, [dispatch]);

    const onChangeSearch = e => {
        setSearch(e.target.value);
        setResetPageDoc(true);
    };

    const onSwitch = (value, event) => {
        const { checked } = event.target;
        const list = subject.selectedFiles;
        if (!checked) {
            const doc = list.find(d => String(d.id) === String(value));
            const index = list.indexOf(doc);
            index > -1 && list.splice(index, 1);
            if (!list[activePageSelectedDoc * obj_per_page] && activePageSelectedDoc > 0) {
                setActivePageSelectedDoc(activePageSelectedDoc - 1);
            }
        }
        else {
            const doc = documents.find(d => String(d.id) === String(value));
            list.push(doc);
        }
        setValues({ ...subject, selectedFiles: list });
    };

    const removeFile = (id) => {
        const list = subject.selectedFiles;
        const doc = list.find(d => String(d.id) === String(id));
        const index = list.indexOf(doc);
        index > -1 && list.splice(index, 1);
        setValues({ ...subject, selectedFiles: list });
        if (!list[activePageSelectedDoc * obj_per_page] && activePageSelectedDoc > 0) {
            setActivePageSelectedDoc(activePageSelectedDoc - 1);
        }
    };

    const onSearchDoc = useCallback((searchDoc) => {
        const timer = setTimeout(() => {
            const page = resetPageDoc ? 0 : activePageDoc;
            const filterBy = [{ search: String(subject.clientId), filterBy: 'client' }];
            searchDoc !== '' && filterBy.push({ search: searchDoc, filterBy: 'search-bar-subject' });
            dispatch(getDocuments(filterBy, "recent-files", page, obj_per_page, true, 'subject'));
            setResetPageDoc(false);
        }, 1000);
        return () => clearTimeout(timer);
        // eslint-disable-next-line
    }, [dispatch]);

    useEffect(() => {
        const page = resetPageDoc ? 0 : activePageDoc;
        const filterBy = [{ search: String(subject.clientId), filterBy: 'client' }];
        search !== '' && filterBy.push({ search: search, filterBy: 'search-bar-subject' });
        dispatch(getDocuments(filterBy, "recent-files", page, obj_per_page, true, 'subject'));
        setResetPageDoc(false);
        // eslint-disable-next-line
    }, [dispatch, activePageDoc]);

    useEffect(() => {
        if (isCreatedFile)
            onSearchDoc(search);
        // eslint-disable-next-line
    }, [onSearchDoc, isCreatedFile]);

    useEffect(() => {
        onSearchDoc(search);
        // eslint-disable-next-line
    }, [onSearchDoc]);

    const totalNumPages = () => {
        return Math.ceil(subject.selectedFiles.length / obj_per_page);
    };

    const changeSelectedDocPage = (page) => {
        setActivePageSelectedDoc(page - 1);
    };

    const drawRowSelectedDocs = useCallback((page, selectedFiles) => {
        const list = [];
        for (let index = page * obj_per_page; index < (page * obj_per_page) + obj_per_page; index++) {
            if (selectedFiles.length > index) {
                list.push(selectedFiles[index]);
            }
        }
        setSelectedDocs(prev => ([...list]));
    }, []);

    useEffect(() => {
        drawRowSelectedDocs(activePageSelectedDoc, subject.selectedFiles);
        // eslint-disable-next-line 
    }, [drawRowSelectedDocs, activePageSelectedDoc, subject.selectedFiles.length]);

    return (
        <div>
            <File open={openFile} closeFile={() => setOpenFile(false)} clientId={subject.clientId} />
            <div className="container-app-div">
                <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 42 }}>
                    <Typography className="app-form-title">{t("selectFilesLbl")}</Typography>
                    <Button className='button-app-create' variant="text" startIcon={<CloudUpload />}
                        onClick={() => setOpenFile(true)}>
                        {t("uploadFilesLbl")}
                    </Button>
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div style={{ width: '85%', marginRight: 20 }}>
                        <TextFieldComponent Icon={<Search />} position='start'
                            placeholder={t("searchFileOrFolderLbl")}
                            valueInput={search}
                            callback={onChangeSearch.bind(this)}
                        />
                    </div>
                    <div>
                        <Button variant="contained" className="button-app-save"
                            onClick={() => onSearchDoc(search)}>
                            {t("common:commonSearchPlh")}
                        </Button>
                    </div>
                </div>
                <Divider className="divider-line" style={{ marginTop: 26, marginBottom: 29 }} />
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div style={{ width: '35%' }}>
                        <Typography className="doc-title-text">{t("common:commonFileLbl") + "s"}</Typography>
                    </div>
                </div>
                <Divider className="divider-line" style={{ marginTop: 29 }} />
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell className='table-app-title-header'>{t("common:commonNameLbl")}</TableCell>
                            <TableCell className='table-app-title-header'>{t("common:commonFolderLbl")}</TableCell>
                            <TableCell className='table-app-title-header'>{t("common:commonCreatedByLbl")}</TableCell>
                            <TableCell className='table-app-title-header'>{t("common:commonTypeLbl")}</TableCell>
                            <TableCell className='table-app-title-header'>{t("common:commonDateLbl")}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {documents.length === 0 &&
                            <TableRow key='empty'>
                                <TableCell colSpan={5} style={{ textAlign: 'center' }}>
                                    {t("common:commonNoResultsLbl")}
                                </TableCell>
                            </TableRow>
                        }
                        {documents.map(item => (
                            <TableRow key={item.id}>
                                <TableCell component="th" scope="row" className='app-form-subtitle'>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <Checkbox
                                            name='documents'
                                            onChange={onSwitch.bind(this, item.id)}
                                            value={!!subject.selectedFiles.find(f => String(f.id) === String(item.id))}
                                            checkboxCheckedValue={true}
                                        />
                                        <AccessRowAut
                                            title={item.fileName}
                                            Icon={imageExtensions.includes(item.fileExtension.toUpperCase().trim())
                                                ? Image : Document}
                                        />
                                    </div>
                                </TableCell>
                                <TableCell component="th" scope="row"  >
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <Folder style={{ marginRight: '10.14px' }} />
                                        <Typography className='folder-title-cel-text'>
                                            {item.folder.name}
                                        </Typography>
                                    </div>
                                </TableCell>
                                <TableCell component="th" scope="row" className='app-form-subtitle'>
                                    {item.uploadedByUser.firstName + ' ' + item.uploadedByUser.lastName}
                                </TableCell>
                                <TableCell component="th" scope="row" className='app-form-subtitle'>
                                    {item.fileExtension.replace(".", "").toUpperCase()}
                                </TableCell>
                                <TableCell component="th" scope="row" className='app-form-subtitle'>
                                    <Moment className="app-form-subtitle" style={{ marginTop: '4px' }}
                                        format="DD/MM/YYYY">
                                        {item.created}
                                    </Moment>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                <div style={{ marginTop: 24 }}>
                    <PaginationCustom pages={pagesDoc} activePage={activePageDoc}
                        onChange={page => dispatch(changeDocPage(page))} />
                </div>
            </div>
            <div className="container-app-div">
                <Typography className="app-form-title">{t("selectedFilesLbl")}</Typography>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell className='table-app-title-header'>{t("common:commonNameLbl")}</TableCell>
                            <TableCell className='table-app-title-header'>{t("common:commonFolderLbl")}</TableCell>
                            <TableCell className='table-app-title-header'>{t("common:commonCreatedByLbl")}</TableCell>
                            <TableCell className='table-app-title-header'>{t("common:commonTypeLbl")}</TableCell>
                            <TableCell className='table-app-title-header'>{t("common:commonDateLbl")}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {selectedDocs.length === 0 &&
                            <TableRow key='empty'>
                                <TableCell colSpan={5} style={{ textAlign: 'center' }}>
                                    {t("subtitle6")}
                                </TableCell>
                            </TableRow>
                        }
                        {selectedDocs.map(item => (
                            <TableRow key={item.id}>
                                <TableCell component="th" scope="row" className='app-form-subtitle'>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <AccessRowAut
                                            title={item.fileName}
                                            Icon={imageExtensions.includes(item.fileExtension.toUpperCase().trim())
                                                ? Image : Document}
                                        />
                                    </div>
                                </TableCell>
                                <TableCell component="th" scope="row"  >
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <Folder style={{ marginRight: '10.14px' }} />
                                        <Typography className='folder-title-cel-text'>
                                            {item.folder.name}
                                        </Typography>
                                    </div>
                                </TableCell>
                                <TableCell component="th" scope="row" className='app-form-subtitle'>
                                    {item.uploadedByUser.firstName + ' ' + item.uploadedByUser.lastName}
                                </TableCell>
                                <TableCell component="th" scope="row" className='app-form-subtitle'>
                                    <div style={{ width: "80px", textOverflow: "ellipsis", overflow: "hidden" }}>
                                        {item.fileExtension.replace(".", "").toUpperCase()}
                                    </div>
                                </TableCell>
                                <TableCell component="th" scope="row" className='app-form-subtitle'>
                                    <div style={{
                                        width: "150px", textOverflow: "ellipsis", overflow: "hidden",
                                        display: 'flex', justifyContent: 'center', alignItems: 'center'
                                    }}>
                                        <Moment style={{ marginTop: '4px', display: 'flex', alignItems: 'center' }}
                                            format="DD/MM/YYYY">
                                            <span className="app-form-subtitle">{item.created}</span>
                                        </Moment>
                                        <Trash style={{ marginLeft: '20.14px', cursor: 'pointer' }}
                                            className="subjects-icon-svg-delete subjects-icon-delete"
                                            onClick={() => removeFile(item.id)} />
                                    </div>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                <div style={{ marginTop: 24 }}>
                    <PaginationCustom pages={totalNumPages()} activePage={activePageSelectedDoc}
                        onChange={page => changeSelectedDocPage(page)} />
                </div>
                {error.selectedFiles &&
                    <p className='app-error-text'>{error.selectedFiles}</p>
                }
            </div>
        </div>
    );
}

export default RecentDocuments;
