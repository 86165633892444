import React from "react";
import { Container, Grid, Typography, Card, CardMedia } from "@mui/material";
import helpImage21 from '../../assets/Images/help21.png';
import { useTranslation } from "react-i18next";
import './Help.css';

const HelpMatter = () => {

    const { t } = useTranslation(['help']);

    return (
        <Container>
         <Typography className="admin-business-text" style={{ marginBottom: '20px' }}>
                            {t('help:matter:category')+"|"+t('help:matter:title')}
                        </Typography>
            <Grid item xs={12}>
                <div className="container-app-div help-screen header" style={{ marginTop: 10 }}>
                    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                      
                        <p className="paragraph-text strong" style={{ marginBottom: '20px' }} >
                            {t('help:thisPageLbl')}
                        </p>
                        <label className="help-label-text">
                        <span className="enum">1</span> {t('help:matter:lbl1')}
                        </label>
                        </div><div>
                        <br />
                        <label className="help-label-text">
                            {t('help:matter:lbl2')}
                        </label>
                       </div>
                       </div>
                       <div className="container-app-div" style={{  display: 'flex', flexDirection: 'row', marginTop: 10 }}>
                    
                            <div style={{ width: '40%', display: 'flex', flexDirection: 'column' }}>
                                <p className="paragraph-text strong">
                                    {t('help:step1')}<span className="enum">1</span>:&nbsp;{t('help:matter:lbl3')}
                                </p>
                                <label className="help-label-text p">
                                    {t('help:matter:lbl4')}
                                </label>
                                <label className="help-label-text p">
                                    {t('help:matter:lbl5')}
                                </label>
                                <label className="help-label-text p">
                                    {t('help:matter:lbl6')}
                                </label>
                            </div>
                            <div style={{
                                width: '60%', display: 'flex', marginTop: 40,
                                justifyContent: 'center', alignItems: 'center'
                            }}>
                                <Card sx={{ maxWidth: 250, height: 98, boxShadow: 'none' }}>
                                    <CardMedia
                                        component="img"
                                        image={helpImage21}
                                        alt="img"
                                        style={{ objectFit: 'contain' }}
                                    />
                                </Card>
                            </div>
                        </div>
                    
            </Grid>
        </Container>
    );
}
export default HelpMatter;