import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { changeIsCreating, getBusinessById } from '../../../redux/BusinessDuck';
import Form from '../Form';

const UpdateBusiness = props => {

    const isCreating = useSelector(store => store.business.isCreating);
    const dispatch = useDispatch();

    useEffect(() => {
        const { id } = props.match.params;
        if (id) {
            dispatch(changeIsCreating(false));
            dispatch(getBusinessById(id));
        }
    }, [dispatch, props.match.params]);

    return !isCreating && (
        <Form />
    );
};

export default withRouter(UpdateBusiness);