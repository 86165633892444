export const validateSystem = (system, imgRef, t) => {
    const systemErrors = {};
    const allowedExtensions = /(.jpg|.jpeg|.png)$/i;
    const maxSizeKB = 5000;
    const onlyLetters = /^[a-zA-Z0-9!@#$%^&*()_+\-=[\]{};':"\\|,íúóéáñ./\n/\r/\t<>/?]*$/;

    if (system.name === null || system.name.length === 0 ||
        /^\s+$/.test(system.name))
        systemErrors.name = t("systemValidations:errorOrgNameRequired");
    else if (system.name.length > 25)
        systemErrors.name = t("systemValidations:errorOrgNameSize");
    if (system.webSite === null || system.webSite.length === 0 ||
        /^\s+$/.test(system.webSite))
        systemErrors.webSite = t("systemValidations:errorWebsiteRequired");
    else if (!isValidURL(system.webSite))
        systemErrors.webSite = t("systemValidations:errorInvalidURL");
    if (system.countryCode === null || system.countryCode.length === 0 ||
        /^\s+$/.test(system.countryCode))
        systemErrors.countryCode = t("systemValidations:errorCountryCode");
    if (system.country === null || system.country.length === 0 ||
        /^\s+$/.test(system.country))
        systemErrors.country = t("systemValidations:errorCountry");
    if (system.phoneNumber === null || system.phoneNumber.length === 0 ||
        /^\s+$/.test(system.phoneNumber))
        systemErrors.phoneNumber = t("systemValidations:errorPhoneNumber");
    else if (!system.phoneNumber.replaceAll(" ", "").match(onlyLetters))
        systemErrors.phoneNumber = t("systemValidations:errorPhoneNumberFormat");

    if (system.email === null || system.email.length === 0 ||
        /^\s+$/.test(system.email))
        systemErrors.email = t("systemValidations:errorCompanyEmail");
    else if (!validateUserEmail(system.email))
        systemErrors.email = t("validations:errorEmailFormat");
    if (system.languageId === null || system.languageId.length === 0 ||
        /^\s+$/.test(system.languageId))
        systemErrors.languageId = t("systemValidations:errorMainLanguage");
    if (!system.imageUrl && (system.image === null || system.image.length === 0))
        systemErrors.image = t("validations:errorImg");
    else if (!!system.image) {
        const size = system.image.size;
        const sizeKB = parseInt(size / 1024);
        let uploaded_img = new Image();
        uploaded_img.src = system.imageUrl;
       
        if (uploaded_img.width !== 1024 || uploaded_img.height !== 1024)
            systemErrors.image = t("validations:errorDimensions") + ` (${uploaded_img.width} x ${uploaded_img.height}px)`;
        if (!allowedExtensions.exec(system.image.name))
            systemErrors.image = t("validations:errorImgExt");
        else if (sizeKB > maxSizeKB)
            systemErrors.image = t("validations:errorImgSize");
    }
    if (system.facebookUrl && !isValidURL(system.facebookUrl))
        systemErrors.facebookUrl = t("validations:errorURL");
    if (system.instagramUrl && !isValidURL(system.instagramUrl))
        systemErrors.instagramUrl = t("validations:errorURL");
    if (system.linkedinUrl && !isValidURL(system.linkedinUrl))
        systemErrors.linkedinUrl = t("validations:errorURL");
    if (system.twitterUrl && !isValidURL(system.twitterUrl))
        systemErrors.twitterUrl = t("validations:errorURL");
    if (system.whatsAppCountryCode !== 'null' || !!system.whatsApp) {
        if (system.whatsAppCountryCode === 'null')
            systemErrors.whatsAppCountryCode = t("validations:errorCountryCode");
        if (system.whatsApp === null || system.whatsApp.length === 0 ||
            /^\s+$/.test(system.whatsApp))
            systemErrors.whatsApp = t("validations:WhatsApp");
        else if (!system.whatsApp.replaceAll(" ", "").match(onlyLetters))
            systemErrors.whatsApp = t("validations:errorInvalidWhatsApp");
    }

    return systemErrors;
};

const validateUserEmail = ((email) => {
    const regex = /^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    return regex.test(email) ? true : false;
});

const isValidURL = (url) => {
 //   return /^(([a-zA-Z0-9][-a-zA-Z0-9]{0,62})?[a-zA-Z0-9]\.)+[a-zA-Z]{2,256}\b([-a-zA-Z0-9@:%_+.~#?&//=]+)?(\/[-a-zA-Z0-9@:%_+.~#?&//=]+)?$/g.test(url);
 return /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$/.test(url);
};
