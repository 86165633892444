import React from 'react';
import './Checkbox.css';

const FormExperienceChecbox = ({ name, value, disabled = false,
    onChange, checkboxCheckedValue, checkboxLabel }) => {

    return (
        <div className='form-radio-container'>
            <input type='checkbox' name={name}
                value={value} onChange={onChange}
                className={`form-radio ${disabled && 'form-radio-disabled'}`} checked={value === checkboxCheckedValue}
            />
            <label htmlFor={name} className='form-radio-label'>
                {checkboxLabel}
            </label>
            <br />
        </div>
    );
};

export default FormExperienceChecbox;