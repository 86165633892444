export const helpModalText = {
    0: {
        es: [
            [
                `Escoja el idioma principal de este sistema web y de sus apps. Luego podrá escoger un idioma secundario para las apps.`,
                `Introduzca la infomación general de su organización. La información de sus empresas y oficinas se incluye después.`
            ]
        ],
        en: [
            [
                `Choose this web system and your app's main language. Later you will be able to choose a secondary language for the apps.`,
                `Enter the general information about your organization. You can include your companies and offices data later.`
            ]
        ],
    },
    1: {
        es: [
            [
                `Indique si desea que las apps tengan un idioma adicional al que ya escogió. Si escoge otro idioma deberá proveer las traducciones respectivas.`,
                `Indique el nombre de sus apps y suba el logo. Note las limitaciones de la extención del nombre y el tamaño y formato del logo.`
            ],
            [
                `Considere cuidadosamente el nombre de sus apps y el logo.`,
                `Una vez que sus app son inscritas con Apple y Google, cambiar el nombre o el logo es un proceso lento que puede generar gastos adicionales.`
            ],
            [
                `Luego de completar esta información nosotros haremos la inscripción de sus apps con Google y Apple.
                Usted no tiene que hacer nada.
                El registro usualmente dura algunos días. Lo mantendremos informado por correo.`
            ]
        ],
        en: [
            [
                `Indicate whether you prefer the apps to have an additional language to the one you have already chosen. If you choose another language, you must provide the respective translations.`,
                `Indicate the name of your apps and upload the logo.
                Note the app name length limitations, including the size and format of the logo.`
            ],
            [
                `Carefully consider your apps name and logo.`,
                `Once your apps are registered with Apple and Google, changing the name or logo is time-consuming and can incur additional costs.`,
            ],
            [
                `After completing this information, we will register your apps with Google and Apple.
            You don't have to do anything else.
            Registration usually takes a few days. We will keep you informed by mail.`
            ]
        ]
    },
    2: {
        es: [
            [
                `FOS se estructura en Empresas y Oficinas. Esta organización sirve para dividir al personal y la información y controlar el acceso.
                Las Oficinas son parte de las Empresas.`,
            ],
            [
                `Las Oficinas prestan servicios a los clientes, y pueden ser organizaciones o personas.`,
                `Los Usuarios y los Clientes se registran a las Oficinas.`
            ],
        ],
        en: [
            [
                `FOS is structured into Companies and Offices. This organization serves to divide personnel and information as well as control access.
                The Offices are part of the Companies.`
            ],
            [
                `Offices provide services to clients, and can be either entities or individuals.`,
                `Users and Clients are registered under the Offices.`
            ],
        ]
    },
    3: {
        es: [
            [
                `El Contenido Móvil es la información pública de sus apps. Es su página-web-en-el-app. Describa su empresa y promueva su negocio.`
            ],
            [
                `Puede crear hasta 15 páginas para describir su negocio.`,
                `Considere cuidadosamente el contenido móvil y el diseño de las páginas de su app.`
            ],
            [
                `Use los elementos de contenido para crear sus páginas. Puede moverlos e insertarlos para crear el diseño que desee. Antes de publicar su contenido móvil revise la vista previa.`
            ]
        ],
        en: [
            [
                `Your app's content is public information. It's your website-in-the-app. Describe your company and promote your business.`
            ],
            [
                `You can create up to 15 pages to describe your business.`,
                `Carefully consider the content and page layout of your app.`
            ],
            [
                `Use the content elements to create your pages. You can move and insert them to create any design you want. Before publishing your content, check the preview.`
            ]
        ]
    },
    4: {
        es: [
            [
                `Los Usuarios son las personas que prestan servicios y tiene acceso a este sistema web. Los Usuarios se asignan a Empresas, Oficinas y Clientes.`,
            ],
            [
                `Una vez creados, asigne los Usuarios a las Empresas y Oficinas a las que pertenecen. Los Usuarios sólo podrán ver la información de los Clientes de las Oficinas a las que pertenezcan.`
            ]
        ],
        en: [
            [
                `Users are the people who provide the services and have access to this web system. Users are assigned to Companies, Offices, and Clients.`
            ],
            [
                `Once created, assign the Users to the Companies and Offices to which they belong. Users will only be able to see the information of the Clients in the Offices to which they are registered under.`
            ]
        ]
    },
    5: {
        es: [
            [
                `Los permisos de acceso determinan cuáles páginas pueden usar los usuarios.`,
                `Permisos funcionales determinan si los Usuarios  son notificados cuando alguien se registra como cliente nuevo, o cuando completa un formulario.`
            ],
        ],
        en: [
            [
                `Access permits determine which pages users can visit.`,
                `Functional permits determine whether Users will be notified when someone signs up as a new customer or fill out a form from the mobile apps.`
            ],
        ]
    },
    6: {
        es: [
            [
                `Asigne a los usuarios a sus clientes para que puedan comunicarse con ellos y administrar la información.`,
            ],
        ],
        en: [
            [
                `Assign users to your customers so that they can communicate with them and manage information.`
            ],
        ]
    },
    7: {
        es: [
            [
                `Bitácoras son registros de algunas de las acciones que ocurren en el sistema. Son una forma de auditar las acciones de los Usuarios.`,
            ],
        ],
        en: [
            [
                `Logs are records of some of the actions that occur in the system. They are a way to audit Users' actions.`
            ],
        ]
    },
    8: {
        es: [
            [
                `Los Clientes son las personas o empresas que reciben sus servicios.
                Asigne los Clientes a las Oficinas donde son atendidos.`,
            ],
        ],
        en: [
            [
                `Clients are the people or companies that receive your services.
                Assign Clients to the Offices where they are served.`
            ],
        ]
    },
    9: {
        es: [
            [
                `El "Personal del Cliente" son los usuarios móviles que usan sus apps. 
                Registre a sus clientes e invételos a usar sus apps.
                Asigne estas personas a sus Clientes. Asignar una persona a un Cliente le da acceso a toda la información privada de ese Cliente.`
            ],
            [
                `"Usuarios Asignados" es la lista de personas que ya están asignadas a Clientes, y que tienen acceso en su teléfono a toda la información de ese Cliente.
                Una persona puede ser parte de varios Clientes.`
            ],
            [
                `Cuando registre un nuevo usuario móvil puede escoger enviarle un correo con su nombre y logo, invitándolo a descargar su app e ingresar al área de Clientes. `
            ],
            [
                `"Usuarios Pendientes de Asignación" son las personas que se registraron  desde su teléfono pero no han sido asignadas aún a ningún Cliente.
                Asígnelas a uno o varios Clientes para que puedan ver la información privada.`
            ]
        ],
        en: [
            [
                `"Customer Personnel" refers to your app's mobile user. 
                Register your clients and invite them to use your apps.
                Assign these people to your Clients. Assigning a person to a client gives them access to all the Client's private information.`
            ],
            [
                `"Assigned Users" refers to the list of people who have been assigned to Clients. They have access to the Clients information on their phones.
                An individual can be assigned to several Clients.`
            ],
            [
                `When a mobile user is created, you can choose to send them an Correo Electrónico with your name and logo, inviting them to download your apps and log into the client's area.`
            ],
            [
                `"Users Pending Assignment" refers to the people who registered from their phone but have not yet been assigned to any Client.
                Assign them to one or more Clients so that they can access the private information.`
            ]
        ]
    },
    10: {
        es: [
            [
                `Los Asuntos son los trabajos que usted hace para sus clientes. Los Asuntos permiten ordenar la información que sus clientes accedan desde sus apps.`
            ],
        ],
        en: [
            [
                `Tasks refer to the jobs you do for your clients. Tasks allow you to organize the information your clients can access through your apps.`
            ]
        ]
    },
    11: {
        es: [
            [
                `Procure que las notificaciones a sus clientes sean apropiadas, claras y relevantes.`
            ],
        ],
        en: [
            [
                `Make sure your clients' notifications are appropriate, clear, and relevant.`
            ]
        ]
    },
    12: {
        es: [
            [
                `Las autorizaciones son la herramienta para solicitar permisos a sus clientes.`
            ],
        ],
        en: [
            [
                `Authorizations refer to the tools for requesting permissions from your clients.`
            ]
        ]
    },
    13: {
        es: [
            [
                `Suba los documentos que sus clientes podrán accesar desde sus teléfonos.
                Todos los documentos deben guardarse en Carpetas.`
            ],
        ],
        en: [
            [
                `Upload the documents that your clients will access from their phones.
                All documents must be kept in Folders.`
            ]
        ]
    },
    14: {
        es: [
            [
                `Advertencia:  Asignar un usuario le permite ver la información confidencial del Cliente.`
            ],
        ],
        en: [
            [
                `Warning: Assigning a user allows this user to view confidential Client information.`
            ]
        ]
    },
};
