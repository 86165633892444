import React from 'react';
import { Backdrop, CircularProgress } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useSelector } from 'react-redux';

const useStyles = makeStyles(() => ({
    backdrop: {
        zIndex: 10000,
        color: '#FFF',
    },
}));

const Loading = () => {

    const classes = useStyles();
    const authLoading = useSelector(store => store.auth.loading);
    const systemLoading = useSelector(store => store.system.loading);
    const appSettingLoading = useSelector(store => store.appSetting.loading);
    // const lenguageLoading = useSelector(store => store.lenguage.loading);
    // const countryLoading = useSelector(store => store.country.loading);
    const businessLoading = useSelector(store => store.business.loading);
    const userLoading = useSelector(store => store.user.loading);
    const permissionLoading = useSelector(store => store.permission.loading);
    const clientLoading = useSelector(store => store.client.loading);
    const assignLoading = useSelector(store => store.assign.loading);
    const subjectLoading = useSelector(store => store.subject.loading);
    const docLoading = useSelector(store => store.doc.loading);
    const authorizationLoading = useSelector(store => store.authorization.loading);
    const notificationLoading = useSelector(store => store.notification.loading);
    const subscriptionLoading = useSelector(store => store.subscription.loading);
    const logLoading = useSelector(store => store.log.loading);
    const mobileLoading = useSelector(store => store.mobile.loading);

    const active = () => {
        return !!(authLoading || systemLoading ||
            // lenguageLoading || countryLoading ||
            appSettingLoading || businessLoading ||
            userLoading || permissionLoading ||
            clientLoading || assignLoading ||
            subjectLoading || docLoading || subscriptionLoading ||
            authorizationLoading || notificationLoading ||
            logLoading || mobileLoading);
    };

    return (
        <Backdrop className={classes.backdrop} open={active()}>
            <CircularProgress color="inherit" />
        </Backdrop>
    );
}

export default Loading;