import { getCountryURL,getCountriesENURL } from '../constants/apiUrls';
import { getAxios } from '../services/Axios';
import { logoutAction, renewTokenAction } from './AuthDuck'

// constantes
const initialState = {
    countries: [],
    countriesName: [],
    countriesOriginal: [],
    loading: false,
    error: null,
};

// types
const COUNTRY_SUCCESS = 'COUNTRY_SUCCESS';
const COUNTRY_ERROR = 'COUNTRY_ERROR';
const LOADING_COUNTRY = 'LOADING_COUNTRY';
const CLEAR_RESPONSE = 'CLEAR_RESPONSE';
const LOG_OUT = 'LOG_OUT';

// reducer
export default function reducer(state = initialState, action) {
    switch (action.type) {
        case LOADING_COUNTRY:
            return { ...state, loading: true, error: null };
        case COUNTRY_SUCCESS:
            return {
                ...state, countries: action.countriesCode,
                countriesName: action.countriesName,
                countriesOriginal: action.countriesOriginal,
                error: null, loading: false
            };
        case COUNTRY_ERROR:
            return {
                ...state, error: action.payload, loading: false,
                response: action.payload
            };
        case CLEAR_RESPONSE:
            return { ...state, response: null };
        case LOG_OUT:
            return { ...initialState };
        default:
            return state;
    }
};

export const getCountriesEN = () => async (dispatch, getState) => {
    try {
        const res = await getAxios(getCountriesENURL);
        if (res.status === 200) {
            const countries = res.data.response;
            const list = [];
            const list2 = [];
            countries.forEach(item => {
                list.push({
                    key: item.id,
                    value: '(' + item.code + ') ' + ( item.nameEn ),
                });
                list2.push({
                    key: item.id,
                    value: (item.nameEn)
                });
            });
            return dispatch({
                type: COUNTRY_SUCCESS,
                countriesCode: list,
                countriesName: list2
            });
        }
        throw new Error();
    } catch (error) {
        if (error.response && ((error.response.data.message === 'Unauthorized, User Disabled' || error.response.data.message === 'Unauthorized') && error.response.status === 401))
            dispatch(logoutAction());
        dispatch({
            type: COUNTRY_ERROR,
            payload: error.response ? error.response.data.message : 'Error al cargar los paises.'
        });
    }
};

export const getCountries = () => async (dispatch, getState) => {
    try {
        const res = await getAxios(getCountryURL);
        const { lenguageId } = getState().auth;
        if (res.status === 200) {
            const token = res.headers.refreshtoken;
            const expiration = res.headers.expirationToken;
            (token && expiration) && dispatch(renewTokenAction(token, expiration));
            const countries = res.data.response;
            const list = [];
            const list2 = [];
            const list3 = [];
            countries.forEach(item => {
                list.push({
                    key: item.id,
                    value: '(' + item.code + ') ' + (lenguageId === 1 ? item.nameEn : item.nameEs),
                });
                list2.push({
                    key: item.id,
                    value: (lenguageId === 1 ? item.nameEn : item.nameEs),
                });
                list3.push({
                    key: item.id,
                    code: item.code,
                    name: (lenguageId === 1 ? item.nameEn : item.nameEs)
                });
            });
            return dispatch({
                type: COUNTRY_SUCCESS,
                countriesCode: list,
                countriesName: list2,
                countriesOriginal: list3
            });
        }
        throw new Error();
    } catch (error) {
        if (error.response && error.response.status === 401)
            dispatch(logoutAction());
        dispatch({
            type: COUNTRY_ERROR,
            payload: error.response ? error.response.data.message : "common:errorLoadingCountries"
        });
    }
};
export const getCountriesWithNames = () => async (dispatch) => {
    try {
        const res = await getAxios(getCountryURL);
        if (res.status === 200) {
            const token = res.headers.refreshtoken;
            const expiration = res.headers.expirationToken;
            (token && expiration) && dispatch(renewTokenAction(token, expiration));
            const countries = res.data.response;
            return dispatch({
                type: COUNTRY_SUCCESS,
                payload: countries
            });
        }
        throw new Error();
    } catch (error) {
        if (error.response && error.response.status === 401)
            dispatch(logoutAction());
        dispatch({
            type: COUNTRY_ERROR,
            payload: error.response ? error.response.data.message : "common:errorLoadingCountries"
        });
    }
};