import React, { useState, useRef, useEffect } from "react";
import {
    Container, Typography, Grid,
    Accordion, AccordionDetails, AccordionSummary,
    Button
} from "@mui/material";
import { ReactComponent as Journals } from '../../assets/Icons/journals.svg';
import { ReactComponent as ExpandMoreIcon } from '../../assets/Icons/drawerControl.svg';
import { ReactComponent as Printer } from '../../assets/Icons/printer.svg';
import IconSvg from '../../components/Icon';
import ResultDetails from "./ResultDetails";
import { useSelector } from "react-redux";
import Moment from "react-moment";
import { useTranslation } from 'react-i18next';
import { useReactToPrint } from 'react-to-print';
import './Results.css';

const Results = () => {
    const { t } = useTranslation(['log', 'label']);
    const system = useSelector(store => store.system.system);
    const logs = useSelector(store => store.log.logs);
    const totalFilter = useSelector(store => store.log.totalFilter);
    const [expanded, setExpanded] = useState([]);
    const componentRef = useRef();
    const allComponentRef = useRef();
    const [printed, setPrinted] = useState(false);
    const pageStyle = `
    @page {
      size: auto;
      margin: 20mm 1mm;
    }

    @media print {
        html, body {
          background-color: #FFFFFF !important;
        }
      }
  `;

    const handlePrint = useReactToPrint({
        content: () => allComponentRef.current,
        documentTitle: "Titulo del articulo",
        pageStyle: pageStyle,
    });

    const print = () => {
        getLogsId();
        const timer = setTimeout(() => {
            document.getElementById("container-print-download").style.display = "none";
            handlePrint();
            setPrinted(true);
        }, 1000);
        return () => clearTimeout(timer);
    };

    const onExpanded = (id) => {
        const list = expanded;
        const log = list.find(l => String(l) === String(id));
        if (log) {
            const index = list.indexOf(log);
            index > -1 && list.splice(index, 1);
        } else {
            list.push(String(id));
        }
        setExpanded(prev => ([...list]));
    };

    const getLogsId = () => {
        const list = [];
        logs.forEach(item => {
            list.push(String(item.id));
        });
        setExpanded(prev => ([...list]));
    };

    useEffect(() => {
        if (printed) {
            document.getElementById("container-print-download").style.display = "flex";
            setExpanded(prev => ([]));
            setPrinted(false);
        }
    }, [printed]);

    return (
        <Container style={{ padding: 0 }}>
            <Grid item xs={12}>
                <div style={{
                    display: 'flex',
                    justifyContent: 'space-between'
                }}>
                    <Typography style={{
                        color: '#231E60',
                        fontStyle: 'normal',
                        fontWeight: 'normal',
                        fontSize: '20px',
                        lineHeight: '115%',
                        marginBottom: '4%', marginTop: '5%'
                    }} noWrap>
                        {totalFilter} {t("subtitle2")}
                    </Typography>
                    <Button className="button-print-download" variant="text" startIcon={<Printer />}
                        onClick={() => print()}>
                        Imprimir Resultados
                    </Button>
                </div>
                <div ref={allComponentRef}>
                    {logs.map(item => (
                        <Accordion className="accordion-sumary-row-title"
                            key={item.id} ref={expanded.indexOf(String(item.id)) !== -1 ? componentRef : null}
                            expanded={expanded.indexOf(String(item.id)) !== -1}
                            onChange={() => onExpanded(item.id)} style={{ width: '100%', marginBottom: 15 }}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon style={{ color: '#5AB6DF' }} />}
                                aria-controls="panel1bh-content"
                                id="panel1bh-header"
                            >
                                <div style={{
                                    display: 'flex', alignItems: 'center',
                                    margin: '29px 8px', width: '40%', flexShrink: 0
                                }}>
                                    <IconSvg Icon={Journals} className="results-icon-svg" />
                                    <Typography className="accordion-title-text" >
                                        {item.authorizationId ? item.authorization.title : item.notification.title}
                                    </Typography>
                                </div>
                                <div style={{
                                    display: 'flex', alignItems: 'center',
                                    margin: '29px 8px', width: '40%', flexShrink: 0
                                }}>
                                    <Typography className="accordion-title-text">
                                        <Moment style={{ marginTop: '4px', textTransform: 'capitalize' }}
                                            format="DD MMMM YYYY" locale={system && system.defaultLanguageId === 1 ? 'en' : 'es-cr'}>
                                            {item.date}
                                        </Moment>
                                    </Typography>
                                </div>
                                <div style={{
                                    display: 'flex', alignItems: 'center',
                                    margin: '29px 25px 29px 8px',
                                    width: '15%', flexShrink: 0
                                }}>
                                    <Typography className="accordion-title-text">
                                        {item.authorizationId
                                            ? item.authorization.client.name
                                            : item.notification.client
                                                ? item.notification.client.name
                                                : t("label:allsLbl")}
                                    </Typography>
                                </div>
                            </AccordionSummary>
                            <AccordionDetails>
                                <ResultDetails log={item} componentRef={componentRef} />
                            </AccordionDetails>
                        </Accordion>
                    ))}
                </div>
            </Grid>
        </Container>
    );
}
export default Results;
