import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Typography, InputLabel, Button, Modal
} from "@mui/material";
import { ReactComponent as CloseCross } from '../../assets/Icons/closeCross.svg';
import TextFieldComponent from '../TextField';
import { createFolder, closeModal, getFoldersFilter } from '../../redux/DocumentDuck';
import { withRouter } from 'react-router-dom';
import './Folder.css';
import { useTranslation } from "react-i18next";

const Folder = (props) => {
    const { t } = useTranslation(['folder']);
    const { open = false, closeFolder, clientId } = props;

    const clients = useSelector(store => store.client.clients);
    const isCreated = useSelector(store => store.doc.isCreatedFolder);
    const [folderName, setFolderName] = useState('');
    const [errorFolderName, setErrorFolderName] = useState('');
    const dispatch = useDispatch();

    const onSubmit = () => {
        var error = '';
        if (folderName === null || folderName.length === 0 ||
            /^\s+$/.test(folderName))
            error = t("nameRequired");
        if (folderName.length > 50)
            error = t("max50");
        setErrorFolderName(error);
        if (!error) {
            dispatch(createFolder(folderName, clientId));
        }
    };

    useEffect(() => {// reset input text on open.
        setFolderName('');
            setErrorFolderName('');
    }, [open]);
    useEffect(() => {
        if (isCreated) {
            setFolderName('');
            setErrorFolderName('');
            dispatch(closeModal())
            closeFolder();
        }
    }, [dispatch, isCreated, closeFolder]);

    useEffect(() => {
        const filterBy = [];
        clientId !== 'all' && filterBy.push({ search: String(clientId), filterBy: 'client' });
        dispatch(getFoldersFilter(filterBy));
    }, [dispatch, clientId]);

    return (
        <Modal
            open={open}
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }}
        >
            <div className="container-div-modal-folder">
                <div>
                    <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 20 }}>
                        <Typography className="folder-title">
                            {t("title")} {(clients.length > 0 && clientId) && clients.find(c => c.id === clientId).name}
                        </Typography>
                        <Button className='button-close-cross' variant="text" onClick={() => closeFolder()} startIcon={<CloseCross />}></Button>
                    </div>
                    <Typography className='folder-subtitle'>
                        {t("subtitle1")}
                    </Typography>
                    <InputLabel className="label-app">{t("nameLbl")}</InputLabel>
                    <div></div>
                    <div class="folder-text-container" style={{ display: 'flex', width: '50%', alignItems: 'center' }}>
                    <TextFieldComponent className="folder-text" name="folderName" placeholder={t("namePlh")}
                            valueInput={folderName} callback={e => setFolderName(e.target.value)}
                            errorInput={errorFolderName}
                        />
                        <Button className="button-app-save" variant="contained"
                            onClick={() => onSubmit()} style={{ marginLeft: 15 }}>{t("createLbl")} </Button>
                    </div>
                </div>
            </div>
        </Modal>
    );
}

export default withRouter(Folder);