import React from "react";
import { Container, Grid, Typography, Card, CardMedia } from "@mui/material";
import helpImage3 from '../../assets/Images/help3.png';
import helpImage4 from '../../assets/Images/help4.png';
import { useTranslation } from "react-i18next";
import './Help.css';

const HelpAppSetting = () => {

    const { t } = useTranslation(['help']);

    return (
        <Container>
            <Typography className="admin-business-text" style={{ marginBottom: '20px' }}>
                {t('help:appSetting:category') + "|" + t('help:appSetting:title')}
            </Typography>

            <Grid item xs={12}>
                <div className="container-app-div  help-screen header" style={{ marginTop: 10 }}>
                    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                        <p className="paragraph-text strong" style={{ marginBottom: '20px' }} >
                            {t('help:thisPageLbl')}
                        </p>
                        <label className="help-label-text index-1">
                            <span className="enum">1</span>{t('help:appSetting:lbl1')}
                        </label>
                        <label className="help-label-text">
                            <span className="enum">2</span> {t('help:appSetting:lbl2')}
                        </label>
                        <label className="help-label-text">
                            <span className="enum">3</span> {t('help:appSetting:lbl3')}
                        </label>
                    </div>
                </div>
                <div className="container-app-div" style={{ marginTop: 10 }}>
                    <div>
                        <label className="help-label-text">
                            {t('help:appSetting:lbl4')}
                        </label>
                        <label className="help-label-text">
                            {t('help:appSetting:lbl5')}
                        </label>
                        <label className="help-label-text">
                            {t('help:appSetting:lbl6')}
                        </label>
                        <label className="help-label-text">
                            {t('help:appSetting:lbl7')}
                        </label>
                    </div>
                </div>
                <div className="container-app-div" style={{ marginTop: 10 }}>
                    <div style={{ display: 'flex', marginBottom: 30 }}>
                        <div style={{ width: '50%', display: 'flex', flexDirection: 'column' }}>
                            <p className="paragraph-text strong">
                                {t('help:step1')} <span className="enum">1</span>  {t('help:optional')} {t('help:appSetting:lbl8')}
                            </p>
                            <label className="help-label-text">
                                {t('help:appSetting:lbl9')}
                            </label>
                            <label className="help-label-text">
                                {t('help:appSetting:lbl10')}
                            </label>
                        </div>
                        <div style={{ width: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Card sx={{ maxWidth: 382, height: 300, boxShadow: 'none' }}>
                                <CardMedia
                                    component="img"
                                    image={helpImage3}
                                    alt="img"
                                    style={{ objectFit: 'contain' }}
                                />
                            </Card>
                        </div>
                    </div>

                </div>
                <div className="container-app-div" style={{ marginTop: 10 }}>
                    <div style={{ display: 'flex', marginBottom: 30 }}>
                        <div style={{ width: '50%', display: 'flex', flexDirection: 'column' }}>
                            <p className="paragraph-text strong">
                                {t('help:step2')} <span className="enum">2</span> : {t('help:appSetting:lbl11')}
                            </p>
                            <label className="help-label-text">
                                {t('help:appSetting:lbl12')}
                            </label>
                            <label className="help-label-text">
                                {t('help:appSetting:lbl13')}
                            </label>
                            <label className="help-label-text">
                                {t('help:appSetting:lbl14')}
                            </label>
                            <br />
                            <label className="help-label-text">
                                {t('help:appSetting:lbl15')}
                            </label>
                        </div>
                        <div style={{ width: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Card sx={{ maxWidth: 376, height: 364, boxShadow: 'none' }}>
                                <CardMedia
                                    component="img"
                                    image={helpImage4}
                                    alt="img"
                                    style={{ objectFit: 'contain' }}
                                />
                            </Card>
                        </div>
                    </div>
                </div>
                <div className="container-app-div" style={{ marginTop: 10 }}>
                    <div style={{ display: 'flex', marginBottom: 30 }}>
                        <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                            <p className="paragraph-text strong">
                                {t('help:step3')} <span className="enum">3</span> : {t('help:appSetting:lbl16')}
                            </p>

                            <label className="help-label-text">
                                {t('help:appSetting:lbl17')}
                            </label>

                            <label className="help-label-text">
                                {t('help:appSetting:lbl18')}
                            </label>
                        </div>
                    </div>
                </div>

            </Grid>
        </Container>
    );
}
export default HelpAppSetting;