import React from 'react';
import { Typography, CardContent } from '@mui/material';
import { Link } from 'react-router-dom';
import './Dashboard.css'

function CardContainer(props) {

    return (
        <CardContent className='card-content-item' component={Link}
            to={props.route}>
            <div>
                {props.icono}
                <Typography className='card-text'>
                    {props.texto}
                </Typography>
            </div>
        </CardContent>
    );
}

export default CardContainer;