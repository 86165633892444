import React from "react";
import {
    TableCell, TableHead, TableContainer,
    Container, TableBody, Table, TableRow,
    Button
} from "@mui/material";
import { ReactComponent as Printer } from '../../assets/Icons/printer.svg';
import { useReactToPrint } from 'react-to-print';
import Moment from "react-moment";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import './ResultDetails.css';

const ResultDetails = ({ log, componentRef }) => {

    const system = useSelector(store => store.system.system);
    const { t } = useTranslation(['document', 'log', 'label']);
    const pageStyle = `
    @page {
      size: auto;
      margin: 20mm 1mm;
    }

    @media print {
        html, body {
          background-color: #FFFFFF !important;
        }
      }
  `;

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: log.authorizationId ? log.authorization.title : log.notification.title,
        pageStyle: pageStyle,
    });

    const print = () => {
        document.getElementById("container-print-download").style.display = "none";
        handlePrint();
        document.getElementById("container-print-download").style.display = "flex";
    };

    return (
        <Container>
            <TableContainer>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow className="header-cell">
                            <TableCell className="detail-table-cell" align="right">{t('descriptionLbl')}</TableCell>
                            <TableCell sx={{ width: '78%' }} className="detail-table-cell">{t('detailLbl')}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow key={'id'}>
                            <TableCell component="th" scope="row" className="description-table-cell" >
                                {t('userLbl')}
                            </TableCell>
                            <TableCell className="detail-table-cell">
                                {log.user.firstName + ' ' + log.user.lastName}
                            </TableCell>
                        </TableRow>
                        <TableRow key={'id2'}>
                            <TableCell component="th" scope="row" className="description-table-cell">
                                {t('clientLbl')}
                            </TableCell>
                            <TableCell className="detail-table-cell">
                                {log.authorizationId
                                    ? log.authorization.client.name
                                    : log.notification.client
                                        ? log.notification.client.name
                                        : t("label:allsLbl")}
                            </TableCell>
                        </TableRow>
                        <TableRow key={'id4'}>
                            <TableCell component="th" scope="row" className="description-table-cell">
                                {t('titleLbl')}
                            </TableCell>
                            <TableCell className="detail-table-cell">
                                {log.authorizationId ? log.authorization.title : log.notification.title}
                            </TableCell>
                        </TableRow>
                        <TableRow key={'id5'}>
                            <TableCell component="th" scope="row" className="description-table-cell">
                                {t('dateLbl')}
                            </TableCell>
                            <TableCell className="detail-table-cell">
                                <Moment style={{ marginTop: '4px', textTransform: 'capitalize' }}
                                    format="DD MMMM YYYY, LT"
                                    locale={system && system.defaultLanguageId === 1 ? 'en' : 'es-cr'}>
                                    {log.authorizationId ? log.authorization.created : log.notification.created}
                                </Moment>
                            </TableCell>
                        </TableRow>
                        <TableRow key={'id6'}>
                            <TableCell component="th" scope="row" className="description-table-cell">
                                IP
                            </TableCell>
                            <TableCell className="detail-table-cell">
                                {log.ip}
                            </TableCell>
                        </TableRow>
                        <TableRow key={'id7'}>
                            <TableCell component="th" scope="row" className="description-table-cell">
                                {t("log:headerTbl1")}
                            </TableCell>
                            <TableCell className="detail-table-cell">
                                {log.authorizationId ? log.authorization.description : log.notification.description}
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
            <div style={{ display: 'flex', marginTop: 19 }} id="container-print-download">
                <Button className="button-print-download" variant="text" startIcon={<Printer />}
                    style={{ marginRight: 24 }} onClick={() => print()}>
                    {t('printLbl')}
                </Button>
            </div>
        </Container>
    );

}
export default ResultDetails;