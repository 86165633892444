import React from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Image from "./Image";
import Images from "./Images";
import Map from "./Map";
import UserData from './UserData';
import UserForm from "./UserForm";
import Text from "./Text";
import Video from "./Video";
import './DragDropItem.css';

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

const getItemStyle = (draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    padding: '0px 0px 24px 0px',
    ...draggableStyle
});

const DragDropItem = ({ onChangeContentOrder, page = {}, deleteContent,
    onContentValueChange, deleteImageToGallery, deleteFields, contentPageError,
    pageSelected }) => {

    const onDragEnd = (result) => {
        if (!result.destination) return;

        const temp = reorder(
            page.content,
            result.source.index,
            result.destination.index
        );

        onChangeContentOrder(temp, page.pageIndex);
    };

    return (
        <div>
            <DragDropContext onDragEnd={i => onDragEnd(i)}>
                <Droppable droppableId="droppable">
                    {(provided) => (
                        <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                        >
                            {page.content.map((item, index) => (
                                <Draggable
                                    key={item.contentIndex}
                                    draggableId={String(item.contentIndex)}
                                    index={index}
                                >
                                    {(provided) => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            style={getItemStyle(
                                                provided.draggableProps.style
                                            )}
                                        >
                                            {item.contentType === 'TEXT' &&
                                                <Text
                                                    index={index + 1}
                                                    pageIndex={page.pageIndex}
                                                    onHtmlChange={onContentValueChange}
                                                    value={item.value}
                                                    deleteContent={deleteContent}
                                                    error={(Object.entries(contentPageError).length !== 0 && pageSelected in contentPageError && contentPageError[page.pageIndex].find(key => item.contentIndex in key))
                                                        ? contentPageError[page.pageIndex].find(key => item.contentIndex in key)[item.contentIndex]
                                                        : []
                                                    }
                                                />
                                            }
                                            {item.contentType === 'IMAGE' &&
                                                <Image
                                                    index={index + 1}
                                                    pageIndex={page.pageIndex}
                                                    onImageChange={onContentValueChange}
                                                    value={item.value}
                                                    deleteContent={deleteContent}
                                                    error={(Object.entries(contentPageError).length !== 0 && pageSelected in contentPageError && contentPageError[page.pageIndex].find(key => item.contentIndex in key))
                                                        ? contentPageError[page.pageIndex].find(key => item.contentIndex in key)[item.contentIndex]
                                                        : []
                                                    }
                                                />
                                            }
                                            {item.contentType === 'GALLERY' &&
                                                <Images
                                                    index={index + 1}
                                                    pageIndex={page.pageIndex}
                                                    onImagesChange={onContentValueChange}
                                                    value={item.value}
                                                    deleteContent={deleteContent}
                                                    deleteImageToGallery={deleteImageToGallery}
                                                    error={(Object.entries(contentPageError).length !== 0 && pageSelected in contentPageError && contentPageError[page.pageIndex].find(key => item.contentIndex in key))
                                                        ? contentPageError[page.pageIndex].find(key => item.contentIndex in key)[item.contentIndex]
                                                        : []
                                                    }
                                                />
                                            }
                                            {item.contentType === 'VIDEO' &&
                                                <Video
                                                    index={index + 1}
                                                    pageIndex={page.pageIndex}
                                                    onURLChange={onContentValueChange}
                                                    value={item.value}
                                                    deleteContent={deleteContent}
                                                    error={(Object.entries(contentPageError).length !== 0 && pageSelected in contentPageError && contentPageError[page.pageIndex].find(key => item.contentIndex in key))
                                                        ? contentPageError[page.pageIndex].find(key => item.contentIndex in key)[item.contentIndex]
                                                        : []
                                                    }
                                                />
                                            }
                                            {item.contentType === 'MAP' &&
                                                <Map
                                                    index={index + 1}
                                                    pageIndex={page.pageIndex}
                                                    onMapChange={onContentValueChange}
                                                    value={item.value}
                                                    deleteContent={deleteContent}
                                                    error={(Object.entries(contentPageError).length !== 0 && pageSelected in contentPageError && contentPageError[page.pageIndex].find(key => item.contentIndex in key))
                                                        ? contentPageError[page.pageIndex].find(key => item.contentIndex in key)[item.contentIndex]
                                                        : []
                                                    }
                                                />
                                            }
                                            {item.contentType === 'FORM' &&
                                                <UserForm
                                                    index={index + 1}
                                                    pageIndex={page.pageIndex}
                                                    onFormItemChange={onContentValueChange}
                                                    value={item.value}
                                                    deleteContent={deleteContent}
                                                    deleteFields={deleteFields}
                                                    error={(Object.entries(contentPageError).length !== 0 && pageSelected in contentPageError && contentPageError[page.pageIndex].find(key => item.contentIndex in key))
                                                        ? contentPageError[page.pageIndex].find(key => item.contentIndex in key)[item.contentIndex]
                                                        : []
                                                    }
                                                />
                                            }
                                            {item.contentType === 'USER_CARD' &&
                                                <UserData
                                                    index={index + 1}
                                                    pageIndex={page.pageIndex}
                                                    onUserCardChange={onContentValueChange}
                                                    value={item.value}
                                                    deleteContent={deleteContent}
                                                    error={(Object.entries(contentPageError).length !== 0 && pageSelected in contentPageError && contentPageError[page.pageIndex].find(key => item.contentIndex in key))
                                                        ? contentPageError[page.pageIndex].find(key => item.contentIndex in key)[item.contentIndex]
                                                        : []
                                                    }
                                                />
                                            }
                                        </div>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        </div>
    );
};

export default DragDropItem;