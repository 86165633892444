import React, { useState, useEffect } from "react";
import {
    Container, Grid, Avatar, Typography, InputLabel,
    TableContainer, Table, TableHead, TableRow,
    TableCell, TableBody, Button
} from "@mui/material";
import Title from "../../components/Title";
import SelectFieldComponent from "../../components/SelectField";
import SwitchComponent from "../../components/Switch";
import CircleIcon from '@mui/icons-material/Circle';
import { ReactComponent as Search } from '../../assets/Icons/search.svg';
import TextFieldComponent from "../../components/TextField";
import PaginationCustom from "../../components/Pagination";
import { changeClientPage, getClients } from "../../redux/ClientDuck";
import { useDispatch, useSelector } from "react-redux";
import { assignClientToUser } from "../../redux/UserAssignDuck";
//REMOVE IF SURE WE'RE NOT USING IT // import { getUserById, getUsers, clearUserList } from '../../redux/UserDuck'
import { getUserById, getUsers } from '../../redux/UserDuck'
import { getFileURL } from "../../constants/apiUrls";
import './UserAssigment.css';
import { getBusinessesFilter, getOfficesFilter } from '../../redux/BusinessDuck'
import { useTranslation } from 'react-i18next';

const UserAssigment = () => {

    const { t } = useTranslation(['userAssignment', 'common', 'validations']);

    const userAuth = useSelector(store => store.auth.user);
    const user = useSelector(store => store.user.user);
    const users = useSelector(store => store.user.users);
    const activePageClient = useSelector(store => store.client.activePage);
    const pagesClient = useSelector(store => store.client.pages);

    const clientsRedux = useSelector(store => store.client.clients);
    const officesRedux = useSelector(store => store.business.offices);

    const businesses = useSelector(store => store.business.businesses);
    const [activeClients, setActiveClients] = useState([]);
    const [activeClientsCount, setActiveClientsCount] = useState("");
    const [userList, setUserList] = useState([]);
    const [businessList, setBusinessList] = useState([]);
    const [searchClient, setSearchClient] = useState('');
    const [userSelected, setUserSelected] = useState('');
    const [businessId, setBusinessId] = useState('all');
    const [officeId, setOfficeId] = useState('all');
    const [resetPage, setResetPage] = useState(false);
    const [error, setError] = useState({});
    const [offices, setOffices] = useState([]);
    const dispatch = useDispatch();

    //componentDidMount
    useEffect(() => {
        dispatch(changeClientPage(1));
        //component will unmount
        return () => {
            dispatch(changeClientPage(1));
        }
    }, [dispatch]);

    const onSubmit = () => {
        if (validateUser()) {
            dispatch(assignClientToUser(userSelected, activeClients));
        }
    };

    const onUserChange = (e) => {
        const { value } = e.target;
        setUserSelected(value);
        dispatch(getUserById(value, true));
    };

    const onChangeClient = (event) => {
        setSearchClient(event.target.value);
        setResetPage(true);
    };

    const onSwitch = event => {
        const { name, checked } = event.target;

        const list = activeClients;
        if (checked) {
            list.push(String(name));
        } else {
            const index = list.indexOf(String(name));
            index > -1 && list.splice(index, 1);
        }
        setActiveClients(prev => ([...list]));
    };

    const validateUser = () => {
        var errorTemp = { userSelected: '' };

        if (userSelected === null || userSelected.length === 0
            || /^\s+$/.test(userSelected))
            errorTemp.userSelected = t("errorUser");
        setError({ ...error, ...errorTemp });
        return !errorTemp.userSelected;
    };

    useEffect(() => {
        if (userSelected) {
            const page = resetPage ? 0 : activePageClient;
            const timer = setTimeout(() =>
                dispatch(getClients([
                    { search: searchClient, filterBy: 'name' },
                    { search: 'false', filterBy: 'disabled' },
                    { search: userSelected, filterBy: 'user-office' }
                ], page, 10, false, 'user-assignment')), 1000);
            setResetPage(false);
            return () => clearTimeout(timer);
        }
        // eslint-disable-next-line
    }, [dispatch, searchClient, userSelected]);

    useEffect(() => {
        const page = resetPage ? 0 : activePageClient;
        dispatch(getClients([
            { search: searchClient, filterBy: 'name' },
            { search: 'false', filterBy: 'disabled' }
        ], page, 10, false, 'user-assignment'));
        setResetPage(false);
        // eslint-disable-next-line
    }, [dispatch, activePageClient]);

    useEffect(() => {
        const list = [];
        users.forEach(item => {
            list.push({
                key: item.id,
                value: item.firstName + ' ' + item.lastName,
            });
        });
        setUserList(list);
    }, [users]);

    useEffect(() => {
        const officesTemp = [];
        officesRedux.forEach(item => {
            officesTemp.push({ key: item.id, value: item.name });
        });
        setOffices(officesTemp);
        setOfficeId('all');
    }, [businessId, officesRedux]);

    useEffect(() => {
        const list = [];
        businesses.forEach(item => {
            const officeList = [];
            item.offices.forEach(office => {
                officeList.push({ key: office.id, value: office.name });
            });
            list.push({
                key: item.id, value: item.name,
                offices: officeList
            });
        });
        setBusinessList(list);
    }, [businesses]);

    useEffect(() => {
        if (userAuth) {
            const filterBy = [];
            businessId !== 'all' && filterBy.push({ search: String(businessId), filterBy: 'company' });
            officeId !== 'all' && filterBy.push({ search: String(officeId), filterBy: 'office' });
            setUserSelected('');
            dispatch(getUsers(filterBy, 0, 10000, false, "users-assignment"));
        }
    }, [dispatch, businessId, officeId, userAuth]);

    /*
    useEffect(() => {
        if (businessId || officeId)
            dispatch(getUsers([
               
                { search: String(businessId), filterBy: 'company', service: 'users-assignment'  },
                { search: String(officeId), filterBy: 'office', service: 'users-assignment' }
            ]));
        else {
            dispatch(clearUserList());
        }
    }, [dispatch, businessId, officeId]);
    */

    useEffect(() => {
        dispatch(getBusinessesFilter([], false));
    }, [dispatch]);

    useEffect(() => {
        dispatch(getOfficesFilter(businessId !== 'all' ? [{
            "search": String(businessId),
            "filterBy": "company"
        }] : [], false));
    }, [dispatch, businessId]);

    const changeBusinessId = e => {
        setBusinessId(e.target.value);
        setOfficeId('all');
        setUserSelected('');
    };

    useEffect(() => {
        if (user) {
            const clientList = [];
            let activeClientsCount = 0;
            const clientUsers = user.organizationUser ? user.clientUsers : [];
            clientUsers.forEach(item => {
                !item.client.disabled ? activeClientsCount += 1 : activeClientsCount += 0;
                clientList.push(String(item.clientId));
            });
            setActiveClientsCount(activeClientsCount);
            setActiveClients(clientList);
        };
    }, [user]);

    useEffect(() => {
        if (userSelected !== '' && activePageClient !== 0) {
            dispatch(changeClientPage(1));
        }
        // eslint-disable-next-line 
    }, [dispatch, userSelected]);

    return (
        <Container>
            <Title title={t("title")} style={{ marginLeft: 5 }} />
            <Grid item xs={12}>
                <div className="container-app-div">
                    <Typography className="app-form-title">{t("common:commonFilterLbl")}s</Typography>
                    <div style={{
                        display: 'flex', flexDirection: 'row',
                        width: '100%', justifyContent: 'space-between',
                        marginBottom: 24
                    }}>
                        <div style={{ width: '48%' }}>
                            <InputLabel className="label-app">{t("common:commonFilterLbl")}</InputLabel>
                            <SelectFieldComponent name="businessName"
                                valueInput={businessId}
                                options={[
                                    { key: 'all', value: t("common:commonAllLbl") },
                                    ...businessList
                                ]}
                                onChange={changeBusinessId}
                            />
                        </div>

                        <div style={{ width: '48%' }}>
                            <InputLabel className="label-app">{t("common:commonOfficeLbl")}</InputLabel>
                            <SelectFieldComponent name="officesName"
                                valueInput={officeId}
                                options={[{ key: 'all', value: t("common:commonAllLbl") }, ...offices]}
                                onChange={e => setOfficeId(e.target.value)}
                            />
                        </div>

                    </div>
                    <InputLabel className="label-app">{t("common:commonUserLbl")}</InputLabel>
                    <SelectFieldComponent
                        valueInput={userSelected}
                        onChange={onUserChange}
                        options={userList.length === 0 ? [{ key: '', value: t("noUsersFoundLbl") }] : userList}
                        errorInput={error.userSelected}
                    />
                </div>
                {(userSelected && user && userList.find(item => item.key === userSelected)) &&
                    <div className="container-app-div" style={{ display: 'flex' }}>
                        <div>
                            <Avatar
                                className='avatar-image'
                                alt={userList.find(item => item.key === userSelected).value}
                                src={getFileURL + user.fileId}
                                sx={{ width: 112, height: 112 }}
                            />
                        </div>
                        <div style={{ marginLeft: 37 }}>
                            <Typography className="user-name-text">
                                {userList.find(item => item.key === userSelected).value}
                            </Typography>
                            <div className='table-row-cell' style={{
                                color: user.disabled ? '#6C757D' : '#28A745', margin: '9px 0px'
                            }}>
                                <CircleIcon style={{ fontSize: 7, marginRight: 7 }} />
                                <Typography>
                                    {!user.disabled ? t("common:commonStatusActive") : t("common:commonStatusArchived")}
                                </Typography>
                            </div>
                            <Typography className="clients-number-assigned" style={{ margin: '4px 0px' }}>
                                {user.organizationUser ?
                                    activeClientsCount
                                    : 0} {t("subtitle1")}
                            </Typography>
                        </div>
                    </div>
                }
                {(userSelected && user) &&

                    <div className="container-app-div">
                        <Typography className="app-form-title">{t("subtitle2")}</Typography>
                        <div style={{ width: '35%' }}>
                            <TextFieldComponent Icon={<Search />} position='start' placeholder={t("common:commonSearchPlh")}
                                valueInput={searchClient} callback={onChangeClient}
                            />
                        </div>
                        <TableContainer>
                            <Table aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell className='table-row-info'>{t("headerTbl1")}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {clientsRedux.length === 0 &&
                                        <TableRow key='empty'>
                                            <TableCell colSpan={1} style={{ textAlign: 'center' }}>
                                                {t("common:commonNoResultsLbl")}
                                            </TableCell>
                                        </TableRow>
                                    }
                                    {clientsRedux.map((option) => (
                                        <TableRow key={option.id}>
                                            <TableCell className="table-row-clients-cell">
                                                <div className='align-divs-clients' >
                                                    <Typography className={`access-text-clients ${activeClients.includes(String(option.id)) && "access-text-active-clients"}`} >
                                                        {option.name}</Typography>
                                                    <div style={{ marginRight: 18 }}>
                                                        <SwitchComponent
                                                            callback={onSwitch}
                                                            name={option.id}
                                                            checked={activeClients.includes(String(option.id))}
                                                        />
                                                    </div>
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <br />
                        <PaginationCustom pages={pagesClient} activePage={activePageClient}
                            onChange={page => dispatch(changeClientPage(page))} />
                    </div>
                }
                {userSelected &&
                    <div style={{ display: 'flex' }}>
                        <Button variant="contained" className="button-app-save" style={{ marginRight: 18 }}
                            onClick={() => onSubmit()}>
                            {t("common:commonSubmitBtn")}
                        </Button>
                    </div>
                }
            </Grid>
        </Container>
    );
}
export default (UserAssigment);