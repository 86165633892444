import { getPermissionByIdURL, getPermissionCopyURL, putPermissionURL } from '../constants/apiUrls';
import { getAxios, putAxios } from '../services/Axios';
import { logoutAction, renewTokenAction } from './AuthDuck'

// constantes
const initialState = {
    permission: null,
    response: null,
    loading: false,
    error: null,
};

// types
const PERMISSION_SUCCESS = 'PERMISSION_SUCCESS';
const PERMISSION_UDPATE_SUCCESS = 'PERMISSION_UDPATE_SUCCESS';
const PERMISSION_ERROR = 'PERMISSION_ERROR';
const LOADING_PERMISSION = 'LOADING_PERMISSION';
const CLEAR_RESPONSE = 'CLEAR_RESPONSE';
const LOG_OUT = 'LOG_OUT';
const INITIAL_STATE = 'INITIAL_STATE';

// reducer
export default function reducer(state = initialState, action) {
    switch (action.type) {
        case LOADING_PERMISSION:
            return { ...state, loading: true, error: null };
        case PERMISSION_SUCCESS:
            return { ...state, permission: action.payload, error: null, loading: false };
        case PERMISSION_UDPATE_SUCCESS:
            return { ...state, response: action.response, error: null, loading: false };
        case PERMISSION_ERROR:
            return {
                ...state, error: action.payload, loading: false,
                response: action.payload
            };
        case CLEAR_RESPONSE:
            return { ...state, response: null };
        case LOG_OUT:
            return { ...initialState };
        case INITIAL_STATE:
            return {
                ...state,
                permission: null,
            };
        default:
            return state;
    }
};

export const getPermissionById = (id) => async (dispatch) => {
    dispatch({ type: LOADING_PERMISSION });
    try {
        const res = await getAxios(getPermissionByIdURL, { id: id });
        if (res.status === 200) {
            const token = res.headers.refreshtoken;
            const expiration = res.headers.expirationToken;
            (token && expiration) && dispatch(renewTokenAction(token, expiration));
            return dispatch({
                type: PERMISSION_SUCCESS,
                payload: res.data.response
            });
        }
        throw new Error();
    } catch (error) {
        if (error.response && error.response.status === 401)
            dispatch(logoutAction());
        dispatch({
            type: PERMISSION_ERROR,
            payload: error.response ? error.response.data.message : "common:errorLoadingPermissions"
        });
    }
};

export const copyPermission = (userIdFrom, userIdTo) => async dispatch => {
    dispatch({ type: LOADING_PERMISSION });
    try {
        const res = await getAxios(getPermissionCopyURL, {
            'userIdFrom': userIdFrom,
            'userIdTo': userIdTo
        });
        if (res.status === 200) {
            const token = res.headers.refreshtoken;
            const expiration = res.headers.expirationToken;
            (token && expiration) && dispatch(renewTokenAction(token, expiration));
            dispatch({
                type: PERMISSION_UDPATE_SUCCESS,
                response: res.data.response
            });
            return dispatch(getPermissionById(userIdTo));
        }
        throw new Error();
    } catch (error) {
        if (error.response && error.response.status === 401)
            dispatch(logoutAction());
        dispatch({
            type: PERMISSION_ERROR,
            payload: error.response ? error.response.data.message : "common:errorCopyingPermissions"
        });
    }
};

export const editPermission = (userId, permissions, functionalPermissions) => async dispatch => {
    dispatch({ type: LOADING_PERMISSION });
    try {
        const permissionList = [];
        permissions.forEach(permission => {
            permission === 'Permissions.Companies' &&
                permissionList.push({ 'permissionName': 'Permissions.Offices' });
            permissionList.push({ 'permissionName': permission });
        });
        if (functionalPermissions.receiveMailFormApp)
            permissionList.push({ 'permissionName': 'FunctionalPermissions.AppFormNotification' });
        if (functionalPermissions.receiveMailNewClient && functionalPermissions.offices.length > 0) {
            permissionList.push({
                'permissionName': 'FunctionalPermissions.NewClientNotification',
                'offices': functionalPermissions.offices
            });
        }
        const res = await putAxios(putPermissionURL, {
            'userId': userId,
            'permissions': permissionList,
        });
        if (res.status === 200) {
            const token = res.headers.refreshtoken;
            const expiration = res.headers.expirationToken;
            (token && expiration) && dispatch(renewTokenAction(token, expiration));
            dispatch({
                type: PERMISSION_UDPATE_SUCCESS,
                response: res.data.response
            });
            return dispatch(getPermissionById(userId));
        }
        throw new Error();
    } catch (error) {
        if (error.response && error.response.status === 401)
            dispatch(logoutAction());
        dispatch({
            type: PERMISSION_ERROR,
            payload: error.response ? error.response.data.message : "common:errorEditingPermissions"
        });
    }
}
