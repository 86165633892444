import React from "react";
import { Grid } from "@mui/material";
import { ReactComponent as Card } from '../../../assets/Icons/card-heading.svg';
import { ReactComponent as Imagen } from '../../../assets/Icons/imagen.svg';
import { ReactComponent as Video } from '../../../assets/Icons/video.svg';
import { ReactComponent as Images } from '../../../assets/Icons/images.svg';
import { ReactComponent as Send } from '../../../assets/Icons/send.svg';
import { ReactComponent as Direccionpoint } from '../../../assets/Icons/direccionpoint.svg';
import { ReactComponent as Blueuser } from '../../../assets/Icons/blueuser.svg';
import FolderContainer from "./FolderContainer";
import { useTranslation } from 'react-i18next';
const Folders = ({ addContent, pageIndex }) => {
    const { t } = useTranslation(['mobileContent', 'common']);
  
    return (
        <Grid container>
            <Grid item xs={12} >
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                    <FolderContainer type='TEXT' pageIndex={pageIndex} addContent={addContent}
                        texto={t("textLbl")} icono={<Card />}
                    />
                    <FolderContainer type='IMAGE' pageIndex={pageIndex} addContent={addContent}
                        texto={t("imageLbl")} icono={<Imagen />}
                    />
                    <FolderContainer type='GALLERY' pageIndex={pageIndex} addContent={addContent}
                        texto={t("galleryLbl")} icono={<Images />}
                    />
                    <FolderContainer type='VIDEO' pageIndex={pageIndex} addContent={addContent}
                        texto={t("videoLbl")} icono={<Video />}
                    />
                    <FolderContainer type='FORM' pageIndex={pageIndex} addContent={addContent}
                        texto={t("formLbl")} icono={<Send />}
                    />
                    <FolderContainer type='MAP' pageIndex={pageIndex} addContent={addContent}
                        texto={t("mapLbl")} icono={<Direccionpoint />}
                    />
                    <FolderContainer type='USER_CARD' pageIndex={pageIndex} addContent={addContent}
                        texto={t("userCardLbl")} icono={<Blueuser />}
                    />
                </div>
            </Grid>
        </Grid>
    );
}

export default Folders;