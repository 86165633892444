import React, { useState, useEffect } from "react";
import {
    Grid, Typography, IconButton, Button,
    Accordion, AccordionDetails, AccordionSummary,
} from "@mui/material";
import { ReactComponent as Edit } from '../../../assets/Icons/edit.svg';
import { ReactComponent as Drag } from '../../../assets/Icons/drag.svg';
import { ReactComponent as Files } from '../../../assets/Icons/files.svg';
import { ReactComponent as Trash } from '../../../assets/Icons/trash.svg';
import { ReactComponent as ImagesIcon } from '../../../assets/Icons/images.svg';
import { ReactComponent as Cloud } from '../../../assets/Icons/cloudUpload.svg';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import SelectFieldComponent from "../../../components/SelectField";
import Checkbox from '../../../components/Checkbox';
import IconSvg from '../../../components/Icon';
import { useSelector } from "react-redux";
import './Images.css';
import { useTranslation } from 'react-i18next';

const Images = ({ index, pageIndex, value = null, onImagesChange, deleteContent,
    deleteImageToGallery, error = [] }) => {

    const { t } = useTranslation(['mobileContent', 'common']);
    const lenguages = useSelector(store => store.lenguage.lenguages);
    const defaultLanguageId = useSelector(store => store.system.system.defaultLanguageId);
    const secondaryLanguageId = useSelector(store => store.appSetting.appSetting.secundaryLanguageId);
    const [language, setLanguage] = useState(defaultLanguageId);
    const [languageList, setLanguageList] = useState([]);
    const [expanded, setExpanded] = useState(false);
    const [duplicateContent, setDuplicateContent] = useState(false);
    const [fileList, setFileList] = useState([]);
    const [isChanged, setIsChanged] = useState(false);

    const onImageChange = event => {
        if (!!event.target.files) {
            const files = value[language] ? value[language] : [];
            let folderId = null;
            if (files.length > 0) {
                folderId = files[0].folderId;
            }
            Array.from(event.target.files).forEach(file => {
                files.push({
                    id: null,
                    fileId: null,
                    folderId: folderId,
                    file: file,
                    width: 0,
                    height: 0,
                    imageUrl: URL.createObjectURL(file),
                    check: duplicateContent
                });
            });
            setFileList(prev => ([...files]));
            setIsChanged(true);
        }
    };

    useEffect(() => {
        if (isChanged) {
            const list = fileList;

            list.forEach(item => {
                const img = new Image();
                img.src = URL.createObjectURL(item.file);
                img.onload = () => {
                    item.width = img.width;
                    item.height = img.height;
                };
            });

            const timer = setTimeout(() => {
                duplicateContent ?
                    lenguages.forEach(({ key }) => {
                        onImagesChange(pageIndex, index - 1, list, key);
                    })
                    :
                    onImagesChange(pageIndex, index - 1, list, language);
                setIsChanged(false);
            }, 1000);

            return () => clearTimeout(timer);
        }

        // eslint-disable-next-line
    }, [fileList, isChanged]);

    const onDeleteImage = (indexImg) => {
        const fileList = value[language] ? value[language] : [];
        fileList[indexImg].fileId && deleteImageToGallery(fileList[indexImg].fileId);
        fileList.splice(indexImg, 1);
        onImagesChange(pageIndex, index - 1, fileList, language);
    };

    useEffect(() => {
        const list = [];
        lenguages
            .filter(lng => [secondaryLanguageId, defaultLanguageId].includes(lng.key))
            .forEach(item => {
                list.push({
                    key: item.key,
                    value: <span style={{ color: '#5AB6DF' }}>{item.code.toUpperCase()}</span>
                });
            });
        setLanguageList(list);
    }, [lenguages, secondaryLanguageId, defaultLanguageId]);

    useEffect(() => {
        if (value[language]) {
            const check = value[language].find(img => img.check === true);
            setDuplicateContent(check ? true : false);
        }
    }, [value, language]);

    return (
        <Accordion className="accordion-sumary-row-title-mobile" expanded={expanded}
            style={{ width: '100%' }}>
            <AccordionSummary
                aria-controls="panel1bh-content"
                id="panel1bh-header"
            >
                <div style={{ width: '98%', display: 'flex', justifyContent: 'space-between' }}>
                    <div style={{
                        display: 'flex', alignItems: 'center',
                        margin: '29px 8px', flexShrink: 0, width: '44%'
                    }} onClick={() => setExpanded(!expanded)}>
                        <IconSvg Icon={ImagesIcon} className="icon-svg-mobile" />
                        <Typography className="accordion-title-text-mobile" >
                            {index}. {t("galleryLbl2")}
                        </Typography>
                    </div>
                    <div style={{
                        display: 'flex', alignItems: 'center',
                        width: '48%', justifyContent: 'flex-end'
                    }}>
                        {secondaryLanguageId &&
                            <SelectFieldComponent
                                name="language"
                                className="language-code"
                                valueInput={language}
                                options={[
                                    ...languageList
                                ]}
                                onChange={e => setLanguage(e.target.value)}
                            />
                        }
                        <div style={{ display: 'flex', alignItems: 'center', marginTop: 5 }}>
                            <Edit style={{ margin: '0 20px' }} />
                            <Files style={{ marginRight: 20 }} />
                            <Drag />
                        </div>
                    </div>
                </div>
                {(error.length > 0 && error.find(err => language in err))
                    && error.find(err => language in err)[language] &&
                    <p className='app-error-text'>{error.find(err => language in err)[language]}</p>
                }
            </AccordionSummary>
            <AccordionDetails>
                <div style={{ margin: '15px 16px 29px 15px' }}>
                    {secondaryLanguageId &&
                        <>
                            <Typography className="app-form-title">{t("subtitle5")}</Typography>
                            <Checkbox
                                name='primaryLng'
                                value={duplicateContent}
                                checkboxCheckedValue={true}
                                checkboxLabel={t("subtitle22")}
                                onChange={() => secondaryLanguageId ? setDuplicateContent(!duplicateContent) : null}
                                disabled={!!value[language]}
                            />
                        </>
                    }
                    <div className='div-images'>
                        <div style={{
                            display: 'flex', flexWrap: 'wrap', margin: 15
                        }}>
                            {value[language] && value[language].map((image, index) => (
                                <Grid item xs={12} sm={4} md={3} key={index}
                                    style={{ position: 'relative', height: 100, maxWidth: 100, margin: '0px 15px 15px 0px' }}
                                >
                                    <img src={image.imageUrl} alt=''
                                        style={{
                                            height: 100,
                                            width: 100,
                                            objectFit: "scale-down"
                                        }}
                                    />
                                    <IconButton style={{
                                        position: 'absolute', top: '0%',
                                        right: '0%', cursor: 'pointer'
                                    }} onClick={onDeleteImage.bind(this, index)}>
                                        <Trash className="mobile-form-icon-svg-delete mobile-form-icon-delete" />
                                    </IconButton>
                                </Grid>
                            ))}
                            <Button className='button-mobile' style={{ marginBottom: 15 }}>
                                <input type="file" accept=".jpg, image/png" multiple
                                    name="image" onChange={onImageChange} />
                                <div>
                                    <Cloud className='mobile-button-icon-svg mobile-button-icon' />
                                    <Typography className='button-text-upload'>
                                        {t("subtitle8")}
                                    </Typography>
                                </div>
                            </Button>
                        </div>
                    </div>
                </div>
                <div style={{ display: 'flex', marginTop: 20, justifyContent: 'space-between' }}>
                    {secondaryLanguageId ?
                        <Typography className='mobile-content-alert'>
                            <InfoOutlinedIcon fontSize='small' style={{ marginRight: 10 }} />
                            {t("subtitle20")}
                        </Typography>
                        : <div></div>
                    }
                    <IconButton onClick={() => deleteContent(pageIndex, index - 1)}>
                        <Trash className="mobile-icon-svg-delete mobile-icon-delete" />
                    </IconButton>
                </div>
            </AccordionDetails>
        </Accordion >
    );
}

export default Images;