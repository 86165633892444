import React from "react";
import { Container, Grid, Typography, Card, CardMedia } from "@mui/material";
import helpImage7 from '../../assets/Images/help7.png';
import helpImage8 from '../../assets/Images/help8.png';
import helpImage9 from '../../assets/Images/help9.png';
import helpImage10 from '../../assets/Images/help10.png';
import { useTranslation } from "react-i18next";
import './Help.css';

const HelpMobileContent = () => {

    const { t } = useTranslation(['help']);

    return (
        <Container>
          <Typography className="admin-business-text" style={{ marginBottom: '20px' }}>
                            {t('help:mobileContent:category')+"|"+t('help:mobileContent:title')}
                        </Typography>

            <Grid item xs={12}>
                
                    <div className="container-app-div help-screen header" style={{ marginTop: 10 , display: 'flex', flexDirection: 'column', width: '100%' }}>
                        <div style={{ width:'50%'}}>
                        <p className="paragraph-text strong" style={{ marginBottom: '20px' }} >
                            {t('help:thisPageLbl')}
                        </p>
                        <label className="help-label-text">
                        <span className="enum">1</span>{t('help:mobileContent:lbl1')}
                        </label>
                        <label className="help-label-text">
                        <span className="enum">2</span>{t('help:mobileContent:lbl2')}
                        </label>
                        <label className="help-label-text">
                        <span className="enum">3</span>{t('help:mobileContent:lbl3')}
                        </label>
                        </div>
                        <div style={{ width:'50%'}}>
                        <br />
                        <label className="help-label-text">
                            {t('help:mobileContent:lbl4')}
                        </label>
                        <label className="help-label-text">
                            {t('help:mobileContent:lbl5')}
                        </label>
                        
                            
                        </div>
                        
                        </div>
                        <div className="container-app-div" style={{ marginTop: 10 , display: 'flex', flexDirection: 'column', width: '100%' }}>
                        <div style={{ marginBottom: 30 }}>
                            <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                                <p className="paragraph-text strong">
                                    {t('help:step1')}<span className="enum">1</span> :&nbsp;{t('help:mobileContent:lbl6')}
                                </p>
                                <label className="help-label-text">
                                    {t('help:mobileContent:lbl7')}
                                </label>
                                <label className="help-label-text">
                                    {t('help:mobileContent:lbl8')}
                                </label>
                                <br />
                            </div>
                            <div style={{
                                width: '100%', display: 'flex',
                                justifyContent: 'center', alignItems: 'center'
                            }}>
                                <Card sx={{ width: 450, height: 153, boxShadow: 'none' }}>
                                    <CardMedia
                                        component="img"
                                        image={helpImage7}
                                        alt="img"
                                        style={{ objectFit: 'contain' }}
                                    />
                                </Card>
                            </div>
                        </div>
                        </div>
                        <div className="container-app-div help-screen " style={{ marginTop: 10 , display: 'flex', flexDirection: 'column', width: '100%' }}>
                    
                            <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                                <p className="paragraph-text strong">
                                    {t('help:step2')}<span className="enum">2</span> {": "+ t('help:mobileContent:lbl9')}
                                </p>
                                
                                <label className="help-label-text">
                                    {t('help:mobileContent:lbl10')}
                                </label>
                                <label className="help-label-text">
                                    {t('help:mobileContent:lbl11')}
                                </label>
                            </div>
                            <div style={{
                                width: '100%', display: 'flex', marginTop: 40,
                                justifyContent: 'center', alignItems: 'center'
                            }}>
                                <Card sx={{ width: '707px', height: 206, boxShadow: 'none',marginBottom:'30px' }}>
                                    <CardMedia
                                        component="img"
                                        image={helpImage8}
                                        alt="img"
                                        style={{ objectFit: 'contain' }}
                                    />
                                </Card>
                            </div>
                    
                        <div style={{ display: 'flex', marginBottom: 30 }}>
                            <div style={{ width: '50%', display: 'flex', flexDirection: 'column' }}>
                                <label className="help-label-text">
                                    {t('help:mobileContent:lbl12')}
                                </label>
                                <label className="help-label-text">
                                    {t('help:mobileContent:lbl13')}
                                </label>
                                <label className="help-label-text">
                                    {t('help:mobileContent:lbl14')}
                                </label>
                                <label className="help-label-text">
                                    {t('help:mobileContent:lbl15')}
                                </label>
                                <label className="help-label-text">
                                    {t('help:mobileContent:lbl16')}
                                </label>
                                <label className="help-label-text">
                                    {t('help:mobileContent:lbl17')}
                                </label>
                            </div>
                            <div style={{ width: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <Card sx={{ maxWidth: 374, height: 297, boxShadow: 'none' }}>
                                    <CardMedia
                                        component="img"
                                        image={helpImage9}
                                        alt="img"
                                        style={{ objectFit: 'contain' }}
                                    />
                                </Card>
                            </div>
                        </div>
                        </div>
                        
                        <div className="container-app-div help-screen " style={{ marginTop: 10 , display: 'flex', flexDirection: 'row', width: '100%' }}>
                       
                            <div style={{ width: '50%', display: 'flex', flexDirection: 'column' }}>
                                <p className="paragraph-text strong">
                                    {t('help:step3')}<span className="enum">3</span> {":  "+   t('help:mobileContent:lbl18')}
                              
                                </p>
                                <label className="help-label-text">
                                    {t('help:mobileContent:lbl19')}
                                </label>
                                <label className="help-label-text">
                                    {t('help:mobileContent:lbl20')}
                                </label>
                                <br />
                                <label className="help-label-text">
                                    {t('help:mobileContent:lbl21')}
                                </label>
                            </div>
                            <div style={{ width: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <Card sx={{ maxWidth: 375, height: 78, boxShadow: 'none' }}>
                                    <CardMedia
                                        component="img"
                                        image={helpImage10}
                                        alt="img"
                                        style={{ objectFit: 'contain' }}
                                    />
                                </Card>
                            </div>
                        </div>
                   
                
            </Grid>
        </Container>
    );
}
export default HelpMobileContent;