import React from "react";
import { withRouter } from "react-router";
import { Link } from 'react-router-dom';
import {
    Typography, Button, TableContainer, TableRow,
    TableCell, Table, TableHead, TableBody
} from "@mui/material";
import CircleIcon from '@mui/icons-material/Circle';
import * as ROUTES from '../../../constants/routes';
import './Table.css';
import { useTranslation } from 'react-i18next';
const TableBusiness = ({ businessList = [] }) => {
    const { t } = useTranslation(['business']);
    return (
        <TableContainer>
            <Table aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell sx={{ width: '65%' }} className='table-app-title-header'>{t("headerTbl1")}</TableCell>
                        <TableCell className='table-app-title-header'>{t("headerTbl2")}</TableCell>
                        <TableCell className='table-app-title-header' align="right">{t("headerTbl3")}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {businessList.length === 0 &&
                        <TableRow key='empty'>
                            <TableCell colSpan={3} style={{ textAlign: 'center' }}>
                            {t("noResultsLbl")}
                                </TableCell>
                        </TableRow>
                    }
                    {businessList.map(item => (
                        <TableRow key={item.id}>
                            <TableCell component="th" scope="row" className='table-row-name'>
                                {item.name}
                            </TableCell>
                            <TableCell>
                                <div className='table-row-cell' style={{ color: item.disabled ? '#6C757D' : '#28A745' }}>
                                    <CircleIcon style={{ fontSize: 10, marginRight: 11 }} />
                                    <Typography>
                                        {item.disabled ? t("archivedStatusLbl") : t("activeStatusLbl")}
                                    </Typography>
                                </div>
                            </TableCell>
                            <TableCell align="right">
                                <Button className='button-app-see-detail' component={Link}
                                    to={ROUTES.BUSINESS_UPDATE.replace(':id', item.id)}>
                                    {t("seeLbl")}
                                </Button>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

export default withRouter(TableBusiness)