import { postAxios, getAxios } from "../services/Axios";
import { getNotificationsURL, getNotificationByIdURL, postNotificationURL } from "../constants/apiUrls";
import { logoutAction, renewTokenAction } from './AuthDuck'

// constantes
const initialState = {
    notifications: [],
    notification: null,
    pages: 1,
    activePage: 0,
    isCreated: false,
    response: null,
    loading: false,
    error: null,
};

// types
const NOTIFICATION_SUCCESS = 'NOTIFICATION_SUCCESS';
const CREATE_NOTIFICATION_SUCCESS = 'CREATE_NOTIFICATION_SUCCESS';
const NOTIFICATION_GET_BY_ID = 'NOTIFICATION_GET_BY_ID';
const NOTIFICATION_ERROR = 'NOTIFICATION_ERROR';
const LOADING_NOTIFICATION = 'LOADING_NOTIFICATION';
const CLEAR_RESPONSE = 'CLEAR_RESPONSE';
const NOTIFICATION_CHANGE_PAGE = 'NOTIFICATION_CHANGE_PAGE';
const LOG_OUT = 'LOG_OUT';
const INITIAL_STATE = 'INITIAL_STATE';

// reducer
export default function reducer(state = initialState, action) {
    switch (action.type) {
        case LOADING_NOTIFICATION:
            return { ...state, loading: true, error: null };
        case NOTIFICATION_SUCCESS:
            return {
                ...state, notifications: action.payload, isCreated: false,
                pages: action.pages, error: null, loading: false
            };
        case CREATE_NOTIFICATION_SUCCESS:
            return {
                ...state, response: action.response, isCreated: true,
                loading: false, error: null,
                notifications: [], activePage: 0, pages: 1
            };
        case NOTIFICATION_GET_BY_ID:
            return {
                ...state, notification: action.payload,
                isCreated: false, error: null, loading: false,
                notifications: [], activePage: 0, pages: 1
            };
        case NOTIFICATION_CHANGE_PAGE:
            return { ...state, activePage: action.activePage };
        case CLEAR_RESPONSE:
            return { ...state, response: null };
        case NOTIFICATION_ERROR:
            return {
                ...state, error: action.payload,
                response: action.payload, loading: false
            };
        case LOG_OUT:
            return { ...initialState };
        case INITIAL_STATE:
            return {
                ...state,
                notifications: [],
                notification: null,
                pages: 1,
                activePage: 0,
            };
        default:
            return state;
    }
};

export const getNotifications = (filterBy = [], page = 0, pageSize = 10000, loading = false) => async (dispatch, getState) => {
    loading && dispatch({ type: LOADING_NOTIFICATION });
    try {
        const { user } = getState().auth;
        const res = await postAxios(getNotificationsURL, {
            "organizationId": user.organizationId,
            "page": page,
            "pageSize": pageSize,
            "filterBy": filterBy,
            "orderBy": "",
            "orderDesc": true
        });
        if (res.status === 200) {
            const token = res.headers.refreshtoken;
            const expiration = res.headers.expirationToken;
            (token && expiration) && dispatch(renewTokenAction(token, expiration));
            return dispatch({
                type: NOTIFICATION_SUCCESS,
                payload: res.data.response,
                pages: res.data.totalPages
            });
        }
        throw new Error();
    } catch (error) {
        if (error.response && error.response.status === 401)
            dispatch(logoutAction());
        dispatch({
            type: NOTIFICATION_ERROR,
            payload: error.response ? error.response.data.message : "common:errorLoadingNotifications"
        });
    }
};

export const getNotificationById = (id) => async (dispatch) => {
    dispatch({ type: LOADING_NOTIFICATION });
    try {
        const res = await getAxios(getNotificationByIdURL + id);
        if (res.status === 200) {
            const token = res.headers.refreshtoken;
            const expiration = res.headers.expirationToken;
            (token && expiration) && dispatch(renewTokenAction(token, expiration));
            return dispatch({
                type: NOTIFICATION_GET_BY_ID,
                payload: res.data.response
            });
        }
        throw new Error();
    } catch (error) {
        if (error.response && error.response.status === 401)
            dispatch(logoutAction());
        dispatch({
            type: NOTIFICATION_ERROR,
            payload: error.response ? error.response.data.message : "common:errorLoadingAuth"
        });
    }
};

export const saveNotification = (notification) => async (dispatch, getState) => {
    dispatch({ type: LOADING_NOTIFICATION });
    try {
        const userRedux = getState().auth.user;
        const receiverIds = [];

        notification.receivers.forEach(rec => {
            receiverIds.push(rec.id);
        });

        const res = await postAxios(postNotificationURL, {
            'userId': userRedux.id,
            'companyId': notification.companyId,
            'officeId': notification.officeId,
            'clientId': notification.clientId !== 'all' ? notification.clientId : null,
            'title': notification.title,
            'description': notification.description,
            'receivers': receiverIds
        });

        if (res.status === 200) {
            const token = res.headers.refreshtoken;
            const expiration = res.headers.expirationToken;
            (token && expiration) && dispatch(renewTokenAction(token, expiration));
            return dispatch({
                type: CREATE_NOTIFICATION_SUCCESS,
                response: res.data.response
            });
        }
        throw new Error();
    } catch (error) {
        if (error.response && error.response.status === 401)
            dispatch(logoutAction());
        dispatch({
            type: NOTIFICATION_ERROR,
            payload: error.response ? error.response.data.message : "common:errorSavingNotification"
        });
    }
};

export const changeNotificationPage = (page) => (dispatch) => {
    dispatch({
        type: NOTIFICATION_CHANGE_PAGE,
        activePage: page - 1
    });
};
