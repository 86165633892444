import React, { useState, useEffect } from "react";
import { withRouter } from "react-router";
import {
    TableContainer, Table, TableHead, TableCell, TableRow,
    TableBody, Button, Modal, Typography, Box, Tab, Divider, InputLabel
} from "@mui/material";
import { ReactComponent as User } from '../../../assets/Icons/user.svg';
import { ReactComponent as CloseCross } from '../../../assets/Icons/closeCross.svg';
import { ReactComponent as Email } from '../../../assets/Icons/email.svg';
import { ReactComponent as Search } from '../../../assets/Icons/search.svg';
import { ReactComponent as BriefCase } from '../../../assets/Icons/briefcase.svg';
import { ReactComponent as Spam } from '../../../assets/Icons/spam.svg';
import { ReactComponent as CheckCircle } from '../../../assets/Icons/checkCircle.svg';
import './TableUserPending.css';
import { useDispatch, useSelector } from "react-redux";
import {
    getClientsOfClientStaff,
    getPendingClientStaffById, clientStaffSpam, saveClientStaff, changeClientPage
} from "../../../redux/UserAssignDuck";
import { TabContext, TabList, TabPanel } from '@mui/lab'
import TextFieldComponent from "../../../components/TextField";
import SelectFieldComponent from "../../../components/SelectField";
import TableClientAssigment from "../TableClientAssigments";
import PaginationCustom from "../../../components/Pagination";
import { custStaffValidation } from "../Read/CustomerStaffValidation";
import { getCountries } from "../../../redux/CountryDuck";
import HelpClientStaff from "../../Help/HelpClientStaff";
import { useTranslation } from "react-i18next";

const TableUserPending = ({ userList = [], assigned }) => {
    const { t } = useTranslation(['customerStaff', 'common']);
    const clientStaff = useSelector(store => store.assign.pendingClient);
    const activePageClient = useSelector(store => store.assign.activePageClient);
    const pagesClient = useSelector(store => store.assign.pagesClient);
    const businesses = useSelector(store => store.business.businesses);
    const countries = useSelector(store => store.country.countries);
    const [value, setValue] = useState('1');
    const [open, setOpen] = useState(false);
    const [activeClients, setActiveClients] = useState([]);
    const [openClient, setOpenClient] = useState(false);
    const [searchNewCustStaff, setSearchNewCustStaff] = useState('');
    const [resetPageClient, setResetPageClient] = useState(false);
    const [client, setClient] = useState({
        firstName: '', lastName: '', email: '',
        countryCodeId: '', phoneNumber: ''
    });
    const [error, setError] = useState({});
    const [valueClient, setValueClient] = useState('1');
    const [openModal, setOpenModal] = useState(false);

    const dispatch = useDispatch();

    //componentDidMount
    useEffect(() => {
        dispatch(changeClientPage(1));
        //component will unmount
        return () => {
            dispatch(changeClientPage(1));
        }
    }, [dispatch]);

    const onChangeClient = e => {
        const { name, value } = e.target;
        setClient({ ...client, [name]: value })
    };

    const handleOpenClient = () => {
        setClient({
            id: clientStaff.id,
            firstName: clientStaff.firstName, lastName: clientStaff.lastName,
            email: clientStaff.email, countryCodeId: '', phoneNumber: ''
        });
        setOpenClient(true);
    };

    const handleCloseClient = () => {
        handleClose();
        setClient({
            id: '', firstName: '', lastName: '', email: '',
            countryCodeId: '', phoneNumber: ''
        });
        setActiveClients([]);
        setError({});
        setValueClient("1");
        setOpenClient(false);
        dispatch(changeClientPage(1));
        setSearchNewCustStaff('');
    };

    const onSubmit = () => {
        const custStaffError = custStaffValidation(client, activeClients, true);
        setError(custStaffError);
        if (Object.entries(custStaffError).length === 0) {
            dispatch(saveClientStaff(client, activeClients, true));
            setClient({
                id: '', firstName: '', lastName: '', email: '',
                countryCodeId: '', phoneNumber: ''
            });
            setValueClient('1');
            setActiveClients([]);
            setOpenClient(false);
            setOpen(false);
            dispatch(changeClientPage(1));
        }
    };

    const onChangeStep = (newValue) => {
        const custStaffError = custStaffValidation(client, activeClients, false);
        setError(custStaffError);
        if (Object.entries(custStaffError).length === 0) {
            setValueClient(newValue);
            newValue === "2" && setOpenModal(true);
        }
    };

    const nextStep = () => {
        const custStaffError = custStaffValidation(clientStaff, activeClients, false);
        setError(custStaffError);
        if (Object.entries(custStaffError).length === 0) {
            setValueClient('2');
            setOpenModal(true);
        }
    };

    const onChangeSearchNewCustStaff = (e) => {
        setSearchNewCustStaff(e.target.value);
        setResetPageClient(true);
    };

    useEffect(() => {
        const page = resetPageClient ? 0 : activePageClient;
        const filterBy = [{ search: "false", filterBy: "disabled" }];
        searchNewCustStaff !== '' && filterBy.push({ search: searchNewCustStaff, filterBy: 'name' });
        const timer = setTimeout(() => dispatch(getClientsOfClientStaff(filterBy, page, 10)), 1000);
        setResetPageClient(false);
        return () => clearTimeout(timer);
        // eslint-disable-next-line
    }, [dispatch, searchNewCustStaff]);

    useEffect(() => {
        const page = resetPageClient ? 0 : activePageClient;
        const filterBy = [{ search: "false", filterBy: "disabled" }];
        searchNewCustStaff !== '' && filterBy.push({ search: searchNewCustStaff, filterBy: 'name' });
        dispatch(getClientsOfClientStaff(filterBy, page, 10));
        setResetPageClient(false);
        // eslint-disable-next-line
    }, [dispatch, activePageClient]);

    useEffect(() => {
        dispatch(getCountries());
        // dispatch(getBusinessesFilter());
    }, [dispatch]);


    const handleOpen = (userId) => {
        dispatch(getPendingClientStaffById(userId));
        setOpen(true);
    };
    const handleClose = () => setOpen(false);

    const handleChange = (value, newValue) => {
        setValue(newValue);
    };

    return (
        <TableContainer>
            <Table aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell className='table-app-title-header'>{t("headerTbl5")}</TableCell>
                        <TableCell className='table-app-title-header'>{t("headerTbl6")}</TableCell>
                        <TableCell className='table-app-title-header'>{t("headerTbl8")}</TableCell>
                        <TableCell className='table-app-title-header' align="right">{t("headerTbl9")}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {userList.length === 0 &&
                        <TableRow key='empty'>
                            <TableCell colSpan={5} style={{ textAlign: 'center' }}>
                                {t("noResultsLbl")}
                            </TableCell>
                        </TableRow>
                    }
                    {userList.map(item => (
                        <TableRow key={item.id}>
                            <TableCell component="th" scope="row" className='app-form-subtitle'
                                style={{
                                    maxWidth: 150,
                                    whiteSpace: 'nowrap',
                                    textOverflow: 'ellipsis',
                                    overflow: 'hidden'
                                }}>
                                {item.firstName} {item.lastName}
                            </TableCell>
                            <TableCell component="th" scope="row" className='app-form-subtitle'>
                                {item.email}
                            </TableCell>
                            <TableCell component="th" scope="row" className='app-form-subtitle'
                                style={{
                                    maxWidth: 150,
                                    whiteSpace: 'nowrap',
                                    textOverflow: 'ellipsis',
                                    overflow: 'hidden'
                                }}
                            >
                                {item.clientCompany}
                            </TableCell>
                            <TableCell align="right">
                                <Button className='button-app-see-detail'
                                    onClick={() => handleOpen(item.id)}
                                >
                                    {t("seeLbl")}
                                </Button>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <div>
                <Modal
                    open={open}
                    onClose={handleClose}
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}
                >
                    <div className="container-div-modal">
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Typography className="modal-title-text">{t("subtitle3")}</Typography>
                            <Button className='button-close-cross' variant="text" onClick={handleClose} startIcon={<CloseCross />}></Button>
                        </div>
                        <div>

                            <TabContext value={value}>
                                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                    <TabList
                                        onChange={handleChange}
                                        aria-label="lab API tabs example">
                                        <Tab label={t("subtitle4")} value="1" />
                                    </TabList>
                                </Box>
                                <TabPanel value="1" className='tab-select-page-assigments-data-user'>
                                    <Typography className="subtitle-data-client" style={{ marginBottom: 32 }}>{t("subtitle4")}</Typography>
                                    <div style={{ display: 'flex', marginBottom: 39 }}>
                                        <div> <User /> </div>
                                        <div style={{ marginLeft: 19 }}>
                                            <Typography className="title-data-client">{t("headerTbl1")}</Typography>
                                            <Typography className="subtitle-data-client" style={{ marginTop: '4px' }}>
                                                {clientStaff && (clientStaff.firstName + ' ' + clientStaff.lastName)}
                                            </Typography>
                                        </div>
                                    </div>
                                    <div style={{ display: 'flex', marginBottom: 39 }}>
                                        <div className="icon-for-clients-text"> <Email /> </div>
                                        <div style={{ marginLeft: 19 }}>
                                            <Typography className="title-data-client">{t("headerTbl2")}</Typography>
                                            <Typography className="subtitle-data-client" style={{ marginTop: '4px' }}>
                                                {clientStaff && clientStaff.email}
                                            </Typography>
                                        </div>
                                    </div>
                                    <div style={{ display: 'flex', marginBottom: 39 }}>
                                        <div className="icon-for-clients-text"> <BriefCase /> </div>
                                        <div style={{ marginLeft: 19 }}>
                                            <Typography className="title-data-client">{t("headerTbl3")}</Typography>
                                            <Typography className="subtitle-data-client" style={{ marginTop: '4px' }}>
                                                {clientStaff && clientStaff.clientCompany}
                                            </Typography>
                                        </div>
                                    </div>
                                    <div style={{ display: 'flex', marginBottom: 39 }}>
                                        <div className="icon-for-clients-text"> <BriefCase /> </div>
                                        <div style={{ marginLeft: 19 }}>
                                            <Typography className="title-data-client">{t("companyFilterLbl")}</Typography>
                                            <Typography className="subtitle-data-client" style={{ marginTop: '4px' }}>
                                                {(businesses.length > 0 && clientStaff) &&
                                                    businesses.find(item => item.id === clientStaff.companyId) ?
                                                    businesses.find(item => item.id === clientStaff.companyId).name
                                                    : 'Empresa inactiva'
                                                }
                                            </Typography>
                                        </div>
                                    </div>
                                    <div style={{ display: 'flex', marginBottom: 39 }}>
                                        <div className="icon-for-clients-text"> <BriefCase /> </div>
                                        <div style={{ marginLeft: 19 }}>
                                            <Typography className="title-data-client">{t("officeLbl")}</Typography>
                                            <Typography className="subtitle-data-client" style={{ marginTop: '4px' }}>
                                                {(businesses.length > 0 && clientStaff) &&
                                                    businesses.find(item => item.id === clientStaff.companyId) ?
                                                    businesses.find(item => item.id === clientStaff.companyId)
                                                        .offices.find(of => of.id === clientStaff.officeId).name
                                                    : 'Oficina inactiva'
                                                }
                                            </Typography>
                                        </div>
                                    </div>
                                    <Divider className="divider-style" />
                                    <Typography className="app-form-title">{t("optionsLbl")}</Typography>
                                    {clientStaff &&
                                        <Button variant="contained" className="button-app-save"
                                            style={{ marginRight: 20 }}
                                            onClick={() => handleOpenClient()}>
                                            {t("assignBtn")}
                                        </Button>
                                    }
                                    {clientStaff && !clientStaff.spam
                                        ?
                                        <Button className='button-spam-no-spam' variant="text"
                                            onClick={() => dispatch(clientStaffSpam(clientStaff.id))} startIcon={<Spam />}>
                                            {t("markSpamLbl")}
                                        </Button>
                                        :
                                        <Button className='button-spam-no-spam no-spam-icon'
                                            variant="text" startIcon={<CheckCircle className="no-spam-icon" />}
                                            onClick={() => dispatch(clientStaffSpam(clientStaff.id))}>
                                            {t("notSpamLbl")}
                                        </Button>
                                    }
                                </TabPanel>
                            </TabContext>
                        </div>
                    </div>
                </Modal>
            </div>

            <HelpClientStaff open={openModal} closeModal={() => setOpenModal(false)} />

            <div>
                <Modal
                    open={openClient}
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}
                >
                    <div className="container-div-modal-create-update-customer">
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Typography className="modal-title-text">{t("subtitle7")}</Typography>
                            <Button className='button-close-cross' variant="text"
                                onClick={handleCloseClient} startIcon={<CloseCross />}></Button>
                        </div>
                        <div>
                            <TabContext value={valueClient}>
                                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                    <TabList onChange={(value, newValue) => onChangeStep(newValue)}
                                        aria-label="lab API tabs example">
                                        <Tab label={t("subtitle4")} value="1" />
                                        <Tab label={t("subtitle5")} value="2" />
                                    </TabList>
                                </Box>
                                <TabPanel value="1" className='tab-select-page-assigments-data-user'>
                                    <Typography className="app-form-title">{t("subtitle4")}</Typography>
                                    <div style={{
                                        display: 'flex', flexDirection: 'row', marginBottom: 10,
                                        width: '99%', justifyContent: 'space-between'
                                    }}>
                                        <div style={{ width: '48%' }}>
                                            <InputLabel className="label-app">{t("headerTbl1")}</InputLabel>
                                            <TextFieldComponent name="firstName" placeholder={t("headerTbl1")}
                                                valueInput={client.firstName}
                                                errorInput={error.firstName}
                                                callback={onChangeClient.bind(this)}
                                            />
                                        </div>
                                        <div style={{ width: '48%' }}>
                                            <InputLabel className="label-app">{t("headerTblLast")}</InputLabel>
                                            <TextFieldComponent name="lastName" placeholder={t("headerTblLast")}
                                                valueInput={client.lastName}
                                                errorInput={error.lastName}
                                                callback={onChangeClient.bind(this)}
                                            />
                                        </div>
                                    </div>
                                    <div style={{
                                        display: 'flex', flexDirection: 'row', marginBottom: 35,
                                        width: '99%', justifyContent: 'space-between'
                                    }}>
                                        <div style={{ width: '48%' }}>
                                            <InputLabel className="label-app">Email</InputLabel>
                                            <TextFieldComponent name="email" placeholder="Email"
                                                valueInput={client.email}
                                                errorInput={error.email}
                                                callback={onChangeClient.bind(this)}
                                            />
                                        </div>
                                        <div style={{
                                            width: '48%', display: 'flex',
                                            flexDirection: 'row', justifyContent: 'space-between'
                                        }}>
                                            <div style={{ width: '28%' }}>
                                                <InputLabel className="label-app">{t("headerTblPhone") + " (" + t("headerTblOptional") + ")"}</InputLabel>
                                                <SelectFieldComponent
                                                    name="countryCodeId"
                                                    onChange={onChangeClient.bind(this)}
                                                    errorInput={error.countryCodeId}
                                                    valueInput={client.countryCodeId}
                                                    options={countries.length > 0 ? [{ key: '', value: t("headerTblOptional") }, ...countries] : []}
                                                />
                                            </div>
                                            <div style={{ width: '68%', display: 'flex', alignItems: 'flex-end', flexDirection: 'column' }}>
                                                <InputLabel className="label-app" style={{ opacity: 0 }}>1</InputLabel>
                                                <TextFieldComponent name="phoneNumber" placeholder='88888888'
                                                    maxLength={30}
                                                    valueInput={client.phoneNumber}
                                                    errorInput={error.phoneNumber}
                                                    callback={onChangeClient.bind(this)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <Divider className="divider-style" />
                                    <Button variant="contained" className="button-app-save"
                                        style={{ marginRight: 18 }}
                                        onClick={() => nextStep()}>
                                        {t("nextLbl")}
                                    </Button>
                                </TabPanel>
                                <TabPanel value="2" className='tab-select-page-assigments-clients-assigment'>
                                    <Typography className="clients-assigment-title">{t("subtitle6")}</Typography>
                                    <div style={{ width: '35%' }}>
                                        <TextFieldComponent Icon={<Search />} position='start'
                                            callback={onChangeSearchNewCustStaff}
                                            valueInput={searchNewCustStaff}
                                            placeholder={t("common:commonSearchPlh")} />
                                    </div>
                                    <TableClientAssigment
                                        isCreating={true}
                                        setActivatedClients={setActiveClients}
                                        activatedClients={activeClients}
                                    />
                                    <br />
                                    <PaginationCustom pages={pagesClient} activePage={activePageClient}
                                        onChange={page => dispatch(changeClientPage(page))} />
                                    {!error.activeClients && <br />}
                                    {error.activeClients &&
                                        <>
                                            <p className='app-error-text'>{error.activeClients}</p>
                                            <br />
                                        </>
                                    }
                                    <Button variant="contained" className="button-app-save"
                                        style={{ marginRight: 18 }}
                                        onClick={() => onSubmit()}>
                                        {t("submitBtn")}
                                    </Button>
                                </TabPanel>
                            </TabContext>
                        </div>
                    </div>
                </Modal>
            </div>

        </TableContainer>
    );

}

export default withRouter(TableUserPending);