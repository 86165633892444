import React from "react";
import { Container, Grid, Typography, Card, CardMedia } from "@mui/material";
// import helpImage22 from '../../assets/Images/help22.png';
// import helpImage23 from '../../assets/Images/help23.png';
import helpImage24 from '../../assets/Images/help24b.png';
import { useTranslation } from "react-i18next";
import './Help.css';

const HelpNotification = () => {

    const { t } = useTranslation(['help']);

    return (
        <Container>
                 <Typography className="admin-business-text" style={{ marginBottom: '20px' }}>
                            {t('help:notification:category')+"|"+t('help:notification:title')}
                        </Typography>

            <Grid item xs={12}>
                <div className="container-app-div help-screen header" style={{ marginTop: 10 }}>
                    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                        <p className="paragraph-text strong" style={{ marginBottom: '20px' }} >
                            {t('help:thisPageLbl')}
                        </p>
                        <label className="help-label-text">
                        <span className="enum">1</span> {t('help:notification:lbl1')}
                        </label>
                        </div>
                        <div>
                        <label className="help-label-text">
                           {t('help:notification:lbl2')}
                        </label>
                        <label className="help-label-text">
                            {t('help:notification:lbl3')}
                        </label>
                        </div></div>
                        <div className="container-app-div" style={{ marginTop: 10 }}>
                
                        <div style={{ display: 'flex',
marginBottom: '60px',
flexDirection: 'column' }}>
                            <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                            <p className="paragraph-text strong">
                                    {t('help:step1')}<span className="enum">1</span>:&nbsp;{t('help:notification:lbl1')}
                                </p>
                                <p className="paragraph-text">
                                    {t('help:notification:lbl4')}
                                </p>
                                <label className="help-label-text">
                                    1.{t('help:notification:lbl5')}
                                </label>
                                <label className="help-label-text">
                                    {t('help:notification:lbl6')}
                                </label>
                                <label className="help-label-text">
                                    {t('help:notification:lbl7')}
                                </label>
                                <label className="help-label-text">
                                    {t('help:notification:lbl8')}
                                </label>
                                <label className="help-label-text p">
                                    2.{t('help:notification:lbl9')}
                                </label>
                                <label className="help-label-text">
                                    {t('help:notification:lbl10')}
                                </label>
                                <label className="help-label-text p">
                                   3. {t('help:notification:lbl11')}
                                </label>
                                <label className="help-label-text">
                                    {t('help:notification:lbl12')}
                                </label>
                                <label className="help-label-text">
                                    {t('help:notification:lbl13')}
                                </label>
                            
                           
                        </div>
                        
                                <Card sx={{ maxWidth: 652, height: 170, boxShadow: 'none',margin: 'auto' }}>
                                    <CardMedia
                                        component="img"
                                        image={helpImage24}
                                        alt="img"
                                        style={{ objectFit: 'contain' }}
                                    />
                                </Card>
                            
                        
                    </div>
                </div>
            </Grid>
        </Container>
    );
}
export default HelpNotification;