import React from "react";
import { Container, Grid, Typography, Card, CardMedia } from "@mui/material";
// import helpImage25 from '../../assets/Images/help25.png';
// import helpImage26 from '../../assets/Images/help26.png';
// import helpImage27 from '../../assets/Images/help27.png';
import helpImage28 from '../../assets/Images/help28b.png';
import { useTranslation } from "react-i18next";
import './Help.css';

const HelpAuthorization = () => {

    const { t } = useTranslation(['help']);

    return (
        <Container>
                        <Typography className="admin-business-text" style={{ marginBottom: '20px' }}>
                            {t('help:authorization:category')+"|"+t('help:authorization:title')}
                        </Typography>

            <Grid item xs={12}>
                <div className="container-app-div help-screen header" style={{ marginTop: 10 }}>
                    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                      
                      

                        <p className="paragraph-text strong" style={{ marginBottom: '20px' }} >
                            {t('help:thisPageLbl')}
                        </p>
                        <label className="help-label-text">
                        <span className="enum">1</span> {t('help:authorization:lbl1')}
                        </label>
                        
                      </div>
                      <div>

                        <label className="help-label-text">
                            {t('help:authorization:lbl2')}
                        </label>
                        <label className="help-label-text">
                            {t('help:authorization:lbl3')}
                        </label>
                        <br />
                      </div>
                        </div>
                    
                    <div className="container-app-div" style={{ marginTop: 10 }}>
                
                        <div style={{ display: 'flex', marginBottom: 60 }}>
                            <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                            <label className="help-label-text">
                            {t('help:step1')}<span className="enum">1</span>:&nbsp;{t('help:authorization:lbl1')}
                        </label>
                       
                                <p className="paragraph-text">
                                    {t('help:authorization:lbl4')}
                                </p>
                                <label className="help-label-text">
                                    1.{t('help:authorization:lbl5')}
                                </label>
                                <label className="help-label-text">
                                    {t('help:authorization:lbl6')}
                                </label>
                                <label className="help-label-text p">
                                    2.{t('help:authorization:lbl7')}
                                </label>
                                <label className="help-label-text">
                                    {t('help:authorization:lbl8')}
                                </label>
                                <label className="help-label-text p">
                                    3.{t('help:authorization:lbl9')}
                                </label>
                                <label className="help-label-text">
                                    {t('help:authorization:lbl10')}
                                </label>
                                <label className="help-label-text p">
                                    4.{t('help:authorization:lbl11')}
                                </label>
                                <label className="help-label-text">
                                    {t('help:authorization:lbl12')}
                                </label>
                                <label className="help-label-text">
                                    {t('help:authorization:lbl13')}
                                </label>
                            </div>
                            
                        </div>
                        <div style={{ display: 'flex', marginBottom: 60 }}>
                                <Card sx={{ maxWidth: 652, height: 165, boxShadow: 'none',margin:'auto' }}>
                                    <CardMedia
                                        component="img"
                                        image={helpImage28}
                                        alt="img"
                                        style={{ objectFit: 'contain' }}
                                    />
                                </Card>
                        </div>
                        
                    </div>
                
            </Grid>
        </Container>
    );
}
export default HelpAuthorization;