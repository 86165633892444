import { putOrganizationURL, getOrganizationIdURL } from '../constants/apiUrls';
import { getAxios, putAxios } from '../services/Axios';
import { logoutAction, renewTokenAction } from './AuthDuck';

// constantes
const initialState = {
    system: null,
    region: null,
    loadedSystem: false,
    systemUpdated: false,
    loading: false,
    error: null,
    response: null
};

// types
const SYSTEM_SUCCESS = 'SYSTEM_SUCCESS';
const SYSTEM_ERROR = 'SYSTEM_ERROR';
const LOADING_SYSTEM = 'LOADING_SYSTEM';
const SYSTEM_SUCCESS_SAVE = 'SYSTEM_SUCCESS_SAVE';
const LOG_OUT = 'LOG_OUT';
const CLEAR_RESPONSE = 'CLEAR_RESPONSE';
const SYSTEM_RESPONSE = 'SYSTEM_RESPONSE';
const LENGUAGE_ID_SUCCESS = 'LENGUAGE_ID_SUCCESS';
const REGION_SUCCESS = 'REGION_SUCCESS';

// reducer
export default function reducer(state = initialState, action) {
    switch (action.type) {
        case LOADING_SYSTEM:
            return { ...state, loading: true, error: null };
        case SYSTEM_SUCCESS:
            return {
                ...state, system: action.payload,
                loadedSystem: true, error: null, loading: false
            };
        case SYSTEM_SUCCESS_SAVE:
            return { ...state, response: action.response, systemUpdated: true, loadedSystem: false };
        case SYSTEM_ERROR:
            return {
                ...state, error: action.payload, loading: false,
                response: action.payload
            };
        case REGION_SUCCESS:
            return {
                ...state, region: action.payload
            }
        case CLEAR_RESPONSE:
            return { ...state, response: null };
        case SYSTEM_RESPONSE:
            return { ...state, response: action.response };
        case LOG_OUT:
            return { ...initialState };
        default:
            return state;
    }
};

// actions
export const saveSystemConfiguration = (config) => async (dispatch, getState) => {
    dispatch({ type: LOADING_SYSTEM });
    try {
        const { system } = getState().system;
        const bodyFormData = new FormData();
        bodyFormData.append('id', system.id);
        bodyFormData.append('defaultLanguageId', config.languageId);
        bodyFormData.append('appSettingId', system.appSettingId);
        bodyFormData.append('logo', config.image ? config.image : null);
        bodyFormData.append('fileId', system.fileId);
        bodyFormData.append('socialMediaOrganizationId', system.socialMediaOrganizationId ? system.socialMediaOrganizationId : 0);
        bodyFormData.append('name', config.name);
        bodyFormData.append('webSite', config.webSite);
        bodyFormData.append('email', config.email);
        bodyFormData.append('countryCodeId', config.countryCode);
        bodyFormData.append('country', config.country);
        bodyFormData.append('phone', config.phoneNumber);
        bodyFormData.append('numberOfLicense', system.numberOfLicense);
        bodyFormData.append('disabled', config.phoneNumber);
        bodyFormData.append('socialMediaOrganization.id', system.socialMediaOrganizationId ? system.socialMediaOrganizationId : 0);
        bodyFormData.append('socialMediaOrganization.Facebook', config.facebookUrl ? config.facebookUrl : '');
        bodyFormData.append('socialMediaOrganization.Instagram', config.instagramUrl ? config.instagramUrl : '');
        bodyFormData.append('socialMediaOrganization.Linkedin', config.linkedinUrl ? config.linkedinUrl : '');
        bodyFormData.append('socialMediaOrganization.Twitter', config.twitterUrl ? config.twitterUrl : '');
        bodyFormData.append('socialMediaOrganization.CountryCodeId', config.whatsAppCountryCode !== 'null' ? config.whatsAppCountryCode : '');
        bodyFormData.append('socialMediaOrganization.WhatsApp', config.whatsApp ? config.whatsApp : '');
        bodyFormData.append('users', null);

        const res = await putAxios(putOrganizationURL, bodyFormData);

        if (res.status === 200) {
            const token = res.headers.refreshtoken;
            const expiration = res.headers.expirationtoken;
            (token && expiration) && dispatch(renewTokenAction(token, expiration));
            dispatch({
                type: SYSTEM_SUCCESS_SAVE,
                response: res.data.response
            })
            return dispatch(getOrgSystemById());
        }
        throw new Error();
    } catch (error) {
        if (error.response && error.response.status === 401)
            dispatch(logoutAction());
        dispatch({
            type: SYSTEM_ERROR,
            payload: error.response ? error.response.data.message : "common:errorSavingConfig"
        });
    }
};

export const getOrgSystemById = () => async (dispatch, getState) => {
    dispatch({ type: LOADING_SYSTEM });
    try {
        const { user } = getState().auth;
        const res = await getAxios(getOrganizationIdURL, { id: user.organizationId });
        if (res.status === 200) {
            const token = res.headers.refreshtoken;
            const expiration = res.headers.expirationToken;
            (token && expiration) && dispatch(renewTokenAction(token, expiration));

            localStorage.setItem('lng-fos', res.data.response.defaultLanguageId);
            dispatch({
                type: LENGUAGE_ID_SUCCESS,
                payload: res.data.response.defaultLanguageId
            });
            if (res.data.response.countryNavigation) {
                localStorage.setItem('region', res.data.response.countryNavigation.alfa2.toUpperCase());
                dispatch({
                    type: REGION_SUCCESS,
                    payload: res.data.response.countryNavigation.alfa2.toUpperCase()
                });
            }
            return dispatch({
                type: SYSTEM_SUCCESS,
                payload: res.data.response
            });
        }
        throw new Error();
    } catch (error) {
        if (error.response && error.response.status === 401)
            dispatch(logoutAction());
        dispatch({
            type: SYSTEM_ERROR,
            payload: error.response ? error.response.data.message : "common:errorLoadingCompany"
        });
    }
};

export const showMessage = () => async (dispatch) => {
    return dispatch({
        type: SYSTEM_RESPONSE,
        response: "common:commonRestoredConfigChanges"
    });
};