import React, { useState } from "react";
import {
    Typography, Button, IconButton, Divider
} from "@mui/material";
import { ReactComponent as FolderIcon } from '../../../assets/Icons/folder.svg';
import { ReactComponent as Trash } from '../../../assets/Icons/trash.svg';
import { ReactComponent as CloudUpload } from '../../../assets/Icons/cloudUpload.svg';
import { ReactComponent as CloudDownload } from '../../../assets/Icons/cloudDownloadBlue.svg';
//import { ReactComponent as Filter } from '../../../assets/Icons/filter.svg';
import { ReactComponent as Search } from '../../../assets/Icons/search.svg';
import { ReactComponent as BlueImage } from '../../../assets/Icons/blueImage.svg';
import { ReactComponent as BlueFile } from '../../../assets/Icons/blueFile.svg';
import TextFieldComponent from "../../../components/TextField";
//import SelectFieldComponent from "../../../components/SelectField";
import DocumentResults from "../Results/Results";
import Folders from "../Folders/Folder";
import Folder from "../../../components/Folder";
import File from "../../../components/File";
import PaginationCustom from "../../../components/Pagination";
import { useDispatch, useSelector } from "react-redux";
import { changeDocPage, deleteDoc, getDocumentDownload } from "../../../redux/DocumentDuck";
import Moment from 'react-moment';
import { useTranslation } from "react-i18next";
import './RecentDocuments.css';

const RecentDocuments = ({ searchDoc, onChangeSearchDoc, onSearchDoc,
    searchFolder, onChangeSearchFolder, onSearchFolder, clientId }) => {
    const { t } = useTranslation(['document', 'common']);
    const activePageDoc = useSelector(store => store.doc.activePageDoc);
    const pagesDoc = useSelector(store => store.doc.pagesDoc);
    const documents = useSelector(store => store.doc.documents);
    const folderId = useSelector(store => store.doc.folderId);
    const totalFilterFolder = useSelector(store => store.doc.totalFilterFolder);
    const totalFileFilter = useSelector(store => store.doc.totalFileFilter);
    // const totalFolder = useSelector(store => store.doc.totalFolder);
    const [openFolder, setOpenFolder] = useState(false);
    const [openFile, setOpenFile] = useState(false);
    const dispatch = useDispatch();

    const imageExtensions = [".JPG", ".JPEG", ".GIF", ".PNG", ".TIFF",
        ".TIF", ".RAW", ".BMP", ".PSD", ".EPS", ".PIC",
        ".MOV", ".MKV", ".MP4", ".WMV", ".FLV"];

    // useEffect(() => onSearchFolder(""), [documents,onSearchFolder]);

    const downloadFile = (fileId, fileName) => {
        dispatch(getDocumentDownload(fileId, fileName, true));
    };

    return (
        <div>
            <Folder open={openFolder} closeFolder={() => setOpenFolder(false)} clientId={clientId} />
            <File open={openFile} closeFile={() => setOpenFile(false)} clientId={clientId} />
            <div className="container-app-div">
                <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 42 }}>
                    <Typography style={{ textTransform: "capitalize" }} className="app-form-title">{t('title')}</Typography>
                    <Button className='button-app-create' variant="text" style={{ textTransform: "capitalize" }}
                        onClick={() => setOpenFile(true)} startIcon={<CloudUpload />}>
                        {t('uploadDocBtn')}
                    </Button>
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div style={{ width: '85%' }}>
                        <TextFieldComponent style={{ textTransform: "capitalize" }} Icon={<Search />} position='start'
                            valueInput={searchDoc}
                            callback={onChangeSearchDoc.bind(this)}
                            placeholder={t('searchFileLbl')} />
                    </div>
                    <div>
                        <Button variant="contained" style={{ textTransform: "capitalize" }} className="button-app-save"
                            onClick={() => onSearchDoc()}>
                            {t('searchFolderBtn')}
                        </Button>
                    </div>
                </div>
                <Divider className="divider-line" style={{ marginTop: 25, marginBottom: 17 }} />
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    {totalFileFilter > 0 ?
                        <Typography style={{ textTransform: "capitalize" }} className="recents-title-text">{t('subtitle1')} : {totalFileFilter}  {t('resultsLbl')}</Typography>
                        :
                        <div style={{ textAlign: 'center', marginTop: 20 }}>
                            {t("common:commonNoResultsLbl")}
                        </div>
                    }
                </div>

                <Divider className="divider-line-docs" style={{ marginTop: 17 }} />
                {documents.length === 0 &&
                    <div style={{ textAlign: 'center', marginTop: 20 }}>
                        {t("common:commonNoResultsLbl")}
                    </div>
                }
                {documents.map(item => (
                    <div className="container-doc-div" style={{
                        display: 'flex', justifyContent: 'space-between',
                        alignItems: 'center', marginTop: 20
                    }} key={item.id}>
                        <div style={{ display: 'flex', width: '45%' }}>
                            <div>
                                {imageExtensions.includes(item.fileExtension.toUpperCase().trim())
                                    ?
                                    <BlueImage />
                                    :
                                    <BlueFile />
                                }
                            </div>
                            <div style={{ marginLeft: 11, width: '100%' }}>
                                <Typography className="document-name" style={{
                                    width: 250,
                                    whiteSpace: 'nowrap',
                                    textOverflow: 'ellipsis',
                                    overflow: 'hidden'
                                }}>
                                    {item.fileName}
                                </Typography>
                                <Moment className="document-date-text" style={{ marginTop: '4px' }}
                                    format="DD/MM/YYYY">
                                    {item.created}
                                </Moment>
                            </div>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', width: '15%' }}>
                            <FolderIcon style={{ marginRight: '10.14px' }} />
                            <Typography className='folder-name'
                                style={{
                                    width: 160,
                                    whiteSpace: 'nowrap',
                                    textOverflow: 'ellipsis',
                                    overflow: 'hidden'
                                }}>
                                {item.folder.name}
                            </Typography>
                        </div>
                        <div style={{ width: '12%' }}>
                            <Typography className='user-name-title'>
                                {item.uploadedByUser.firstName + ' ' + item.uploadedByUser.lastName}
                            </Typography>
                        </div>
                        <div style={{ width: '12%' }}>
                            <Button className='download-button' variant="text" startIcon={<CloudDownload />}
                                onClick={() => downloadFile(item.id, item.fileName)}>
                                {t('downloadLbl')}
                            </Button>
                        </div>
                        <div style={{ width: '5%' }}>
                            <IconButton onClick={() => dispatch(deleteDoc(item.id, "", clientId))}>
                                <Trash className="documents-icon-svg-delete documents-icon-delete" />
                            </IconButton>
                        </div>
                    </div>
                ))}
                <br />
                <PaginationCustom pages={pagesDoc} activePage={activePageDoc}
                    onChange={page => dispatch(changeDocPage(page))} />
            </div>
            {!folderId ?
                <div className="container-app-div">
                    <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 15 }}>
                        <Typography className="app-form-title">{t('subtitle2')}</Typography>
                        <Button className='button-app-create' variant="text"
                            onClick={() => setOpenFolder(true)} style={{ textTransform: "capitalize" }}

                            startIcon={<FolderIcon className="documents-icon-svg-folder documents-icon-folder" />}>
                            {t('createFolderBtn')}
                        </Button>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <div style={{ width: '85%' }}>
                            <TextFieldComponent Icon={<Search />} position='start'
                                valueInput={searchFolder}
                                callback={onChangeSearchFolder.bind(this)}
                                placeholder={t('searchFolderLbl')}
                            />
                        </div>
                        <div>
                            <Button variant="contained" className="button-app-save cap-first-letter" style={{ textTransform: "capitalize" }}
                                onClick={() => onSearchFolder()}>
                                {t('search')}
                            </Button>
                        </div>
                    </div>
                    <Divider className="divider-line-docs" style={{ marginTop: 26, marginBottom: 17 }} />
                    {totalFilterFolder > 0 ?
                        <Typography className="recents-title-text">{totalFilterFolder}  {t('resultsLbl')}</Typography>
                        :
                        <div style={{ textAlign: 'center', marginTop: 20 }}>
                            {t("common:commonNoResultsLbl")}
                        </div>
                    }

                    <Divider className="divider-line-docs" style={{ marginTop: 20, marginBottom: 25 }} />
                    <Folders setOpenFile={() => setOpenFolder(true)} />
                </div>
                :
                <DocumentResults clientId={clientId} />
            }
        </div>
    );
}

export default RecentDocuments;
