import React from "react";
import { Container, Grid, Typography, Card, CardMedia } from "@mui/material";
import helpImage5 from '../../assets/Images/help5.png';
import helpImage6 from '../../assets/Images/help6.png';
import { useTranslation } from "react-i18next";
import './Help.css';

const HelpBusiness = () => {

    const { t } = useTranslation(['help']);

    return (
        <Container>
                <Typography className="admin-business-text" style={{ marginBottom: '20px' }}>
                {t('help:business:category')+"|"+t('help:business:title')}
                        </Typography>

            <Grid item xs={12}>
                <div className="container-app-div help-screen header" style={{ marginTop: 10 }}>

                    <div style={{ display: 'flex',flexDirection: 'column' }}>
                        <p className="paragraph-text strong" style={{ marginBottom: '20px' }} >
                            {t('help:thisPageLbl')}
                        </p>
                        <label className="help-label-text">
                        <span className="enum">1</span>{t('help:business:lbl1')}
                        </label>
                        
                        <label className="help-label-text">
                        <span className="enum">2</span>{t('help:business:lbl2')}
                        </label>
                    </div>
                        
                </div>
                
                        <div className="container-app-div" style={{ marginTop: 10 ,display: 'flex', marginBottom: 30 }}>
                            <div style={{ width: '50%', display: 'flex', flexDirection: 'column' }}>
                                <p className="paragraph-text strong">
                                    {t('help:step1')}<span className="enum">1</span> : {t('help:business:lbl3')}
                                </p>
                                <label className="help-label-text">
                                    {t('help:business:lbl4')}
                                </label>
                                <label className="help-label-text">
                                    {t('help:business:lbl5')}
                                </label>
                                <label className="help-label-text">
                                    {t('help:business:lbl6')}
                                </label>
                            </div>
                            <div style={{ width: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <Card sx={{ maxWidth: 190, height: 75, boxShadow: 'none' }}>
                                    <CardMedia
                                        component="img"
                                        image={helpImage5}
                                        alt="img"
                                        style={{ objectFit: 'contain' }}
                                    />
                                </Card>
                            </div>
                        </div>
                        <div className="container-app-div" style={{ marginTop: 10 ,display: 'flex', marginBottom: 30 }}>
                       
                            <div style={{ width: '50%', display: 'flex', flexDirection: 'column' }}>
                                <p className="paragraph-text strong">
                                    {t('help:step2')}<span className="enum">2</span> : {t('help:business:lbl7')}
                                </p>
                                <label className="help-label-text">
                                    {t('help:business:lbl8')}
                                </label>
                                <label className="help-label-text">
                                    {t('help:business:lbl9')}
                                </label>
                                
                                <label className="help-label-text">
                                    {t('help:business:lbl10')}
                                </label>
                                <label className="help-label-text">
                                    {t('help:business:lbl11')}
                                </label>
                                <br />
                                <label className="help-label-text">
                                    {t('help:business:lbl12')}
                                </label>
                            </div>
                            <div style={{ width: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <Card sx={{ maxWidth: 300, height: 133, boxShadow: 'none' }}>
                                    <CardMedia
                                        component="img"
                                        image={helpImage6}
                                        alt="img"
                                        style={{ objectFit: 'contain' }}
                                    />
                                </Card>
                            </div>
                        </div>
                
            </Grid>
        </Container>
    );
}
export default HelpBusiness;