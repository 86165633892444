import React from "react";
import { Container, Grid, Typography, Card, CardMedia } from "@mui/material";
import helpImage11 from '../../assets/Images/help11.png';
import helpImage12 from '../../assets/Images/help12.png';
import { useTranslation } from "react-i18next";
import './Help.css';

const HelpUser = () => {

    const { t } = useTranslation(['help']);

    return (

        <Container>
            <Typography className="admin-business-text" style={{ marginBottom: '20px' }}>
                {t('help:user:category') + "|" + t('help:user:title')}
            </Typography>

            <Grid item xs={12}>
                <div className="container-app-div help-screen header 1column" style={{ marginTop: 10 }}>
                    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                        <p className="paragraph-text strong" style={{ marginBottom: '20px' }} >
                            {t('help:thisPageLbl')}
                        </p>
                        <label className="help-label-text">
                            <span className="enum">1</span>{t('help:user:lbl1')}
                        </label>
                        <label className="help-label-text">
                            <span className="enum">2</span>{t('help:user:lbl2')}
                        </label>
                    </div>
                </div>
                <div className="container-app-div" style={{
                    marginTop: '10px',
                    display: 'flex'
                }}>

                    <div style={{ width: '50%', display: 'flex', flexDirection: 'column' }}>
                        <p className="paragraph-text">
                            {t('help:step1')} <span className="enum">1</span> :&nbsp;{t('help:user:lbl3')}
                        </p>
                        <label className="help-label-text">
                            {t('help:user:lbl4')}
                        </label>
                        <label className="help-label-text">
                            {t('help:user:lbl5')}
                        </label>
                        <br />
                        <label className="help-label-text">
                            {t('help:user:lbl6')}
                        </label>
                    </div>
                    <div style={{ width: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Card sx={{ maxWidth: 230, height: 88, boxShadow: 'none' }}>
                            <CardMedia
                                component="img"
                                image={helpImage11}
                                alt="img"
                                style={{ objectFit: 'contain' }}
                            />
                        </Card>
                    </div>
                </div>
                <div className="container-app-div" style={{ marginTop: 10, display: 'flex', flexDirection: 'column' }}>

                    <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                        <p className="paragraph-text">
                            {t('help:step2')}<span className="enum">2</span> :&nbsp;{t('help:user:lbl7')}
                        </p>
                        <label className="help-label-text">
                            {t('help:user:lbl8')}
                        </label>
                        <label className="help-label-text">
                            {t('help:user:lbl9')}
                        </label>
                    </div>
                    <div style={{
                        width: '100%', display: 'flex', marginTop: 40,
                        justifyContent: 'center', alignItems: 'center'
                    }}>
                        <Card sx={{ maxWidth: 791, height: 393, boxShadow: 'none' }}>
                            <CardMedia
                                component="img"
                                image={helpImage12}
                                alt="img"
                                style={{ objectFit: 'contain' }}
                            />
                        </Card>
                    </div>
                </div>


            </Grid>
        </Container>
    );
}
export default HelpUser;