

export const getClientsName = (clients) => {
    const list = [];
    clients.forEach(item => {
        !list.includes(item.name) && list.push(item.name);
    });

    const strClients = list.join(', ');
    return (strClients && strClients.length >= 140) ? strClients.substring(0, 140) + '...' : strClients;
};

export const GoogleApiKey = 'AIzaSyBdy5FPR9RF5rMYyUfe6HuT6IWuEfDr5E0';