export const HOME = '/';
export const LOGIN = '/login';

export const FORGET_PASSWORD = '/forget-password';
export const DATA_DELETION = '/help/data-deletion';
export const RESET_PASSWORD = '/reset-password';
export const DASHBOARD = '/dashboard';

export const SYSTEM_CONFIGURATION = '/system-configuration';
export const APP_CONFIGURATION = '/app-configuration';
export const BUSINESS = '/business';
export const BUSINESS_UPDATE = '/business/:id/edit';
export const BUSINESS_CREATE = '/business/create';
export const MOBILE_CONTENT = '/mobile-content';
export const MOBILE_CONTENT_UPDATE = '/mobile-content/:id/edit';
export const USERS = '/users';
export const USERS_CREATE = '/users/create';
export const USERS_UPDATE = '/users/:id/edit';
export const PERMITS = '/permits';
export const USER_ASSIGNMENT = '/user-assignment';
export const USER_PROFILE = '/user-profile';
export const USER_PROFILE_UPDATE = '/user-profile/:id/edit';
export const USER_PROFILE_CREATE = '/user-profile/create';
export const LOGS = '/logs';
export const CLIENTS = '/clients';
export const CLIENT_DETAIL = '/clients/detail/:id';
export const CLIENT_CREATE = '/clients/create';
export const CLIENT_UPDATE = '/clients/edit/:id';
export const CUSTOMER_STAFF = '/customer-staff';
export const CUSTOMER_STAFF_DETAIL = '/customer-staff/:id';
export const MATTERS = '/matters';
export const MATTERS_CREATE = '/matters/create';
export const MATTERS_DETAIL = '/matters/detail/:id';
export const NOTIFICATIONS = '/notifications';
export const NOTIFICATIONS_DETAIL = '/notifications/detail/:id';
export const NOTIFICATIONS_CREATE = '/notifications/create';
export const AUTHORIZATIONS = '/authorizations';
export const AUTHORIZATIONS_CREATE = '/authorizations/create';
export const AUTHORIZATIONS_DETAIL = '/authorizations/detail/:id';
export const DOCUMENTS = '/documents';

export const HELP_SYSTEM_CONFIGURATION = '/help-system-configuration';
export const HELP_APP_CONFIGURATION = '/help-app-configuration';
export const HELP_BUSINESS = '/help-business';
export const HELP_MOBILE_CONTENT = '/help-mobile-content';
export const HELP_USERS = '/help-users';
export const HELP_PERMITS = '/help-permits';
export const HELP_USER_ASSIGNMENT = '/help-user-assignment';
export const HELP_LOGS = '/help-logs';
export const HELP_CLIENTS = '/help-clients';
export const HELP_CUSTOMER_STAFF = '/help-customer-staff';
export const HELP_MATTERS = '/help-matters';
export const HELP_NOTIFICATIONS = '/help-notifications';
export const HELP_AUTHORIZATIONS = '/help-authorizations';
export const HELP_DOCUMENTS = '/help-documents';