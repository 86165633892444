import React, { useState, useEffect, createRef } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
    Container, Grid, Typography,
    InputLabel, Avatar, Divider,
    TableContainer, TableRow, Button,
    TableCell, Table, TableHead, TableBody,
    CardMedia
} from '@mui/material';
import Title from '../../../components/Title';
import AccessRow from './AccessRow';
import TextFieldComponent from '../../../components/TextField';
import SelectFieldComponent from '../../../components/SelectField';
import { ReactComponent as Less } from '../../../assets/Icons/less.svg';
import { ReactComponent as More } from '../../../assets/Icons/more.svg';
import { ReactComponent as CheckCircle } from '../../../assets/Icons/checkCircle.svg';
import { ReactComponent as DisableCircle } from '../../../assets/Icons/disableCircle.svg';
import CircleIcon from '@mui/icons-material/Circle';
import Envelope from '@mui/icons-material/Send'
import SwitchComponent from '../../../components/Switch';
import Checkbox from '../../../components/Checkbox';
import { getCountries } from '../../../redux/CountryDuck';
import { getBusinesses, changeBusinessPage } from '../../../redux/BusinessDuck';
import { getClients, changeClientPage } from '../../../redux/ClientDuck';
import { saveUser, changeStatusUser, updateUser } from '../../../redux/UserDuck';
import * as ROUTES from '../../../constants/routes';
import { getFileURL } from '../../../constants/apiUrls';
import './Form.css';
import PaginationCustom from '../../../components/Pagination';
import { userValidation } from './FormValidation';

import { resendFirstLoginEmail } from '../../../redux/AuthDuck';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ArrowBack } from '../../../assets/Icons/arrowBack.svg';
import DeleteModalUser from '../../../components/DeleteMessage';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const UserForm = props => {
    const { t } = useTranslation(['user', 'common', 'validations', 'customerStaff', 'label']);
    const userRedux = useSelector(store => store.user.user);
    const permission = useSelector(store => store.auth.permission);
    const activePageClient = useSelector(store => store.client.activePage);
    const pagesClient = useSelector(store => store.client.pages);
    const activePageBusiness = useSelector(store => store.business.activePage);
    const pagesBusiness = useSelector(store => store.business.pages);
    const isCreating = useSelector(store => store.user.isCreating);
    const isCreated = useSelector(store => store.user.isCreated);
    const countries = useSelector(store => store.country.countries);
    const businesses = useSelector(store => store.business.businesses);


    const [user, setUser] = useState({
        id: '', firstName: '', lastName: '', email: '',
        countryCode: '', phoneNumber: '', position: '',
        profileDescription: '', image: '', imageUrl: '',
        mobileAccess: false
    });
    const clients = useSelector(store => store.client.clients);
    const [business, setBusiness] = useState([]);
    const [offices, setOffices] = useState([]);
    const [error, setError] = useState({});
    const [isFirst, setIsFirst] = useState(true);
    const [openChangeStatus, setOpenChangeStatus] = useState(false);
    const imgRef = createRef();
    const dispatch = useDispatch();

    useEffect(() => {
        if (isFirst) {
            dispatch(changeBusinessPage(1));
            dispatch(changeClientPage(1));
            setIsFirst(false);
        }
    }, [dispatch, isFirst]);

    const onSwitch = (id, event) => {
        const { checked } = event.target;
        const list = business;
        if (checked) {
            list.push(String(id));
        } else {
            const index = list.indexOf(String(id));
            index > -1 && list.splice(index, 1);
            inactiveBusiness(id);
        }
        setBusiness(prev => ([...list]));
    };

    const inactiveBusiness = (id) => {
        var businessTemp = businesses.find(item => String(item.id) === String(id));
        const list = offices;
        businessTemp.offices.forEach((item) => {
            const index = list.indexOf(String(item.id));
            index > -1 && list.splice(index, 1);
        });
        setOffices(prev => ([...list]));
    };

    const onCheckbox = (id, event) => {
        const { checked } = event.target;
        const list = offices;
        if (checked) {
            list.push(String(id));
        } else {
            const index = list.indexOf(String(id));
            index > -1 && list.splice(index, 1);
        }
        setOffices(prev => ([...list]));
    }

    const onChange = e => {
        const { name, value } = e.target;
        if (name === "mobileAccess") {
            setUser({ ...user, [name]: e.target.checked });
        }
        else {
            setUser({ ...user, [name]: value });
        }
    };

    const onImageChange = event => {
        if (!!event.target.files[0]) {
            const file = event.target.files[0];
            let img = new Image();
            img.src = window.URL.createObjectURL(file);
            img.onload = () => {
                let Width = img.width;
                let Height = img.height;
                var ratio = 1;
                if (Height > Width) {
                    ratio = (Height / Width);
                } else {
                    ratio = (Width / Height);
                }
                var ThresHoldVal = 0.15; // 15% out of proportion treshold.

                if ((img.width <= 400 && img.height <= 400) && ((ratio - 1) < ThresHoldVal)) {
                    setUser({
                        ...user,
                        image: file,
                        imageUrl: URL.createObjectURL(file)
                    });
                    setError({ ...error, image: '' });
                } else {
                    setError({
                        ...error, image: t("common:commonNoValidDimensions") + `
                    (${img.width} x ${img.height}),` + t("textAllowed") + ` :  (400 x 400 / 200 x 200)`
                    });
                }
            }
        }
    };

    const onSubmit = () => {
        const userError = userValidation(user, offices, business, businesses, imgRef, t);
        setError(userError);
        if (Object.entries(userError).length === 0) {
            if (isCreating) dispatch(saveUser(user, offices));
            else dispatch(updateUser(user, offices));
        }
    };

    useEffect(() => {
        if (userRedux && !isCreating) {
            setUser({
                id: userRedux.id,
                firstName: userRedux.firstName,
                lastName: userRedux.lastName,
                email: userRedux.email,
                countryCode: userRedux.countryCodeId,
                phoneNumber: userRedux.phone,
                position: userRedux.organizationUser.position,
                profileDescription: userRedux.organizationUser.profileDescription,
                imageUrl: userRedux.fileId ? getFileURL + userRedux.fileId : '',
                image: '',
                mobileAccess: userRedux.statusMobile === "1"
            });

            const listOff = offices;
            const listBus = business;
            userRedux.userOffices.forEach(item => {
                !listBus.includes(String(item.office.companyId)) && listBus.push(String(item.office.companyId));
                !listOff.includes(String(item.officeId)) && listOff.push(String(item.officeId));
            })
            setOffices(prev => ([...listOff]));
            setBusiness(prev => ([...listBus]));
        }
        // eslint-disable-next-line
    }, [userRedux, isCreating]);

    useEffect(() => {
        dispatch(getCountries());
    }, [dispatch]);

    useEffect(() => {
        if (!isFirst) {
            dispatch(getBusinesses('', 'false', activePageBusiness, 3, false, 'users'));
        }
    }, [dispatch, activePageBusiness, isFirst]);

    useEffect(() => {
        if (!isFirst) {
            if (user.id && user.id !== null && user.id !== "") {
                dispatch(getClients([{ search: user.id, filterBy: "user" },
                { search: 'false', filterBy: 'disabled' }], activePageClient, 3, false, 'edit-user'))
            }
        }
    }, [user, dispatch, activePageClient, isFirst]);

    useEffect(() => {
        (isCreated) && props.history.push(ROUTES.USERS);
    }, [isCreated, props.history]);

    const status = [
        { status: t("common:commonStatusProcessing"), color: '#6C757D' },
        { status: t("common:commonStatusActive"), color: '#28A745' },
        { status: t("common:commonStatusArchived"), color: '#6C757D' },
    ];

    // useEffect(() => {
    //     const list = [];
    //     businesses.forEach((business) => {
    //         const offices = business.offices.filter(off => off.disabled === false);
    //         if (offices.length > 0) {
    //             list.push(business);
    //         }
    //     });
    //     setBusinessList(list);
    // }, [businesses]);

    return (
        <Container>
            {(!isCreating && userRedux) &&
                <DeleteModalUser
                    open={openChangeStatus}
                    closeModal={() => setOpenChangeStatus(false)}
                    handleClick={() => dispatch(changeStatusUser(userRedux.id))}
                    buttonMessage={userRedux.status === 2 ? t("activateBtn") : t("suspendBtn")}
                    textMessage={`${t("common:textConfirmation")} ${userRedux.status === 2 ? t("activateLbl") : t("suspendLbl")} ${t("common:textUserSure")} `}
                />
            }
            <div style={{ display: 'flex', alignItems: 'center', marginLeft: 30 }}>
                <Button className="arrow-back-icon-svg"
                    style={{ marginBottom: '2%', minWidth: 30 }} variant="text"
                    component={Link}
                    to={ROUTES.USERS} startIcon={<ArrowBack />}>
                </Button>
                <Title title={t("title")} />
            </div>
            <Grid item xs={12}>
                {(!isCreating && userRedux) &&
                    <div className="container-app-div">
                        <Typography className="app-form-title">{t("subtitle1")}</Typography>
                        <div style={{ display: 'flex' }}>
                            <Button component="label" style={{
                                background: 'transparent',
                                textTransform: 'capitalize'
                            }}>
                                <input type="file" accept="image/*"
                                    name="image" onChange={onImageChange}
                                    style={{ display: "none" }}
                                />
                                <p style={{ position: 'absolute', color: "white", top: "60px", zIndex: "2", fontSize: "11px", textShadow: "2px 2px 6px #000000" }}>{t("common:commonChangePicLbl")}</p>
                                <Avatar
                                    className='avatar-user-form' alt='Photo'
                                    src={user.imageUrl}
                                    sx={{ width: 112, height: 112 }}
                                    ref={imgRef}
                                />
                            </Button>
                            <div style={{ marginLeft: 37 }}>
                                <Typography className="user-name-form">
                                    {userRedux.firstName + ' ' + userRedux.lastName}
                                </Typography>
                                <Typography className="job-user-form" style={{ margin: '4px 0px' }}>
                                    {userRedux.position}
                                </Typography>
                                <div style={{
                                    display: 'flex', alignItems: 'center',
                                    color: status[userRedux.status].color, margin: '9px 0px'
                                }}>
                                    <CircleIcon style={{ fontSize: 7, marginRight: 7 }} />
                                    <Typography>
                                        {status[userRedux.status].status}
                                    </Typography>
                                </div>
                                <p className='app-error-text'>{error.image}</p>
                            </div>
                        </div>
                    </div>
                }
                <div className="container-app-div">
                    <Typography className="app-form-title">{t("subtitle2")}</Typography>
                    <Typography className="app-form-subtitle">
                        {t("subtitle3")}
                    </Typography>
                    <div style={{
                        display: 'flex', flexDirection: 'row', marginBottom: 10,
                        width: '100%', justifyContent: 'space-between'
                    }}>
                        <div style={{ width: '48%' }}>
                            <InputLabel className="label-app">{t("headerTbl1")}</InputLabel>
                            <TextFieldComponent name="firstName" placeholder={t("headerTbl1")}
                                valueInput={user.firstName} callback={onChange.bind(this)}
                                errorInput={error.firstName}
                            />
                        </div>
                        <div style={{ width: '48%' }}>
                            <InputLabel className="label-app">{t("headerTbl1b")}</InputLabel>
                            <TextFieldComponent name="lastName" placeholder={t("common:commonLastNameLbl")}
                                valueInput={user.lastName} callback={onChange.bind(this)}
                                errorInput={error.lastName}
                            />
                        </div>
                    </div>
                    <div style={{
                        display: 'flex', flexDirection: 'row', marginBottom: 10,
                        width: '100%', justifyContent: 'space-between'
                    }}>
                        <div style={{ width: '48%' }}>
                            <InputLabel className="label-app">Email</InputLabel>
                            <TextFieldComponent name="email" placeholder="Email"
                                valueInput={user.email} callback={onChange.bind(this)}
                                errorInput={error.email}
                            />
                        </div>
                        <div style={{ width: '12%' }}>
                            <InputLabel className="label-app" style={{ overflow: 'inherit' }}>{t("common:commonPhoneLbl")}</InputLabel>
                            <SelectFieldComponent name="countryCode"
                                onChange={onChange.bind(this)}
                                valueInput={user.countryCode} options={countries}
                                errorInput={error.countryCode}
                            />
                        </div>
                        <div style={{ width: '32%' }}>
                            <InputLabel className="label-app" style={{ opacity: 0 }}>{t("common:commonPhoneLbl")}</InputLabel>
                            <TextFieldComponent name="phoneNumber" placeholder='88888888'
                                maxLength={30}
                                valueInput={user.phoneNumber} callback={onChange.bind(this)}
                                errorInput={error.phoneNumber}
                            />
                        </div>
                    </div>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'space-between'
                    }}>

                        <div style={{ width: '48%', marginBottom: 10 }}>
                            <InputLabel className="label-app">{t("headerTbl2")}</InputLabel>
                            <TextFieldComponent name="position" placeholder={t("headerTbl2")}
                                valueInput={user.position} callback={onChange.bind(this)}
                                errorInput={error.position}
                            />
                        </div>
                        {isCreating &&
                            <div style={{ width: '48%', marginBottom: 10 }}>
                                <InputLabel className="label-app x">{t("user:lblMobileAccess")}</InputLabel>
                                <div style={{ marginRight: 18, marginTop: '20px', display: "flex" }}>
                                    <SwitchComponent
                                        callback={onChange.bind(this)}
                                        // checked={item.active}
                                        checked={user.mobileAccess}
                                        name='mobileAccess'
                                    />
                                    {user.mobileAccess &&
                                        <Typography className='mobile-content-alert' style={{ marginLeft: 20, width: "85%" }}>
                                            <InfoOutlinedIcon fontSize='small' style={{ marginRight: 10 }} />
                                            {t("user:mobileAccessAlert")}
                                        </Typography>
                                    }
                                </div>
                            </div>
                        }
                    </div>
                    <InputLabel className="label-app">{t("profileLbl")}</InputLabel>
                    <TextFieldComponent name="profileDescription" row={4}
                        maxLength={300}
                        placeholder={t("userCV")}
                        valueInput={user.profileDescription} callback={onChange.bind(this)}
                        errorInput={error.profileDescription}
                    />
                </div>
                {isCreating &&
                    <div className="container-app-div">
                        <Typography className="app-form-title">{t("profileImageLbl")} ({t("label:optionalLbl")})</Typography>
                        <div className='image-div'>
                            {user.imageUrl &&
                                <CardMedia component="img"
                                    image={user.imageUrl}
                                    className="card-img-form"
                                    ref={imgRef}
                                />
                            }
                            <br />
                            <div style={{ textAlign: 'center' }}>
                                <Button component="label"
                                    className='button-add-image'
                                >
                                    <input type="file" accept="image/*"
                                        name="image" onChange={onImageChange}
                                        style={{ display: "none" }}
                                    />
                                    {t("profileImageBtn")}
                                </Button>
                                <br />
                                <Typography className="add-image-title">{t("common:commonValidDimensionsLbl")} : 400 x 400 px / 200 x 200 px </Typography>
                            </div>
                        </div>
                        <p className='app-error-text'>{error.image}</p>
                    </div>
                }
                <div className="container-app-div">
                    <Typography className="app-form-title">{t("subtitle4")}</Typography>
                    <Typography className="app-form-subtitle">
                        {t("subtitle5")}
                    </Typography>
                    {businesses.map(item => (
                        <div key={item.id}>
                            <Divider />
                            <div className='business-user-form' >
                                <AccessRow
                                    title={item.name}
                                    Icon={business.includes(String(item.id)) ? Less : More}
                                />
                                <div style={{ marginRight: 18 }}>
                                    <SwitchComponent
                                        callback={onSwitch.bind(this, item.id)}
                                        // checked={item.active}
                                        checked={business.includes(String(item.id))}
                                        name='business'
                                    />
                                </div>
                            </div>
                            <Divider />
                            {business.includes(String(item.id)) &&
                                <div className='business-form-offices'>
                                    <Typography className="business-form-offices-title">{t("common:commonOfficeLbl") + "s"}</Typography>
                                    {item.offices.map(office => (
                                        !office.disabled &&
                                        <div className='business-user-form' key={office.id}>
                                            <Typography className="business-form-offices-name">
                                                {office.name}
                                            </Typography>
                                            <Checkbox
                                                name='office'
                                                onChange={onCheckbox.bind(this, office.id)}
                                                value={offices.includes(String(office.id))}
                                                checkboxCheckedValue={true}
                                            />
                                        </div>
                                    ))}
                                </div>
                            }
                        </div>
                    ))}
                    <p className='app-error-text'>{error.office}</p>
                    <br />
                    <PaginationCustom pages={pagesBusiness} activePage={activePageBusiness}
                        onChange={page => dispatch(changeBusinessPage(page))} />
                </div>
                {!isCreating &&
                    <div className="container-app-div">
                        <Typography className="app-form-title">{t("subtitle6")}</Typography>
                        <Typography className="app-form-subtitle">
                            {t("subtitle7")}
                        </Typography>
                        <TableContainer>
                            <Table aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell className='table-head-user'>{t("headerTbl5")}:</TableCell>
                                        {permission && permission.permissions.find(p => p.permissionName === 'Permissions.Clients')
                                            &&
                                            <TableCell className='table-head-user' align="right">{t("headerTbl6")}</TableCell>
                                        }
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {clients.length === 0 &&
                                        <TableRow key='empty'>
                                            <TableCell colSpan={2} style={{ textAlign: 'center' }}>
                                                {t("common:commonNoResultsLbl")}
                                            </TableCell>
                                        </TableRow>
                                    }
                                    {clients.map(item => (
                                        <TableRow key={item.id}>
                                            <TableCell component="th" scope="row" className='table-row-user'>
                                                {item.name}
                                            </TableCell>
                                            {permission && permission.permissions.find(p => p.permissionName === 'Permissions.Clients')
                                                &&
                                                <TableCell component="th" scope="row" align="right">
                                                    <Button style={{
                                                        minWidth: 0, padding: 0,
                                                        textTransform: 'capitalize'
                                                    }}
                                                        onClick={() => props.history.push(ROUTES.CLIENT_DETAIL.replace(':id', item.id))}>
                                                        {t("common:commonEditBtn")}
                                                    </Button>
                                                </TableCell>
                                            }
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <br />
                        <PaginationCustom pages={pagesClient} activePage={activePageClient}
                            onChange={page => dispatch(changeClientPage(page))} />
                    </div>
                }
                {(!isCreating && userRedux) &&
                    <div className="container-app-div">
                        <Typography className="app-form-title">{t("common:commonOptionsLbl")}</Typography>
                        <Button className='button-active-inactive' variant="text" style={{ marginRight: 18 }}
                            startIcon={userRedux.disabled ? <CheckCircle /> : <DisableCircle />}
                            onClick={() => setOpenChangeStatus(true)}>
                            {userRedux.status === 2 ? t("activateBtn") : t("suspendBtn")}
                        </Button>
                        {userRedux.status !== 2 &&
                            <Button className='button-active-inactive' variant="text"
                                startIcon={<Envelope></Envelope>}
                                onClick={() => dispatch(resendFirstLoginEmail(userRedux))}>
                                {t("customerStaff:resendLoginLbl")}
                            </Button>
                        }

                    </div>
                }
                <div style={{ display: 'flex' }}>
                    <Button variant="contained" className="button-app-save" style={{ marginRight: 18 }}
                        onClick={() => onSubmit()}>
                        {t("common:commonSubmitBtn")}
                    </Button>
                    <Button variant="contained" className="button-app-cancel"
                        onClick={() => props.history.push(ROUTES.USERS)}>
                        {t("common:commonCancelBtn")}
                    </Button>
                </div>
            </Grid>
        </Container>
    )
};

export default withRouter(UserForm);