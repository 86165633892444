import React from "react";
import { Container, Grid, Typography, Card, CardMedia } from "@mui/material";
import helpImage14 from '../../assets/Images/help14.png';
import { useTranslation } from "react-i18next";
import './Help.css';

const HelpLog = () => {

    const { t } = useTranslation(['help']);

    return (
        <Container>
                        <Typography className="admin-business-text" style={{ marginBottom: '20px' }}>
                         {  t('help:log:title')}
                        </Typography>

            <Grid item xs={12}>
                <div className="container-app-div help-screen header" style={{ marginTop: 10 }}>
                
                    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                        <p className="paragraph-text strong" style={{ marginBottom: '20px' }} >
                            {t('help:thisPageLbl')}
                        </p>
                        <label className="help-label-text">
                        <span className="enum">1</span> {t('help:log:lbl1')}
                        </label>
                        <br />
                        <br />
                </div>
                <div>
                        <label className="help-label-text">
                         {t('help:log:lbl2')}
                        </label>
                        <label className="help-label-text">
                            {t('help:log:lbl3')}
                        </label>

                </div>
                        </div>
                        <div className="container-app-div" style={{ marginTop: 10 }}>
                            <div style={{ width: '90%', display: 'flex', flexDirection: 'column' }}>
                                <p className="paragraph-text strong">
                                    {t('help:step1')} <span className="enum">1</span> :&nbsp;   {t('help:log:lbl4b')}

                                </p>
                                <label className="help-label-text">
                                    {t('help:log:lbl4')}
                                </label>
                                <label className="help-label-text">
                                    {t('help:log:lbl5')}
                                </label>
                                <label className="help-label-text">
                                    {t('help:log:lbl6')}
                                </label>
                            </div>
                            <div style={{
                                width: '100%', display: 'flex', marginTop: 40,
                                justifyContent: 'center', alignItems: 'center'
                            }}>
                                <Card sx={{ maxWidth: 652, height: 425, boxShadow: 'none' }}>
                                    <CardMedia
                                        component="img"
                                        image={helpImage14}
                                        alt="img"
                                        style={{ objectFit: 'contain' }}
                                    />
                                </Card>
                            </div>
                        </div>
                   
            </Grid>
        </Container>
    );
}
export default HelpLog;