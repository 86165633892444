import React, { useState, useEffect } from "react";
import { withRouter } from "react-router";
import { Link } from 'react-router-dom';
import {
    Typography, Button, TableContainer, TableRow,
    TableCell, Table, TableHead, TableBody, Container,
    Grid, InputLabel,Alert
} from "@mui/material";
import Title from '../../../components/Title';
import SelectFieldComponent from "../../../components/SelectField";
import TextFieldComponent from "../../../components/TextField";
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import CircleIcon from '@mui/icons-material/Circle';
import { ReactComponent as Search } from '../../../assets/Icons/search.svg';
import { ReactComponent as Filter } from '../../../assets/Icons/filter.svg';
import * as ROUTES from '../../../constants/routes';
import './Read.css';
import { useDispatch, useSelector } from "react-redux";
import { getAuthorizations, changeAuthorizationPage } from "../../../redux/AuthorizationDuck";
import { getBusinessesFilter, getOfficesFilter } from "../../../redux/BusinessDuck";
import PaginationCustom from "../../../components/Pagination";
import { useTranslation } from 'react-i18next';
const ReadAuthorizations = () => {
    const { t } = useTranslation(['authorization', 'common']);
    const businesses = useSelector(store => store.business.businesses);
    const officesRedux = useSelector(store => store.business.offices);
    const authorizations = useSelector(store => store.authorization.authorizations);
    const activePage = useSelector(store => store.authorization.activePage);
    const pages = useSelector(store => store.authorization.pages);

    const [filter, setFilter] = useState('none');
    const [search, setSearch] = useState('');

    const [businessList, setBusinessList] = useState([]);
    const [offices, setOffices] = useState([]);
    const [businessId, setBusinessId] = useState('all');
    const [officeId, setOfficeId] = useState('all');
    const dispatch = useDispatch();

    const changeBusinessId = e => {
        setBusinessId(e.target.value);
        setOfficeId('all');
    };

    //componentDidMount
    useEffect(() => {
        dispatch(changeAuthorizationPage(1));
        //component will unmount
        return () => {
            dispatch(changeAuthorizationPage(1));
        }
    }, [dispatch]);

    useEffect(() => {
        const businessList = [];
        businesses.forEach(item => {
            businessList.push({ key: item.id, value: item.name });
        });
        setBusinessList(businessList);
    }, [businesses]);

    useEffect(() => {
        const officesTemp = [];
        officesRedux.forEach(item => {
            officesTemp.push({ key: item.id, value: item.name });
        });
        setOffices(officesTemp);
        setOfficeId('all');
    }, [businessId, officesRedux]);

    useEffect(() => {
        const filterBy = [];
        search !== '' && filterBy.push({ search: search, filterBy: 'search-bar' });
        filter !== 'none' && filterBy.push({ search: filter, filterBy: 'disabled' });
        businessId !== 'all' && filterBy.push({ search: String(businessId), filterBy: 'company' });
        officeId !== 'all' && filterBy.push({ search: String(officeId), filterBy: 'office' });
        const timer = setTimeout(() => dispatch(getAuthorizations(filterBy, activePage, 10, true)), 1000);
        return () => clearTimeout(timer);
        // eslint-disable-next-line
    }, [dispatch, businessId, officeId, filter, search]);

    useEffect(() => {
        const filterBy = [];
        search !== '' && filterBy.push({ search: search, filterBy: 'search-bar' });
        filter !== 'none' && filterBy.push({ search: filter, filterBy: 'disabled' });
        businessId !== 'all' && filterBy.push({ search: String(businessId), filterBy: 'company' });
        officeId !== 'all' && filterBy.push({ search: String(officeId), filterBy: 'office' });
        dispatch(getAuthorizations(filterBy, activePage, 10, false));
        // eslint-disable-next-line
    }, [dispatch, activePage]);

    useEffect(() => {
        dispatch(getBusinessesFilter());
    }, [dispatch]);

    useEffect(() => {
        dispatch(getOfficesFilter(businessId !== 'all' ? [{
            "search": String(businessId),
            "filterBy": "company"
        }] : []));
    }, [dispatch, businessId]);

    return (
        <Container>
            <Title title={t("subtitle6b")} style={{ marginLeft: 5 }} />
            <Grid item xs={12}>
                <div className="container-app-div">
                    <Typography className="app-form-title">{t("common:commonFilterLbl")}</Typography>
                    
                    <div style={{
                        display: 'flex', flexDirection: 'row',
                        width: '100%', justifyContent: 'space-between',
                    }}>
                        <div style={{ width: '48%' }}>
                            <InputLabel className="label-app">{t("common:commonCompanyLbl")}</InputLabel>
                            <SelectFieldComponent name="businessName"
                                valueInput={businessId}
                                options={[{ key: 'all', value: t("common:commonAllLbl") }, ...businessList]}
                                onChange={changeBusinessId}
                            />
                        </div>
                        <div style={{ width: '48%' }}>
                            <InputLabel className="label-app">{t("common:commonOfficeLbl")}</InputLabel>
                            <SelectFieldComponent name="officesName"
                                valueInput={officeId}
                                options={[
                                    { key: 'all', value: t("common:commonAllLbl") },
                                    ...offices
                                ]}
                                onChange={e => setOfficeId(e.target.value)}
                            />
                        </div>
                    </div>
                </div>
                <div className="container-app-div">
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Typography className="app-form-title">{t("subtitle6b")}</Typography>
                        <Button className='button-app-create' component={Link}
                            to={ROUTES.AUTHORIZATIONS_CREATE} variant="text" startIcon={<AddRoundedIcon />} >
                            {t("title")}
                        </Button>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div style={{ width: '35%' }}>
                            <TextFieldComponent Icon={<Search />}
                                valueInput={search}
                                callback={e => setSearch(e.target.value)}
                                position='start' placeholder={t("common:commonSearchPlh")}
                            />
                        </div>
                        <div>
                            <SelectFieldComponent name="filter"
                                placeholder={t("common:commonFilterByStatusPlh")}
                                valueInput={filter}
                                onChange={e => setFilter(e.target.value)}
                                options={[
                                    { key: 'false', value: t("common:commonStatusActive") },
                                    { key: 'true', value: t("common:commonStatusArchived") }
                                ]}
                                Icon={Filter}
                            />
                        </div>
                    </div>
                    <TableContainer>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{ width: '40%' }} className='table-app-title-header'>{t("thisLbl")}</TableCell>
                                    <TableCell sx={{ width: '30%' }} className='table-app-title-header'>{t("headerTbl8")}</TableCell>
                                    <TableCell className='table-app-title-header'>{t("headerTbl16")}</TableCell>
                                    <TableCell className='table-app-title-header' align="right">{t("headerTbl10")}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {authorizations.length === 0 &&
                                    <TableRow key='empty'>
                                        <TableCell colSpan={4} style={{ textAlign: 'center' }}>
                                            {t("common:commonNoResultsLbl")}
                                        </TableCell>
                                    </TableRow>
                                }
                                {authorizations.map(item => (
                                    <TableRow key={item.id}>
                                        <TableCell component="th" scope="row" className='app-form-subtitle'>
                                            {item.title}
                                        </TableCell>
                                        <TableCell component="th" scope="row" className='app-form-subtitle'>
                                            {item.client.name}
                                        </TableCell>
                                        <TableCell>
                                            <div className='table-row-cell-status-matters'
                                                style={{ color: item.disabled ? '#6C757D' : '#28A745' }}>
                                                <CircleIcon style={{ fontSize: 10, marginRight: 11 }} />
                                                <Typography>
                                                    {item.disabled ? t("common:commonStatusArchived") : t("common:commonStatusActive")}
                                                </Typography>
                                            </div>
                                        </TableCell>
                                        <TableCell align="right">
                                            <Button className='button-app-see-detail' component={Link}
                                                to={ROUTES.AUTHORIZATIONS_DETAIL.replace(':id', item.id)}>
                                                {t("common:commonSeeLbl")}
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <br />
                    <PaginationCustom pages={pages} activePage={activePage}
                        onChange={page => dispatch(changeAuthorizationPage(page))} />
                </div>
            </Grid>
        </Container>
    );
}

export default withRouter(ReadAuthorizations);