import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import {
    Drawer, MenuList, MenuItem, InputLabel,
    Button, Alert
} from '@mui/material';
import Navbar from '../Navbar';
import { ReactComponent as PersonOutline } from '../../assets/Icons/personOutline.svg';
import { ReactComponent as Journals } from '../../assets/Icons/journals.svg';
import { ReactComponent as Toggles } from '../../assets/Icons/toggles.svg';
import { ReactComponent as PersonSquare } from '../../assets/Icons/personSquare.svg';
import { ReactComponent as Phone } from '../../assets/Icons/phone.svg';
import { ReactComponent as Building } from '../../assets/Icons/building.svg';
import { ReactComponent as Subtract } from '../../assets/Icons/subtract.svg';
import { ReactComponent as Settings } from '../../assets/Icons/settings.svg';
import { ReactComponent as PersonCheck } from '../../assets/Icons/personCheck.svg';
import { ReactComponent as Person } from '../../assets/Icons/person.svg';
import { ReactComponent as BriefCase } from '../../assets/Icons/briefcase.svg';
import { ReactComponent as Document } from '../../assets/Icons/document.svg';
import { ReactComponent as Quote } from '../../assets/Icons/quote.svg';
import { ReactComponent as CheckCircle } from '../../assets/Icons/checkCircle.svg';
import IconSvg from '../../components/Icon';
import HelpSidebar from '../HelpSidebar/HelpSidebar';
import { useTranslation } from 'react-i18next';
import * as ROUTES from '../../constants/routes';
import Title from '../Title';
import { ReactComponent as ArrowBack } from '../../assets/Icons/arrowBack.svg';
import './Sidebar.css';
import { useDispatch } from 'react-redux';
import { alertMessage } from '../../redux/AuthDuck';
import { getAppSettingById } from '../../redux/AppConfigurationDuck';
import { getOrgSystemById } from '../../redux/SystemConfigurationDuck';
import { ConstructionOutlined } from '@mui/icons-material';

const Sidebar = props => {

    const { t } = useTranslation(['sidebar']);
    const user = useSelector(store => store.auth.user);
    const appSetting = useSelector(store => store.appSetting.appSetting);
    const loadedSetting = useSelector(store => store.appSetting.loadedSetting);
    const system = useSelector(store => store.system.system);
    const loadedSystem = useSelector(store => store.system.loadedSystem);

    const permission = useSelector(store => store.auth.permission);
    const { location: { pathname }, children } = props;
    const [firstSection, setFirstSection] = useState(true);
    const [alert, setAlert] = useState('');
    const dispatch = useDispatch();

    useEffect(() => {
        if ((user && user.temporaryPasswordLogin) && pathname !== ROUTES.USER_PROFILE) {
            if (!firstSection) {
                dispatch(alertMessage());
            }
            setFirstSection(false);
            props.history.push(ROUTES.USER_PROFILE);
        } else if ((user && !user.temporaryPasswordLogin) && loadedSystem && (!system || (system && !system.webSite))) {
            setAlert(t("AlertConfigInfoRequired"));
            props.history.push(ROUTES.SYSTEM_CONFIGURATION);
        } else if ((user && !user.temporaryPasswordLogin) && loadedSetting && !appSetting) {
            setAlert(t("AlertConfigInfoRequired"));
            props.history.push(ROUTES.APP_CONFIGURATION);
        } else {
            setAlert("");
        }
        // eslint-disable-next-line
    }, [dispatch, user, props.history, pathname, loadedSystem, loadedSetting]);

    useEffect(() => {
        if (user) {
            if (!loadedSetting)
                dispatch(getAppSettingById());
            if (!loadedSystem)
                dispatch(getOrgSystemById());
        }
    }, [dispatch, user, loadedSetting, loadedSystem]);

    useEffect(() => {
        dispatch({ type: 'INITIAL_STATE' });
    }, [dispatch, pathname]);

    const sidebarListOne = [
        {
            name: t('sidebar:configuration'),
            Icon: Settings,
            selected: pathname.includes(ROUTES.SYSTEM_CONFIGURATION),
            to: ROUTES.SYSTEM_CONFIGURATION,
            permission: 'Permissions.Configuration'
        },
        {
            name: t('sidebar:configurationApp'),
            Icon: Subtract,
            selected: pathname.includes(ROUTES.APP_CONFIGURATION),
            to: ROUTES.APP_CONFIGURATION,
            permission: 'Permissions.AppConfiguration'
        },
        {
            name: t('sidebar:business'),
            Icon: Building,
            selected: pathname.includes(ROUTES.BUSINESS),
            to: ROUTES.BUSINESS,
            permission: 'Permissions.Companies'
        },
        {
            name: t('sidebar:mobileContent'),
            Icon: Phone,
            selected: pathname.includes(ROUTES.MOBILE_CONTENT),
            to: ROUTES.MOBILE_CONTENT,
            permission: 'Permissions.Mobile'
        }
    ];

    const sidebarListTwo = [
        {
            name: t('sidebar:user'),
            Icon: PersonSquare,
            selected: pathname.includes(ROUTES.USERS),
            to: ROUTES.USERS,
            permission: 'Permissions.Users'
        },
        {
            name: t('sidebar:permission'),
            Icon: Toggles,
            selected: pathname.includes(ROUTES.PERMITS),
            to: ROUTES.PERMITS,
            permission: 'Permissions.Permissions'
        },
        {
            name: t('sidebar:userAssigment'),
            Icon: PersonOutline,
            selected: pathname.includes(ROUTES.USER_ASSIGNMENT),
            to: ROUTES.USER_ASSIGNMENT,
            permission: 'Permissions.AssignUsers'
        },
    ];

    const sidebarListThree = [
        {
            name: t('sidebar:log'),
            Icon: Journals,
            selected: pathname.includes(ROUTES.LOGS),
            to: ROUTES.LOGS,
            permission: 'Permissions.Logbook'
        },
        {
            name: t('sidebar:client'),
            Icon: BriefCase,
            selected: pathname.includes(ROUTES.CLIENTS),
            to: ROUTES.CLIENTS,
            permission: 'Permissions.Clients'
        },
        {
            name: t('sidebar:clientStaff'),
            Icon: PersonCheck,
            selected: pathname.includes(ROUTES.CUSTOMER_STAFF),
            to: ROUTES.CUSTOMER_STAFF,
            permission: 'Permissions.ClientStaff'
        },
        {
            name: t('sidebar:matter'),
            Icon: Person,
            selected: pathname.includes(ROUTES.MATTERS),
            to: ROUTES.MATTERS,
            permission: 'Permissions.Subject'
        },
    ];

    const sidebarListFour = [
        {
            name: t('sidebar:notification'),
            Icon: Quote,
            selected: pathname.includes(ROUTES.NOTIFICATIONS),
            to: ROUTES.NOTIFICATIONS,
            permission: 'Permissions.Notifications'
        },
        {
            name: t('sidebar:authorization'),
            Icon: CheckCircle,
            selected: pathname.includes(ROUTES.AUTHORIZATIONS),
            to: ROUTES.AUTHORIZATIONS,
            permission: 'Permissions.Authorizations'
        },
        {
            name: t('sidebar:document'),
            Icon: Document,
            selected: pathname.includes(ROUTES.DOCUMENTS),
            to: ROUTES.DOCUMENTS,
            permission: 'Permissions.Documents'
        }
    ];

    const showTitle = (list, title) => {
        if (permission) {
            const value = permission.permissions.find(per =>
                list.find(element => {
                    return element.permission === per.permissionName
                })
            )
            return value ? <InputLabel className="label-title-sidebar">{title}</InputLabel> : null;
        }
        return null;
    };

    const drawer = (
        <MenuList className="menu-list-sidebar">
            {showTitle(sidebarListOne, t('sidebar:firstSection'))}
            {sidebarListOne.map((item) => (
                permission &&
                permission.permissions.find(per => per.permissionName === item.permission) &&
                <MenuItem className="item-sidebar"
                    key={item.name}
                    component={Link} to={item.to}
                    selected={item.selected}
                >
                    <IconSvg Icon={item.Icon} className="item-sidebar-icon-svg item-sidebar-icon" />
                    {item.name}
                </MenuItem>
            ))}
            {showTitle(sidebarListTwo, t('sidebar:secondSection'))}
            {sidebarListTwo.map((item) => (
                permission &&
                permission.permissions.find(per => per.permissionName === item.permission) &&
                <MenuItem className="item-sidebar"
                    key={item.name}
                    component={Link} to={item.to}
                    selected={item.selected}
                >
                    <IconSvg Icon={item.Icon} className="item-sidebar-icon-svg item-sidebar-icon" />
                    {item.name}
                </MenuItem>
            ))}
            {showTitle(sidebarListThree, t('sidebar:thirdSection'))}
            {sidebarListThree.map((item) => (
                permission &&
                permission.permissions.find(per => per.permissionName === item.permission) &&
                <MenuItem className="item-sidebar"
                    key={item.name}
                    component={Link} to={item.to}
                    selected={item.selected}
                >
                    <IconSvg Icon={item.Icon} className="item-sidebar-icon-svg item-sidebar-icon" />
                    {item.name}
                </MenuItem>
            ))}
            {showTitle(sidebarListFour, t('sidebar:fourthSection'))}
            {sidebarListFour.map((item) => (
                permission &&
                permission.permissions.find(per => per.permissionName === item.permission) &&
                <MenuItem className="item-sidebar"
                    key={item.name}
                    component={Link} to={item.to}
                    selected={item.selected}
                >
                    <IconSvg Icon={item.Icon} className="item-sidebar-icon-svg item-sidebar-icon" />
                    {item.name}
                </MenuItem>
            ))}
        </MenuList>
    );

    return (
        <div>
            <Navbar />
            <div style={{ display: 'flex' }}>
                {!(pathname.includes(ROUTES.DASHBOARD) || pathname === "/")
                    &&
                    <div>
                        <Drawer variant="permanent" open
                            className="drawer"
                        >
                            {pathname.includes('help') ?
                                <HelpSidebar />
                                :
                                drawer
                            }
                        </Drawer>
                    </div>
                }
                <div style={{
                    margin: (pathname.includes(ROUTES.DASHBOARD) || pathname === "/") ? '100px 7.5% 5% 7.5%' : '100px 0 5%',
                    width: !(pathname.includes(ROUTES.DASHBOARD) || pathname === "/") ? 'calc(100vw - 440px)' : 'calc(100vw)'
                }} id="principal">
                    {pathname.includes('help') &&
                        <div style={{ display: 'flex', alignItems: 'center', marginLeft: 30 }}>
                            <Button className="arrow-back-icon-svg"
                                style={{ marginBottom: '2%', minWidth: 30 }} variant="text"
                                component={Link}
                                to={ROUTES.DASHBOARD} startIcon={<ArrowBack />}>
                            </Button>
                            <Title title={t('sidebar:helpLbl')} />
                        </div>
                    }
                    {alert &&
                        <Alert severity="error" className='change-pass-alert'>{alert}</Alert>
                    }
                    {children}
                </div>
            </div>
        </div>
    )
};

export default withRouter(Sidebar);