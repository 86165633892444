import React, { useState, useEffect, useRef } from 'react';
import { withRouter } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
    Modal, Typography, Button,Container,
    TableContainer, TableHead, TableRow,
    TableCell, TableBody, Table, Grid
} from "@mui/material";
import TextFieldComponent from '../../components/TextField';
import SelectFieldComponent from "../../components/SelectField";
import Loading from '../../components/Loading';
//import Logo from '../../assets/Images/Login.png';
import SwitchToggleComponent from "../../components/SwitchToggle";
import Checkbox from "../../components/Checkbox";
//import { useTranslation, initReactI18next } from "react-i18next";
import { getCountriesEN } from "../../redux/CountryDuck";
import './DataDeletion.css';
import { useTranslation } from 'react-i18next';

//import Logo from '../../assets/Logos/Logo.png';

const DataDeletion = props => {

    const { t } = useTranslation([ 'help']);
    const [checkoutVisible, setCheckoutVisible] = useState(false);
    const countries = useSelector(store => store.country.countries);
    const subPlans = useSelector(store => store.subscription.plans);
    const checkoutError = useSelector(store => store.subscription.alertMessage);
                              
    const inputRef = useRef(null);
   
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getCountriesEN());
    }, [dispatch]);

    const colors = [
        { "band-color": "#16acea", "title-color": "#fff" },
        { "band-color": "#5ab6df", "title-color": "#fff" },
        { "band-color": "#bee3e9", "title-color": "#959393" },
        { "band-color": "#aba3d0", "title-color": "#fff" },
        { "band-color": "#8976b6", "title-color": "#fff" },
        { "band-color": "#fff", "title-color": "#fff" }
    ];

   
return (
    <Container>

<div>
<Typography className="admin-business-text" style={{ marginBottom: '20px' }}>
                {t('help:dataDeletion:title') }
            </Typography>
            <Grid item xs={12}>
                
                
                <div className="container-app-div" style={{ marginTop: 10 }}>
                    To request your account and associated data deletion please perform the following steps:
                    1.Go to the Profile Page on the App. 
                    2. Press on "Delete Account"
                    3.Confirm your request.
                    Your account and all associated data will be deleted.
                    Further information can be found in our <a target="_blank" rel="noreferrer" href="https://fos.services/privacy-policy/">Privacy Policy</a>
                </div>
                <div className="container-app-div" style={{ marginTop: 10 }}>
                    
                </div>
                
            </Grid>
</div>
    </Container>
);

}

export default withRouter(DataDeletion);