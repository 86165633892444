import React, { useState, useEffect } from "react";
import { withRouter } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import {
    Container, Typography, Button,
    Grid, InputLabel,
} from "@mui/material";
import TextFieldComponent from "../../../components/TextField";
import SelectFieldComponent from "../../../components/SelectField";
import Title from "../../../components/Title";
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { ReactComponent as Search } from '../../../assets/Icons/search.svg';
import { ReactComponent as Filter } from '../../../assets/Icons/filter.svg';
import TableDetails from '../TableDetail';
import PaginationCustom from "../../../components/Pagination";
import { getClients, changeClientPage } from "../../../redux/ClientDuck";
import { getBusinessesFilter, getOfficesFilter } from '../../../redux/BusinessDuck'
import * as ROUTES from '../../../constants/routes';
import { useTranslation } from "react-i18next";
const Read = props => {

    const { t } = useTranslation(['customer']);
    const clients = useSelector(store => store.client.clients);
    const businesses = useSelector(store => store.business.businesses);
    const officesRedux = useSelector(store => store.business.offices);
    const activePage = useSelector(store => store.client.activePage);
    const pages = useSelector(store => store.client.pages);
    const [filter, setFilter] = useState('none');
    const [search, setSearch] = useState('');
    const [companyId, setCompanyId] = useState('all');
    const [companies, setCompanies] = useState([]);
    const [officeId, setOfficeId] = useState('all');
    const [offices, setOffices] = useState([]);
    const [resetPage, setResetPage] = useState(false);
    const dispatch = useDispatch();

    //componentDidMount
    useEffect(() => {
        dispatch(changeClientPage(1));
        //component will unmount
        return () => {
            dispatch(changeClientPage(1));
        }
    }, [dispatch]);

    const onChangeFilter = (event) => {
        setFilter(event.target.value);
        setResetPage(true);
    };

    const onChange = (event) => {
        setSearch(event.target.value);
        setResetPage(true);
    };

    const onChangeCompanyId = (event) => {
        setCompanyId(event.target.value);
        setResetPage(true);
    };

    const onChangeOfficeId = (event) => {
        setOfficeId(event.target.value);
        setResetPage(true);
    };

    useEffect(() => {
        const businessList = [];
        businesses.forEach(item => {
            businessList.push({ key: item.id, value: item.name });
        });
        setCompanies(businessList);
    }, [businesses]);


    useEffect(() => {
        const officesTemp = [];
        officesRedux.forEach(item => {
            officesTemp.push({ key: item.id, value: item.name });
        });
        setOffices(officesTemp);
        setOfficeId('all');
    }, [companyId, officesRedux]);

    useEffect(() => {
        const page = resetPage ? 0 : activePage;
        const filterBy = [];
        search !== '' && filterBy.push({ search: search, filterBy: 'name' });
        filter !== 'none' && filterBy.push({ search: filter, filterBy: 'disabled' });
        companyId !== 'all' && filterBy.push({ search: String(companyId), filterBy: 'company' });
        officeId !== 'all' && filterBy.push({ search: String(officeId), filterBy: 'office' });
        const timer = setTimeout(() => dispatch(getClients(filterBy, page, 10, true, "client")), 1000);
        setResetPage(false);
        return () => clearTimeout(timer);
        // eslint-disable-next-line
    }, [dispatch, search, filter, companyId, officeId]);

    useEffect(() => {
        const page = resetPage ? 0 : activePage;
        const filterBy = [];
        search !== '' && filterBy.push({ search: search, filterBy: 'name' });
        filter !== 'none' && filterBy.push({ search: filter, filterBy: 'disabled' });
        companyId !== 'all' && filterBy.push({ search: String(companyId), filterBy: 'company' });
        officeId !== 'all' && filterBy.push({ search: String(officeId), filterBy: 'office' });
        dispatch(getClients(filterBy, page, 10, false, "client"));
        setResetPage(false);
        // eslint-disable-next-line
    }, [dispatch, activePage]);

    useEffect(() => {
        dispatch(getBusinessesFilter());
    }, [dispatch]);

    useEffect(() => {
        dispatch(getOfficesFilter(companyId !== 'all' ? [{
            "search": String(companyId),
            "filterBy": "company"
        }] : []));
    }, [dispatch, companyId]);

    return (
        <Container>
            <Title title={t("title")} style={{ marginLeft: 5 }} />
            <Grid item xs={12}>
                <div className="container-app-div">
                    <Typography className="app-form-title">{t("filterLbl")}</Typography>
                    <div style={{
                        display: 'flex', flexDirection: 'row',
                        width: '100%', justifyContent: 'space-between'
                    }}>
                        <div style={{ width: '48%' }}>
                            <InputLabel className="label-app">{t("companyLbl")}</InputLabel>
                            <SelectFieldComponent name="businessName"
                                valueInput={companyId}
                                onChange={onChangeCompanyId}
                                options={[
                                    { key: 'all', value: t("allPlh") },
                                    ...companies
                                ]}
                            />
                        </div>
                        <div style={{ width: '48%' }}>
                            <InputLabel className="label-app">{t("officeLbl")}</InputLabel>
                            <SelectFieldComponent name="officesName"
                                valueInput={officeId}
                                onChange={onChangeOfficeId}
                                options={[
                                    { key: 'all', value: t("allPlh") },
                                    ...offices
                                ]}
                            />
                        </div>
                    </div>
                </div>
                <div className="container-app-div">
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Typography className="app-form-title">{t("clientsLbl")}</Typography>
                        <Button className='button-app-create' variant="text"
                            startIcon={<AddRoundedIcon />}
                            onClick={() => props.history.push(ROUTES.CLIENT_CREATE)}>
                            {t("subtitle6")}
                        </Button>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div style={{ width: '35%' }}>
                            <TextFieldComponent Icon={<Search />}
                                valueInput={search}
                                callback={onChange}
                                position='start' placeholder={t("searchLbl")}
                            />
                        </div>
                        <div>
                            <SelectFieldComponent name="filter"
                                placeholder={t("filterByStatusLbl")}
                                valueInput={filter}
                                onChange={onChangeFilter}
                                options={[
                                    { key: 'false', value: t("statusActiveLbl") },
                                    { key: 'true', value: t("statusArchivedLbl") }
                                ]}
                                Icon={Filter}
                            />
                        </div>
                    </div>
                    <div>
                        <TableDetails clients={clients} typeOfTable="Principal" />
                    </div>
                    <br />
                    <PaginationCustom pages={pages} activePage={activePage}
                        onChange={page => dispatch(changeClientPage(page))} />
                </div>
            </Grid>
        </Container>
    );
}

export default withRouter(Read);