import React from 'react';
import { Typography, CardContent } from '@mui/material';
import { ReactComponent as Folder } from '../../../assets/Icons/folder.svg';
import './Folder.css';
import { useDispatch } from 'react-redux';
import { selectFileId } from '../../../redux/DocumentDuck';

function FolderContainer(props) {

    const dispatch = useDispatch();

    return (
        <CardContent className='card-content-item-folder' onClick={() => dispatch(selectFileId(props.id, 0, 5, true))}>
            <div>
                <Folder className="icon-folder icon-svg-folder" />
                <Typography className='card-text-folder' style={{
                    width: 100,
                    textOverflow: 'ellipsis',
                    overflow: 'hidden'
                }}>
                    {props.texto}
                </Typography>
                <Typography className='card-text-counter'>
                    {props.counterText}
                </Typography>
            </div>
        </CardContent>
    );
}

export default FolderContainer;