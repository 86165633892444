import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    Container, Grid, Typography, InputLabel,
    Avatar, Button, Divider,
} from "@mui/material";
import Checkbox from "../../components/Checkbox";
import SelectFieldComponent from "../../components/SelectField";
import Title from "../../components/Title";
import TextFieldComponent from "../../components/TextField";
import SwitchComponent from "../../components/Switch";
import AccessRow from "./AccessRow";
import CircleIcon from '@mui/icons-material/Circle';
import { ReactComponent as Arrow } from '../../assets/Icons/arrow.svg';
import { ReactComponent as PersonOutline } from '../../assets/Icons/personOutline.svg';
import { ReactComponent as Journals } from '../../assets/Icons/journals.svg';
import { ReactComponent as Toggles } from '../../assets/Icons/toggles.svg';
import { ReactComponent as PersonSquare } from '../../assets/Icons/personSquare.svg';
import { ReactComponent as Phone } from '../../assets/Icons/phone.svg';
import { ReactComponent as Building } from '../../assets/Icons/building.svg';
import { ReactComponent as Subtract } from '../../assets/Icons/subtract.svg';
import { ReactComponent as Settings } from '../../assets/Icons/settings.svg';
import { ReactComponent as PersonCheck } from '../../assets/Icons/personCheck.svg';
import { ReactComponent as Person } from '../../assets/Icons/person.svg';
import { ReactComponent as BriefCase } from '../../assets/Icons/briefcase.svg';
import { ReactComponent as Document } from '../../assets/Icons/document.svg';
import { ReactComponent as Quote } from '../../assets/Icons/quote.svg';
import { ReactComponent as CheckCircle } from '../../assets/Icons/checkCircle.svg';
// import { ReactComponent as Question } from '../../assets/Icons/question.svg';
import { ReactComponent as Email } from '../../assets/Icons/email.svg';
import {
    getUsers, getUserById,
    // clearUserList 
} from '../../redux/UserDuck';
import './Permission.css';
import { copyPermission, editPermission, getPermissionById } from "../../redux/PermissionDuck";
import { getFileURL } from "../../constants/apiUrls";
import { getBusinessesFilter, getOfficesFilter } from "../../redux/BusinessDuck";
import { useTranslation } from 'react-i18next';

const Permission = () => {
    const { t } = useTranslation(['permission', 'common', 'error']);
    const users = useSelector(store => store.user.users);
    const userAuth = useSelector(store => store.auth.user);
    const user = useSelector(store => store.user.user);
    const permission = useSelector(store => store.permission.permission);
    const officesRedux = useSelector(store => store.business.offices);

    const businesses = useSelector(store => store.business.businesses);
    const [functionalPermissions, setFunctionalPermissions] = useState({
        receiveMailNewClient: false,
        receiveMailFormApp: false, offices: []
    });
    const [activePermissions, setActivePermissions] = useState([]);
    const [userList, setUserList] = useState([]);
    const [userSelected, setUserSelected] = useState('');
    const [userIdFrom, setUserIdFrom] = useState('');
    const [permissionError, setPermissionError] = useState({});
    const [businessId, setBusinessId] = useState('all');
    const [officeId, setOfficeId] = useState('all');
    const [businessList, setBusinessList] = useState([]);
    const [offices, setOffices] = useState([]);
    const dispatch = useDispatch();

    const status = [
        { status: t("common:commonStatusProcessing"), color: '#6C757D' },
        { status: t("common:commonStatusActive"), color: '#28A745' },
        { status: t("common:commonStatusArchived"), color: '#6C757D' },
    ];

    const permissionsList = [
        {
            icon: Settings,
            name: 'Permissions.Configuration',
            text: t("permission1")
        },
        {
            icon: Subtract,
            name: 'Permissions.AppConfiguration',
            text: t("permission2")
        },
        {
            icon: Building,
            name: 'Permissions.Companies',
            text: t("permission3")
        },
        {
            icon: Phone,
            name: 'Permissions.Mobile',
            text: t("permission4"),
        },
        {
            icon: PersonSquare,
            name: 'Permissions.Users',
            text: t("permission5"),
        },
        {
            icon: Toggles,
            name: 'Permissions.Permissions',
            text: t("permission6"),
        },
        {
            icon: PersonOutline,
            name: 'Permissions.AssignUsers',
            text: t("permission7"),
        },
        {
            icon: Journals,
            name: 'Permissions.Logbook',
            text: t("permission8"),
        },
        {
            icon: BriefCase,
            name: 'Permissions.Clients',
            text: t("permission9"),
        },
        {
            icon: PersonCheck,
            name: 'Permissions.ClientStaff',
            text: t("permission10"),
        },
        {
            icon: Person,
            name: 'Permissions.Subject',
            text: t("permission11"),
        },
        {
            icon: Quote,
            name: 'Permissions.Notifications',
            text: t("permission12"),
        },
        {
            icon: CheckCircle,
            name: 'Permissions.Authorizations',
            text: t("permission13"),
        },
        {
            icon: Document,
            name: 'Permissions.Documents',
            text: t("permission14"),
        }
    ];

    const onUserChange = (e) => {
        const { value } = e.target;
        setUserSelected(value);
        dispatch(getUserById(value, true));
        dispatch(getPermissionById(value));
    }

    const onSwitch = event => {
        const { name, checked } = event.target;
        const list = activePermissions;
        if (checked) {
            list.push(name);
        } else {
            const index = list.indexOf(name);
            index > -1 && list.splice(index, 1);
        }
        setActivePermissions(prev => ([...list]));
    };

    const onSwitchFunctionalPermission = event => {
        const { name, checked } = event.target;
        setFunctionalPermissions({
            ...functionalPermissions,
            [name]: checked
        });
    };

    const onOfficeChange = event => {
        const { name, checked } = event.target;
        const list = functionalPermissions.offices;
        if (checked) {
            list.push({ id: parseInt(name) });
        } else {
            const index = list.findIndex(item => parseInt(item.id) === parseInt(name));
            index > -1 && list.splice(index, 1);
        }

        setFunctionalPermissions({ ...functionalPermissions, offices: list });
    };

    const onSubmit = () => {
        if (validatePermission()) {
            dispatch(editPermission(userSelected, activePermissions, functionalPermissions));
            setPermissionError({});
        }
    };

    const onSubmitCopy = () => {
        if (validatePermissionCopy()) {
            dispatch(copyPermission(userIdFrom, userSelected));
            setPermissionError({});
        }
    };

    const validatePermission = () => {
        var error = { userSelected: '', receiveMailNewClient: '' };

        if (userSelected === null || userSelected.length === 0
            || /^\s+$/.test(userSelected))
            error.userSelected = t("error:requiredUser");

        if (functionalPermissions.receiveMailNewClient && functionalPermissions.offices.length === 0) {
            error.receiveMailNewClient = t("error:requiredOffices");
        }
        setPermissionError({ ...permissionError, ...error });
        return !error.userSelected && !error.receiveMailNewClient;
    };

    const validatePermissionCopy = () => {
        var error = { userIdFrom: '' };

        if (userIdFrom === null || userIdFrom.length === 0
            || /^\s+$/.test(userIdFrom))
            error.userIdFrom = t("error:requiredUser");
        setPermissionError({ ...permissionError, ...error });
        return !error.userIdFrom;
    };

    useEffect(() => {
        const list = [];
        if (userAuth) {
            users.forEach(item => {
                list.push({
                    key: item.id,
                    value: item.firstName + ' ' + item.lastName,
                });
            });
            setUserList(list);
        }
    }, [users, userAuth]);

    useEffect(() => {
        const permissionListTemp = [];
        const officeListTemp = [];
        var AppFormNotification = false;
        var NewClientNotification = false;
        permission &&
            permission.permissions.forEach(item => {
                if (!item.permissionType && item.permissionName !== 'Permissions.Offices') {
                    permissionListTemp.push(item.permissionName);
                } else if (item.permissionType) {
                    if (item.permissionName === 'FunctionalPermissions.AppFormNotification')
                        AppFormNotification = true;
                    else if (item.permissionName === 'FunctionalPermissions.NewClientNotification')
                        NewClientNotification = true;
                    item.offices.forEach(off => {
                        officeListTemp.push({ id: off.id });
                    });
                }
            });
        setFunctionalPermissions({
            receiveMailNewClient: NewClientNotification,
            receiveMailFormApp: AppFormNotification,
            offices: officeListTemp
        });
        setActivePermissions(prev => ([...permissionListTemp]));

    }, [permission]);

    useEffect(() => {
        if (userAuth) {
            const filterBy = [];
            businessId !== 'all' && filterBy.push({ search: String(businessId), filterBy: 'company' });
            officeId !== 'all' && filterBy.push({ search: String(officeId), filterBy: 'office' });
            dispatch(getUsers(filterBy, 0, 10000, false, "permissions"));
        }
    }, [dispatch, businessId, officeId, userAuth]);

    useEffect(() => {
        const officesTemp = [];
        officesRedux.forEach(item => {
            officesTemp.push({ key: item.id, value: item.name });
        });
        setOffices(officesTemp);
        setOfficeId('all');
    }, [businessId, officesRedux]);

    useEffect(() => {
        dispatch(getBusinessesFilter([], false));
    }, [dispatch]);

    useEffect(() => {
        dispatch(getOfficesFilter(businessId !== 'all' ? [{
            "search": String(businessId),
            "filterBy": "company"
        }] : [], false));
    }, [dispatch, businessId]);

    useEffect(() => {
        const list = [];
        businesses.forEach(item => {
            const officeList = [];
            item.offices.forEach(office => {
                officeList.push({ key: office.id, value: office.name });
            });
            list.push({
                key: item.id, value: item.name,
                offices: officeList
            });
        });
        setBusinessList(list);
    }, [businesses]);

    const changeBusinessId = e => {
        setBusinessId(e.target.value);
        setOfficeId('all');
        setUserSelected('');
    };

    return (
        <Container>
            <Title title={t("title")} style={{ marginLeft: 5 }} />
            <Grid item xs={12}>
                <div className="container-app-div">
                    <Typography className="app-form-title">{t("subtitle1")}</Typography>
                    <div style={{
                        display: 'flex', flexDirection: 'row',
                        width: '100%', justifyContent: 'space-between',
                        marginBottom: 24
                    }}>
                        <div style={{ width: '48%' }}>
                            <InputLabel className="label-app">{t("common:commonCompanyLbl")}</InputLabel>
                            <SelectFieldComponent name="businessName"
                                valueInput={businessId}
                                options={[
                                    { key: 'all', value: t("common:commonAllLbl") },
                                    ...businessList
                                ]}
                                onChange={changeBusinessId}
                            />
                        </div>
                        <div style={{ width: '48%' }}>
                            <InputLabel className="label-app">{t("common:commonOfficeLbl")}</InputLabel>
                            <SelectFieldComponent name="officesName"
                                valueInput={officeId}
                                options={[{ key: 'all', value: t("common:commonAllLbl") }, ...offices]}
                                onChange={e => setOfficeId(e.target.value)}
                            />
                        </div>
                    </div>
                    <InputLabel className="label-app">{t("subtitle2")}</InputLabel>
                    <SelectFieldComponent name="userName" className="replication"
                        valueInput={userSelected}
                        onChange={onUserChange}
                        // options={userList}
                        options={userList.length === 0 ? [{ key: '', value: t("noUsersFound") }] : userList}
                        errorInput={permissionError.userSelected}
                    />
                </div>
                {(userSelected && user) &&
                    <div className="container-app-div" style={{ display: 'flex' }}>
                        <div>
                            <Avatar
                                className='avatar-image'
                                alt={userList.find(item => item.key === userSelected).value}
                                src={getFileURL + user.fileId}
                                sx={{ width: 112, height: 112 }}
                            />
                        </div>
                        <div style={{ marginLeft: 37 }}>
                            <Typography className="user-name-text">
                                {userList.find(item => item.key === userSelected).value}
                            </Typography>
                            <Typography className="job-user-tite" style={{ margin: '4px 0px' }}>
                                {user.organizationUser.position}
                            </Typography>
                            <div className='table-row-cell'
                                style={{
                                    color: status[user.status].color,
                                    margin: '9px 0px'
                                }}
                            >
                                <CircleIcon style={{ fontSize: 7, marginRight: 7 }} />
                                <Typography>
                                    {status[user.status].status}
                                </Typography>
                            </div>
                        </div>
                    </div>
                }
                {(userSelected && user) &&
                    <div className="container-app-div replication">
                        <Typography className="app-form-title">{t("subtitle3")}</Typography>
                        <Typography className="app-form-subtitle">
                            {t("subtitle4")}
                        </Typography>
                        <InputLabel className="label-app">{t("subtitle5")}</InputLabel>
                        <div style={{ width: '100%', height: 55 }} className='align-divs'>
                            <div style={{ width: '40%' }}>
                                <SelectFieldComponent name="userName"
                                    valueInput={userIdFrom}
                                    onChange={e => setUserIdFrom(e.target.value)}
                                    options={userList.filter(item => item.key !== userSelected)}
                                    errorInput={permissionError.userIdFrom}
                                />
                            </div>
                            <div style={{ margin: 33 }}>
                                <Arrow />
                            </div>
                            <div style={{ width: '40%' }}>
                                <TextFieldComponent name="userIdTo"
                                    valueInput={userList.find(item => item.key === userSelected).value}
                                    disabled={true} callback={e => null}
                                />
                            </div>
                            <div style={{ marginLeft: 27 }}>
                                <Button variant="contained" className="button-app-save"
                                    onClick={() => onSubmitCopy()}>
                                    {t("common:commonSubmitBtn")}
                                </Button>
                            </div>
                        </div>
                    </div>
                }
                {(userSelected && user) &&
                    <div className="container-app-div">
                        <Typography className="app-form-title">{t("subtitle6")}</Typography>
                        <Typography className="app-form-subtitle">
                            {t("subtitle7")}
                        </Typography>
                        {permissionsList.map((option, index) => (
                            <div key={index}>
                                <div className='align-divs' >
                                    <AccessRow
                                        title={option.text}
                                        Icon={option.icon}
                                        active={user.isAdmin
                                            ? false
                                            : activePermissions.includes(option.name)}
                                    />
                                    <div style={{ marginRight: 18 }}>
                                        <SwitchComponent
                                            callback={onSwitch}
                                            checked={activePermissions.includes(option.name)}
                                            // checked={user.isAdmin ? true : activePermissions.includes(option.name)}
                                            name={option.name}
                                            // disabled={user.isAdmin}
                                        />
                                    </div>
                                </div>
                                <Divider />
                            </div>
                        ))}
                    </div>
                }
                {(userSelected && user) &&
                    <div className="container-app-div">
                        <Typography className="app-form-title">{t("subtitle8")}</Typography>
                        <Typography className="app-form-subtitle">
                            {t("subtitle9")}
                        </Typography>
                        <Divider />
                        <div className='align-divs' >
                            <AccessRow
                                title={t("subtitle10")}
                                Icon={Email}
                                active={functionalPermissions.receiveMailNewClient}
                            />
                            <div style={{ marginRight: 18 }}>
                                <SwitchComponent
                                    callback={onSwitchFunctionalPermission}
                                    checked={functionalPermissions.receiveMailNewClient}
                                    name='receiveMailNewClient'
                                />
                            </div>
                        </div>
                        <Divider />
                        {functionalPermissions.receiveMailNewClient &&
                            <div className='offices-list-div'>
                                <Typography className="offices-title">{t("common:commonOfficeLbl") + "s"}</Typography>
                                {user.userOffices.map(item => (
                                    <div className='align-divs' key={item.officeId}>
                                        <Typography className="offices-list-text">
                                            {item.office.name}
                                        </Typography>
                                        <Checkbox
                                            name={item.officeId}
                                            onChange={onOfficeChange}
                                            value={!!functionalPermissions.offices.find(off => String(off.id) === String(item.officeId))}
                                            checkboxCheckedValue={true}
                                        />
                                    </div>
                                ))}
                            </div>
                        }
                        <p className='app-error-text'>{permissionError.receiveMailNewClient}</p>
                        <Divider />
                        <div className='align-divs' >
                            <div className='table-row-cell'>
                                <AccessRow
                                    title={t("subtitle11")}
                                    Icon={Email}
                                    active={functionalPermissions.receiveMailFormApp}
                                />
                                {/* <Question style={{ fontSize: 7, marginLeft: 11 }} /> */}
                            </div>
                            <div style={{ marginRight: 18 }}>
                                <SwitchComponent
                                    callback={onSwitchFunctionalPermission}
                                    checked={functionalPermissions.receiveMailFormApp}
                                    name='receiveMailFormApp'
                                />
                            </div>
                        </div>
                        <Divider />
                    </div>
                }
                {userSelected &&
                    <div style={{ display: 'flex' }}>
                        <Button variant="contained" className="button-app-save" style={{ marginRight: 18 }}
                            onClick={() => onSubmit()}>
                            {t("common:commonSubmitBtn")}
                        </Button>
                    </div>
                }
            </Grid>
        </Container>
    );
}

export default (Permission);