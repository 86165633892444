import React, { useEffect, useState } from "react";
import { Breadcrumbs, Typography, Link, Button, Divider, IconButton } from "@mui/material";
import { ReactComponent as Arrow } from '../../../assets/Icons/arrowNext.svg';
import { ReactComponent as Folder } from '../../../assets/Icons/folder.svg';
import { ReactComponent as BlueImage } from '../../../assets/Icons/blueImage.svg';
import { ReactComponent as BlueFile } from '../../../assets/Icons/blueFile.svg';
import { ReactComponent as CloudDownload } from '../../../assets/Icons/cloudDownloadBlue.svg';
import { ReactComponent as Trash } from '../../../assets/Icons/trash.svg';
import { useDispatch, useSelector } from "react-redux";
import {
    selectFileId, deleteDoc, changeFolderDocPage,
    changeIsDeleted, validateFolder, getDocumentDownload
} from "../../../redux/DocumentDuck";
import './Results.css';
import Moment from "react-moment";
import PaginationCustom from "../../../components/Pagination";
import { useTranslation } from "react-i18next";
import DeleteFolder from "../Folders/Delete";
const DocumentResults = ({ clientId }) => {

    const { t } = useTranslation(['document']);
    const dispatch = useDispatch();
    const folders = useSelector(store => store.doc.folders);
    const folderId = useSelector(store => store.doc.folderId);
    const folderDocs = useSelector(store => store.doc.folderDocs);
    const folderValidation = useSelector(store => store.doc.folderValidation);

    const pagesFolderDoc = useSelector(store => store.doc.pagesFolderDoc);
    const activeFolderPageDoc = useSelector(store => store.doc.activePageFolderDoc);
    const isDeletedFile = useSelector(store => store.doc.isDeletedFile);

    const [openModal, setOpenModal] = useState(false);

    function handleClick(event) {
        event.preventDefault();
        dispatch(selectFileId(null));
    };

    const imageExtensions = [".JPG", ".JPEG", ".GIF", ".PNG", ".TIFF",
        ".TIF", ".RAW", ".BMP", ".PSD", ".EPS", ".PIC",
        ".MOV", ".MKV", ".MP4", ".WMV", ".FLV"];

    useEffect(() => {
        if (folderId || isDeletedFile) {
            if (folderId && !isDeletedFile) {
                dispatch(validateFolder(folderId));
            }
            dispatch(selectFileId(folderId, activeFolderPageDoc, 5, true));
            if (isDeletedFile)
                dispatch(changeIsDeleted());
        }
    }, [dispatch, folderId, activeFolderPageDoc, isDeletedFile, folders]);
    //folders was not in dependencies above before, maybe we need to remove it?

    const downloadFile = (fileId, fileName) => {
        dispatch(getDocumentDownload(fileId, fileName, true));
    };

    return folderId && (
        <div className="container-app-div">
            <DeleteFolder
                open={openModal}
                closeModal={() => setOpenModal(false)}
                folderId={folderId}
                clientId={clientId}
                folderValidation={folderValidation}
            />

            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Breadcrumbs
                    separator={<Arrow />}
                    aria-label="breadcrumb"
                >
                    <Link
                        underline="none"
                        key="1"
                        color="inherit"
                        href="/"
                        onClick={handleClick}
                    >
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Folder className="icon-folder icon-svg-folder" style={{ marginRight: '10.29px' }} />
                            <Typography className='folder-text-result'>
                                {t('foldersLbl')}
                            </Typography>
                        </div>
                    </Link>
                    ,
                    <Typography key="2" className="folder-results-name" style={{ marginLeft: '10px' }}>
                        {folders.find(f => f.id === folderId).name}
                    </Typography>
                </Breadcrumbs>
                <div style={{ width: '5%' }}>
                    <IconButton onClick={() => setOpenModal(true)}>
                        <Trash className="documents-icon-svg-delete-results documents-icon-delete-results" />
                    </IconButton>
                </div>

            </div>
            <Divider className="divider-line-results" />
            {folderDocs.length === 0 &&
                <div style={{ textAlign: 'center' }}>
                    {t('noFilesText1')} "{folders.find(f => f.id === folderId).name}" {t('noFilesText2')}
                </div>
            }
            {folderDocs.map(item =>
                <div className="container-results-div" style={{
                    display: 'flex', justifyContent: 'space-between',
                    alignItems: 'center'
                }} key={item.id}>
                    <div style={{ display: 'flex', width: '45%' }}>
                        <div>
                            {imageExtensions.includes(item.fileExtension.toUpperCase().trim())
                                ?
                                <BlueImage />
                                :
                                <BlueFile />
                            }
                        </div>
                        <div style={{ marginLeft: 11 }}>
                            <Typography className="document-name-results"
                                style={{
                                    width: 250,
                                    whiteSpace: 'nowrap',
                                    textOverflow: 'ellipsis',
                                    overflow: 'hidden'
                                }}>
                                {item.fileName}
                            </Typography>
                            <Moment className="document-date-text" style={{ marginTop: '4px' }}
                                format="DD/MM/YYYY">
                                {item.created}
                            </Moment>
                        </div>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', width: '15%' }}>
                        <Folder style={{ marginRight: '10.14px' }} />
                        <Typography className='folder-name-results'
                            style={{
                                width: 160,
                                whiteSpace: 'nowrap',
                                textOverflow: 'ellipsis',
                                overflow: 'hidden'
                            }}>
                            {item.folder.name}
                        </Typography>
                    </div>
                    <div style={{ width: '12%' }}>
                        <Typography className='user-name-title-results'>
                            {item.uploadedByUser.firstName + ' ' + item.uploadedByUser.lastName}
                        </Typography>
                    </div>
                    <div style={{ width: '12%' }}>
                        {/* <Button className='download-button-results' variant="text" startIcon={<CloudDownload />}>
                            {t('downloadLbl')}
                        </Button> */}
                        <Button className='download-button' variant="text" startIcon={<CloudDownload />}
                            onClick={() => downloadFile(item.id, item.fileName)}>
                            {t('downloadLbl')}
                        </Button>
                    </div>
                    <div style={{ width: '5%' }}>
                        <IconButton onClick={() => dispatch(deleteDoc(item.id, "", clientId))}>
                            <Trash className="documents-icon-svg-delete-results documents-icon-delete-results" />
                        </IconButton>
                    </div>
                </div>
            )}
            <br />
            <PaginationCustom pages={pagesFolderDoc} activePage={activeFolderPageDoc}
                onChange={page => dispatch(changeFolderDocPage(page))} />
        </div>
    );

}

export default DocumentResults;