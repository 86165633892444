export const validateNewPage = (page, t) => {
    if (page.page === null || page.page.length === 0 ||
        /^\s+$/.test(page.page))
        return t("requiredPageNameError")
    if (page.content.length === 0)
        return t("emptyPageError")
    return null;
};

export const validateDefaultPage = (page, t) => {
    if (page.content.length > 0 && (page.page === null || page.page.length === 0 ||
        /^\s+$/.test(page.page)))
        return t("requiredPageNameError")
    if (page.page.length > 0 && page.content.length === 0)
        return t("emptyPageError")

    if (page.page !== null && page.content.length !== 0)
        return t("emptyPageError");
    return null;
};

export const validatePageContent = (page, languages, t) => {
    var error = {};
    error[page.pageIndex] = [];
    page.content.forEach(content => {
        const value = content.value;
        const errors = { [content.contentIndex]: [] };

        languages.forEach(({ key }) => {
            if (content.contentType === 'TEXT') {
                if (!value[key] || (value[key] && (value[key].text === null || value[key].text.length === 0
                    || /^\s+$/.test(value[key].text) || value[key].text === "<p></p>\n")))
                    errors[content.contentIndex].push({
                        [key]: t("requiredTextError")
                    });
            } else if (content.contentType === 'IMAGE') {
                if (!value[key] || (value[key] && (value[key].imageUrl === null
                    || value[key].imageUrl.length === 0 || /^\s+$/.test(value[key].imageUrl))))
                    errors[content.contentIndex].push({
                        [key]: t("requiredImageError")
                    });
            } else if (content.contentType === 'GALLERY') {
                if (!value[key] || (value[key] && (value[key] === null
                    || value[key].length === 0 || /^\s+$/.test(value[key]))))
                    errors[content.contentIndex].push({
                        [key]: t("requiredGalleryError")
                    });
            } else if (content.contentType === 'MAP') {
                if (!value[key] || (value[key] && (value[key].map === null
                    || value[key].map.length === 0 || /^\s+$/.test(value[key].map))))
                    errors[content.contentIndex].push({
                        [key]: t("requiredCoordinatesError")
                    });
            } else if (content.contentType === 'FORM') {
                if (!value[key] || (value[key] && (value[key] === null
                    || value[key].length === 0 || /^\s+$/.test(value[key]))))
                    errors[content.contentIndex].push({
                        [key]: t("requiredFormError")
                    });
            } else if (content.contentType === 'USER_CARD') {
                if (!value[key] || (value[key] && (value[key].userId === null
                    || value[key].userId.length === 0 || /^\s+$/.test(value[key].userId))))
                    errors[content.contentIndex].push({
                        [key]: t("requiredUserCardError")
                    });
            } else if (content.contentType === 'VIDEO') {
               
                if (!value[key] || (value[key] && (value[key].url === null || value[key].url.length === 0
                    || /^\s+$/.test(value[key].url)))){
                    errors[content.contentIndex].push({
                        [key]: t("requiredUrlError")
                    });
                    }
                else if (!isValidYoutubeLink(value[key].url)) {
                    errors[content.contentIndex].push({
                        [key]: t("errorInvalidURL")
                    });
                }
            }
        });
        if (errors[content.contentIndex].length > 0)
            error[page.pageIndex].push(errors);
    });

    return error;
};

export const validatePagesContents = (pages, languages, t) => {
    var error = {};
    pages.forEach(page => {
        error[page.pageIndex] = [];
        page.content.forEach(content => {
            const value = content.value;
            const errors = { [content.contentIndex]: [] };
            languages.forEach(({ key }) => {
                if (content.contentType === 'TEXT') {
                    if (!value[key] || (value[key] && (value[key].text === null
                        || value[key].text.length === 0 || /^\s+$/.test(value[key].text) || value[key].text === "<p></p>\n")))
                        errors[content.contentIndex].push({
                            [key]: t("requiredTextError")
                        });
                } else if (content.contentType === 'IMAGE') {
                    if (!value[key] || (value[key] && (value[key].imageUrl === null
                        || value[key].imageUrl.length === 0 || /^\s+$/.test(value[key].imageUrl))))
                        errors[content.contentIndex].push({
                            [key]: t("requiredImageError")
                        });
                } else if (content.contentType === 'GALLERY') {
                    if (!value[key] || (value[key] && (value[key] === null
                        || value[key].length === 0 || /^\s+$/.test(value[key]))))
                        errors[content.contentIndex].push({
                            [key]: t("requiredGalleryError")
                        });
                } else if (content.contentType === 'MAP') {
                    if (!value[key] || (value[key] && (value[key].map === null
                        || value[key].map.length === 0 || /^\s+$/.test(value[key].map))))
                        errors[content.contentIndex].push({
                            [key]: t("requiredCoordinatesError")
                        });
                } else if (content.contentType === 'FORM') {
                    if (!value[key] || (value[key] && (value[key] === null
                        || value[key].length === 0 || /^\s+$/.test(value[key]))))
                        errors[content.contentIndex].push({
                            [key]: t("requiredFormError")
                        });
                } else if (content.contentType === 'USER_CARD') {
                    if (!value[key] || (value[key] && (value[key].userId === null
                        || value[key].userId.length === 0 || /^\s+$/.test(value[key].userId))))
                        errors[content.contentIndex].push({
                            [key]: t("requiredUserCardError")
                        });
                } else if (content.contentType === 'VIDEO') {
                    if (!value[key] || (value[key] && (value[key].url === null || value[key].url.length === 0
                        || /^\s+$/.test(value[key].url))))
                        errors[content.contentIndex].push({
                            [key]: t("requiredUrlError")
                        });
                    else if ( !isValidYoutubeLink(value[key].url) ) {
                        errors[content.contentIndex].push({
                            [key]: t("errorInvalidURL")
                        });
                    }
                }
            });

            if (errors[content.contentIndex].length > 0)
                error[page.pageIndex].push(errors);
        });
    });
    return error;
};

const isValidYoutubeLink = (url) => {
    // tests wether it is a valid URL or a youtubeLink , if its not a full URL will just validate if content is an apparent valid youtube id
    const IDRegex = new RegExp(/^(?!.*http)([A-Za-z0-9-_]{11,})$/g);
    const YURL = url;
    //console.log(url,IDRegex.test(url),"url");
    try {
        const miUrl = new URL(url);
        if (miUrl){
            return (url.includes("embed"));
        }
        else {
            return IDRegex.test(YURL); 
        }
        //return (miUrl && url.includes("embed")) ? true : false;
    } catch (error) {
        //console.log(IDRegex.test(YURL),error);

        return IDRegex.test(YURL);
    }
};