import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
    Typography, IconButton, InputLabel,
    Accordion, AccordionDetails, AccordionSummary,
    Button
} from "@mui/material";
import IconSvg from '../../../components/Icon';
import { ReactComponent as Drag } from '../../../assets/Icons/drag.svg';
import { ReactComponent as Files } from '../../../assets/Icons/files.svg';
import { ReactComponent as Trash } from '../../../assets/Icons/trash.svg';
import { ReactComponent as Edit } from '../../../assets/Icons/edit.svg';
import { ReactComponent as Send } from '../../../assets/Icons/send.svg';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import TextFieldComponent from "../../../components/TextField";
import SelectFieldComponent from "../../../components/SelectField";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Checkbox from "../../../components/Checkbox";
import './UserForm.css';
import { useTranslation } from 'react-i18next';

const UserForm = ({ index, pageIndex, value = null, onFormItemChange, deleteContent,
    deleteFields, error = [] }) => {
    const { t } = useTranslation(['mobileContent', 'common']);

    const lenguages = useSelector(store => store.lenguage.lenguages);
    const defaultLanguageId = useSelector(store => store.system.system.defaultLanguageId);
    const secondaryLanguageId = useSelector(store => store.appSetting.appSetting.secundaryLanguageId);
    const [language, setLanguage] = useState(defaultLanguageId);
    const [languageList, setLanguageList] = useState([]);
    const [expanded, setExpanded] = useState(false);
    // const placeholder = [
    //     {
    //         1: 'Enter your text here',
    //         2: 'Ingrese su texto aquí',
    //     },
    //     'https://www.fos.com',
    //     '88888888',
    //     {
    //         1: 'Enter your text here',
    //         2: 'Ingrese su texto aquí',
    //     },
    // ];

    const [form, setForm] = useState({
        id: null,
        type: '0',
        // placeholder: placeholder[0][language],
        placeholder: '',
        label: '',
        required: false,
        formId: null
    });
    const [edit, setEdit] = useState(null);
    const [add, setAdd] = useState(false);
    const [selectItemForm, setSelectItemForm] = useState(null);

    const onCheckbox = e => {
        const { checked } = e.target;
        setForm({ ...form, required: checked });
    };

    const onChangeForm = e => {
        const { name, value } = e.target;
        // const ph = (parseInt(form.type) === 1 || parseInt(form.type) === 2)
        //     ? placeholder[parseInt(form.type)]
        //     : placeholder[parseInt(form.type)][language];

        setForm({
            ...form, [name]: value,
            // placeholder: ph
        });
    };

    const selectItem = index => {
        setSelectItemForm(selectItemForm === index ? null : index);
    };

    const onAddItem = () => {
        if (form.label) {
            const list = value[language] ? value[language] : [];
            list.push(form);
            onFormItemChange(pageIndex, index - 1, list, language);

            setForm({
                id: null, type: '0',
                // placeholder: placeholder[0][language],
                label: '', required: false, formId: null
            });
        }
    };

    const onDeleteItem = (indexItem) => {
        const list = value[language];
        list[indexItem].id && deleteFields(list[indexItem].id);
        list.splice(indexItem, 1);
        onFormItemChange(pageIndex, index - 1, list, language);
        onAddClick();
    };

    const onEditItem = (indexItem) => {
        const list = value[language];
        list[indexItem] = form;
        onFormItemChange(pageIndex, index - 1, list, language);
        onAddClick();
    };

    const onEditClick = (index, item) => {
        setEdit(index);
        setAdd(false);
        setForm({
            id: item.id, type: String(item.type),
            placeholder: '',
            // placeholder: item.placeholder,
            label: item.label, required: item.required, formId: item.formId
        });
    };

    const onAddClick = () => {
        setAdd(true);
        setEdit(null);
        setForm({
            id: null, type: '0',
            placeholder: '',
            // placeholder: placeholder[0][language], 
            label: '', required: false, formId: null
        });
    };

    useEffect(() => {
        const list = [];
        lenguages
            .filter(lng => [secondaryLanguageId, defaultLanguageId].includes(lng.key))
            .forEach(item => {
                list.push({
                    key: item.key,
                    value: <span style={{ color: '#5AB6DF' }}>{item.code.toUpperCase()}</span>
                });
            });
        setLanguageList(list);
    }, [lenguages, secondaryLanguageId, defaultLanguageId]);

    return (
        <Accordion className="accordion-sumary-row-title-mobile" expanded={expanded}
            style={{ width: '100%' }}>
            <AccordionSummary
                aria-controls="panel1bh-content"
                id="panel1bh-header"
            >
                <div style={{ width: '98%', display: 'flex', justifyContent: 'space-between' }}>
                    <div style={{
                        display: 'flex', alignItems: 'center',
                        margin: '29px 8px', flexShrink: 0, width: '50%'
                    }} onClick={() => setExpanded(!expanded)}>
                        <IconSvg Icon={Send} className="icon-svg-mobile" />
                        <Typography className="accordion-title-text-mobile" >
                            {index}. {t("formLbl")}
                        </Typography>
                    </div>
                    <div style={{
                        display: 'flex', alignItems: 'center',
                        width: '48%', justifyContent: 'flex-end'
                    }}>
                        {secondaryLanguageId &&
                            <SelectFieldComponent
                                name="language"
                                className="language-code"
                                valueInput={language}
                                options={[
                                    ...languageList
                                ]}
                                onChange={e => setLanguage(e.target.value)}
                            />
                        }
                        <div style={{ display: 'flex', alignItems: 'center', marginTop: 5 }}>
                            <Edit style={{ margin: '0 20px' }} />
                            <Files style={{ marginRight: 20 }} />
                            <Drag />
                        </div>
                    </div>
                </div>
                {(error.length > 0 && error.find(err => language in err))
                    && error.find(err => language in err)[language] &&
                    <p className='app-error-text'>{error.find(err => language in err)[language]}</p>
                }
            </AccordionSummary>
            <AccordionDetails>
                <div style={{ padding: (value[language] || add) && '40px 20px' }}>
                    <div style={{ marginBottom: 20 }}>
                        {value[language] && value[language].map((item, index) => (
                            <div key={index}>
                                <div style={{
                                    background: selectItemForm === index ? 'rgba(10, 162, 192, 0.05)' : '',
                                    borderRadius: 10, padding: 20, marginBottom: 20, cursor: 'pointer'
                                }} onClick={() => selectItem(index)}>
                                    <div style={{
                                        display: 'flex', flexDirection: 'row',
                                        justifyContent: 'space-between', alignItems: 'center'
                                    }}>
                                        <InputLabel className="label-app">
                                            {item.label} <span style={{ color: '#DC3545' }}>{item.required && '*'}</span>
                                        </InputLabel>
                                        {selectItemForm === index &&
                                            <IconButton onClick={() => onEditClick(index, item)}>
                                                <Edit className="mobile-icon-svg-delete" />
                                            </IconButton>
                                        }
                                    </div>
                                    <TextFieldComponent placeholder={item.placeholder}
                                        callback={() => null}
                                        row={item.type === '3' ? 5 : null}
                                        disabled={true}
                                    />
                                </div>
                                {edit === index &&
                                    <div style={{
                                        background: 'rgba(10, 162, 192, 0.05)',
                                        borderRadius: 10, padding: 20
                                    }}>
                                        <InputLabel className="label-app">{t("typeLbl")}</InputLabel>
                                        <SelectFieldComponent
                                            name="type"
                                            valueInput={form.type}
                                            options={[
                                                { key: '0', value: t("textLbl") },
                                                { key: '1', value: t("emailLbl") },
                                                { key: '2', value: t("phoneLbl") },
                                                { key: '3', value: t("commentLbl") },
                                            ]}
                                            onChange={onChangeForm}
                                        />
                                        <InputLabel className="label-app" style={{ marginTop: 10 }}>{t("labelLbl")}</InputLabel>
                                        <TextFieldComponent
                                            name="label"
                                            valueInput={form.label}
                                            callback={onChangeForm}
                                        />
                                        <div style={{
                                            display: 'flex', justifyContent: 'space-between',
                                            alignItems: 'center', marginBottom: 20
                                        }}>
                                            <div style={{ display: 'flex' }}>
                                                <Checkbox
                                                    name='offices'
                                                    onChange={onCheckbox}
                                                    value={form.required}
                                                    checkboxCheckedValue={true}
                                                />
                                                <InputLabel className="label-required-text">
                                                    {t("requiredLbl")}
                                                </InputLabel>
                                            </div>
                                            <div style={{ display: 'flex', float: 'right' }}>
                                                <IconButton onClick={() => onDeleteItem(index)}>
                                                    <Trash className="mobile-form-icon-svg-delete mobile-form-icon-delete" />
                                                </IconButton>
                                            </div>
                                        </div>
                                        <Button variant="contained" className="button-app-save" style={{ marginRight: 18 }}
                                            onClick={() => onEditItem(index)} disabled={!form.label}>
                                            {t("editLbl")}
                                        </Button>
                                    </div>
                                }
                            </div>
                        ))}
                    </div>
                    <div>
                        {add &&
                            <div style={{
                                background: 'rgba(10, 162, 192, 0.05)',
                                borderRadius: 10, padding: 20
                            }}>
                                <InputLabel className="label-app">{t("typeLbl")}</InputLabel>
                                <SelectFieldComponent
                                    name="type"
                                    valueInput={form.type}
                                    options={[
                                        { key: '0', value: t("textLbl") },
                                        { key: '1', value: t("emailLbl") },
                                        { key: '2', value: t("phoneLbl") },
                                        { key: '3', value: t("commentLbl") },
                                    ]}
                                    onChange={onChangeForm}
                                />
                                <InputLabel className="label-app" style={{ marginTop: 10 }}>{t("labelLbl")}</InputLabel>
                                <TextFieldComponent
                                    name="label"
                                    valueInput={form.label}
                                    callback={onChangeForm}
                                />
                                <div style={{
                                    display: 'flex', flexDirection: 'column', marginBottom: 20
                                }}>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <Checkbox
                                            name='offices'
                                            onChange={onCheckbox}
                                            value={form.required}
                                            checkboxCheckedValue={true}
                                        />
                                        <InputLabel className="label-required-text">
                                            {t("requiredLbl")}
                                        </InputLabel>
                                    </div>
                                    {/* <br />
                                    <div style={{ display: 'flex' }}>
                                        <Checkbox
                                            name='duplicate'
                                            onChange={() => setDuplicateContent(!duplicateContent)}
                                            value={duplicateContent}
                                            checkboxCheckedValue={true}
                                        />
                                        <InputLabel className="label-required-text" style={{ whiteSpace: 'pre-wrap' }}>
                                            {t("subtitle23")}
                                        </InputLabel>
                                    </div> */}
                                </div>
                                <Button variant="contained" className="button-app-save" style={{ marginRight: 18 }}
                                    onClick={() => onAddItem()} disabled={!form.label}>
                                    {t("addLbl")}
                                </Button>
                            </div>
                        }
                    </div>
                </div>
                <div>
                    {secondaryLanguageId &&
                        <Typography className='mobile-content-alert'>
                            <InfoOutlinedIcon fontSize='small' style={{ marginRight: 10 }} />
                            {t("subtitle20")}
                        </Typography>
                    }
                </div>
                <div style={{ display: 'flex', marginBottom: 18, justifyContent: 'space-between' }}>
                    <div style={{ display: 'flex', alignItems: 'center', marginTop: 25 }}>
                        <Button variant="text" startIcon={<AddRoundedIcon />}
                            className='button-app-create' onClick={() => onAddClick()}>
                            {t("addFieldLbl")}
                        </Button>
                    </div>
                    <div style={{ marginTop: 25 }}>
                        <IconButton onClick={() => deleteContent(pageIndex, index - 1)}>
                            <Trash className="mobile-icon-svg-delete mobile-icon-delete" />
                        </IconButton>
                    </div>
                </div>
            </AccordionDetails>
        </Accordion >
    );
}

export default UserForm;