import React, { useState, useEffect } from "react";
import {
    Container, Grid, Typography, InputLabel, Button,
    Modal, Box, Divider, Tab
} from "@mui/material";
import { TabContext, TabList, TabPanel,Alert } from '@mui/lab';
import Title from "../../../components/Title";
import SelectFieldComponent from "../../../components/SelectField";
import TextFieldComponent from "../../../components/TextField";
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { ReactComponent as Search } from '../../../assets/Icons/search.svg';
import { ReactComponent as Filter } from '../../../assets/Icons/filter.svg';
import { ReactComponent as CloseCross } from '../../../assets/Icons/closeCross.svg';
import TableUserAssigned from "../TableUserAssigned";
import TableUserPending from "../TableUserPending";
import TableClientAssigment from "../TableClientAssigments";
import { useSelector, useDispatch } from "react-redux";
import { getBusinessesFilter, getOfficesFilter } from '../../../redux/BusinessDuck'
import {
    getAssignedClientStaff, getPendingClientStaff,
    changeUserAssignedPage, changeUserPendingPage,
    saveClientStaff, setCreatedClientStaff,
    changeClientPage,
    getClientsOfClientStaff
} from '../../../redux/UserAssignDuck'
import PaginationCustom from "../../../components/Pagination";
import './Read.css';
import { getClients } from "../../../redux/ClientDuck";
import { getCountries } from "../../../redux/CountryDuck";
import { custStaffValidation } from "./CustomerStaffValidation";
import HelpClientStaff from "../../Help/HelpClientStaff";
//import { DisabledByDefault } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
const ReadCustomerStaff = () => {

    const { t } = useTranslation(['customerStaff', 'common', 'error']);
    const clients = useSelector(store => store.client.clients);
    const businesses = useSelector(store => store.business.businesses);
    const officesRedux = useSelector(store => store.business.offices);
    const activePageAssigned = useSelector(store => store.assign.activePageAssigned);
    const assignedClientStaff = useSelector(store => store.assign.assignedClientStaff);
    const pendingClientStaff = useSelector(store => store.assign.pendingClientStaff);
    const activePagePending = useSelector(store => store.assign.activePagePending);
    const pagesAssigned = useSelector(store => store.assign.pagesAssigned);
    const pagesPending = useSelector(store => store.assign.pagesPending);
    const makeRequest = useSelector(store => store.assign.makeRequest);
    const reload = useSelector(store => store.assign.reload);


    const isCreated = useSelector(store => store.assign.isCreated);
    const countries = useSelector(store => store.country.countries);

    const activePageClient = useSelector(store => store.assign.activePageClient);
    const pagesClient = useSelector(store => store.assign.pagesClient);
    const [open, setOpen] = useState(false);
    const [value, setValue] = useState('1');

    const [companyId, setCompanyId] = useState('all');
    const [companies, setCompanies] = useState([]);
    const [officeId, setOfficeId] = useState('all');
    const [offices, setOffices] = useState([]);
    const [clientList, setClientList] = useState([]);
    const [searchAssigned, setSearchAssigned] = useState('');
    const [searchPending, setSearchPending] = useState('');
    const [filterClientAssigned, setFilterClientAssigned] = useState('none');
    const [filterClientPending, setFilterClientPending] = useState('none');
    const [assigned, setAssigned] = useState(false);
    const [activeClients, setActiveClients] = useState([]);
    const [searchNewCustStaff, setSearchNewCustStaff] = useState('');
    const [clientStaff, setClientStaff] = useState({
        firstName: '', lastName: '', email: '',
        countryCodeId: '', phoneNumber: ''
    });
    const [error, setError] = useState({});

    const [resetPageClient, setResetPageClient] = useState(false);
    const [resetPageClientAssigned, setResetPageClientAssigned] = useState(false);
    const [resetPageClientPending, setResetPageClientPending] = useState(false);
    const [openModal, setOpenModal] = useState(false);

    const dispatch = useDispatch();

    // useEffect(() => {
    //     dispatch(changeUserAssignedPage(1));
    //     dispatch(changeUserPendingPage(1));
    // }, [dispatch]);

    useEffect(() => {
        setSearchNewCustStaff('');
        dispatch(changeClientPage(1));
    }, [open, dispatch]);

    const handleOpen = () => setOpen(true);

    const handleClose = () => {
        setOpen(false);
        setClientStaff({
            firstName: '', lastName: '', email: '',
            countryCodeId: '', phoneNumber: ''
        });
        setActiveClients([]);
        setError({});
        setValue("1");
        setSearchNewCustStaff('');
    };

    const onChange = e => {
        const { name, value } = e.target;
        setClientStaff({ ...clientStaff, [name]: value })
    };

    const nextStep = () => {
        const custStaffError = custStaffValidation(clientStaff, activeClients, false, t);
        setError(custStaffError);
        if (Object.entries(custStaffError).length === 0) {
            setValue('2');
            setOpenModal(true);
        }
    };

    const onSubmit = () => {
        const custStaffError = custStaffValidation(clientStaff, activeClients, true, t);
        setError(custStaffError);
        if (Object.entries(custStaffError).length === 0) {
            dispatch(saveClientStaff(clientStaff, activeClients));
            setClientStaff({
                firstName: '', lastName: '', email: '',
                countryCodeId: '', phoneNumber: ''
            });
            setValue('1');
            setActiveClients([]);
        }
    };

    useEffect(() => {
        const list = [];
        clients.forEach(item => {
            list.push({ key: item.id, value: item.name });
        });
        setClientList(list);
    }, [clients]);

    useEffect(() => {
        const businessList = [];
        businesses.forEach(item => {
            businessList.push({ key: item.id, value: item.name });
        });
        setCompanies(businessList);
    }, [businesses]);

    useEffect(() => {
        const officesTemp = [];
        officesRedux.forEach(item => {
            officesTemp.push({ key: item.id, value: item.name });
        });
        setOffices(officesTemp);
        setOfficeId('all');
    }, [companyId, officesRedux]);

    useEffect(() => {
        dispatch(getCountries());
        dispatch(getBusinessesFilter());
    }, [dispatch]);

    useEffect(() => {
        if (businesses.length > 0) {
            const page = resetPageClientAssigned ? 0 : activePageAssigned;
            const filterBy = [];
            searchAssigned !== '' && filterBy.push({ search: searchAssigned, filterBy: 'search-bar' });
            filterClientAssigned !== 'none' && filterBy.push({ search: String(filterClientAssigned), filterBy: 'client' });
            companyId !== 'all' && filterBy.push({ search: String(companyId), filterBy: 'company' });
            officeId !== 'all' && filterBy.push({ search: String(officeId), filterBy: 'office' });

            const timer = setTimeout(() =>
                dispatch(getAssignedClientStaff(filterBy, page, 4, true)), 1000);
            assigned && setAssigned(false);
            setResetPageClientAssigned(false);
            return () => clearTimeout(timer);
        }
        // eslint-disable-next-line
    }, [dispatch, companyId, officeId, businesses,
        filterClientAssigned, searchAssigned, assigned, isCreated, makeRequest, reload]);

    useEffect(() => {
        if (businesses.length > 0) {
            const page = activePageAssigned;
            const filterBy = [];
            searchAssigned !== '' && filterBy.push({ search: searchAssigned, filterBy: 'search-bar' });
            filterClientAssigned !== 'none' && filterBy.push({ search: String(filterClientAssigned), filterBy: 'client' });
            companyId !== 'all' && filterBy.push({ search: String(companyId), filterBy: 'company' });
            officeId !== 'all' && filterBy.push({ search: String(officeId), filterBy: 'office' });

            dispatch(getAssignedClientStaff(filterBy, page, 4));
            assigned && setAssigned(false);
            setResetPageClientAssigned(false);
        }
        // eslint-disable-next-line
    }, [dispatch, activePageAssigned]);

    useEffect(() => {
        if (businesses.length > 0) {
            const page = resetPageClientPending ? 0 : activePagePending;
            const filterBy = [];
            searchPending !== '' && filterBy.push({ search: searchPending, filterBy: 'search-bar' });
            filterClientPending !== 'none' && filterBy.push({ search: String(clientList.find(item => item.key === filterClientPending).value), filterBy: 'client' });

            companyId !== 'all' && filterBy.push({ search: String(companyId), filterBy: 'company' });
            officeId !== 'all' && filterBy.push({ search: String(officeId), filterBy: 'office' });

            const timer = setTimeout(() =>
                dispatch(getPendingClientStaff(filterBy, page, 4)), 1000);
            assigned && setAssigned(false);
            setResetPageClientPending(false);
            return () => clearTimeout(timer);
        }
        // eslint-disable-next-line
    }, [dispatch, companyId, officeId, isCreated, businesses,
        filterClientPending, searchPending, assigned, reload]);

    useEffect(() => {
        if (businesses.length > 0) {
            const page = activePagePending;
            const filterBy = [];
            searchPending !== '' && filterBy.push({ search: searchPending, filterBy: 'search-bar' });
            filterClientPending !== 'none' && filterBy.push({ search: String(clientList.find(item => item.key === filterClientPending).value), filterBy: 'client' });

            companyId !== 'all' && filterBy.push({ search: String(companyId), filterBy: 'company' });
            officeId !== 'all' && filterBy.push({ search: String(officeId), filterBy: 'office' });

            dispatch(getPendingClientStaff(filterBy, page, 4));
            assigned && setAssigned(false);
            setResetPageClientPending(false);
        }
        // eslint-disable-next-line
    }, [dispatch, activePagePending]);

    useEffect(() => {
        dispatch(getOfficesFilter(companyId !== 'all' ? [{
            "search": String(companyId),
            "filterBy": "company"
        }] : []));
    }, [dispatch, companyId]);

    useEffect(() => {
        dispatch(getClients(
            [{ search: "false", filterBy: "disabled" }],
            0, 10000, false, "client-staff"
        ));
    }, [dispatch]);

    useEffect(() => {
        if (isCreated) {
            dispatch(setCreatedClientStaff());
            setOpen(false);
        }
    }, [isCreated, dispatch]);

    const onChangeSearchNewCustStaff = (e) => {
        setSearchNewCustStaff(e.target.value);
        setResetPageClient(true);
    }

    useEffect(() => {
        const page = resetPageClient ? 0 : activePageClient;
        const filterBy = [{ search: "false", filterBy: "disabled" }];
        searchNewCustStaff !== '' && filterBy.push({ search: searchNewCustStaff, filterBy: 'name' });
        const timer = setTimeout(() => dispatch(getClientsOfClientStaff(filterBy, page, 10)), 1000);
        setResetPageClient(false);
        return () => clearTimeout(timer);
        // eslint-disable-next-line
    }, [dispatch, searchNewCustStaff, reload]);

    useEffect(() => {
        const page = resetPageClient ? 0 : activePageClient;
        const filterBy = [{ search: "false", filterBy: "disabled" }];
        searchNewCustStaff !== '' && filterBy.push({ search: searchNewCustStaff, filterBy: 'name' });
        dispatch(getClientsOfClientStaff(filterBy, page, 10));
        setResetPageClient(false);
        // eslint-disable-next-line
    }, [dispatch, activePageClient]);

    const onChangeSearchAssigned = (e) => {
        setSearchAssigned(e.target.value);
        setResetPageClientAssigned(true);
    };

    const onChangeFilterClientAssigned = (e) => {
        setFilterClientAssigned(e.target.value);
        setResetPageClientAssigned(true);
    };

    const onChangeSearchPending = (e) => {
        setSearchPending(e.target.value);
        setResetPageClientPending(true);
    };

    const onChangeFilterClientPending = (e) => {
        setFilterClientPending(e.target.value);
        setResetPageClientPending(true);
    };

    const onChangeCompany = e => {
        setCompanyId(e.target.value);
        setResetPageClientPending(true);
        setResetPageClientAssigned(true);
    };

    const onChangeOffice = e => {
        setOfficeId(e.target.value);
        setResetPageClientPending(true);
        setResetPageClientAssigned(true);
    };

    const onChangeStep = (newValue) => {
        const custStaffError = custStaffValidation(clientStaff, activeClients, false, t);
        setError(custStaffError);
        if (Object.entries(custStaffError).length === 0) {
            setValue(newValue);
            newValue === "2" && setOpenModal(true);
        }
    };

    return (
        <Container>
            <Title title={t("title")} style={{ marginLeft: 5 }} />
            <Grid item xs={12}>
                <div className="container-app-div">
                    <Typography className="app-form-title">{t("filterLbl")}</Typography>
                    <div style={{
                        display: 'flex', flexDirection: 'row',
                        width: '100%', justifyContent: 'space-between'
                    }}>
                        <div style={{ width: '48%' }}>
                            <InputLabel className="label-app">{t("companyLbl")}</InputLabel>
                            <SelectFieldComponent name="businessName"
                                valueInput={companyId}
                                onChange={onChangeCompany}
                                options={[
                                    { key: 'all', value: t("all") },
                                    ...companies
                                ]}
                            />
                        </div>
                        <div style={{ width: '48%' }}>
                            <InputLabel className="label-app">{t("officeLbl")}</InputLabel>
                            <SelectFieldComponent name="officesName"
                                valueInput={officeId}
                                onChange={onChangeOffice}
                                options={[
                                    { key: 'all', value: t("all") },
                                    ...offices
                                ]}
                            />
                        </div>
                    </div>
                </div>
                <div className="container-app-div">
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Typography className="app-form-title">{t("subtitle1")}</Typography>
                        <Button className='button-app-create' variant="text"
                            startIcon={<AddRoundedIcon />} onClick={handleOpen}>
                            {t("createCustomerStaffBtn")}
                        </Button>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div style={{ width: '35%' }}>
                            <TextFieldComponent Icon={<Search />} position='start'
                                valueInput={searchAssigned}
                                callback={onChangeSearchAssigned}
                                placeholder={t("common:commonSearchPlh")}
                            />
                        </div>
                        <div>
                            <SelectFieldComponent name="filter"
                                placeholder={t("filterByClient")}
                                valueInput={filterClientAssigned}
                                onChange={onChangeFilterClientAssigned}
                                options={clientList}
                                Icon={Filter}
                            />
                        </div>
                    </div>
                    <div>
                        <TableUserAssigned userList={assignedClientStaff} />
                        <br />
                        <PaginationCustom pages={pagesAssigned} activePage={activePageAssigned}
                            onChange={page => dispatch(changeUserAssignedPage(page))} />
                    </div>
                </div>
                <div className="container-app-div">
                    <Typography className="app-form-title">{t("subtitle2")}</Typography>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div style={{ width: '35%' }}>
                            <TextFieldComponent Icon={<Search />} position='start'
                                valueInput={searchPending}
                                callback={onChangeSearchPending}
                                placeholder={t("common:commonSearchPlh")}
                            />
                        </div>
                        <div>
                            <SelectFieldComponent name="filter"
                                placeholder={t("filterByClient")}
                                valueInput={filterClientPending}
                                onChange={onChangeFilterClientPending}
                                options={clientList}
                                Icon={Filter}
                            />
                        </div>
                    </div>
                    <div>
                        <TableUserPending userList={pendingClientStaff}
                            assigned={res => setAssigned(res)} />
                        <br />
                        <PaginationCustom pages={pagesPending} activePage={activePagePending}
                            onChange={page => dispatch(changeUserPendingPage(page))} />
                    </div>
                </div>
            </Grid>
            <HelpClientStaff open={openModal} closeModal={() => setOpenModal(false)} />
            <div>
                <Modal
                    open={open}
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}
                >
                    <div className="container-div-modal-create-update-customer">
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Typography className="modal-title-text">{t("subtitle7")}</Typography>
                            <Button className='button-close-cross' variant="text"
                                onClick={handleClose} startIcon={<CloseCross />}></Button>
                        </div>
                        <div>
                            <TabContext value={value}>
                                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                    <TabList onChange={(value, newValue) => onChangeStep(newValue)}
                                        aria-label="lab API tabs example">
                                        <Tab label={t("subtitle4")} value="1" />
                                        <Tab label={t("subtitle5")} value="2" />
                                    </TabList>
                                </Box>
                                <TabPanel value="1" className='tab-select-page-assigments-data-user'>
                                    <Typography className="app-form-title">{t("subtitle4")}</Typography>
                                    <div style={{
                                        display: 'flex', flexDirection: 'row', marginBottom: 10,
                                        width: '99%', justifyContent: 'space-between'
                                    }}>
                                        <div style={{ width: '48%' }}>
                                            <InputLabel className="label-app">{t("headerTbl1")}</InputLabel>
                                            <TextFieldComponent name="firstName" placeholder={t("headerTbl1")}
                                                valueInput={clientStaff.firstName}
                                                errorInput={error.firstName}
                                                callback={onChange.bind(this)}
                                            />
                                        </div>
                                        <div style={{ width: '48%' }}>
                                            <InputLabel className="label-app">{t("headerTblLast")}</InputLabel>
                                            <TextFieldComponent name="lastName" placeholder={t("headerTblLast")}
                                                valueInput={clientStaff.lastName}
                                                errorInput={error.lastName}
                                                callback={onChange.bind(this)}
                                            />
                                        </div>
                                    </div>
                                    <div style={{
                                        display: 'flex', flexDirection: 'row', marginBottom: 35,
                                        width: '99%', justifyContent: 'space-between'
                                    }}>
                                        <div style={{ width: '48%' }}>
                                            <InputLabel className="label-app">{t("headerTbl2")}</InputLabel>
                                            <TextFieldComponent name="email" placeholder={t("headerTbl2")}
                                                valueInput={clientStaff.email}
                                                errorInput={error.email}
                                                callback={onChange.bind(this)}
                                            />
                                        </div>
                                        <div style={{
                                            width: '48%', display: 'flex',
                                            flexDirection: 'row', justifyContent: 'space-between'
                                        }}>
                                            <div style={{ width: '28%' }}>
                                                <InputLabel style={{ overflow: "visible" }} className="label-app">{t("headerTblPhone") + " (" + t("headerTblOptional") + ")"}</InputLabel>
                                                <SelectFieldComponent
                                                    name="countryCodeId"
                                                    onChange={onChange.bind(this)}
                                                    errorInput={error.countryCodeId}
                                                    valueInput={clientStaff.countryCodeId}
                                                    options={countries.length > 0 ? [{ key: '', value: t("headerTblOptional") }, ...countries] : []}
                                                />
                                            </div>
                                            <div style={{ width: '68%', display: 'flex', alignItems: 'flex-end', flexDirection: 'column' }}>
                                                <InputLabel className="label-app" style={{ opacity: 0 }}>1</InputLabel>
                                                <TextFieldComponent name="phoneNumber" placeholder='88888888'
                                                    maxLength={30}
                                                    valueInput={clientStaff.phoneNumber}
                                                    errorInput={error.phoneNumber}
                                                    callback={onChange.bind(this)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <Divider className="divider-style" />
                                    <Button variant="contained" className="button-app-save"
                                        style={{ marginRight: 18 }}
                                        onClick={() => nextStep()}>
                                        {t("nextLbl")}
                                    </Button>
                                </TabPanel>
                                <TabPanel value="2" className='tab-select-page-assigments-clients-assigment'>
                                <Alert severity="error" className='change-pass-alert'>
                                                        {t("common:clientFindWarning")}
                                                    </Alert>
                                    <Typography className="clients-assigment-title">{t("subtitle5")}</Typography>
                                    
                                    <div style={{ width: '35%' }}>
                                        <TextFieldComponent Icon={<Search />} position='start'
                                            callback={onChangeSearchNewCustStaff}
                                            valueInput={searchNewCustStaff}
                                            placeholder={t("common:commonSearchPlh")} />
                                    </div>
                                    <TableClientAssigment isCreating={true}
                                        activatedClients={activeClients} setActivatedClients={setActiveClients}
                                    />
                                    <br />
                                    <PaginationCustom pages={pagesClient} activePage={activePageClient}
                                        onChange={page => dispatch(changeClientPage(page))} />
                                    {!error.activeClients && <br />}
                                    {error.activeClients &&
                                        <>
                                            <p className='app-error-text'>{error.activeClients}</p>
                                            <br />
                                        </>
                                    }
                                    <Button variant="contained" className="button-app-save"
                                        style={{ marginRight: 18 }}
                                        onClick={() => onSubmit()}>
                                        {t("submitBtn")}
                                    </Button>
                                </TabPanel>
                            </TabContext>
                        </div>
                    </div>
                </Modal>
            </div>
        </Container>
    );
}

export default (ReadCustomerStaff);