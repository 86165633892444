import { getLenguageURL } from '../constants/apiUrls';
import { getAxios } from '../services/Axios';
import { logoutAction, renewTokenAction } from './AuthDuck'

// constantes
const initialState = {
    lenguages: [],
    loading: false,
    error: null,
};

// types
const LENGUAGE_SUCCESS = 'LENGUAGE_SUCCESS';
const LENGUAGE_ERROR = 'LENGUAGE_ERROR';
const LOADING_LENGUAGE = 'LOADING_LENGUAGE';
const CLEAR_RESPONSE = 'CLEAR_RESPONSE';
const LOG_OUT = 'LOG_OUT';

// reducer
export default function reducer(state = initialState, action) {
    switch (action.type) {
        case LOADING_LENGUAGE:
            return { ...state, loading: true, error: null };
        case LENGUAGE_SUCCESS:
            return { ...state, lenguages: action.payload, error: null, loading: false };
        case LENGUAGE_ERROR:
            return {
                ...state, error: action.payload, loading: false,
                response: action.payload
            };
        case CLEAR_RESPONSE:
            return { ...state, response: null };
        case LOG_OUT:
            return { ...initialState };
        default:
            return state;
    }
};

export const getLenguages = () => async (dispatch, getState) => {
    try {
        const res = await getAxios(getLenguageURL);
        if (res.status === 200) {
            const token = res.headers.refreshtoken;
            
            const expiration = res.headers.expirationToken;
            const currLang= localStorage.getItem('lng-fos');
            (token && expiration) && dispatch(renewTokenAction(token, expiration));
            const lenguages = res.data.response;
         
            const list = [];
            lenguages.forEach(item => {
                list.push({
                    key: item.id,
                    value: currLang === '1'? item.nameEn : item.nameEs,
                    code: item.code
                })
            });
            return dispatch({
                type: LENGUAGE_SUCCESS,
                payload: list
            });
        }
        throw new Error();
    } catch (error) {
        if (error.response && error.response.status === 401)
            dispatch(logoutAction());
        dispatch({
            type: LENGUAGE_ERROR,
            payload: error.response ? error.response.data.message : "common:errorLoadingLanguages"
        });
    }
};