import React from 'react';

const Icon = ({ Icon, className }) => {

    return (
        <i aria-hidden="true" className={className}
            style={{ display: 'flex', marginRight: 10 }}>
            <Icon aria-hidden="true" className={className} />
        </i>
    );
};

export default Icon;