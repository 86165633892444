import React from "react";
import { Container, Grid, Typography, Card, CardMedia } from "@mui/material";
import helpImage15 from '../../assets/Images/help15.png';
import helpImage16 from '../../assets/Images/help16.png';
import { useTranslation } from "react-i18next";
import './Help.css';

const HelpCustomer = () => {

    const { t } = useTranslation(['help']);

    return (
        <Container>
                                <Typography className="admin-business-text" style={{ marginBottom: '20px' }}>
                            {t('help:customer:title')}
                        </Typography>

            <Grid item xs={12}>
                <div className="container-app-div help-screen header" style={{ marginTop: 10 }}>
                    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                        <p className="paragraph-text strong" style={{ marginBottom: '20px' }} >
                        {t('help:thisPageLbl')}
                        </p>
                        <label className="help-label-text">
                        <span className="enum">1</span>{t('help:customer:lbl1')}
                        </label>
                        <label className="help-label-text">
                        <span className="enum">2</span>    {t('help:customer:lbl2')}
                        </label>
                        <br />

                        </div>
                        
                        <div style={{paddingTop: '20px'}}>
                        <label className="help-label-text">
                            {t('help:customer:lbl3')}
                        </label>
                        <label className="help-label-text">
                            {t('help:customer:lbl4')}
                        </label>
                        <br />
                            
                        </div>
                        </div>
                        
                        <div className="container-app-div" style={{ marginTop: 10 }}>
                
                        <div style={{ display: 'flex', marginBottom: 30 }}>
                            <div style={{ width: '60%', display: 'flex', flexDirection: 'column' }}>
                                <p className="paragraph-text strong">
                                    {t('help:step1')} <span className="enum">1</span> : &nbsp; {t('help:customer:lbl5')}
                                </p>
                                <label className="help-label-text">
                                    {t('help:customer:lbl6')}
                                </label>
                            </div>
                            <div style={{ width: '40%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <Card sx={{ maxWidth: 225, height: 60, boxShadow: 'none' }}>
                                    <CardMedia
                                        component="img"
                                        image={helpImage15}
                                        alt="img"
                                        style={{ objectFit: 'contain' }}
                                    />
                                </Card>
                            </div>
                        </div>
                        </div>
                        <div className="container-app-div" style={{ marginTop: 10 }}>
                    <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                                <p className="paragraph-text strong">
                                    {t('help:step2')} <span className="enum">2</span> : &nbsp;  {t('help:customer:lbl7')}
                                </p>
                                <label className="help-label-text">
                                    {t('help:customer:lbl8')}
                                </label>
                                <label className="help-label-text">
                                    {t('help:customer:lbl9')}
                                </label>
                                <br />
                                <label className="help-label-text">
                                    {t('help:customer:lbl10')}
                                </label>
                                <label className="help-label-text p">
                                    1.{t('help:customer:lbl11')}
                                </label>
                                <label className="help-label-text p">
                                    2.{t('help:customer:lbl12')}
                                </label>
                                <label className="help-label-text p">
                                    3.{t('help:customer:lbl13')}
                                </label>
                            </div>
                            <div style={{
                                width: '100%', display: 'flex', margin: '40px 0 20px 0',
                                justifyContent: 'center', alignItems: 'center'
                            }}>
                                <Card sx={{ maxWidth: 400, height: 270, boxShadow: 'none' }}>
                                    <CardMedia
                                        component="img"
                                        image={helpImage16}
                                        alt="img"
                                        style={{ objectFit: 'contain' }}
                                    />
                                </Card>
                            </div>
                       
                        <div style={{ marginBottom: 30 }}>
                            <div style={{ width: '90%', display: 'flex', flexDirection: 'column' }}>
                                <label className="help-label-text">
                                    {t('help:customer:lbl14')}
                                </label>
                                <br />
                                <label className="help-label-text">
                                    {t('help:customer:lbl15')}
                                </label>
                                <label className="help-label-text">
                                    {t('help:customer:lbl16')}
                                </label>
                                <label className="help-label-text">
                                    {t('help:customer:lbl17')}
                                </label>
                            </div>
                        </div>
                        </div>
            </Grid>
        </Container>
    );
}
export default HelpCustomer;