import React from "react";
import { Container, Grid, Typography, Card, CardMedia } from "@mui/material";
import helpImage1 from '../../assets/Images/help1.png';
import helpImage2 from '../../assets/Images/help2.png';
import { useTranslation } from "react-i18next";
import './Help.css';

const HelpSetting = () => {

    const { t } = useTranslation(['help']);

    return (
        <Container>
            <Typography className="admin-business-text" style={{ marginBottom: '20px' }}>
                {t('help:setting:category') + "|" + t('help:setting:title')}
            </Typography>
            <Grid item xs={12}>
                <div className="container-app-div help-screen header" style={{ marginTop: 10 }}>
                    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                        <p className="paragraph-text strong" style={{ marginBottom: '20px' }} >
                            {t('help:thisPageLbl')}
                        </p>
                        <label className="help-label-text">
                            <span className="enum">1</span>{t('help:setting:lbl1')}
                        </label>
                        <label className="help-label-text">
                            <span className="enum">2</span> {t('help:setting:lbl2')}
                        </label>
                        <label className="help-label-text">
                            <span className="enum">3</span>{t('help:setting:lbl3')}
                        </label>
                    </div>
                </div>
                <div className="container-app-div help-screen" style={{ marginTop: 10 }}>

                    <div style={{ display: 'flex', marginBottom: 30 }}>
                        <div style={{ width: '50%', display: 'flex', flexDirection: 'column' }}>
                            <p className="paragraph-text strong">
                                {t('help:step1')} <span className="enum">1</span>:&nbsp;{t('help:setting:lbl4')}
                            </p>
                            <label className="help-label-text">
                                {t('help:setting:lbl5')}
                            </label>
                            <label className="help-label-text">
                                {t('help:setting:lbl6')}
                            </label>
                        </div>
                        <div style={{ width: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Card sx={{ maxWidth: 300, border: '2px solid lightblue', boxShadow: '0px 0px 10px #7777' }}>
                                <CardMedia
                                    component="img"
                                    height="178"
                                    image={helpImage1}
                                    alt="img"
                                    style={{ objectFit: 'contain' }}
                                />
                            </Card>
                        </div>
                    </div>


                </div>
                <div className="container-app-div help-screen" style={{ marginTop: 10 }}>
                    <div style={{ display: 'flex', marginBottom: 30 }}>
                        <div style={{ width: '50%', display: 'flex', flexDirection: 'column' }}>
                            <p className="paragraph-text strong">
                                {t('help:step2')} <span className="enum">2</span>:&nbsp;{t('help:setting:lbl7')}
                            </p>
                            <label className="help-label-text">
                                {t('help:setting:lbl8')}
                            </label>
                            <label className="help-label-text">
                                {t('help:setting:lbl9')}
                            </label>
                            <br />
                            <label className="help-label-text">
                                {t('help:setting:lbl10')}
                            </label>
                        </div>
                        <div style={{ width: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Card sx={{ maxWidth: 200, boxShadow: 'none' }}>
                                <CardMedia
                                    component="img"
                                    height="210"
                                    image={helpImage2}
                                    alt="img"
                                    style={{ objectFit: 'contain' }}
                                />
                            </Card>
                        </div>
                    </div>

                </div>
                <div className="container-app-div help-screen" style={{ marginTop: 10 }}>
                    <div style={{ display: 'flex', marginBottom: 30 }}>
                        <div style={{ width: '50%', display: 'flex', flexDirection: 'column' }}>
                            <p className="paragraph-text">
                                {t('help:step3')}<span className="enum">3</span> :&nbsp;{t('help:optional')}
                            </p>
                            <label className="help-label-text">
                                {t('help:setting:lbl11')}
                            </label>
                        </div>
                    </div>

                </div>

            </Grid>
        </Container>
    );
}
export default HelpSetting;