import React from "react";
import { Typography } from "@mui/material";
import IconSvg from '../../components/Icon';

const AccessRow = ({ title, Icon = null, active }) => {

    return (
        <div style={{ display: 'flex', alignItems: 'center', margin: '8px 0px 11px 8px' }}>
            <IconSvg Icon={Icon} className={`access-icon-svg access-icon ${active && "access-icon-active"}`} />
            <Typography className={`access-text ${active && "access-text-active"}`}>
                {title}
            </Typography>
        </div>
    );
}
export default (AccessRow);