import React from "react";
import { Container, Grid, Typography, Card, CardMedia } from "@mui/material";
import permissionImage1 from '../../assets/Images/permission1.png';
import permissionImage2 from '../../assets/Images/permission2.png';
import { useTranslation } from "react-i18next";
import './Help.css';

const HelpPermission = () => {

    const { t } = useTranslation(['help']);

    return (
        <Container>
                <Typography className="admin-business-text" style={{ marginBottom: '20px' }}>
                            {t('help:permission:category')+"|"+t('help:permission:title')}
                        </Typography>

            <Grid item xs={12}>
                <div className="container-app-div help-screen header" style={{ marginTop: 10 }}>
                    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                        <p className="paragraph-text strong" style={{ marginBottom: '20px' }} >
                        {t('help:thisPageLbl')}
                        </p>
                        <label className="help-label-text">
                        <span className="enum">1</span>  {t('help:permission:lbl1')}
                        </label>
                        <label className="help-label-text">
                        <span className="enum">2</span>   {t('help:permission:lbl2')}
                        </label>
                        <br />
                        </div>
                </div>
                        <div className="container-app-div" style={{ marginTop: 10 }}>
                
                            <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                                <p className="paragraph-text strong">
                                    {t('help:step1')} <span className="enum">1</span>: &nbsp; {t('help:permission:lbl3')}
                                </p>
                                <label className="help-label-text">
                                    {t('help:permission:lbl4')}
                                </label>
                                <label className="help-label-text">
                                    {t('help:permission:lbl5')}
                                </label>
                            </div>
                            <div style={{
                                width: '100%', display: 'flex', marginTop: 40,
                                justifyContent: 'center', alignItems: 'center'
                            }}>
                                <Card sx={{ maxWidth: 704, height: 329 }}>
                                    <CardMedia
                                        component="img"
                                        image={permissionImage1}
                                        alt="img"
                                        style={{ objectFit: 'contain' }}
                                    />
                                </Card>
                            </div>
                        </div>
                    
                        <div className="container-app-div" style={{ marginTop: 10 }}>
                
                            <div style={{ width: '85%', display: 'flex', flexDirection: 'column' }}>
                                <p className="paragraph-text strong">
                                    {t('help:step2')}<span className="enum">2</span>: &nbsp;{t('help:permission:lbl6')}
                                </p>
                                
                                <label className="help-label-text">
                                    {t('help:permission:lbl7')}
                                </label>
                                <label className="help-label-text">
                                    {t('help:permission:lbl8')}
                                </label>
                                <label className="help-label-text">
                                    {t('help:permission:lbl9')}
                                </label>
                            </div>
                            <div style={{
                                width: '100%', display: 'flex', marginTop: 40,
                                justifyContent: 'center', alignItems: 'center',boxShadow: 'none'
                            }}>
                                <Card sx={{ maxWidth: 704, height: 242 }}>
                                    <CardMedia
                                        component="img"
                                        image={permissionImage2}
                                        alt="img"
                                        style={{ objectFit: 'contain' }}
                                    />
                                </Card>
                            </div>
                        </div>
                
            </Grid>
        </Container>
    );
}
export default HelpPermission;