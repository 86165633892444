import {
    getSubjectsURL, postSubjectURL, putSubjectURL,
    getSubjectByIdURL, putSubjectDisableURL, putSubjectActiveURL,
    subjectDeleteFileURL, putSubjectAddFileURL
} from '../constants/apiUrls';
import { postAxios, getAxios, putAxios } from '../services/Axios';
import { logoutAction, renewTokenAction } from './AuthDuck'

// constantes
const initialState = {
    subjects: [],
    subject: null,
    description: null,
    title: null,
    attachments: [],
    companyId: null,
    clientId: null,
    officeId: null,
    pages: 1,
    activePage: 0,
    isCreating: true,
    isCreated: false,
    isDeletedFile: false,
    response: null,
    loading: false,
    error: null,
};

// types
const SUBJECT_SUCCESS = 'SUBJECT_SUCCESS';
const CREATE_UPDATE_SUBJECT_SUCCESS = 'CREATE_UPDATE_SUBJECT_SUCCESS';
const SUBJECT_GET_BY_ID = 'SUBJECT_GET_BY_ID';
const IS_CREATING_SUBJECT = 'IS_CREATING_SUBJECT';
const SUBJECT_STATUS_CHANGE = 'SUBJECT_STATUS_CHANGE';
const SUBJECT_ERROR = 'SUBJECT_ERROR';
const LOADING_SUBJECT = 'LOADING_SUBJECT';
const CLEAR_RESPONSE = 'CLEAR_RESPONSE';
const SUBJECT_CHANGE_PAGE = 'SUBJECT_CHANGE_PAGE';
const SUBJECT_FILE_DELETE_SUCCESS = 'SUBJECT_FILE_DELETE_SUCCESS';
const SET_IS_DELETED = 'SET_IS_DELETED';
const LOG_OUT = 'LOG_OUT';
const INITIAL_STATE = 'INITIAL_STATE';

// reducer
export default function reducer(state = initialState, action) {
    switch (action.type) {
        case LOADING_SUBJECT:
            return { ...state, loading: true, error: null };
        case SUBJECT_SUCCESS:
            return {
                ...state, subjects: action.payload, isCreated: false,
                pages: action.pages, error: null, loading: false
            };
        case CREATE_UPDATE_SUBJECT_SUCCESS:
            return {
                ...state, isCreated: true, response: action.response,
                loading: false, error: null,
                subjects: [], pages: 1, activePage: 0
            };
        case SUBJECT_GET_BY_ID:
            return {
                ...state, subject: action.payload, error: null, loading: false,
                subjects: [], pages: 1, activePage: 0
            };
        case SUBJECT_STATUS_CHANGE:
            return { ...state, response: action.response, loading: false, error: null };
        case IS_CREATING_SUBJECT:
            return {
                ...state, isCreating: action.payload, user: null, isCreated: false,
                subjects: [], pages: 1, activePage: 0
            };
        case SUBJECT_CHANGE_PAGE:
            return { ...state, activePage: action.activePage };
        case SUBJECT_FILE_DELETE_SUCCESS:
            return {
                ...state, isDeletedFile: true, response: action.response,
                loading: false, error: null
            };
        case SET_IS_DELETED:
            return { ...state, isDeletedFile: false };
        case CLEAR_RESPONSE:
            return { ...state, response: null };
        case SUBJECT_ERROR:
            return {
                ...state, error: action.payload,
                response: action.payload, loading: false
            };
        case LOG_OUT:
            return { ...initialState };
        case INITIAL_STATE:
            return {
                ...state,
                subjects: [],
                subject: null,
                description: null,
                title: null,
                attachments: [],
                companyId: null,
                clientId: null,
                officeId: null,
                pages: 1,
                activePage: 0,
            };
        default:
            return state;
    }
};

export const getSubjects = (filterBy = [], page = 0, pageSize = 10, loading = false) => async (dispatch, getState) => {
    loading && dispatch({ type: LOADING_SUBJECT });
    try {
        const { user } = getState().auth;
        const res = await postAxios(getSubjectsURL, {
            "organizationId": user.organizationId,
            "page": page,
            "pageSize": pageSize,
            "filterBy": filterBy,
            "orderBy": "",
            "orderDesc": true
        });
        if (res.status === 200) {
            const token = res.headers.refreshtoken;
            const expiration = res.headers.expirationToken;
            (token && expiration) && dispatch(renewTokenAction(token, expiration));
            return dispatch({
                type: SUBJECT_SUCCESS,
                payload: res.data.response,
                pages: res.data.totalPages
            });
        }
        throw new Error();
    } catch (error) {
        if (error.response && error.response.status === 401)
            dispatch(logoutAction());
        dispatch({
            type: SUBJECT_ERROR,
            payload: error.response ? error.response.data.message : "common:errorLoadingTasks"
        });
    }
};

export const getSubjectById = (id) => async (dispatch) => {
    dispatch({ type: LOADING_SUBJECT });
    try {
        const res = await getAxios(getSubjectByIdURL + id);
        if (res.status === 200) {
            const token = res.headers.refreshtoken;
            const expiration = res.headers.expirationToken;
            (token && expiration) && dispatch(renewTokenAction(token, expiration));
            return dispatch({
                type: SUBJECT_GET_BY_ID,
                payload: res.data.response
            });
        }
        throw new Error();
    } catch (error) {
        if (error.response && error.response.status === 401)
            dispatch(logoutAction());
        dispatch({
            type: SUBJECT_ERROR,
            payload: error.response ? error.response.data.message : "common:errorLoadingTask"
        });
    }
};
export const saveUpdateSubject = (subject) => async (dispatch, getState) => {
    dispatch({ type: LOADING_SUBJECT });
    try {
        //const userRedux = getState().auth.user;
        const { isCreating } = getState().subject;
        const subjectRedux = getState().subject.subject;
        const filesIds = [];
        subject.selectedFiles.forEach(doc => {
            filesIds.push(doc.id);
        });

        const res = isCreating
            ? await postAxios(postSubjectURL, {
                filesIds: filesIds,
                title: subject.title,
                description: subject.description,
                companyId: subject.companyId,
                officeId: subject.officeId,
                clientId: subject.clientId
            })
            : await putAxios(putSubjectURL, {
                id: subjectRedux.id,
                filesIds: filesIds,
                title: subject.title,
                description: subject.description,
                companyId: subject.companyId,
                officeId: subject.officeId,
                clientId: subject.clientId
            });
        if (res.status === 200) {
            const token = res.headers.refreshtoken;
            const expiration = res.headers.expirationToken;
            (token && expiration) && dispatch(renewTokenAction(token, expiration));
            return dispatch({
                type: CREATE_UPDATE_SUBJECT_SUCCESS,
                response: res.data.response
            });
        }
        throw new Error();
    } catch (error) {
        if (error.response && error.response.status === 401)
            dispatch(logoutAction());
        dispatch({
            type: SUBJECT_ERROR,
            payload: error.response ? error.response.data.message : "common:errorSavingTask"
        });
    }
};

export const changeStatusSubject = (id) => async (dispatch, getState) => {
    dispatch({ type: LOADING_SUBJECT });
    try {
        const { subject } = getState().subject;
        const res = !subject.disabled
            ? await putAxios(putSubjectDisableURL + id)
            : await putAxios(putSubjectActiveURL + id);

        if (res.status === 200) {
            const token = res.headers.refreshtoken;
            const expiration = res.headers.expirationToken;
            (token && expiration) && dispatch(renewTokenAction(token, expiration));
            dispatch({
                type: SUBJECT_STATUS_CHANGE,
                response: res.data.response
            });
            return dispatch(getSubjectById(id));
        }
        throw new Error();
    } catch (error) {
        if (error.response && error.response.status === 401)
            dispatch(logoutAction());
        dispatch({
            type: SUBJECT_ERROR,
            payload: error.response ? error.response.data.message : "common:errorChangingTaskStatus"
        });
    }
};

export const deleteFile = (subjectId, fileId) => async (dispatch) => {
    try {
        dispatch({ type: LOADING_SUBJECT });
        const res = await putAxios(subjectDeleteFileURL, {
            subjectId: parseInt(subjectId),
            fileId: parseInt(fileId)
        });

        if (res.status === 200) {
            const token = res.headers.refreshtoken;
            const expiration = res.headers.expirationToken;
            (token && expiration) && dispatch(renewTokenAction(token, expiration));
            return dispatch({
                type: SUBJECT_FILE_DELETE_SUCCESS,
                response: res.data.response
            });
        }
        throw new Error();
    } catch (error) {
        if (error.response && error.response.status === 401)
            dispatch(logoutAction());
        dispatch({
            type: SUBJECT_ERROR,
            payload: error.response ? error.response.data.message : "common:errorDeletingFiles"
        });
    }
};

export const subjectAddFile = (subjectId, selectedDocs) => async (dispatch) => {
    try {
        dispatch({ type: LOADING_SUBJECT });

        const filesIds = [];
        selectedDocs.forEach(doc => {
            filesIds.push({
                subjectId: subjectId,
                fileId: doc.id
            });
        });
        const res = await putAxios(putSubjectAddFileURL, filesIds);

        if (res.status === 200) {
            const token = res.headers.refreshtoken;
            const expiration = res.headers.expirationToken;
            (token && expiration) && dispatch(renewTokenAction(token, expiration));
            return dispatch({
                type: CREATE_UPDATE_SUBJECT_SUCCESS,
                response: res.data.response
            });
        }
        throw new Error();

    } catch (error) {
        if (error.response && error.response.status === 401)
            dispatch(logoutAction());
        dispatch({
            type: SUBJECT_ERROR,
            payload: error.response ? error.response.data.message : "common:errorAddingFiles"
        });
    }
};

export const changeSubjectPage = (page) => (dispatch) => {
    dispatch({
        type: SUBJECT_CHANGE_PAGE,
        activePage: page - 1
    });
};

export const changeIsCreating = (state) => (dispatch) => {
    dispatch({
        type: IS_CREATING_SUBJECT,
        payload: state
    });
};

export const changeIsDeleted = () => (dispatch) => {
    dispatch({
        type: SET_IS_DELETED
    });
};