import {
    getCompanyURL, postCompanyURL, getCompanyByIdURL,
    putCompanyDisableURL, putCompanyActiveURL, putCompanyURL,
    putOfficeDisableURL, putOfficeActiveURL, getOfficeURL
} from '../constants/apiUrls';
import { postAxios, getAxios, putAxios } from '../services/Axios';
import { logoutAction, renewTokenAction } from './AuthDuck'

// constantes
const initialState = {
    businesses: [],
    offices: [],
    business: null,
    isCreating: true,
    isCreated: false,
    pages: 1,
    activePage: 0,
    loading: false,
    error: null,
    response: null
};

// types
const BUSINESS_GET_SUCCESS = 'BUSINESS_GET_SUCCESS';
const BUSINESS_GET_BY_ID = 'BUSINESS_GET_BY_ID';
const CREATE_UPDATE_BUSINESS_SUCCESS = 'CREATE_UPDATE_BUSINESS_SUCCESS';
const BUSINESS_STATUS_CHANGE = 'BUSINESS_STATUS_CHANGE';
const OFFICE_STATUS_CHANGE = 'OFFICE_STATUS_CHANGE';
const IS_CREATING_BUSINESS = 'IS_CREATING_BUSINESS';
const BUSINESS_CHANGE_PAGE = 'BUSINESS_CHANGE_PAGE';
const BUSINESS_ERROR = 'BUSINESS_ERROR';
const LOADING_BUSINESS = 'LOADING_BUSINESS';
const OFFICES_GET_SUCCESS = 'OFFICES_GET_SUCCESS';
const CLEAR_OFFICES = 'CLEAR_OFFICES';
const LOG_OUT = 'LOG_OUT';
const INITIAL_STATE = 'INITIAL_STATE';
const CLEAR_RESPONSE = 'CLEAR_RESPONSE';

// reducer
export default function reducer(state = initialState, action) {
    switch (action.type) {
        case LOADING_BUSINESS:
            return { ...state, loading: true, error: null };
        case BUSINESS_GET_SUCCESS:
            return {
                ...state, businesses: action.payload, activePage: action.activePage,
                pages: action.pages, error: null, loading: false, isCreated: false
            };
        case OFFICES_GET_SUCCESS:
            return {
                ...state, offices: action.payload, error: null, loading: false
            };
        case BUSINESS_GET_BY_ID:
            return {
                ...state, business: action.payload, error: null, loading: false,
                businesses: [], pages: 1, activePage: 0
            };
        case CREATE_UPDATE_BUSINESS_SUCCESS:
            return {
                ...state, isCreated: true, response: action.response,
                loading: false, error: null
            };
        case BUSINESS_STATUS_CHANGE:
            return { ...state, response: action.response, loading: false, error: null };
        case OFFICE_STATUS_CHANGE:
            return { ...state, response: action.response, loading: false, error: null };
        case BUSINESS_CHANGE_PAGE:
            return { ...state, activePage: action.activePage };
        case IS_CREATING_BUSINESS:
            return {
                ...state, business: null, isCreating: action.payload, isCreated: false,
                businesses: [], pages: 1, activePage: 0
            };
        case BUSINESS_ERROR:
            return {
                ...state, error: action.payload, loading: false,
                response: action.payload
            };
        case CLEAR_OFFICES:
            return { ...state, offices: [] };
        case CLEAR_RESPONSE:
            return { ...state, response: null };
        case LOG_OUT:
            return { ...initialState };
        case INITIAL_STATE:
            return {
                ...state,
                businesses: [],
                offices: [],
                business: null,
                pages: 1,
                activePage: 0,
            };
        default:
            return state;
    }
};

export const getBusinesses = (search = '', state = 'none', page = 0, pageSize = 1000, loading = false, service = '') => async (dispatch, getState) => {
    loading && dispatch({ type: LOADING_BUSINESS });
    try {
        const { user } = getState().auth;
        const filterBy = [];
        search !== '' && filterBy.push({ search: search, filterBy: 'name' });
        state !== 'none' && filterBy.push({ search: state, filterBy: 'disabled' });
        const res = await postAxios(getCompanyURL, {
            "organizationId": user.organizationId,
            "page": page,
            "pageSize": pageSize,
            "filterBy": filterBy,
            "orderBy": "",
            "orderDesc": true,
            "service": service
        });
        if (res.status === 200) {
            const token = res.headers.refreshtoken;
            const expiration = res.headers.expirationToken;
            (token && expiration) && dispatch(renewTokenAction(token, expiration));
            const businesses = res.data.response;
            const list = [];
            businesses.forEach(item => {
                list.push({
                    id: item.id,
                    name: item.name,
                    offices: item.offices,
                    disabled: item.disabled,
                })
            });
            return dispatch({
                type: BUSINESS_GET_SUCCESS,
                payload: list,
                pages: res.data.totalPages,
                activePage: (res.data.totalPages < page + 1) ? 0 : page
            });
        }
        throw new Error();
    } catch (error) {
        if (error.response && error.response.status === 401)
            dispatch(logoutAction());
        dispatch({
            type: BUSINESS_ERROR,
            payload: error.response ? error.response.data.message : "common:errorLoadingCompanies"
        });
    }
};

export const getBusinessesFilter = (filterByParam = [], filterAdmin = true) => async (dispatch, getState) => {
    try {
        const { user } = getState().auth;
        const filterBy = filterByParam;
        filterBy.push({ search: 'false', filterBy: 'disabled' });
        (filterAdmin) && filterBy.push({ search: user.id, filterBy: 'user' });
        const res = await postAxios(getCompanyURL, {
            "organizationId": user.organizationId,
            "page": 0,
            "pageSize": 1000,
            "filterBy": filterBy,
            "orderBy": "",
            "orderDesc": true,
            "service": ""
        });
        if (res.status === 200) {
            const token = res.headers.refreshtoken;
            const expiration = res.headers.expirationToken;
            (token && expiration) && dispatch(renewTokenAction(token, expiration));
            const businesses = res.data.response;
            const list = [];
            businesses.forEach(item => {
                list.push({
                    id: item.id,
                    name: item.name,
                    offices: item.offices,
                    disabled: item.disabled,
                })
            });
            return dispatch({
                type: BUSINESS_GET_SUCCESS,
                payload: list,
                pages: res.data.totalPages,
                activePage: 0
            });
        }
        throw new Error();
    } catch (error) {
        if (error.response && error.response.status === 401)
            dispatch(logoutAction());
        dispatch({
            type: BUSINESS_ERROR,
            payload: error.response ? error.response.data.message : "common:errorLoadingOffices"
        });
    }
};

export const getOfficesFilter = (filterByParam = [], filterAdmin = true) => async (dispatch, getState) => {
    try {
        if (filterByParam.length === 0) {
            dispatch(clearOffices());
        } else {
            const { user } = getState().auth;
            const filterBy = filterByParam;
            filterBy.push({ search: 'false', filterBy: 'disabled' });
            (filterAdmin) && filterBy.push({ search: user.id, filterBy: 'user' });
            const res = await postAxios(getOfficeURL, {
                "organizationId": user.organizationId,
                "page": 0,
                "pageSize": 1000,
                "filterBy": filterBy,
                "orderBy": "",
                "orderDesc": true
            });
            if (res.status === 200) {
                const token = res.headers.refreshtoken;
                const expiration = res.headers.expirationToken;
                (token && expiration) && dispatch(renewTokenAction(token, expiration));
                return dispatch({
                    type: OFFICES_GET_SUCCESS,
                    payload: res.data.response
                });
            }
            throw new Error();
        }
    } catch (error) {
        if (error.response && error.response.status === 401)
            dispatch(logoutAction());
        dispatch({
            type: BUSINESS_ERROR,
            payload: error.response ? error.response.data.message : "common:errorLoadingCompanies"
        });
    }
};
export const getOfficesFilterWithStatus = (filterByParam = []) => async (dispatch, getState) => {
    try {
        if (filterByParam.length === 0) {
            dispatch(clearOffices());
        } else {
            const { user } = getState().auth;
            const filterBy = filterByParam;
            !user.isAdmin && filterBy.push({ search: user.id, filterBy: 'user' });
            const res = await postAxios(getOfficeURL, {
                "organizationId": user.organizationId,
                "page": 0,
                "pageSize": 1000,
                "filterBy": filterBy,
                "orderBy": "",
                "orderDesc": true
            });
            if (res.status === 200) {
                const token = res.headers.refreshtoken;
                const expiration = res.headers.expirationToken;
                (token && expiration) && dispatch(renewTokenAction(token, expiration));
                return dispatch({
                    type: OFFICES_GET_SUCCESS,
                    payload: res.data.response
                });
            }
            throw new Error();
        }
    } catch (error) {
        if (error.response && error.response.status === 401)
            dispatch(logoutAction());
        dispatch({
            type: BUSINESS_ERROR,
            payload: error.response ? error.response.data.message : "common:errorLoadingCompanies"
        });
    }
};

export const clearOffices = () => dispatch => {
    dispatch({ type: CLEAR_OFFICES });
}

export const changeBusinessPage = (page) => (dispatch) => {
    dispatch({
        type: BUSINESS_CHANGE_PAGE,
        activePage: page - 1
    });
};

export const saveBusiness = (business) => async (dispatch, getState) => {
    dispatch({ type: LOADING_BUSINESS });
    try {
        const { user } = getState().auth;
        const bodyFormData = new FormData();

        business.offices.forEach((item, index) => {
            bodyFormData.append(`offices[${index}][companyId]`, 0);
            bodyFormData.append(`offices[${index}][name]`, item.name);
            item.email && bodyFormData.append(`offices[${index}][email]`, item.email);
            item.webSite && bodyFormData.append(`offices[${index}][webSite]`, item.webSite);
            item.countryCode && bodyFormData.append(`offices[${index}][countryCodeId]`, item.countryCode);
            item.phoneNumber && bodyFormData.append(`offices[${index}][phone]`, item.phoneNumber);
            item.address && bodyFormData.append(`offices[${index}][direction]`, item.address);
            item.GPSCoordinates && bodyFormData.append(`offices[${index}][coordinatesGps]`, item.GPSCoordinates);
        });
        bodyFormData.append('organizationId', user.organizationId);
        bodyFormData.append('name', business.name);
        business.image && bodyFormData.append('logo', business.image);

        const res = await postAxios(postCompanyURL, bodyFormData);
        if (res.status === 200) {
            const token = res.headers.refreshtoken;
            const expiration = res.headers.expirationToken;
            (token && expiration) && dispatch(renewTokenAction(token, expiration));
            return dispatch({
                type: CREATE_UPDATE_BUSINESS_SUCCESS,
                response: res.data.response
            });
        }
        throw new Error();
    } catch (error) {
        if (error.response && error.response.status === 401)
            dispatch(logoutAction());
        dispatch({
            type: BUSINESS_ERROR,
            payload: error.response ? error.response.data.message : "common:errorSavingOfficesAndCompany"
        });
    }
};

export const updateBusiness = (business) => async (dispatch, getState) => {
    dispatch({ type: LOADING_BUSINESS });
    try {
        const businessRedux = getState().business.business;
        const newOffices = business.offices.filter(item => String(item.id).includes('new'));
        const oldOffices = business.offices.filter(item => !String(item.id).includes('new'));
        const bodyFormData = new FormData();

        newOffices.forEach((item, index) => {
            bodyFormData.append(`offices[${index}][companyId]`, businessRedux.id);
            bodyFormData.append(`offices[${index}][name]`, item.name);
            item.email && bodyFormData.append(`offices[${index}][email]`, item.email);
            item.webSite && bodyFormData.append(`offices[${index}][webSite]`, item.webSite);
            item.countryCode && bodyFormData.append(`offices[${index}][countryCodeId]`, item.countryCode);
            item.phoneNumber && bodyFormData.append(`offices[${index}][phone]`, item.phoneNumber);
            item.address && bodyFormData.append(`offices[${index}][direction]`, item.address);
            item.GPSCoordinates && bodyFormData.append(`offices[${index}][coordinatesGps]`, item.GPSCoordinates);
        });
        oldOffices.forEach((item, index) => {
            bodyFormData.append(`company[offices][${index}][id]`, item.id);
            bodyFormData.append(`company[offices][${index}][companyId]`, businessRedux.id);
            item.name && bodyFormData.append(`company[offices][${index}][name]`, item.name);
            item.email && bodyFormData.append(`company[offices][${index}][email]`, item.email);
            item.webSite && bodyFormData.append(`company[offices][${index}][webSite]`, item.webSite);
            item.countryCode && bodyFormData.append(`company[offices][${index}][countryCodeId]`, item.countryCode);
            item.phoneNumber && bodyFormData.append(`company[offices][${index}][phone]`, item.phoneNumber);
            item.address && bodyFormData.append(`company[offices][${index}][direction]`, item.address);
            item.GPSCoordinates && bodyFormData.append(`company[offices][${index}][coordinatesGps]`, item.GPSCoordinates);
        });
        bodyFormData.append('company[id]', businessRedux.id);
        bodyFormData.append('company[organizationId]', businessRedux.organizationId);
        business.image && bodyFormData.append('logo', business.image);
        businessRedux.fileId && bodyFormData.append('company[fileId]', businessRedux.fileId);
        bodyFormData.append('company[name]', business.name);
        bodyFormData.append('company[disabled]', businessRedux.disabled);
        const res = await putAxios(putCompanyURL, bodyFormData);

        if (res.status === 200) {
            const token = res.headers.refreshtoken;
            const expiration = res.headers.expirationToken;
            (token && expiration) && dispatch(renewTokenAction(token, expiration));
            return dispatch({
                type: CREATE_UPDATE_BUSINESS_SUCCESS,
                response: res.data.response
            });
        }
        throw new Error();
    } catch (error) {
        if (error.response && error.response.status === 401)
            dispatch(logoutAction());
        dispatch({
            type: BUSINESS_ERROR,
            payload: error.response ? error.response.data.message : "common:errorUpdatingOfficesAndCompany"
        });
    }
};

export const getBusinessById = (id) => async dispatch => {
    dispatch({ type: LOADING_BUSINESS });
    try {
        const res = await getAxios(getCompanyByIdURL, { id: id });
        if (res.status === 200) {
            const token = res.headers.refreshtoken;
            const expiration = res.headers.expirationToken;
            (token && expiration) && dispatch(renewTokenAction(token, expiration));
            return dispatch({
                type: BUSINESS_GET_BY_ID,
                payload: res.data.response
            });
        }
        throw new Error();
    } catch (error) {
        if (error.response && error.response.status === 401)
            dispatch(logoutAction());
        dispatch({
            type: BUSINESS_ERROR,
            payload: error.response ? error.response.data.message : "common:errorLoadingCompany"
        });
    }
};

export const changeStatusCompany = (id) => async (dispatch, getState) => {
    dispatch({ type: LOADING_BUSINESS });
    try {
        const { business } = getState().business;
        const res = !business.disabled
            ? await putAxios(putCompanyDisableURL + id)
            : await putAxios(putCompanyActiveURL + id);
        if (res.status === 200) {
            const token = res.headers.refreshtoken;
            const expiration = res.headers.expirationToken;
            (token && expiration) && dispatch(renewTokenAction(token, expiration));
            dispatch({
                type: BUSINESS_STATUS_CHANGE,
                response: res.data.response
            });
            return dispatch(getBusinessById(id));
        }
        throw new Error();
    } catch (error) {
        if (error.response && error.response.status === 401)
            dispatch(logoutAction());
        dispatch({
            type: BUSINESS_ERROR,
            payload: error.response ? error.response.data.message : "common:errorChangingCompanyStatus"
        });
    }
};

export const changeStatusOffice = (id) => async (dispatch, getState) => {
    dispatch({ type: LOADING_BUSINESS });
    try {
        const { business } = getState().business;
        const office = business.offices.find(item => item.id === id);
        const res = !office.disabled
            ? await putAxios(putOfficeDisableURL + id)
            : await putAxios(putOfficeActiveURL + id);
        if (res.status === 200) {
            const token = res.headers.refreshtoken;
            const expiration = res.headers.expirationToken;
            (token && expiration) && dispatch(renewTokenAction(token, expiration));
            dispatch({
                type: OFFICE_STATUS_CHANGE,
                response: res.data.response
            });
            return dispatch(getBusinessById(business.id));
        }
        throw new Error();
    } catch (error) {
        if (error.response && error.response.status === 401)
            dispatch(logoutAction());
        dispatch({
            type: BUSINESS_ERROR,
            payload: error.response ? error.response.data.message : "common:errorChangingOfficeStatus"
        });
    }
};

export const changeIsCreating = (state) => (dispatch) => {
    dispatch({
        type: IS_CREATING_BUSINESS,
        payload: state
    });
};