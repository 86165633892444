import {React,useEffect,useState} from 'react';
import { useSelector } from 'react-redux';
import { Grid, Card, Typography,Alert } from '@mui/material';
import Title from '../../components/Title'
import * as ROUTES from '../../constants/routes';
import CardContainer from './CardContainer';
import { ReactComponent as Document } from '../../assets/Icons/document.svg';
import { ReactComponent as Quote } from '../../assets/Icons/quote.svg';
import { ReactComponent as CheckCircle } from '../../assets/Icons/checkCircle.svg';
import { ReactComponent as Person } from '../../assets/Icons/person.svg';
import { ReactComponent as BriefCase } from '../../assets/Icons/briefcase.svg';
import { ReactComponent as PersonCheck } from '../../assets/Icons/personCheck.svg';
import { ReactComponent as Building } from '../../assets/Icons/building.svg';
import { ReactComponent as Journals } from '../../assets/Icons/journals.svg';
import { ReactComponent as PersonOutline } from '../../assets/Icons/personOutline.svg';
import { ReactComponent as PersonSquare } from '../../assets/Icons/personSquare.svg';
import { ReactComponent as Phone } from '../../assets/Icons/phone.svg';
import { ReactComponent as Toggles } from '../../assets/Icons/toggles.svg';
import { ReactComponent as Settings } from '../../assets/Icons/settings.svg';
import { ReactComponent as Subtract } from '../../assets/Icons/subtract.svg';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import './Dashboard.css';
import { useDispatch } from 'react-redux';

const Dashboard = () => {
    const history = useHistory();
    const { t } = useTranslation(['dashboard','userProfile']);
    const permission = useSelector(store => store.auth.permission);
    const gotpermission = useSelector(store => store.auth.gotpermission);
    //const [gotpermission,setGotpermission] = useState(true);
    const dispatch = useDispatch();
  


    const sidebarListOne = [
        {
            name: t('configuration'),
            Icon: <Settings style={{ color: '#8976B6' }} />,
            to: ROUTES.SYSTEM_CONFIGURATION,
            permission: 'Permissions.Configuration'
        },
        {
            name: t('configurationApp'),
            Icon: <Subtract style={{ color: '#8976B6' }} />,
            to: ROUTES.APP_CONFIGURATION,
            permission: 'Permissions.AppConfiguration'
        },
        {
            name: t('business'),
            Icon: <Building style={{ color: '#8976B6' }} />,
            to: ROUTES.BUSINESS,
            permission: 'Permissions.Companies'
        },
        {
            name: t('mobileContent'),
            Icon: <Phone style={{ color: '#8976B6' }} />,
            to: ROUTES.MOBILE_CONTENT,
            permission: 'Permissions.Mobile'
        },
        {
            name: t('user'),
            Icon: <PersonSquare style={{ color: '#8976B6' }} />,
            to: ROUTES.USERS,
            permission: 'Permissions.Users'
        },
        {
            name: t('permission'),
            Icon: <Toggles style={{ color: '#8976B6' }} />,
            to: ROUTES.PERMITS,
            permission: 'Permissions.Permissions'
        },
        {
            name: t('userAssigment'),
            Icon: <PersonOutline style={{ color: '#8976B6' }} />,
            to: ROUTES.USER_ASSIGNMENT,
            permission: 'Permissions.AssignUsers'
        },
        {
            name: t('log'),
            Icon: <Journals style={{ color: '#8976B6' }} />,
            to: ROUTES.LOGS,
            permission: 'Permissions.Logbook'
        },
    ];

    const sidebarListTwo = [
        {
            name: t('matter'),
            Icon: <Person style={{ color: '#8976B6' }} />,
            to: ROUTES.MATTERS,
            permission: 'Permissions.Subject'
        },
        {
            name: t('client'),
            Icon: <BriefCase style={{ color: '#8976B6' }} />,
            to: ROUTES.CLIENTS,
            permission: 'Permissions.Clients'
        },
        {
            name: t('clientStaff'),
            Icon: <PersonCheck style={{ color: '#8976B6' }} />,
            to: ROUTES.CUSTOMER_STAFF,
            permission: 'Permissions.ClientStaff'
        }
    ];

    const sidebarListThree = [
        {
            name: t('notification'),
            Icon: <Document style={{ color: '#8976B6' }} />,
            to: ROUTES.NOTIFICATIONS,
            permission: 'Permissions.Notifications'
        },
        {
            name: t('authorization'),
            Icon: <Quote style={{ color: '#8976B6' }} />,
            to: ROUTES.AUTHORIZATIONS,
            permission: 'Permissions.Authorizations'
        },
        {
            name: t('document'),
            Icon: <CheckCircle style={{ color: '#8976B6' }} />,
            to: ROUTES.DOCUMENTS,
            permission: 'Permissions.Documents'
        }
    ];

    const showSection = (list) => {
        if (permission) {
            const value = permission.permissions.find(per =>
                list.find(element => {
                    return element.permission === per.permissionName
                })
            );
            return value ? true : false;
        }
        //else {history.push(ROUTES.USER_PROFILE);}
        return false;
    };
    
   return  (
    gotpermission ?
        <Grid container style={{ display: "flex", width: "100%" }}>
            <Title title='Dashboard' />
           

            <Grid item xs={6} style={{ paddingRight: 10, display: showSection(sidebarListThree) ? "block" : "none" }}>
                <Card className='card-container' >
                    <Typography className='card-title'>
                        {t('firstSection')}
                    </Typography>
                    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                        {sidebarListThree.map((item) => (
                            permission &&
                            permission.permissions.find(per => per.permissionName === item.permission) &&
                            <CardContainer key={item.name} texto={item.name} route={item.to} icono={item.Icon} />
                        ))}
                    </div>
                </Card>
            </Grid>
            <Grid item xs={6} style={{ paddingRight: 10, display: showSection(sidebarListTwo) ? "block" : "none" }}>
                <Card className='card-container'>
                    <Typography className='card-title'>
                        {t('secondSection')}
                    </Typography>
                    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                        {sidebarListTwo.map((item) => (
                            permission &&
                            permission.permissions.find(per => per.permissionName === item.permission) &&
                            <CardContainer key={item.name} texto={item.name} route={item.to} icono={item.Icon} />
                        ))}
                    </div>
                </Card>
            </Grid>
            <Grid item xs={12} style={{ paddingTop: 20, display: showSection(sidebarListOne) ? "block" : "none" }}>
                <Card className='card-container'>
                    <Typography className='card-title'>
                        {t('thirdSection')}
                    </Typography>
                    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                        {sidebarListOne.map((item) => (
                            permission &&
                            permission.permissions.find(per => per.permissionName === item.permission) &&
                            <CardContainer key={item.name} texto={item.name} route={item.to} icono={item.Icon} />
                        ))}
                    </div>
                </Card>
            </Grid>
        </Grid>
        :
        <Grid container  >
      <Alert severity="error" className='change-pass-alert'> {t("userProfile:NoPermissions")}</Alert> 
    </Grid>
    
    );
    

}

export default Dashboard;