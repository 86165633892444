import React, { useState, useEffect, createRef, useRef } from "react";
import { withRouter } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import {
    Container, Typography, Button,
    Grid, InputLabel, Avatar
} from "@mui/material";
import CircleIcon from '@mui/icons-material/Circle';
import TextFieldComponent from "../../../components/TextField";
//import SelectFieldComponent from "../../../components/SelectField";
import Title from "../../../components/Title";
import ChangePassword from '../../ChangePassword';
import Logo from '../../../assets/Logos/navbarLogo.png';
import FooterFiller from '../../../assets/Images/footer.png';
import { getFileURL } from '../../../constants/apiUrls';
import {
    TableContainer, TableRow,
    TableCell, Table, TableBody, Accordion, AccordionDetails, AccordionSummary, Alert,
} from "@mui/material";
import { useReactToPrint } from 'react-to-print';
import { ReactComponent as Printer } from '../../../assets/Icons/printer.svg';
import { updateUser } from '../../../redux/UserDuck';
//import { changePasswordAction } from '../../../redux/AuthDuck'
import { getBusinessById } from '../../../redux/BusinessDuck'
import { getSubscriptionByOrgId, getPaymentsBySubscriptionId, changeSubscriptionPlan, changePaymentMethod, cancelSubscription, changePaymentPage, resumeSubscription, getPlans } from '../../../redux/SubscriptionDuck'
import { getUserById } from '../../../redux/UserDuck'
import Loading from '../../../components/Loading';
import { userValidation, cardChangeValidation } from './FormValidation';
import { getCompanyById, getAdmin } from '../../../redux/ClientDuck';
import ChangePaymentMethodModal from '../../../components/DeleteMessage';
import ChangePlanModal from '../../../components/DeleteMessage';
import SubscriptionEndWarning from '../../../components/DeleteMessage';
import PaginationCustom from "../../../components/Pagination";

import CancelPlanModal from '../../../components/DeleteMessage';
import Moment from "react-moment";
import { useTranslation } from "react-i18next";
import PaymentForm from "./PaymentForm";
import '../userProfile.css';
import { Payments } from "@mui/icons-material";

const Read = props => {
    const { t } = useTranslation(['userProfile', 'common', 'subscription', 'validations', 'userProfile']);
    const dispatch = useDispatch();
    const inputRef = useRef(null);
    const currentCompany = useSelector(store => store.client.company);
    const [company, setCompany] = useState("");
    const companyAdmin = useSelector(store => store.client.companyAdmin);
    const userRedux = useSelector(store => store.user.user);
    const authUserRedux = useSelector(store => store.auth.user);
    const activePage = useSelector(store => store.subscription.activePage);
    const pages = useSelector(store => store.subscription.pages);
    const subAlert = useSelector(store => store.subscription.subAlert);
    const client = useSelector(store => store.business.business);
    const countries = useSelector(store => store.country.countries);
    const authAlert = useSelector(store => store.auth.subscriptionAlertMessage);
    const subPlans = useSelector(store => store.subscription.plans);
    const checkoutErrorRedux = useSelector(store => store.auth.checkoutError);
    const payments = useSelector(store => store.subscription.payments);
    const subscription = useSelector(store => store.subscription.subscription);
    const [printed, setPrinted] = useState(false);
    const [invoiceRowTotal, setInvoiceRowTotal] = useState("");
    const [invoiceDate, setInvoiceDate] = useState("");
    const [currentPlan, setCurrentPlan] = useState("");
    const [planAlert, setPlanAlert] = useState(false);
    const [selectedPlan, setSelectedPlan] = useState(0);
    const [checkoutError, setCheckoutError] = useState("");
    const [card, setCard] = useState("");
    const [countryAlfa, setCountryAlfa] = useState("");
    const [cardBrand, setCardBrand] = useState("");
    const [accountGracePeriod, setAccountGracePeriod] = useState(true);
    const [monthlyBilling, setMonthlyBilling] = useState(true);
    const [planChangeVisible, setPlanChangeVisible] = useState(false);
    const [paymentChangeVisible, setPaymentChangeVisible] = useState(false);
    const [resumeCheckoutVisible, setResumeCheckoutVisible] = useState(false);
    const [openPlanModal, setOpenPlanModal] = useState(false);
    const [openCancelModal, setOpenCancelModal] = useState(false);
    const [openSubscriptionEndWarning, setOpenSubscriptionEndWarning] = useState(false);
    const [gracePeriodEndDate, setGracePeriodEndDate] = useState("");
    const [resetPage, setResetPage] = useState(false);

    const [PaymentRequest, setPaymentRequest] = useState({
        id: '', firstName: '', lastName: '', email: '',
        countryCode: '', phoneNumber: '', position: '',
        profileDescription: '', image: '', imageUrl: '',
    });

    const [openPaymentModal, setOpenPaymentModal] = useState(false);
    const [user, setUser] = useState({
        id: '', firstName: '', lastName: '', email: '',
        countryCode: '', phoneNumber: '', position: '',
        profileDescription: '', image: '', imageUrl: '', isAdmin: null

    });
    const [locale, setLocale] = useState("en-us");
    const [localeFormat, setLocaleFormat] = useState("MMMM DD YYYY");
    const [error, setError] = useState({});
    const [cardError, setCardError] = useState({});
    const [paymentDate, setPaymentDate] = useState({});
    const imgRef = createRef();
    const [form, setForm] = useState({
        cardHolderName: '',
        cardN: '',
        exp: '',
        expYear: '',
        expMonth: '',
        cvv: '',
        planName: '',

    });
    const [payment, setPayment] = useState("");
    const [paymentError, setPaymentError] = useState({});
    const [activeSubscription, setActiveSubscription] = useState("active");
    const [mockplans, setMockplans] = useState([{}]);
    const allComponentRef = useRef();
    const componentRef = useRef();

    const pageStyle = `
    @page {
      size: auto;
      margin: 20mm 1mm;
    }

    @media print {
        html, body {
          background-color: #FFFFFF !important;
        }
      }
  `;

    const handlePrint = useReactToPrint({

        content: () => componentRef.current,
        documentTitle: t("paymentRecordTitle"),
        pageStyle: pageStyle,
    });

    const print = (charge, date) => {
        //getLogsId();
        setInvoiceRowTotal(charge);
        setInvoiceDate(date);
        const timer = setTimeout(() => {
            const printbutton = document.getElementsByClassName("button-print-download");
            hidePrintButtons(printbutton, "none")
            handlePrint();
            hidePrintButtons(printbutton, "flex")

            //document.getElementsByClassName("button-print-download").style.display = "flex";
            setPrinted(true);
        }, 1000);
        return () => clearTimeout(timer);
    };
    const hidePrintButtons = (elements, displayStyle) => {
        for (var i = 0; i < elements.length; i++) {
            elements[i].style.display = displayStyle;
        }
    };
    useEffect(() => {
        //console.log("user", user, user.temporaryPasswordLogin);
        //console.log("?", (authUserRedux && authUserRedux.temporaryPasswordLogin));
        if (authUserRedux && authUserRedux.temporaryPasswordLogin) {
            const element = document.querySelector('#save-email-btn');
            const rect = element.getBoundingClientRect();
            const offset = rect.top - 40;
            element.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
                inline: 'nearest',
                offset: { top: offset }
            });
        }
    }, [user]);
   
    
    useEffect(() => {
        subPlans && setMockplans(subPlans);
       // subPlans && //console.log("plans", subPlans)
    }, [subPlans]);
    useEffect(() => {
        let currentUser = JSON.parse(localStorage.getItem('user-fos'));
        if (currentUser) {
           // setPlanChangeVisible(false);
            dispatch(getPlans());
            dispatch(getUserById(currentUser.id));
            dispatch(getSubscriptionByOrgId(currentUser.organizationId));
            dispatch(getBusinessById(currentUser.organizationId));
            dispatch(getCompanyById(currentUser.organizationId));
            dispatch(getAdmin(currentUser.organizationId));
        }

        

       // userRedux && //console.log("is admin?", user && user.isAdmin, userRedux);
        // ////console.log(localStorage.getItem('user-fos')(id));
        //    ////console.log("aesjs", aesjs.utils.utf8.toBytes("all random text is a blessing in disguise"));

    }, [dispatch]);

    useEffect(() => {
        const page = resetPage ? 0 : activePage;
        //const filterBy = [];
        //search !== '' && filterBy.push({ search: "false", filterBy: "disabled" });
        //filter !== 'none' && filterBy.push({ search: "false", filterBy: "disabled" });
        dispatch(getPaymentsBySubscriptionId([{ search: "false", filterBy: "disabled" }], page, 10, false, ""));
        setResetPage(false);
        // eslint-disable-next-line
    }, [dispatch, activePage]);

    useEffect(() => {
        if (userRedux) {
            setUser({
                id: userRedux.id,
                temporaryPasswordLogin: userRedux.temporaryPasswordLogin,
                firstName: userRedux.firstName,
                lastName: userRedux.lastName,
                email: userRedux.email,
                countryCode: userRedux.countryCodeId,
                phoneNumber: userRedux.phone,
                position: userRedux.organizationUser.position,
                profileDescription: userRedux.organizationUser.profileDescription,
                imageUrl: userRedux.fileId ? getFileURL + userRedux.fileId : '',
                image: ''
            });
        }
    }, [userRedux]);
    useEffect(() => {
        setCheckoutError(checkoutErrorRedux);
    }, [checkoutErrorRedux]);
    useEffect(() => {
        //  payments && ////console.log("payments", payments)
    }, [payments]);
    useEffect(() => {
        setCompany(currentCompany);
        setForm({
            cardHolderName: '',
            cardN: '',
            exp: '',
            expYear: '',
            expMonth: '',
            cvv: '',
            planName: '',

        });
        setPayment({
            cardHolderName: '',
            cardN: '',
            exp: '',
            expYear: '',
            expMonth: '',
            cvv: '',
            planName: '',
        });
        (currentCompany && currentCompany.countryNavigation) && setCountryAlfa(currentCompany.countryNavigation.alfa2.toUpperCase())
    }, [currentCompany]);
    useEffect(() => {
        company && //console.log("XX", "company", company)
        company && setLocale(company.defaultLanguageId===1?"en-us":"es-cr");
        company && setLocaleFormat(company.defaultLanguageId===1?"MMMM DD YYYY":"DD MMMM YYYY");
    }, [company]);


    useEffect(() => {
        if (Object.keys(subscription).length) {
            dispatch(getPaymentsBySubscriptionId(
                [{ search: "false", filterBy: "disabled" }],
                0, 10, false, ""
            ));
            //console.log("subscription", subscription);
            //console.log("current Plan different from before?", currentPlan!=subscription.subscriptionPlanId - 1);
            currentPlan && setPlanAlert(currentPlan!=subscription.subscriptionPlanId - 1);
            setPlanChangeVisible(false);
            setCard(subscription.cardLast4Digits);
            setCardBrand(subscription.paymentProcessor);
            setCurrentPlan(subscription.subscriptionPlanId ? subscription.subscriptionPlanId - 1 : 2);
            setMonthlyBilling(subscription.billingPeriod === "MONTHLY");
            var nextPaymentDate = new Date(subscription.nextPayment && subscription.nextPayment);
            //console.log("next",nextPaymentDate.toDateString());
            nextPaymentDate && setPaymentDate(nextPaymentDate.toLocaleDateString("en-us") !== "Invalid Date" ? nextPaymentDate.toLocaleDateString("en-us") : "subscription:noDate");
            let activeStatus = "";
            const currentDate = new Date()//.setHours(0, 0, 0, 0); // Create a new Date object with the current date and time, 1 day ahead
            const GraceDays = new Date(nextPaymentDate.getTime() + (10 * 24 * 60 * 60 * 1000))//.setHours(0, 0, 0, 0); // Add 10 days in milliseconds to the current date 
            const PendingDays = new Date(nextPaymentDate.getTime() + (2 * 24 * 60 * 60 * 1000))//.setHours(0, 0, 0, 0); // Add 2 days accounting the pending payment state 
          //  console.log("DATES", GraceDays.toDateString(),currentDate.toDateString());

            if (subscription.status && subscription.status.toLowerCase() === "active" )
             {
                console.log(currentDate.setHours(0, 0, 0, 0),"before than",nextPaymentDate,currentDate<=nextPaymentDate);
                if(currentDate.setHours(0, 0, 0, 0)<=nextPaymentDate){

                    activeStatus =  "active"; 
                }
                else {
                    if (currentDate.setHours(0, 0, 0, 0)<=PendingDays){
                        activeStatus = "on_hold";
                    }
                    else{
                        activeStatus = "disabled";
                    }
                }
                //activeStatus = PendingDays.getTime() >= currentDate.getTime() ? "on_hold" : "disabled";
            }
            else {
                activeStatus = subscription.status.toLowerCase();
            }
            activeStatus && setActiveSubscription(activeStatus);
            let curStatus  = (activeStatus && activeStatus.toLowerCase() === "active");
            let timeLeft  =  GraceDays.getTime() >= currentDate.getTime();
            console.log("Status Active?", curStatus, activeStatus, subscription.status);
            //console.log("TimeLeft?", timeLeft,GraceDays.getTime());
            //console.log("Subscription has expired?", nextPaymentDate, "CURRENT",Date.now());
            console.log("Grace period?", curStatus && timeLeft);
            console.log("Grace period date", GraceDays,new Date().setHours(0, 0, 0, 0), " are we before these days?",  GraceDays>=new Date().setHours(0, 0, 0, 0));
            setGracePeriodEndDate(GraceDays);
            setAccountGracePeriod(!curStatus && timeLeft);
        }
    }, [subscription, dispatch]);

    useEffect(() => {
        ////console.log("subscription active?", activeSubscription !== "active");
        activeSubscription && setOpenSubscriptionEndWarning(activeSubscription !== "active");
    }, []);

    const onSubmitUserInfoChange = () => {
        const userError = userValidation(user, imgRef, t);
        setError(userError);
        if (Object.entries(userError).length === 0) {
            // ////console.log(user);
            dispatch(updateUser(user, null));
        }
    };

    const onClickPlanSwitch = (planId, t) => {
        setSelectedPlan(planId);
        setOpenPlanModal(true);
    };
    const onSubmitPlanChange = () => {
        dispatch(changeSubscriptionPlan(selectedPlan, subscription.greenpaySubscriptionId, company.id, monthlyBilling));

    };

    const onSubmitPaymentMethodChange = () => {
        const paymentFormError = cardChangeValidation(form, t);
        setCardError(paymentFormError);
        if (Object.entries(paymentFormError).length === 0) {
            ////console.log(form);
            dispatch(changePaymentMethod(form, subscription.greenpaySubscriptionId));
        }
    };

    const onSubmitCancelSubscription = () => {
        dispatch(cancelSubscription(subscription.greenpaySubscriptionId, subscription.organizationId));
    };
    const onResumeSubscription = () => {
        ////console.log(form, subscription);
        const paymentFormError = cardChangeValidation(form, t);
        setCardError(paymentFormError);
        if (Object.entries(paymentFormError).length === 0) {
            ////console.log(form);
            dispatch(resumeSubscription(form, subscription, monthlyBilling, company.numberOfLicense, subscription.nextPayment && subscription.nextPayment));
        }
    };
    const onAcceptResumeSubscription = () => {
        document.querySelector('#subscription-enabling').scrollIntoView({
            behavior: 'smooth'
        });
    };
    const onChange = e => {
        const { name, value } = e.target;
        setUser({ ...user, [name]: value });
    };

    const onChangeForm = e => {
        var { name, value } = e.target;
        //////console.log(name,value,value.length,e.target.maxLength,value.substring(0,e.target.maxLength));
        if (e.target.type == "number") {

            if (e.target.maxLength && value.length >= e.target.maxLength) {
                value = value.substring(0, e.target.maxLength)
            }
        }
        setForm({ ...form, [name]: value });
    };

    const onChangePayment = e => {
        const { name, value } = e.target;
        setPayment({ ...form, [name]: value });
    };

    const onImageChange = event => {
        if (!!event.target.files[0]) {
            const file = event.target.files[0];
            let img = new Image();
            img.src = window.URL.createObjectURL(file);
            img.onload = () => {
                if (img.width <= 400 && img.height <= 200) {
                    setUser({
                        ...user,
                        image: file,
                        imageUrl: URL.createObjectURL(file)
                    });
                    setError({ ...error, image: '' });
                } else {
                    setError({
                        ...error, image: t("common:commonNoValidDimensions") + `
                    (${img.width} x ${img.height}),` + t("common:textAllowed") + ` :  (400 x 200)`
                    });
                }
            }
        }
    };

    const expandPlanAccordion = (value) => {
        setPlanChangeVisible(true);
        document.querySelector('#subscription-accordion').scrollIntoView({
            behavior: 'smooth'
        });
    };

    const expandPaymentAccordion = (value) => {
        setPaymentChangeVisible(true);
        setResumeCheckoutVisible(false);
        setCheckoutError("");
        document.querySelector('#paymentMethod-accordion').scrollIntoView({
            behavior: 'smooth'
        });
    };
    const expandResumeAccordion = (value) => {
        setResumeCheckoutVisible(true);
        setPaymentChangeVisible(false);
        setCheckoutError("");
        document.querySelector('#resumeCheckout-accordion').scrollIntoView({
            behavior: 'smooth'
        });
    };

    const status = [
        { status: t("common:commonStatusProcessing"), color: '#6C757D' },
        { status: t("common:commonStatusActive"), color: '#28A745' },
        { status: t("common:commonStatusArchived"), color: '#6C757D' },
    ];

    const colors = [
        { "band-color": "#16acea", "title-color": "#fff" },
        { "band-color": "#5ab6df", "title-color": "#fff" },
        { "band-color": "#bee3e9", "title-color": "#959393" },
        { "band-color": "#aba3d0", "title-color": "#fff" },
        { "band-color": "#8976b6", "title-color": "#fff" },
        { "band-color": "#fff", "title-color": "#fff" }
    ];



    return (
        <Container>
            <ChangePlanModal
                open={openPlanModal}
                closeModal={() => setOpenPlanModal(false)}
                handleClick={() => onSubmitPlanChange()}
                buttonMessage={t("changePlanLbl")}
                textMessage={t("changePlanConfirmationLbl")}
            />
            <ChangePaymentMethodModal
                open={openPaymentModal}
                closeModal={() => setOpenPaymentModal(false)}
                handleClick={() => onSubmitPaymentMethodChange()}
                buttonMessage={t("changePaymentMethodLbl")}
                textMessage={t("changePaymentMethodConfirmationLbl")}
            />
            <CancelPlanModal
                open={openCancelModal}
                closeModal={() => setOpenCancelModal(false)}
                handleClick={() => onSubmitCancelSubscription()}
                buttonMessage={t("cancelSubscriptionLbl")}
                textMessage={t("cancelSubscriptionConfirmationLbl")}
            />
            <SubscriptionEndWarning
                open={openSubscriptionEndWarning}
                closeModal={() => setOpenSubscriptionEndWarning(false)}
                handleClick={() => onAcceptResumeSubscription()}
                buttonMessage={t("resumeSubscriptionLbl")}
                textMessage={t("resumeSubscriptionConfirmationLbl")}
            />
            <Title title={t("title")} style={{ marginLeft: 5 }} />
            <Grid item xs={12}>
                <div className="container-app-div" style={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    flexDirection: 'column'
                }}>
                    <div>

                        {(userRedux) &&
                            <div style={{
                                width: '100%',
                                display: 'flex',
                                alignContent: 'center',
                                alignItems: 'center',

                            }}>
                                <Button component="label" style={{
                                    background: 'transparent',
                                    textTransform: 'capitalize'
                                }}>
                                    <input type="file" accept="image/*"
                                        name="image" onChange={onImageChange}
                                        style={{ display: "none" }}
                                    />
                                    <p style={{ position: 'absolute', color: "white", top: "60px", zIndex: "2", fontSize: "11px", textShadow: "2px 2px 6px #000000" }}>{t("common:commonChangePicLbl")}</p>
                                    <Avatar
                                        className='avatar-user-form' alt='Photo'
                                        src={user.imageUrl}
                                        sx={{ width: 112, height: 112 }}
                                        ref={imgRef}
                                    />
                                </Button>
                                <div style={{ marginLeft: 37 }}>
                                    <Typography className="user-name-form">
                                        {userRedux.firstName + ' ' + userRedux.lastName}
                                    </Typography>
                                    <Typography className="job-user-form" style={{ margin: '4px 0px' }}>
                                        {userRedux.position}
                                    </Typography>
                                    <p className='app-error-text'>{error.image}</p>
                                </div>
                            </div>

                        }
                        <div style={{ width: '100%', marginTop: '35px', marginBottom: '35px' }}>
                            {userRedux &&
                                <div>
                                    <InputLabel className="label-app">{t('userProfile:headerTbl6')}</InputLabel>
                                    <div className="emailInput" style={{ width: '120%' }}>
                                        <TextFieldComponent name="email"
                                            valueInput={user.email}
                                            callback={onChange}
                                            errorInput={error.email}
                                        ></TextFieldComponent>

                                    </div>

                                </div>
                            }
                        </div>
                    </div>
                    <div>
                        <Button id="save-email-btn" className="button-app-save" onClick={onSubmitUserInfoChange}>
                            {t('userProfile:saveChangeLbl')}
                        </Button>
                    </div>
                </div>
                {
                    (authUserRedux && authUserRedux.temporaryPasswordLogin) &&
                    <Alert style={{ marginTop: '35px', marginBottom: '35px' }} id="changepassreq" severity="warning" >
                        {t("userProfile:changePasswordRequired")}
                    </Alert>
                }
                <div className="container-app-div" style={{ display: "flex", alignItems: "flex-end" }}>
                    <ChangePassword>

                    </ChangePassword>
                </div>
                <div className="container-app-div">
                    <Typography className="app-form-title">{t("headerTbl4")}</Typography>
                    {(userRedux) && (company) &&
                        <TableContainer>
                            <Table aria-label="simple table" style={{ padding: '0px !important', borderTop: '1px solid #ddd' }}>
                                <TableBody>
                                    <TableRow>
                                        <TableCell component="th" scope="row" className='table-row-name' style={{ paddingLeft: 30 }}>
                                            <div className="cell-info">
                                                <span> {t("Description")} </span> <span> {t("Detail")}</span>
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                    {
                                        (userRedux.isAdmin && Object.keys(subscription).length > 0) &&
                                        (
                                            <TableRow >
                                                <TableCell component="th" scope="row" className='table-row-name' style={{ paddingLeft: 30 }}>
                                                    <div className="cell-info">
                                                        <span> {t("SubscriptionIdLbl")} </span> <span>    {subscription.greenpaySubscriptionId}</span>
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                        )

                                    }
                                    <TableRow >
                                        <TableCell component="th" scope="row" className='table-row-name' style={{ paddingLeft: 30 }}>
                                            <div className="cell-info">
                                                <span> {t("ClientLbl")} </span> <span>    {company.name}</span>
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow >
                                        <TableCell component="th" scope="row" className='table-row-name' style={{ paddingLeft: 30 }}>
                                            <div className="cell-info">
                                                <span> {t("StatusLbl")} </span> <span>  <div style={{
                                                    display: 'flex', alignItems: 'center', color: status[userRedux.status].color, margin: '0px'
                                                }}>
                                                    <CircleIcon style={{ fontSize: 7, marginRight: 7 }} />
                                                    <Typography>
                                                        {status[userRedux.status].status}
                                                    </Typography>
                                                </div></span>
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow >
                                        <TableCell component="th" scope="row" className='table-row-name' style={{ paddingLeft: 30 }}>
                                            <div className="cell-info">
                                                <span> {t("AdminLbl")} </span> <span>  {companyAdmin ?? t("NoAdminLbl")}
                                                </span>
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow >
                                        <TableCell component="th" scope="row" className='table-row-name mb1' style={{ paddingLeft: 30 }}>
                                            <div className="cell-info">
                                                <span> {t("CreatedOnLbl")} </span> <span>
                                                    <Moment  style={{ marginTop: '4px' }} locale={locale} format={localeFormat}>
                                                        {company.created}
                                                    </Moment>
                                                </span>
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    }
                </div>
                {
                    userRedux && userRedux.isAdmin &&
                    <div>
                        <Title title={t("userProfile:manageSubscription")} style={{ marginLeft: 5 }} />
                        <div className="container-app-div managePlan">
                            {Object.keys(subscription).length ?
                                <div style={{ display: 'flex', flexDirection: 'column', marginBottom: "20px" }}>
                                    <Typography className="app-form-title ">{t("SubscriptionTitle")}</Typography>

                                    {
                                        // Let paymentdate = new Date(subscription.nextPayment.length > 0 && subscription.nextPayment.split("T")[0])

                                        (accountGracePeriod) ?
                                            <Alert style={{ fontSize: "1.5em" }}>
                                                {


                                                    t("gracePeriod") + " " 

                                                }
                                                <Moment style={{ marginTop: '4px' }} locale={locale}
                                                        format={localeFormat}>
                                                        {t(paymentDate && paymentDate)}
                                                    </Moment>
                                            </Alert> :
                                            ((gracePeriodEndDate && gracePeriodEndDate.getTime()>=new Date().setHours(0, 0, 0, 0))  ?
                                                <span>
                                                    {t("currentSubscriptionTerm") + " " }
                                                    <Moment style={{ marginTop: '4px' }} locale={locale}
                                                        format={localeFormat}>
                                                        {t(paymentDate && paymentDate)}
                                                    </Moment>
                                                </span> :
                                                <Alert severity="warning" >
                                                    
                                                    {t("userProfile:expiredSubscriptionTerm") + " " }
                                                    <Moment style={{ marginTop: '4px' }} locale={locale}
                                                        format={localeFormat}>
                                                        {t(paymentDate && paymentDate)}
                                                    </Moment>
                                                </Alert>)
                                    }
                                </div> :
                                <Alert severity="error" className='change-pass-alert'> {t("userProfile:NoPermissions")}</Alert>
                            }
                            <Typography className="app-form-title">{t("LicensingTitleLbl")}</Typography>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <div className="mb1" style={{ display: 'flex', flexDirection: 'column' }}>
                                    <div className="cell-info licenses">
                                        <span> {t("LicensesLbl")} </span> <span> {company.numberOfLicense}</span>
                                    </div>
                                    <div className="cell-info licenses">
                                        <span> {t("UsersLbl")} </span> <span> {company.enabledClientsCount} </span>
                                    </div>
                                    <div className="cell-info licenses">
                                        <span> {t("LicensesLeftLbl")} </span> <span> {(company.numberOfLicense - company.enabledClientsCount) > 0 ? (company.numberOfLicense - company.enabledClientsCount) : 0 } </span>
                                    </div>


                                    <div className="cell-info licenses">
                                        <span> {t("planTypeLbl")} </span> <span> {monthlyBilling ? t("monthly") : t("annual")} </span>

                                    </div>
                                    {

                                        (!planChangeVisible) &&
                                        <div className="plan-warning">
                                            {t("licenseChangeAlertLbl")}

                                        </div>
                                    }

                                </div>
                            </div>
                            {

                                (!planChangeVisible) && (activeSubscription === "active") &&
                                <Button variant="contained" className="button-app-save"
                                    onClick={() => expandPlanAccordion(0)} style={{ marginRight: 18 }}>
                                    {t("licenseChangeLbl")}
                                </Button>
                            }
                            <Typography style={{ display: 'none' }} className="app-form-subtitle">
                                {t("changeEmailNoticeLbl")}
                            </Typography>
                            <Accordion className="accordion-sumary-row-title" id="subscription-accordion"
                                expanded={planChangeVisible} ref={inputRef}>
                                <AccordionSummary style={{ display: 'none' }} />
                                <AccordionDetails>
                                    <div className="cards-container profile">
                                        {

                                            (planChangeVisible) &&
                                            <div className="plan-warning">
                                                {t("licenseChangeAlertLbl")}

                                            </div>
                                        }
                                        <div className='reg-div cards profile' >

                                            {(mockplans) && Array.isArray(mockplans) &&
                                                mockplans.map((row, index) => (
                                                    <div className={index === currentPlan ? "reg-card-container current " : "reg-card-container " + ((row.userQuantity - company.enabledClientsCount) < 0 ? "disabled" : "")} key={index}>
                                                        <div className="reg-card">
                                                            <div className='plan-title' style={{ backgroundColor: colors[index]["band-color"] }}>
                                                                <div className="plan-label-app" style={{ fontSize: 'inherit', color: colors[index]["title-color"] }}>{row.name}</div>
                                                            </div>
                                                            <div className="plan-prices">
                                                                <div className='perUserCost'>${row.perUserCost}<span>/user</span></div>
                                                                <div className='maxUsers'>up to {row.userQuantity} users </div>
                                                                <div> Unlimited mobile users </div>
                                                                {
                                                                    index !== 4
                                                                        ? <div className='totalMonthly'>
                                                                            Total {monthlyBilling
                                                                                ? "Monthly"
                                                                                : "Annual"}
                                                                            : ${monthlyBilling
                                                                                ? row.monthlyCost && row.monthlyCost.toLocaleString("en-us")
                                                                                : row.yearlyCost && row.yearlyCost.toLocaleString("en-us")}
                                                                        </div>
                                                                        : <div className='totalMonthly'> Contact Us </div>
                                                                }
                                                            </div>
                                                        </div>
                                                        {
                                                            ((row.userQuantity - company.enabledClientsCount) >= 0 && index !== currentPlan && (row.name && row.name.toLowerCase() !== "enterprise") ) &&
                                                            <Button variant="contained" value={index} className="button-app-save" onClick={() => onClickPlanSwitch(row.id)} >
                                                                <span>{t("switchPlanLbl")}  </span>
                                                            </Button>
                                                        }
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>
                                </AccordionDetails>
                            </Accordion>
                            
                            {   
                                    (planAlert && !planChangeVisible) &&
                                <Alert severity="info" className='change-pass-alert'> {t("userProfile:successPlanChange")}</Alert>
                            }
                          
                        </div>

                        {
                            activeSubscription === "active" && (subscription.greenpaySubscriptionId) &&
                            <div className="container-app-div paymentMethod">
                                <Typography className="app-form-subtitle">{t("paymentMethodLbl")} </Typography>
                                <span className="paymentMethod" style={{ marginLeft: '25px' }}>
                                    {cardBrand + " ending in " + card}
                                </span>
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'flex-start',
                                    width: '100%',
                                    justifyContent: 'center',
                                    alignContent: 'flex-start',
                                    marginTop: '20px'
                                }}>
                                    {!paymentChangeVisible &&
                                        <Button variant="contained" className="button-app-save"
                                            style={{ marginRight: 18 }} onClick={() => expandPaymentAccordion(0)}>
                                            {t("changePaymentMethodLbl")}
                                        </Button>
                                    }
                                    <Accordion className="accordion-sumary-row-title" id="paymentMethod-accordion"
                                        expanded={paymentChangeVisible} ref={inputRef} >
                                        <AccordionSummary style={{ display: 'none' }} />
                                        <AccordionDetails>
                                            <div style={{ width: 350 }}>
                                                <PaymentForm
                                                    form={form}
                                                    error={cardError}
                                                    onChangeForm={onChangeForm}

                                                />
                                                {checkoutError &&
                                                    <Alert severity="error" className='change-pass-alert'>
                                                        {t(checkoutError)}
                                                    </Alert>
                                                }

                                                <Button variant="contained" className="button-app-save"
                                                    style={{ marginTop: 20 }}
                                                    onClick={() => setOpenPaymentModal(true)}>
                                                    {t("confirmPaymentMethodChange")}
                                                </Button>
                                            </div>
                                        </AccordionDetails>
                                    </Accordion>
                                </div>
                            </div>

                        }
                        <div className="print-only" id="invoice" style={{ paddingBottom: "50px" }} ref={componentRef}>

                            <div className="payment-header" >
                                <img src={Logo} alt="Logo" />
                                <span style={{ float: 'right' }}>fos.services</span>
                            </div>
                            {(company && company.countryNavigation) &&
                                <div className="payment-title ">
                                    <div className="title-wrap">
                                        <span className="invoice-title-text" title={t("InvoiceTitle")} style={{ color: "#17ACEA", fontSize: "2em", fontWeight: '500', opacity: 0.75 }}>{t("InvoiceTitle")}</span>

                                    </div>
                                    <p className="basetext">Front Office Services, Inc. </p>
                                    <div className="basetext">{t("BillTo")} :</div>
                                    <div className="basetext" >{company.name}</div>
                                    <div className="basetext">{companyAdmin && companyAdmin}</div>
                                    <div className="basetext">{t("pdfCountry") + " : " + (countryAlfa && countryAlfa.toUpperCase())}</div>
                                </div>

                            }
                            <div className="payment-content">



                                <div className="title-wrap sub" >
                                    <Title className="app-form-title" title={t("InvoiceTitle2")} style={{ opacity: 0.75 }} />

                                </div>
                                <div className="payment-details">
                                    <div style={{
                                        display: 'flex',
                                        justifyContent: 'flex-start',
                                        alignItems: 'center'
                                    }}>
                                        <div className="basetext" >{t("InvoiceTotalLabel")} : </div>
                                        <div className="basetext" style={{ marginLeft: '6%' }} > US$ {invoiceRowTotal && invoiceRowTotal.toFixed(2)} </div>

                                    </div>
                                    <div style={{
                                        display: 'flex',
                                        justifyContent: 'flex-start',
                                        alignItems: 'center'
                                    }}>
                                        <div className="basetext">{t("InvoiceDateLabel")} : </div>
                                        <div className="basetext" style={{ marginLeft: '6%' }} >{invoiceDate} </div>

                                    </div>
                                </div>


                            </div>

                            <div className="payment-footer">
                                <span style={{ marginLeft: "0px" }}>{t("InvoiceFooter")}</span>


                            </div>
                        </div>
                        {
                            (payments && Object.keys(subscription).length) ?
                                <div className="container-app-div paymentHistory" >

                                    <Typography className="app-form-title">{t("paymentHistoryLbl")} </Typography>
                                    <div style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        flexWrap: 'wrap',
                                        marginBottom: '15px'
                                    }} ref={allComponentRef}>

                                        <div className="historyRow headers" >
                                            <div className="historyColumn">{t("paymentDate")} </div>
                                            <div className="historyColumn">{t("paymentStatus")} </div>
                                            <div className="historyColumn">{t("paymentTotal")} </div>
                                            <div className="historyColumn">{t("paymentDescription")} </div>
                                            <div className="historyColumn">{t("paymentRef")} </div>
                                            <div className="historyColumn" style={{
                                                width: '64px',
                                                padding: '0 15px 0 0'
                                            }}>{t("printLbl")} </div>

                                        </div>
                                        {(payments) && Array.isArray(payments) &&
                                            payments.map((row, index) => (

                                                <div className="historyRow" data-index={index} >
                                                    <div className="historyColumn">  <Moment style={{ marginTop: '4px' }} locale={locale}
                                            format={localeFormat}>
                                            {new Date(row.dateOfCharge).toLocaleDateString("en-us")}
                                        </Moment> </div>
                                                    <div className="historyColumn">{t(row.result)}</div>
                                                    <div className="historyColumn">{row.chargeAmount}</div>
                                                    <div className="historyColumn">{row.cardLast4Digits}</div>
                                                    <div className="historyColumn">{row.transactionReferenceNumber}</div>
                                                    <Button className="button-print-download" variant="text" startIcon={<Printer />}
                                                        style={{ marginRight: 12, float: 'right' }} onClick={() => print(row.chargeAmount, new Date(row.dateOfCharge).toLocaleDateString("en-us"))}>

                                                    </Button>
                                                </div>
                                            ))
                                        }
                                    </div>

                                    <PaginationCustom pages={pages} activePage={activePage}
                                        onChange={page => dispatch(changePaymentPage(page))} />


                                    <div style={{ display: 'flex', marginTop: 19 }} id="container-print-download">

                                    </div>
                                </div>
                                :
                                <div className="container-app-div paymentMethod">
                                    <Typography className="app-form-subtitle">{t("paymentHistoryLbl")} </Typography>
                                    <Alert severity="error" className='change-pass-alert'> {t("NoSubscription")}</Alert>

                                </div>
                        }
                        {
                            Object.keys(subscription).length > 0 &&
                            <div className="container-app-div" id="subscription-enabling" style={{ paddingBottom: "50px" }}>
                                {accountGracePeriod &&
                                    <Alert style={{ fontSize: "1.5em" }}>
                                    {


                                        t("gracePeriod") + " " 

                                    }
                                    <Moment style={{ marginTop: '4px' }} locale={locale}
                                            format={localeFormat}>
                                            {t(paymentDate && paymentDate)}
                                        </Moment>
                                </Alert>}
                                <Typography className="app-form-title" style={{ marginTop: "50px" }}>
                                    {activeSubscription === "active"
                                        ? t("cancelSubscriptionLbl")
                                        : t("reactivateSubscriptionLbl")
                                    }
                                </Typography>
                                {!resumeCheckoutVisible &&
                                    <div style={{ display: 'flex' }}>
                                        <Button variant="contained" onClick={activeSubscription === "active" ? () => setOpenCancelModal(true) : () => expandResumeAccordion(0)}
                                            style={{ marginTop: activeSubscription === "active" ? 0 : 20, marginBotton: 50 }}
                                            className={activeSubscription === "active" ? "button-app-delete" : "button-app-save"}>
                                            {activeSubscription === "active"
                                                ? t("cancelSubscriptionBtn")
                                                : t("reactivateSubscriptionBtn")
                                            }
                                        </Button>
                                    </div>
                                }
                                <Accordion className="accordion-sumary-row-title" id="resumeCheckout-accordion" style={{ boxShadow: 'none !important' }}
                                    expanded={resumeCheckoutVisible} ref={inputRef} >
                                    <AccordionSummary style={{ display: 'none' }} />
                                    <AccordionDetails>
                                        <div style={{ width: 350 }}>
                                            {activeSubscription !== "active" &&
                                                <div style={{ width: 350 }}>
                                                    <PaymentForm
                                                        form={form}
                                                        error={cardError}
                                                        onChangeForm={onChangeForm}
                                                    />
                                                    {checkoutError &&
                                                        <Alert severity="error" className='change-pass-alert'>
                                                            {t(checkoutError)}
                                                        </Alert>
                                                    }
                                                </div>
                                            }

                                            <div style={{ display: 'flex' }}>
                                                <Button variant="contained" onClick={activeSubscription === "active" ? () => setOpenCancelModal(true) : onResumeSubscription}
                                                    style={{ marginTop: activeSubscription === "active" ? 0 : 20, marginBotton: 50 }}
                                                    className={activeSubscription === "active" ? "button-app-delete" : "button-app-save"}>
                                                    {activeSubscription === "active"
                                                        ? t("cancelSubscriptionBtn")
                                                        : t("reactivateSubscriptionBtn")
                                                    }
                                                </Button>
                                            </div>
                                        </div>
                                    </AccordionDetails>
                                </Accordion>


                            </div>

                        }

                    </div>

                }

            </Grid>
        </Container >
    );
}

export default withRouter(Read);