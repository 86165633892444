import React, { useEffect, createRef } from 'react';
import { useState } from 'react';
import {
    Grid, Typography, InputLabel,
    Button, Container, CardMedia, Snackbar
} from "@mui/material";
import IconSvg from '../../components/Icon';
import TextFieldComponent from '../../components/TextField';
import SelectFieldComponent from '../../components/SelectField';
import Title from '../../components/Title';
import { ReactComponent as FB } from '../../assets/Icons/FB.svg';
import { ReactComponent as IG } from '../../assets/Icons/IG.svg';
import { ReactComponent as LN } from '../../assets/Icons/LN.svg';
import { ReactComponent as TW } from '../../assets/Icons/TW.svg';
import { ReactComponent as WA } from '../../assets/Icons/WA.svg';
import { useDispatch, useSelector } from 'react-redux';
import { saveSystemConfiguration, getOrgSystemById } from '../../redux/SystemConfigurationDuck';
import './SystemConfiguration.css';
import { getLenguages } from '../../redux/LenguageDuck';
import { getCountries } from '../../redux/CountryDuck';
import { validateSystem } from './SystemValidation';
import { getFileURL } from '../../constants/apiUrls';
import { useTranslation } from 'react-i18next';
import { saveAppSetting } from '../../redux/AppConfigurationDuck';

const SystemConfiguration = () => {

    const { t } = useTranslation(['config', 'button', 'label', 'common', 'systemValidations', 'validations', 'file']);
    const system = useSelector(store => store.system.system);
    const appSetting = useSelector(store => store.appSetting.appSetting);
    const systemUpdated = useSelector(store => store.system.systemUpdated);
    const lenguageId = useSelector(store => store.auth.lenguageId);
    const lenguages = useSelector(store => store.lenguage.lenguages);
    const countries = useSelector(store => store.country.countries);
    const countriesName = useSelector(store => store.country.countriesName);

    const [configuration, setConfiguration] = useState({
        languageId: '', name: '', webSite: '',
        country: '', countryCode: '', phoneNumber: '',
        email: '', image: '', imageBase64: '', imageUrl: '',
        facebookUrl: '', instagramUrl: '', linkedinUrl: '',
        twitterUrl: '', whatsAppCountryCode: 'null', whatsApp: ''
    });
    const [configurationError, setConfigurationError] = useState({});
    const [open, setOpen] = useState(false);
    const imgRef = createRef();
    const dispatch = useDispatch();

    const onChange = e => {
        const { name, value } = e.target;
        if (name === "languageId" && String(value) !== String(system.defaultLanguageId)) {
            if (appSetting && configuration) {

                setOpen(true);
            }
        } else {
            setOpen(false);
        }
        setConfiguration({ ...configuration, [name]: value });
    };

    const onSubmit = () => {
        const systemError = validateSystem(configuration, imgRef, t);
        setConfigurationError(systemError);
        ////console.log(appSetting,appSetting.secundaryLanguageId);
        if (Object.entries(systemError).length === 0) {
            if (appSetting && appSetting.secundaryLanguageId && configuration.languageId) {
                if ((String(appSetting.secundaryLanguageId) === String(configuration.languageId))) {

                    dispatch(saveAppSetting({
                        id: appSetting.id,
                        name: appSetting.name,
                        languageId: system.defaultLanguageId,
                        imageUrl: getFileURL + appSetting.fileId,
                    }));
                }
            }
            dispatch(saveSystemConfiguration(configuration));
        } else {
            document.querySelector('#principal').scrollIntoView({
                behavior: 'smooth'
            });
        }
    };

    const onImageChange = event => {
        if (!!event.target.files[0]) {
            const { name } = event.target;
            const file = event.target.files[0];

            let uploaded_img = new Image();
            uploaded_img.src = window.URL.createObjectURL(file);
            if (['jpeg', 'jpg', 'png'].includes(file.type.split('/')[1])) {

                uploaded_img.onload = () => {
                    let imgW = uploaded_img.width;
                    let imgH = uploaded_img.height;
                    if (imgW === 1024 && imgH === 1024) {
                        setConfiguration({
                            ...configuration,
                            [name]: file,
                            imageUrl: URL.createObjectURL(file)
                        });

                        setConfigurationError({ ...configurationError, image: '' });
                    } else {
                        setConfigurationError({ ...configurationError, image: `${t("validations:errorDimensions")} (` + imgW + " x " + imgH + ")" });
                    }
                }
            } else {
                setConfigurationError({ ...configurationError, image: `${t('file:subtitle5a')}${file.name}${t('file:subtitle5b')}` });
            }

        }
    };

    useEffect(() => {
        dispatch(getLenguages());
        dispatch(getOrgSystemById());
    }, [dispatch]);

    useEffect(() => {
        dispatch(getLenguages());
        if (lenguageId)
            dispatch(getCountries());
    }, [lenguageId, dispatch]);

    const cancel = () => {
        if (system) {
            document.querySelector('#principal').scrollIntoView({
                behavior: 'smooth'
            });
            //dispatch(showMessage());
            setConfiguration({
                languageId: system.defaultLanguageId,
                name: system.name,
                webSite: system.webSite,
                countryCode: system.countryCodeId,
                country: system.country ? system.country : '',
                phoneNumber: system.phone,
                email: system.email,
                image: '',
                imageUrl: system.fileId ? getFileURL + system.fileId : '',
                facebookUrl: system.socialMediaOrganization ? system.socialMediaOrganization.facebook : '',
                instagramUrl: system.socialMediaOrganization ? system.socialMediaOrganization.instagram : '',
                linkedinUrl: system.socialMediaOrganization ? system.socialMediaOrganization.linkedin : '',
                twitterUrl: system.socialMediaOrganization ? system.socialMediaOrganization.twitter : '',
                whatsAppCountryCode: system.socialMediaOrganization ? (system.socialMediaOrganization.countryCodeId ? system.socialMediaOrganization.countryCodeId : 'null') : 'null',
                whatsApp: system.socialMediaOrganization ? system.socialMediaOrganization.whatsApp : ''
            });
        }
    }

    useEffect(() => {
        if (system) {
            setConfiguration({
                languageId: system.defaultLanguageId,
                name: system.name,
                webSite: system.webSite,
                countryCode: system.countryCodeId,
                country: system.country ? system.country : '',
                phoneNumber: system.phone,
                email: system.email,
                image: '',
                imageUrl: system.fileId ? getFileURL + system.fileId : '',
                facebookUrl: system.socialMediaOrganization ? system.socialMediaOrganization.facebook : '',
                instagramUrl: system.socialMediaOrganization ? system.socialMediaOrganization.instagram : '',
                linkedinUrl: system.socialMediaOrganization ? system.socialMediaOrganization.linkedin : '',
                twitterUrl: system.socialMediaOrganization ? system.socialMediaOrganization.twitter : '',
                whatsAppCountryCode: system.socialMediaOrganization ? (system.socialMediaOrganization.countryCodeId ? system.socialMediaOrganization.countryCodeId : 'null') : 'null',
                whatsApp: system.socialMediaOrganization ? system.socialMediaOrganization.whatsApp : ''
            });
        }
    }, [system]);

    useEffect(() => {
        systemUpdated && window.location.reload(true);
    }, [systemUpdated]);

    const handleClose = () => setOpen(false);

    return countries.length > 0 && (
        <Container>
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={open}
                autoHideDuration={10000}
                onClose={handleClose}
                message={t("alertLngChange")}
            />
            <Title title={t('config:title')} style={{ marginLeft: 26 }} />
            <Grid item xs={12}>
                <div className="container-app-div">
                    <Typography className="app-form-title">{t('config:subtitle1')}</Typography>
                    <Typography className="app-form-subtitle">
                        {t('config:subtitle2')}
                    </Typography>
                    <div style={{ display: 'flex' }}>
                        {lenguages.map(item => (
                            <label className="language-form" key={item.key}>
                                {item.value}
                                <input type="radio" name="languageId" value={item.key} onChange={onChange}
                                    checked={item.key === parseInt(configuration.languageId)} />
                                <span className="checkmark"></span>
                            </label>
                        ))}
                    </div>

                    <p className='app-error-text'>{configurationError.languageId}</p>
                </div>
                <div className="container-app-div">
                    <Typography className="app-form-title">{t('config:subtitle3')}</Typography>
                    <Typography className="app-form-subtitle">
                        {t('config:subtitle4')}
                    </Typography>
                    <div style={{ display: 'flex', flexDirection: 'column', width: '65%', marginTop: 17 }}>
                        <InputLabel className="label-app">{t('config:labelOrgName')}</InputLabel>
                        <TextFieldComponent name="name"
                            maxLength={25}
                            valueInput={configuration.name}
                            callback={onChange.bind(this)}
                            errorInput={configurationError.name}
                        />
                        <InputLabel className="label-app">{t('label:webSiteLbl')}</InputLabel>
                        <TextFieldComponent name="webSite"
                            placeholder='https://www.fos.com'
                            valueInput={configuration.webSite}
                            callback={onChange.bind(this)}
                            errorInput={configurationError.webSite}
                        />
                        <InputLabel className="label-app">{t('config:countyNameLbl')}</InputLabel>
                        <SelectFieldComponent name="country"
                            valueInput={configuration.country}
                            onChange={onChange.bind(this)}
                            options={countriesName}
                            errorInput={configurationError.country}
                        />
                        <br />
                        <div style={{ display: 'flex', width: '100%', marginBottom: '2%' }}>
                            <div style={{ width: '30%', marginRight: '4%' }}>
                                <InputLabel className="label-app">{t('label:countyCodeLbl')}</InputLabel>
                                <SelectFieldComponent name="countryCode"
                                    valueInput={configuration.countryCode}
                                    onChange={onChange.bind(this)}
                                    options={countries}
                                    errorInput={configurationError.countryCode}
                                />
                            </div>
                            <div style={{ width: '70%' }}>
                                <InputLabel className="label-app">{t('label:phoneNumberLbl')}:</InputLabel>
                                <TextFieldComponent name="phoneNumber"
                                    maxLength={30}
                                    placeholder='88888888'
                                    valueInput={configuration.phoneNumber}
                                    callback={onChange.bind(this)}
                                    errorInput={configurationError.phoneNumber}
                                />
                            </div>
                        </div>
                        <InputLabel className="label-app">{t('label:emailLbl')}</InputLabel>
                        <TextFieldComponent name="email" type="email"
                            valueInput={configuration.email}
                            callback={onChange.bind(this)}
                            errorInput={configurationError.email}
                        />
                    </div>
                </div>
                <div className="container-app-div">
                    <Typography className="app-form-title">{t('config:subtitle5')}</Typography>
                    <div className='image-confi-div'>
                        {!!configuration.imageUrl &&
                            <>
                                <CardMedia component="img"
                                    image={configuration.imageUrl}
                                    className="card-image-confi"
                                    ref={imgRef}
                                />
                                <br />
                            </>
                        }
                        <div style={{ justifyContent: 'center', display: 'flex', flexDirection: 'column' }}>
                            <Button component="label" className='button-add-image-confi'>
                                <input type="file" accept="image/*"
                                    name="image" onChange={onImageChange}
                                    style={{ display: "none" }}
                                />
                                {t('button:addImageBtn')}
                            </Button>
                            <Typography className="image-description-confi">
                                {t('label:expectedDimensionLbl')} 1024px x 1024px
                            </Typography>
                        </div>
                    </div>
                    <p className='app-error-text'>{configurationError.image}</p>
                </div>
                <div className="container-app-div">
                    <Typography className="app-form-title">{t('config:subtitle6')}</Typography>
                    <div style={{ display: 'flex', alignItems: 'flex-end', marginBottom: 5 }}>
                        <IconSvg Icon={FB} className='social-network-icon' />
                        <div style={{ width: '60%' }}>
                            <InputLabel className="label-app">Facebook</InputLabel>
                            <TextFieldComponent name="facebookUrl"
                                placeholder='https://www.fos.com'
                                valueInput={configuration.facebookUrl}
                                callback={onChange.bind(this)}
                                errorInput={configurationError.facebookUrl}
                            />
                        </div>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'flex-end', marginBottom: 5 }}>
                        <IconSvg Icon={IG} className='social-network-icon' />
                        <div style={{ width: '60%' }}>
                            <InputLabel className="label-app">Instagram</InputLabel>
                            <TextFieldComponent name="instagramUrl"
                                placeholder='https://www.fos.com'
                                valueInput={configuration.instagramUrl}
                                callback={onChange.bind(this)}
                                errorInput={configurationError.instagramUrl}
                            />
                        </div>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'flex-end', marginBottom: 5 }}>
                        <IconSvg Icon={LN} className='social-network-icon' />
                        <div style={{ width: '60%' }}>
                            <InputLabel className="label-app">Linkedin</InputLabel>
                            <TextFieldComponent name="linkedinUrl"
                                placeholder='https://www.fos.com'
                                valueInput={configuration.linkedinUrl}
                                callback={onChange.bind(this)}
                                errorInput={configurationError.linkedinUrl}
                            />
                        </div>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'flex-end', marginBottom: 5 }}>
                        <IconSvg Icon={TW} className='social-network-icon' />
                        <div style={{ width: '60%' }}>
                            <InputLabel className="label-app">Twitter</InputLabel>
                            <TextFieldComponent name="twitterUrl"
                                placeholder='https://www.fos.com'
                                valueInput={configuration.twitterUrl}
                                callback={onChange.bind(this)}
                                errorInput={configurationError.twitterUrl}
                            />
                        </div>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'flex-end', marginBottom: 5 }}>
                        <IconSvg Icon={WA} className='social-network-icon' />
                        <div style={{ display: 'flex', width: '60%' }}>
                            <div style={{ width: '30%', marginRight: '4%' }}>
                                <InputLabel className="label-app">{t('label:countyCodeLbl')}</InputLabel>
                                <SelectFieldComponent name="whatsAppCountryCode"
                                    valueInput={configuration.whatsAppCountryCode}
                                    onChange={onChange.bind(this)}
                                    errorInput={configurationError.whatsAppCountryCode}
                                    options={[
                                        { key: 'null', value: t("common:commonSelectLbl") },
                                        ...countries
                                    ]}
                                />
                            </div>
                            <div style={{ width: '70%' }}>
                                <InputLabel className="label-app">WhatsApp</InputLabel>
                                <TextFieldComponent name="whatsApp"
                                    maxLength={30}
                                    placeholder='88888888'
                                    valueInput={configuration.whatsApp}
                                    callback={onChange.bind(this)}
                                    errorInput={configurationError.whatsApp}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ display: 'flex' }}>
                    <Button variant="contained" className="button-app-save"
                        style={{ marginRight: 18 }} onClick={() => onSubmit()}>
                        {t('button:saveBtn')}
                    </Button>
                    <Button variant="contained" className="button-app-cancel"
                        onClick={() => cancel()}>
                        {t('button:cancelBtn')}
                    </Button>
                </div>
            </Grid>
        </Container>
    )
};

export default SystemConfiguration;