import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { Typography, InputLabel, Button } from '@mui/material';
import Security from '../../components/Security';
import TextFieldComponent from '../../components/TextField';
import Logo from '../../assets/Images/ResetPassword.png';
import { resetPasswordAction } from '../../redux/AuthDuck';
import { useDispatch, useSelector } from 'react-redux';
import * as ROUTES from '../../constants/routes';
import './ResetPassword.css';
import { useTranslation } from 'react-i18next';
const queryString = require('query-string');

const ResetPassword = props => {
    const { t } = useTranslation(['resetPassword', 'common', 'auth']);
    const resetPassword = useSelector(store => store.auth.resetPassword);
    const error = useSelector(store => store.auth.error);
    const [password, setPassword] = useState({
        password: '', confirmPassword: '', token: '', email: '',
        mobile: false
    });
    const [passwordError, setPasswordError] = useState({});
    const dispatch = useDispatch();

    const onChange = e => {
        const { name, value } = e.target;
        setPassword({ ...password, [name]: value });
    };

    const onSubmit = () => {
        if (validationPassword()) {
            dispatch(resetPasswordAction(password));
        }
    };

    const validationPassword = () => {
        const passwordLength = 8;
        var error = { password: '', confirmPassword: '' };

        if (password.password === null || password.password.length === 0
            || /^\s+$/.test(password.password))
            error.password = t("errorPassRequired");
        else if (password.password.length < passwordLength ||
            !/[^a-zA-Z0-9]/.test(password.password))
            error.password = t("errorPassFormat");
        if (password.confirmPassword === null || password.confirmPassword.length === 0
            || /^\s+$/.test(password.confirmPassword))
            error.confirmPassword = t("errorPassRequired");
        else if (password.confirmPassword.length < passwordLength ||
            !/[^a-zA-Z0-9]/.test(password.confirmPassword))
            error.confirmPassword = t("errorPassFormat");
        if ((!error.password && !error.confirmPassword) &&
            password.password !== password.confirmPassword)
            error.confirmPassword = t("errorPassMismatch");

        setPasswordError({ ...passwordError, ...error });
        return !error.password && !error.confirmPassword;
    };

    useEffect(() => {
        const parsed = queryString.parse(props.location.search);
        if (parsed.email && parsed.token)
            setPassword({
                ...password,
                token: parsed.token.replaceAll(' ', '+'),
                email: parsed.email,
                mobile: parsed.mobile === 'true'
            });
        // eslint-disable-next-line
    }, [props.location.search]);

    useEffect(() => {
        if (resetPassword) {
            !password.mobile
                ? props.history.push(ROUTES.LOGIN)
                : setPassword({
                    ...password, password: '', confirmPassword: '',
                });
        }
    }, [resetPassword, props.history, password]);

    useEffect(() => {
        setPasswordError({ confirmPassword: t(error) });
    }, [error, t]);

    return (
        <Security className="logo-wide" logo={Logo}>
            <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                <form>
                  <div className="logo-small">
                 <img  src={Logo} alt="fos-login-logo"></img>
                    </div>  
                    <Typography className="title-app" style={{ marginBottom: '5%' }}>
                        {t("title")}
                    </Typography>
                    <Typography className="label-reset-password" style={{ marginBottom: '5%' }}>
                        {t("subtitle")}

                    </Typography>
                    <InputLabel className="label-app">{t("newPass")}</InputLabel>
                    <TextFieldComponent name="password"
                        valueInput={password.password} type="password"
                        callback={onChange.bind(this)}
                        errorInput={passwordError.password}
                        activeError={false}
                    />
                    <InputLabel className="label-app">{t("confirmPass")}</InputLabel>
                    <TextFieldComponent name="confirmPassword"
                        valueInput={password.confirmPassword} type="password"
                        callback={onChange.bind(this)}
                        errorInput={passwordError.confirmPassword}
                        activeError={false}
                    />
                    <Button variant="contained" className="button-reset-password"
                        disableElevation onClick={() => onSubmit()}>
                        {t("common:commonSubmitBtn")}
                    </Button>
                </form>
            </div>
        </Security>
    );
}

export default withRouter(ResetPassword);