import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import {
    MenuList, MenuItem, InputLabel
} from '@mui/material';
import { ReactComponent as SidebarIcon } from '../../assets/Icons/sidebar-arrow.svg';
import IconSvg from '../Icon';
import * as ROUTES from '../../constants/routes';
import { useTranslation } from 'react-i18next';
import './HelpSidebar.css';
import { useSelector } from 'react-redux';

const HelpSidebar = props => {
    const { location: { pathname } } = props;
    const { t } = useTranslation(['sidebar']);
    const permission = useSelector(store => store.auth.permission);

    const sidebarListOne = [
        {
            name: t('sidebar:configuration'),
            selected: pathname.includes(ROUTES.HELP_SYSTEM_CONFIGURATION),
            to: ROUTES.HELP_SYSTEM_CONFIGURATION,
            permission: 'Permissions.Configuration'
        },
        {
            name: t('sidebar:configurationApp'),
            selected: pathname.includes(ROUTES.HELP_APP_CONFIGURATION),
            to: ROUTES.HELP_APP_CONFIGURATION,
            permission: 'Permissions.AppConfiguration'
        },
        {
            name: t('sidebar:business'),
            selected: pathname.includes(ROUTES.HELP_BUSINESS),
            to: ROUTES.HELP_BUSINESS,
            permission: 'Permissions.Companies'
        },
        {
            name: t('sidebar:mobileContent'),
            selected: pathname.includes(ROUTES.HELP_MOBILE_CONTENT),
            to: ROUTES.HELP_MOBILE_CONTENT,
            permission: 'Permissions.Mobile'
        }
    ];

    const sidebarListTwo = [
        {
            name: t('sidebar:user'),
            selected: pathname.includes(ROUTES.HELP_USERS),
            to: ROUTES.HELP_USERS,
            permission: 'Permissions.Users'
        },
        {
            name: t('sidebar:permission'),
            selected: pathname.includes(ROUTES.HELP_PERMITS),
            to: ROUTES.HELP_PERMITS,
            permission: 'Permissions.Permissions'
        },
        {
            name: t('sidebar:userAssigment'),
            selected: pathname.includes(ROUTES.HELP_USER_ASSIGNMENT),
            to: ROUTES.HELP_USER_ASSIGNMENT,
            permission: 'Permissions.AssignUsers'
        },
        
    ];

    const sidebarListThree = [
        {
            name: t('sidebar:log'),
            selected: pathname.includes(ROUTES.HELP_LOGS),
            to: ROUTES.HELP_LOGS,
            permission: 'Permissions.Logbook'
        },{
            name: t('sidebar:client'),
            selected: pathname.includes(ROUTES.HELP_CLIENTS),
            to: ROUTES.HELP_CLIENTS,
            permission: 'Permissions.Clients'
        },
        {
            name: t('sidebar:clientStaff'),
            selected: pathname.includes(ROUTES.HELP_CUSTOMER_STAFF),
            to: ROUTES.HELP_CUSTOMER_STAFF,
            permission: 'Permissions.ClientStaff'
        },
        {
            name: t('sidebar:matter'),
            selected: pathname.includes(ROUTES.HELP_MATTERS),
            to: ROUTES.HELP_MATTERS,
            permission: 'Permissions.Subject'
        },
        
    ];
    const sidebarListFour = [
        {
            name: t('sidebar:notification'),
            selected: pathname.includes(ROUTES.HELP_NOTIFICATIONS),
            to: ROUTES.HELP_NOTIFICATIONS,
            permission: 'Permissions.Notifications'
        },
        {
            name: t('sidebar:authorization'),
            selected: pathname.includes(ROUTES.HELP_AUTHORIZATIONS),
            to: ROUTES.HELP_AUTHORIZATIONS,
            permission: 'Permissions.Authorizations'
        },
        {
            name: t('sidebar:document'),
            selected: pathname.includes(ROUTES.HELP_DOCUMENTS),
            to: ROUTES.HELP_DOCUMENTS,
            permission: 'Permissions.Documents'
        }
    ]
    const drawer = (
        <MenuList className="menu-list-help-sidebar">
            <InputLabel className="label-title-help-sidebar">{t('sidebar:helpTitle')}</InputLabel>
            <div className='divider-help-sidebar' />
            <InputLabel className="label-subtitle-help-sidebar">{t('sidebar:firstSection')}</InputLabel>
            {sidebarListOne.map((item) => (
                permission &&
                permission.permissions.find(per => per.permissionName === item.permission) &&
                <MenuItem className="item-help-sidebar"
                    key={item.name}
                    component={Link} to={item.to}
                    selected={item.selected}
                >
                    <IconSvg Icon={SidebarIcon} className="item-help-sidebar-icon-svg item-help-sidebar-icon" />
                    {item.name}
                </MenuItem>
            ))}
            <div className='divider-help-sidebar' />
            <InputLabel className="label-subtitle-help-sidebar">{t('sidebar:secondSection')}</InputLabel>
            {sidebarListTwo.map((item) => (
                permission &&
                permission.permissions.find(per => per.permissionName === item.permission) &&
                <MenuItem className="item-help-sidebar"
                    key={item.name}
                    component={Link} to={item.to}
                    selected={item.selected}
                >
                    <IconSvg Icon={SidebarIcon} className="item-help-sidebar-icon-svg item-help-sidebar-icon" />
                    {item.name}
                </MenuItem>
            ))}
            <div className='divider-help-sidebar' />
            <InputLabel className="label-subtitle-help-sidebar">{t('sidebar:thirdSection')}</InputLabel>
            {sidebarListThree.map((item) => (
                permission &&
                permission.permissions.find(per => per.permissionName === item.permission) &&
                <MenuItem className="item-help-sidebar"
                    key={item.name}
                    component={Link} to={item.to}
                    selected={item.selected}
                >
                    <IconSvg Icon={SidebarIcon} className="item-help-sidebar-icon-svg item-help-sidebar-icon" />
                    {item.name}
                </MenuItem>
            ))}
            <div className='divider-help-sidebar' />
            <InputLabel className="label-subtitle-help-sidebar">{t('sidebar:fourthSection')}</InputLabel>
            {sidebarListFour.map((item) => (
                permission &&
                permission.permissions.find(per => per.permissionName === item.permission) &&
                <MenuItem className="item-help-sidebar"
                    key={item.name}
                    component={Link} to={item.to}
                    selected={item.selected}
                >
                    <IconSvg Icon={SidebarIcon} className="item-help-sidebar-icon-svg item-help-sidebar-icon" />
                    {item.name}
                </MenuItem>
            ))}
        </MenuList>
    );

    return (drawer)
};

export default withRouter(HelpSidebar);