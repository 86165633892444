import React, { useEffect } from "react";
import Form from "../Form"
import { useSelector, useDispatch } from "react-redux";
import { changeIsCreating } from '../../../redux/SubjectDuck';
import './Create.css';

const CreateMatters = () => {
    const isCreating = useSelector(store => store.subject.isCreating);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(changeIsCreating(true));
    }, [dispatch]);

    return isCreating && (
        <Form />
    );
};

export default (CreateMatters);