import React from "react";
import { withRouter } from "react-router";
import { useSelector } from "react-redux";
import { Link } from 'react-router-dom';
import {
    Typography, Button, TableContainer, TableRow,
    TableCell, Table, TableHead, TableBody
} from "@mui/material";
import CircleIcon from '@mui/icons-material/Circle';
import * as ROUTES from '../../../constants/routes';
import './TableDetail.css';
import { useTranslation } from "react-i18next";
import Moment from "react-moment";

const TableDetails = ({ tableHeaders, typeOfTable = "", clients = [] }) => {
    const { t } = useTranslation(['customer']);
    const users = useSelector(store => store.user.users);
    const assignedClientStaff = useSelector(store => store.assign.assignedClientStaff);
    const subjects = useSelector(store => store.subject.subjects);

    const getBusinessesName = (offices) => {
        const list = [];
        offices.forEach(item => {
            const company = item.office.company ? item.office.company.name : "";
            (company && !list.includes(company)) && list.push(company);
        });
        return list.toString().replace(',', ', ');
    };

    // const getUsersName = (users) => {
    //     const list = [];
    //     users.forEach(item => {
    //         !list.includes(item.user.user.firstName + ' ' + item.user.user.lastName)
    //             && list.push(item.user.user.firstName + ' ' + item.user.user.lastName);
    //     });
    //     return list.toString().replace(',', ', ');
    // };

    return (
        <TableContainer>
            <Table aria-label="simple table">
                <TableHead>
                    {typeOfTable === "Principal" ?
                        <TableRow>
                            <TableCell sx={{ width: '35%' }} className='table-app-title-header'>{t("headerTbl1")}</TableCell>
                            <TableCell className='table-app-title-header'>{t("headerTbl2")}</TableCell>
                            <TableCell className='table-app-title-header'>{t("headerTbl3")}</TableCell>
                            <TableCell className='table-app-title-header' align="right">{t("headerTbl4")}</TableCell>
                        </TableRow>
                        :
                        <TableRow >
                            {tableHeaders.map((header, index) => (
                                <TableCell key={index} className='table-app-title-header'>{header.name}</TableCell>
                            ))}
                        </TableRow>
                    }
                </TableHead>
                <TableBody>
                    {typeOfTable === "CustomerStaff" &&
                        <>
                            {assignedClientStaff.length === 0 &&
                                <TableRow key='empty'>
                                    <TableCell colSpan={tableHeaders.length} style={{ textAlign: 'center' }}>
                                        {t("noResultsLbl")}
                                    </TableCell>
                                </TableRow>
                            }
                            {assignedClientStaff.map(item => (
                                <TableRow key={item.id}>
                                    <TableCell component="th" scope="row" className='app-form-subtitle'>
                                        {item.firstName}  {item.lastName}
                                    </TableCell>
                                    <TableCell component="th" scope="row" className='app-form-subtitle'>
                                        {item.email}
                                    </TableCell>
                                    <TableCell component="th" scope="row" className='app-form-subtitle'>
                                        {item.phone}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </>
                    }
                    {typeOfTable === "AssignedUsers" &&
                        <>
                            {users.length === 0 &&
                                <TableRow key='empty'>
                                    <TableCell colSpan={tableHeaders.length} style={{ textAlign: 'center' }}>
                                        {t("noResultsLbl")}
                                    </TableCell>
                                </TableRow>
                            }
                            {users.map(item => (
                                <TableRow key={item.id}>
                                    <TableCell sx={{ width: '100%' }} component="th" scope="row"
                                        className='app-form-subtitle'>
                                        {item.firstName + ' ' + item.lastName}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </>
                    }
                    {typeOfTable === "ClientActivity" &&
                        <>
                            {subjects.length === 0 &&
                                <TableRow key='empty'>
                                    <TableCell colSpan={tableHeaders.length} style={{ textAlign: 'center' }}>
                                        {t("noResultsLbl")}
                                    </TableCell>
                                </TableRow>
                            }
                            {subjects.map(item => (
                                <TableRow key={item.id}>
                                    <TableCell component="th" scope="row" className='app-form-subtitle'>
                                        {item.title}
                                    </TableCell>
                                    <TableCell component="th" scope="row" className='app-form-subtitle'>
                                        <Moment style={{ marginTop: '4px' }}
                                            format="DD/MM/YYYY">
                                            {item.created}
                                        </Moment>
                                    </TableCell>
                                    <TableCell>
                                        <div className='table-row-cell-status' style={{ color: item.disabled ? '#6C757D' : '#28A745' }}>
                                            <CircleIcon style={{ fontSize: 10, marginRight: 11 }} />
                                            <Typography>
                                                {item.disabled ? t("statusArchivedLbl") : t("statusActiveLbl")}
                                            </Typography>
                                        </div>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </>
                    }
                    {typeOfTable === "Principal" &&
                        <>
                            {clients.length === 0 &&
                                <TableRow key='empty'>
                                    <TableCell colSpan={4} style={{ textAlign: 'center' }}>
                                        {t("noResultsLbl")}
                                    </TableCell>
                                </TableRow>
                            }
                            {clients.map(item => (
                                <TableRow key={item.id}>
                                    <TableCell component="th" scope="row" className='app-form-subtitle'>
                                        {item.name}
                                    </TableCell>
                                    <TableCell component="th" scope="row" className='app-form-subtitle'>
                                        {getBusinessesName(item.clientOffices)}
                                    </TableCell>
                                    <TableCell align="right">
                                        <div className='table-row-cell-status' style={{ color: item.disabled ? '#6C757D' : '#28A745' }}>
                                            <CircleIcon style={{ fontSize: 10, marginRight: 11 }} />
                                            <Typography>
                                                {item.disabled ? t("statusArchivedLbl") : t("statusActiveLbl")}
                                            </Typography>
                                        </div>
                                    </TableCell>
                                    <TableCell align="right">
                                        <Button className='button-app-see-detail' component={Link}
                                            to={ROUTES.CLIENT_DETAIL.replace(':id', item.id)}>
                                            {t("seeLbl")}
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ))
                            }
                        </>
                    }
                </TableBody>
            </Table>
        </TableContainer>
    );
}
export default withRouter(TableDetails);