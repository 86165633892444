export const userValidation = (user, offices, business, businesses, imgRef, t) => {
    var error = {};
    const allowedExtensions = /(.jpg|.jpeg|.png)$/i;
    const maxSizeKB = 5000;
    // const onlyLetters = /^[a-zñáéíóúüñ]*$/i;
    const onlyLetters = /^[a-zA-Z0-9!@#$%^&*()_+\-=[\]{};':"\\|,íúóéáñ./\n/\r/\t<>/?]*$/;

    if (user.firstName === null || user.firstName.length === 0 ||
        /^\s+$/.test(user.firstName))
        error.firstName = t("errorName");
    else
        if (!user.firstName.replaceAll(" ", "").match(onlyLetters))
            error.firstName = t("validations:errorAlphabet");
    if (user.lastName === null || user.lastName.length === 0 ||
        /^\s+$/.test(user.lastName))
        error.lastName = t("errorLastName");
    else
        if (!user.lastName.replaceAll(" ", "").match(onlyLetters))
            error.lastName = t("validations:errorAlphabet");
    if (user.email === null || user.email.length === 0 ||
        /^\s+$/.test(user.email))
        error.email = t("validations:errorEmail");
    else if (!validateUserEmail(user.email))
        error.email = t("validations:errorEmailFormat");
    if (user.countryCode === null || user.countryCode.length === 0 ||
        /^\s+$/.test(user.countryCode))
        error.countryCode = t("validations:errorCountryCode");
    if (user.phoneNumber === null || user.phoneNumber.length === 0 ||
        /^\s+$/.test(user.phoneNumber))
        error.phoneNumber = t("validations:errorPhoneNumber");
    else if (!user.phoneNumber.replaceAll(" ", "").match(onlyLetters))
        error.phoneNumber = t("validations:errorPhoneNumberFormat");
    if (user.position === null || user.position.length === 0 ||
        /^\s+$/.test(user.position))
        error.position = t("errorPosition");
    if (user.profileDescription === null || user.profileDescription.length === 0 ||
        /^\s+$/.test(user.profileDescription))
        error.profileDescription = t("errorProfile");
    // if (!user.imageUrl && (user.image === null || user.image.length === 0))
    //     error.image = t("errorProfilePic");
    if (!!user.image) {
        const size = user.image.size;
        const sizeKB = parseInt(size / 1024);
        let Width = user.image.clientWidth;
        let Height = user.image.clientHeight;
        var ratio = 1;
        if (Height > Width) {
            ratio = (Height / Width);
        } else {
            ratio = (Width / Height);
        }
        var ThresHoldVal = 0.15; // 10% out.

        if ((ratio - 1) > ThresHoldVal) {
            error.image = t("validations:errorDimensions") + ` (${imgRef.current.clientWidth} x ${imgRef.current.clientHeight} px)`;

        }
        //if (imgRef.current.clientWidth > 400 || imgRef.current.clientHeight > 400)
        if (user.image.clientWidth > 400 || user.image.clientHeight > 400)
            error.image = t("validations:errorDimensions") + ` (${imgRef.current.clientWidth} x ${imgRef.current.clientHeight} px)`;

        if (!allowedExtensions.exec(user.image.name))
            error.image = t("validations:errorProfileImgExt");
        else if (sizeKB > maxSizeKB)
            error.image = t("validations:errorProfileImgSize");
    }

    var officesSelected = business.length > 0 ? false : true;
    const businessesTemp = business.length > 0 ? businesses.filter(bus => business.includes(String(bus.id))) : [];
    businessesTemp.forEach((bus, index) => {
        const officesTemp = offices.length > 0 ? bus.offices.filter(off => offices.includes(String(off.id))) : [];
        officesSelected = index === 0
            ? officesTemp.length > 0 ? true : false
            : !!officesSelected && officesTemp.length > 0 ? true : false;
    })

    if (!officesSelected)
        error.office = t("errorOffice");

    return error;
};

const validateUserEmail = ((email) => {
    const regex = /^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    return regex.test(email) ? true : false;
});