export const businessValidation = (business, imgRef, t) => {
    var error = { officeList: [] };
    const allowedExtensions = /(.jpg|.jpeg|.png)$/i;
    const maxSizeKB = 5000;
    const onlyLetters = /^[a-zA-Z0-9!@#$%^&*()_+\-=[\]{};':"\\|,íúóéáñ./\n/\r/\t<>/?]*$/;

    if (business.name === null || business.name.length === 0 ||
        /^\s+$/.test(business.name))
        error.name = t("errorCompanyName");
    if (business.name.length > 50)
        error.name = t("errorFieldCharCount50");
    if (business.offices.length === 0)
        error.offices = t("errorOffices");
    if (!!business.image) {
        if (!business.imageUrl && (business.image === null || business.image.length === 0))
            error.image = t("error:requiredLogo");
        else if (!!business.image) {
            const size = business.image.size;
            const sizeKB = parseInt(size / 1024);

            if (imgRef.current.clientWidth > 400 || imgRef.current.clientHeight > 200)
                error.image = t("common:commonNoValidDimensions")`: (${imgRef.current.clientWidth} x ${imgRef.current.clientHeight} px)`;
            if (!allowedExtensions.exec(business.image.name))
                error.image = t("validations:errorImgExt");
            else if (sizeKB > maxSizeKB)
                error.image = t("validations:errorImgSize");
        }
    }

    if (Object.entries(error).length > 1) {
        error.errors = true;
    }

    business.offices.forEach(item => {
        var officeError = { id: item.id };
        if (item.name === null || item.name.length === 0 ||
            /^\s+$/.test(item.name))
            officeError.name = t("errorOfficeName");
        // if (item.email === null || item.email.length === 0 ||
        //     /^\s+$/.test(item.email))
        //     officeError.email = t("error:requiredEmail");
        if (item.email) {
            if (!validateUserEmail(item.email))
                officeError.email = t("error:formatEmail");
        }
        // if (item.countryCode === null || item.countryCode.length === 0 ||
        //     /^\s+$/.test(item.countryCode))
        //     officeError.countryCode = t("error:requiredCountryCode");
        // if (item.phoneNumber === null || item.phoneNumber.length === 0 ||
        //     /^\s+$/.test(item.phoneNumber))
        //     officeError.phoneNumber = t("error:requiredPhoneNumber");

        if (item.phoneNumber || item.countryCode) {
            if (item.countryCode === null || item.countryCode.length === 0 ||
                /^\s+$/.test(item.countryCode))
                officeError.countryCode = t("error:requiredCountryCode");
            if (item.phoneNumber === null || item.phoneNumber.length === 0 ||
                /^\s+$/.test(item.phoneNumber))
                officeError.phoneNumber = t("error:requiredPhoneNumber");
        }

        if (item.phoneNumber) {
            if (!item.phoneNumber.replaceAll(" ", "").match(onlyLetters))
                officeError.phoneNumber = t("error:formatPhoneNumber");
        }

        // if (item.webSite === null || item.webSite.length === 0 ||
        //     /^\s+$/.test(item.webSite))
        //     officeError.webSite = t("error:requiredWebSite");
        if (item.webSite) {
            if (!isValidURL(item.webSite))
                officeError.webSite = t("business:requiredInvalidURL");
        }
        // if (item.address === null || item.address.length === 0 ||
        //     /^\s+$/.test(item.address))
        //     officeError.address = t("error:requiredAddress");
        // if (item.GPSCoordinates === null || item.GPSCoordinates.length === 0 ||
        //     /^\s+$/.test(item.GPSCoordinates))
        //     officeError.GPSCoordinates = t("errorGPS");

        if (item.name && item.name.length > 50)
            officeError.name = t("errorFieldCharCount50");
        if (item.email && item.email.length > 50)
            officeError.email = t("errorFieldCharCount50");
        if (item.webSite && item.webSite.length > 50)
            officeError.webSite = t("errorFieldCharCount50");
        if (item.address && item.address.length > 150)
            officeError.address = t("errorFieldCharCount150");
        if (item.GPSCoordinates && item.GPSCoordinates.length > 50)
            officeError.GPSCoordinates = t("errorGPS");

        if (Object.entries(officeError).length > 1) {
            officeError.errors = true;
            error.errors = true;
        }
        error.officeList.push(officeError);
    });

    return error;
};

export const validateOffice = (office, business, t) => {
    var error = { officeList: [] };
    var officeError = {};
    const onlyLetters = /^[a-zA-Z0-9!@#$%^&*()_+\-=[\]{};':"\\|,íúóéáñ./\n/\r/\t<>/?]*$/;

    if (business.offices.find(item => item.name.toUpperCase() === office.name.toUpperCase()))
        officeError.name = t("errorAlreadyExistingOffice");
    if (office.name === null || office.name.length === 0 ||
        /^\s+$/.test(office.name))
        officeError.name = t("errorOfficeName");
    // if (office.email === null || office.email.length === 0 ||
    //     /^\s+$/.test(office.email))
    //     officeError.email = t("error:requiredEmail");
    if (office.email && !validateUserEmail(office.email))
        officeError.email = t("error:formatEmail");
    // if (office.countryCode === null || office.countryCode.length === 0 ||
    //     /^\s+$/.test(office.countryCode))
    //     officeError.countryCode = t("error:requiredCountryCode");
    // if (office.phoneNumber === null || office.phoneNumber.length === 0 ||
    //     /^\s+$/.test(office.phoneNumber))
    //     officeError.phoneNumber = t("error:requiredPhoneNumber");

    if (office.phoneNumber || office.countryCode) {
        if (office.countryCode === null || office.countryCode.length === 0 ||
            /^\s+$/.test(office.countryCode))
            officeError.countryCode = t("error:requiredCountryCode");
        if (office.phoneNumber === null || office.phoneNumber.length === 0 ||
            /^\s+$/.test(office.phoneNumber))
            officeError.phoneNumber = t("error:requiredPhoneNumber");
    }
    if (office.phoneNumber && !office.phoneNumber.replaceAll(" ", "").match(onlyLetters))
        officeError.phoneNumber = t("error:formatPhoneNumber");
    // if (office.webSite === null || office.webSite.length === 0 ||
    //     /^\s+$/.test(office.webSite))
    //     officeError.webSite = t("error:requiredWebSite");
    if (office.webSite && !isValidURL(office.webSite))
        officeError.webSite = t("error:invalidUrl");
    // if (office.address === null || office.address.length === 0 ||
    //     /^\s+$/.test(office.address))
    //     officeError.address = t("error:requiredAddress");
    // if (office.GPSCoordinates === null || office.GPSCoordinates.length === 0 ||
    //     /^\s+$/.test(office.GPSCoordinates))
    //     officeError.GPSCoordinates = t("errorGPS");

    if (office.name && office.name.length > 50)
        officeError.name = t("errorFieldCharCount50");
    if (office.email && office.email.length > 50)
        officeError.email = t("errorFieldCharCount50");
    if (office.webSite && office.webSite.length > 50)
        officeError.webSite = t("errorFieldCharCount50");
    if (office.address && office.address.length > 150)
        officeError.address = t("errorFieldCharCount50");
    if (office.GPSCoordinates && office.GPSCoordinates.length > 50)
        officeError.GPSCoordinates = t("errorFieldCharCount50");
    if (business.disabled)
        officeError.GPSCoordinates = t("businessDisabled");

    if (Object.entries(officeError).length > 0) {
        officeError.id = 'new';
        error.officeList.push(officeError);
    }
    return error;
};

const validateUserEmail = ((email) => {
    const regex = /^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    return regex.test(email) ? true : false;
});

const isValidURL = (url) => {
    // return /^[-a-zA-Z0-9@:%._+~#=]{2,4}\.[a-z]{2,256}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)\.[a-zA-Z0-9!@#$%^&*()_+\-=[\]{};':"\\|,íúóéáñ./\n/\r/\t<>/?]{2,256}$/g.test(url);
    return /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$/.test(url);
};