import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withRouter, Prompt } from 'react-router-dom';
import {
  Typography, Grid, Box, Container, Tab,
  Button,
  InputLabel
} from "@mui/material";
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { ReactComponent as Clock } from '../../../assets/Icons/clock.svg';
import Title from "../../../components/Title";
import Record from "../Record";
import Form from '../Form/Form';
import { postUpdateContent, getContentById } from "../../../redux/MobileContentDuck";
import { getLenguages } from '../../../redux/LenguageDuck';
import { useTranslation } from 'react-i18next';
import './Read.css';
import { getFileURL } from "../../../constants/apiUrls";
import {
  validateNewPage,
  validatePageContent,
  validatePagesContents,
  validateDefaultPage
} from "./MobileContentValidation";
import TextFieldComponent from "../../../components/TextField";

const Read = props => {

  const { t } = useTranslation(['mobileContent', 'common']);
  const lenguageList = useSelector(store => store.lenguage.lenguages);
  const system = useSelector(store => store.system.system);
  const appSetting = useSelector(store => store.appSetting.appSetting);
  const content = useSelector(store => store.mobile.content);
  // const isCreated = useSelector(store => store.mobile.isCreated);
  const [lenguages, setLenguages] = useState([]);
  const [value, setValue] = useState('1');
  const [pages, setPages] = useState([
    {
      id: null,
      pageIndex: '1',
      page: '',
      content: [],
      default: true
    }
  ]);
  const [versionName, setVersionName] = useState('');
  const [versionNameError, setVersionNameError] = useState('');
  const [openRecord, setOpenRecord] = useState(false);
  const [isCreating, setIsCreating] = useState(true);
  const [pagesDelete, setPagesDelete] = useState([]);
  const [contentsDelete, setContentsDelete] = useState([]);
  const [imagesToDelete, setImagesToDelete] = useState([]);
  const [fieldsToDelete, setFieldsToDelete] = useState([]);
  const [pageError, setPageError] = useState('');
  const [contentPageError, setContentPageError] = useState({});
  const [unsaved, setUnsaved] = useState(false);
  const [totalImages, setTotalImages] = useState(0);
  let countImages = 0;
  const dispatch = useDispatch();
  // const history = useHistory();

  const contentType = [
    'TEXT',
    'IMAGE',
    'GALLERY',
    'FORM',
    'MAP',
    'USER_CARD',
    'VIDEO'
  ];

  const onChangeTab = (value, newValue) => {
    if (newValue !== 'add') setValue(newValue);
  };

  const addPage = () => {
    var pageTemp = pages.find(p => p.default === true);
    const error = validateNewPage(pageTemp, t);
    setPageError(error);
    if (!error) {
      const errorCont = validatePageContent(pageTemp, lenguages, t);
      setContentPageError(errorCont);
      if (errorCont[pageTemp.pageIndex].length === 0) {
        if (pageTemp.page) {
          const pageList = pages;
          pageList.push({
            id: null,
            pageIndex: pageList.length + 1,
            page: pageTemp.page,
            content: pageTemp.content,
            default: false
          });
          const pageIndexOf = pageList.findIndex(p => { return p.default === true });
          pageTemp.page = '';
          pageTemp.content = [];
          pageList[pageIndexOf] = pageTemp;

          setPages(prev => ([...pageList]));
        }
      }
    }
  };

  const deletePage = (pageIndex) => {
    const pagesTemp = pages;
    const pageIndexOf = pagesTemp.findIndex(p => { return String(p.pageIndex) === String(pageIndex) });
    if (!isCreating && pagesTemp[pageIndexOf].id) {
      const deletedPagesList = pagesDelete;
      deletedPagesList.push(pagesTemp[pageIndexOf].id);
      deleteContentPage(pageIndexOf);
      setPagesDelete(prev => ([...deletedPagesList]));
    }
    pagesTemp.splice(pageIndexOf, 1);
    setValue('1');
    setPages(prev => ([...reorderPages(pagesTemp)]));
    setUnsaved(true);
  };

  const reorderPages = (pagesTemp) => {
    const temp = pagesTemp;
    temp.forEach((item, index) => {
      if (!item.default) {
        item.pageIndex = index + 1
      }
    });
    return temp;
  };

  const onChangePageName = (pageIndex, e) => {
    const { value } = e.target;
    const pagesTemp = pages;
    const pageIndexOf = pagesTemp.findIndex(p => { return String(p.pageIndex) === String(pageIndex) });
    var pageTemp = pagesTemp[pageIndexOf];
    pageTemp.page = value;
    pagesTemp[pageIndexOf] = pageTemp;
    setPages(prev => ([...pagesTemp]));
    setUnsaved(true);
  };

  const addContent = (type, pageIndex) => {
    const pagesTemp = pages;
    const pageIndexOf = pagesTemp.findIndex(p => { return String(p.pageIndex) === String(pageIndex) });
    var pageTemp = pagesTemp[pageIndexOf];
    const value = {};
    // if (type === 'TEXT') {
    lenguages.forEach(item => {
      const language = item.key;
      value[language] = null;
    });
    // } else {
    //   value[system.defaultLanguageId] = null;
    // }
    pageTemp.content.push({
      id: null,
      contentIndex: pageTemp.content.length + 1,
      contentType: type,
      value: value
    });
    pagesTemp[pageIndexOf] = pageTemp;
    setPages(prev => ([...pagesTemp]));

    document.querySelector('#principal').scrollIntoView({
      behavior: 'smooth',
      block: 'end'
    });
  };

  const deleteContent = (pageIndex, contentIndex) => {
    const pagesTemp = pages;
    const pageIndexOf = pagesTemp.findIndex(p => { return String(p.pageIndex) === String(pageIndex) });
    var pageTemp = pagesTemp[pageIndexOf];
    if (!isCreating && pageTemp.content[contentIndex].id) {
      const deletedContentsList = contentsDelete;
      deletedContentsList.push(pageTemp.content[contentIndex].id);
      setContentsDelete(prev => ([...deletedContentsList]));
    }
    pageTemp.content.splice(contentIndex, 1);
    setPages(prev => ([...pagesTemp]));
  };

  const deleteImageToGallery = (imageId) => {
    const imagesDeletedTemp = imagesToDelete;
    imagesDeletedTemp.push(imageId);
    setImagesToDelete(prev => ([...imagesDeletedTemp]));
    setUnsaved(true);
  };

  const deleteFields = (fieldId) => {
    const fieldsToDeleteTemp = fieldsToDelete;
    fieldsToDeleteTemp.push(fieldId);
    setFieldsToDelete(prev => ([...fieldsToDeleteTemp]));
    setUnsaved(true);
  };

  const deleteContentPage = (pageIndex) => {
    var pageTemp = pages[pageIndex];
    pageTemp.content.forEach(cont => {
      const deletedContentsList = contentsDelete;
      deletedContentsList.push(cont.id);
      setContentsDelete(prev => ([...deletedContentsList]));
    });
  };

  const onChangeContentOrder = (content, pageIndex) => {
    const pagesTemp = pages;
    const pageIndexOf = pagesTemp.findIndex(p => { return String(p.pageIndex) === String(pageIndex) });
    var pageTemp = pagesTemp[pageIndexOf];
    pageTemp.content = content;
    pagesTemp[pageIndexOf] = pageTemp;
    setPages(prev => ([...pagesTemp]));
    setUnsaved(true);
  };

  const onContentValueChange = (pageIndex, contentIndex, value, language) => {
    const pagesTemp = pages;
    const pageIndexOf = pagesTemp.findIndex(p => { return String(p.pageIndex) === String(pageIndex) });
    var contentTemp = pagesTemp[pageIndexOf].content[contentIndex];
    contentTemp.value[language] = value;
    pagesTemp[pageIndexOf].content[contentIndex] = contentTemp;
    setPages(prev => ([...pagesTemp]));
    setUnsaved(true);
  };

  const onSave = (status) => {
    const error = validatePagesContents(
      pages.filter(item => item.default === false),
      lenguages, t
    );
    setContentPageError(error);
    const isValid = Object.values(error).find(item => item.length > 0);
    let errorPage = '';
    let errorDefaultPage = '';
    let errorVersion = '';
    pages.filter(item => item.default === false).forEach((page, index) => {
      const pageErr = validateNewPage(page, t);
      if (pageErr) {
        errorPage = pageErr;
        setPageError(pageErr);
      }
    });

    const pageDefaultErr = validateDefaultPage(pages.find(item => item.default === true), t);

    if (pageDefaultErr) {
      errorDefaultPage = pageDefaultErr;
      setPageError(pageDefaultErr);
    };

    if (versionName === null || versionName.length === 0 || /^\s+$/.test(versionName)) {
      errorVersion = t("versionNameError");
      setVersionNameError(errorVersion);
    }

    /*console.log('pages', pages);
    console.log('isValid', !isValid);
    console.log('errorPage', !errorPage);
    console.log('errorDefaultPage', !errorDefaultPage);
    console.log('errorVersion', !errorVersion);*/

    if (!isValid && !errorPage && !errorDefaultPage && !errorVersion) {
      dispatch(postUpdateContent(
        pages.filter(item => item.default === false),
        versionName,
        status,
        true,
        pagesDelete,
        contentsDelete,
        imagesToDelete,
        fieldsToDelete
      ));
      setUnsaved(false);
    } else {
      setPageError(t("screensError"));
    }
  };

  useEffect(() => {
    dispatch(getLenguages());
  }, [dispatch]);

  /*useEffect(() => {
    console.log('count', totalImages);
  }, [totalImages]);*/

  useEffect(() => {
    const id = props.match.params.id;
    if (id) {
      dispatch(getContentById(id));
      setIsCreating(false);
    } else {
      setIsCreating(true);
    }
    setPages([
      {
        id: null,
        pageIndex: '1',
        page: '',
        content: [],
        default: true
      }
    ]);
  }, [dispatch, props.match.params.id]);

  const convertURL = (value) => {
    //console.log("validating");
    const embedRegex = new RegExp(/^<iframe[^>]+src=["']([^"']+)["'][^>]*>(?:<\/iframe>)?$/);
    const isIframe = embedRegex.test(value);
    let src = isIframe ? value.match(/src=["']([^"']+)["']/)[1] : value;
    const idPattern = /^.*(youtu\.be\/|youtube(?:-nocookie)?\.com\/(v\/|vi\/|e\/(?:(?:embed|watch)\/)?|shorts\/))?([\w\-]{11}).*/;
    const ID = src.match(idPattern);
    //console.log(value,src,ID);
    const linkview = /watch\?v=/;
    const replacement = "embed/";
    const isWatchLink = value.match(linkview) ? true : false;
    src = isWatchLink ? src.replace(linkview, replacement) : src;
    const catchId = /embed\/([^?]+)/;
    const youtubeId = src.match(catchId);

    return {
      url: youtubeId ? value : "https://www.youtube.com/embed/" + value,
      ytid: value,
    };
  };

  useEffect(() => {
    if (!isCreating && !!content && pages.length === 1) {
      const list = pages;
      setVersionName(content.name);     
      content.pages.forEach((page, pageIndex) => {
        const pageObject = {
          id: page.id,
          pageIndex: String(pageIndex + 2),
          page: page.name,
          content: [],
          default: false
        };
        page.objects.forEach(object => {
          pageObject.content.push(getContentValue(object.position, object.objectLanguages));
        });
        list.push(pageObject);
      });
      setTotalImages(countImages);
      setPages(prev => ([...list]));
    }
    // eslint-disable-next-line
  }, [content, isCreating]);

  const getContentValue = (position, objectLanguages) => {
    const contentObject = {
      id: null,
      contentIndex: position,
      contentType: null,
      value: {}
    };
    
    objectLanguages.forEach(objectLanguage => {
      contentObject.id = objectLanguage.objectId;
      contentObject.contentType = contentType[objectLanguage.type];
      if (contentObject.contentType === 'TEXT') {
        contentObject.value[objectLanguage.languageId] = {
          id: objectLanguage.textId,
          text: objectLanguage.text.text1
        };
      } else if (contentObject.contentType === 'IMAGE') {      
        countImages++;  
         fetch(getFileURL + objectLanguage.image.fileId)
          .then(res => res.blob())
          .then(blob => {
            //console.log('response', totalImages);
            setTotalImages(totalImages - 1);            
            const file = new File([blob], objectLanguage.image.file.fileName, blob);
            contentObject.value[objectLanguage.languageId] = {
              id: objectLanguage.imageId,
              file: file,
              width: objectLanguage.image.file.width,
              height: objectLanguage.image.file.height,
              imageUrl: getFileURL + objectLanguage.image.fileId,
              fileId: objectLanguage.image.fileId,
              check: objectLanguage.image.check
            };
          });
      } else if (contentObject.contentType === 'GALLERY') {
        const imageList = [];
        objectLanguage.imageGallery.folder.files.forEach(item => {         
          countImages++;  
          fetch(getFileURL + item.id)
            .then(res => res.blob())
            .then(blob => {
              const file = new File([blob], item.fileName, blob);
              imageList.push({
                id: objectLanguage.imageGalleryId,
                fileId: item.id,
                file: file,
                width: item.width,
                height: item.height,
                imageUrl: getFileURL + item.id,
                folderId: objectLanguage.imageGallery.folderId,
                check: objectLanguage.imageGallery.check
              });
            });
        });

        contentObject.value[objectLanguage.languageId] = imageList;
      } else if (contentObject.contentType === 'FORM') {
        const formList = [];
        objectLanguage.form.fields.forEach(item => {
          formList.push({
            id: item.id,
            type: item.type,
            // placeholder: item.placeholder,
            placeholder: "",
            label: item.label,
            required: item.required,
            formId: item.formId
          })
        });
        contentObject.value[objectLanguage.languageId] = formList;
      } else if (contentObject.contentType === 'MAP') {
        contentObject.value[objectLanguage.languageId] = {
          id: objectLanguage.mapId,
          map: objectLanguage.map.coordinatesGps
        };
      } else if (contentObject.contentType === 'USER_CARD') {
        contentObject.value[objectLanguage.languageId] = {
          id: objectLanguage.userCardId,
          userId: objectLanguage.userCard.userId,
          fullName: objectLanguage.userCard.fullName,
          email: objectLanguage.userCard.email,
          phone: objectLanguage.userCard.phone,
          position: objectLanguage.userCard.position,
          downloadable: String(objectLanguage.userCard.downloadable)
        };
      } else if (contentObject.contentType === 'VIDEO') {
        contentObject.value[objectLanguage.languageId] = {
          id: objectLanguage.videoId,
          ...convertURL(objectLanguage.video.url),
        };
      }
    });   
    
    return contentObject;
  };

  useEffect(() => {
    if (appSetting && system) {
      const list = [];
      lenguageList
        .filter(lng => [appSetting.secondaryLanguageId, system.defaultLanguageId].includes(lng.key))
        .forEach(item => {
          list.push({
            key: item.key,
            value: <span style={{ color: '#5AB6DF' }}>{item.code.toUpperCase()}</span>
          });
        });
      setLenguages(list);
    }
  }, [lenguageList, appSetting, system]);

  const onChangeVersionName = e => {
    setVersionName(e.target.value);
  };

  return (
    <Container>
      <Prompt
        when={unsaved}
        message={t("messageUnsavedLbl")}
      />
      <Record open={openRecord} closeRecord={() => setOpenRecord(false)} />
      <Title title={t("title1")} style={{ marginLeft: 5 }} />
      <Grid item xs={12}>
        <div className="container-app-div">
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography className="app-form-title">{t("title1")}</Typography>
            <div>
              <Button className='button-history-page'
                onClick={() => setOpenRecord(true)}
                variant="text" startIcon={<Clock />}
              >
                {t("versionsLbl")}
              </Button>
              <Button variant="contained" className="button-movil-save"
                onClick={() => onSave(false)}
                disabled={pages.filter(item => item.default === false).length === 0 || totalImages > 0}
              >
                {t("saveDraftLbl")}
              </Button>
              <Button variant="contained" className="button-movil-publish"
                onClick={() => onSave(true)}
                disabled={pages.filter(item => item.default === false).length === 0 || totalImages > 0}
              >
                {t("saveAndPublishLbl")}
              </Button>
            </div>
          </div>
          <div>
            <InputLabel className="title-page-label">{t("versionNameLbl")}</InputLabel>
            <TextFieldComponent name="name" placeholder={t("versionNamePlh")}
              valueInput={versionName}
              maxLength={30}
              callback={onChangeVersionName.bind(this)}
              errorInput={versionNameError}
            />
          </div>
          <Box sx={{ width: '100%', typography: 'body1' }}>
            <TabContext value={value}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <TabList
                  onChange={onChangeTab}
                  variant="scrollable" scrollButtons="auto">
                  {pages.map(item => (
                    <Tab key={item.pageIndex}
                      value={String(item.pageIndex)}
                      label={item.default ? t("addPageLbl") : item.page}
                      className={item.default ? "new-page" : ""}
                    />
                  ))}
                </TabList>
              </Box>
              {pages.map(item => (
                <TabPanel key={item.pageIndex}
                  value={String(item.pageIndex)}
                  className='tab-select-page'
                  style={{
                    display: String(item.pageIndex) === value ? 'flex' : 'none'
                  }}>
                  <Form
                    pages={pages}
                    onChangePage={onChangeTab}
                    page={item}
                    onChangePageName={onChangePageName}
                    addContent={addContent}
                    onChangeContentOrder={onChangeContentOrder}
                    deleteContent={deleteContent}
                    onContentValueChange={onContentValueChange}
                    deletePage={deletePage}
                    deleteImageToGallery={deleteImageToGallery}
                    deleteFields={deleteFields}
                    pageSelected={value}
                    errorPage={pageError}
                    contentPageError={contentPageError}
                    addPage={addPage}
                  />
                </TabPanel>
              ))}
            </TabContext>
          </Box>
        </div>
      </Grid>
    </Container>
  );
}

export default withRouter(Read);