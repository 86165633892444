import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
    InputLabel, Typography, Container,
    Grid, Divider, Button,
} from "@mui/material";
import SelectFieldComponent from "../../components/SelectField";
import Title from '../../components/Title';
import Results from "./Results";
import { getOfficesFilter, getBusinessesFilter } from "../../redux/BusinessDuck";
import { getClients } from "../../redux/ClientDuck";
import { getLogs } from "../../redux/LogDuck";
import { getUsers } from "../../redux/UserDuck";
import TextFieldComponent from "../../components/TextField";
import { useTranslation } from 'react-i18next';
import './Logs.css';

const Logs = () => {
    const { t } = useTranslation(['log', 'common']);
    const usersRedux = useSelector(store => store.user.users);

    const businesses = useSelector(store => store.business.businesses);
    const officesRedux = useSelector(store => store.business.offices);
    const clientsRedux = useSelector(store => store.client.clients);
    const [companyId, setCompanyId] = useState('all');
    const [companies, setCompanies] = useState([]);
    const [officeId, setOfficeId] = useState('all');
    const [offices, setOffices] = useState([]);
    const [clientId, setClientId] = useState('all');
    const [clients, setClients] = useState([]);
    const [logId, setLogId] = useState('');
    const [users, setUsers] = useState([]);
    const [userId, setUserId] = useState('all');
    const [initialDate, setInitialDate] = useState('');
    const [finalDate, setFinalDate] = useState('');
    const [isSearch, setIsSearch] = useState(false);
    const [errorSearch, setErrorSearch] = useState('');

    const logList = [
        { key: 'authorization', value: t("headerTbl10") },
        { key: 'notification', value: t("headerTbl11") }
    ];
    const dispatch = useDispatch();

    const onChangeUser = e => {
        setUserId(e.target.value);
    };

    const onChangeLog = e => {
        setLogId(e.target.value);
    };

    const onChangeCompany = e => {
        setCompanyId(e.target.value);
    };

    const onChangeOffice = e => {
        setOfficeId(e.target.value);
    };

    const onChangeClient = e => {
        setClientId(e.target.value);
        setUserId("all");
        setUsers([]);
    };

    const clearFilters = () => {
        setUserId('all');
        setLogId('');
        setCompanyId('all');
        setOfficeId('all');
        setClientId('all');
        setInitialDate('');
        setFinalDate('');
    };

    const onChangeInitialDate = (e) => {
        const { value } = e.target;
        setInitialDate(value);
    };

    const onChangeFinalDate = (e) => {
        const { value } = e.target;
        setFinalDate(value);
    };

    useEffect(() => {
        const businessList = [];
        businesses.forEach(item => {
            businessList.push({ key: item.id, value: item.name });
        });
        setCompanies(businessList);
    }, [businesses]);

    useEffect(() => {
        const officesTemp = [];
        officesRedux.forEach(item => {
            officesTemp.push({ key: item.id, value: item.name });
        });
        setOffices(officesTemp);
        setOfficeId('all');
        // eslint-disable-next-line
    }, [companyId, officesRedux]);

    useEffect(() => {
        const clientList = [];
        clientsRedux.forEach(item => {
            clientList.push({ key: item.id, value: item.name });
        });
        setClients(clientList);
        // eslint-disable-next-line
    }, [clientsRedux]);

    useEffect(() => {
        dispatch(getBusinessesFilter());
    }, [dispatch]);

    useEffect(() => {
        dispatch(getOfficesFilter(companyId !== 'all' ? [{
            "search": String(companyId),
            "filterBy": "company"
        }] : []));
    }, [dispatch, companyId]);

    useEffect(() => {
        const list = [];
        usersRedux.forEach(item => {
            list.push({
                key: item.id,
                value: item.firstName + ' ' + item.lastName,
            });
        });
        setUsers(list);
    }, [usersRedux]);

    useEffect(() => {
        const filterBy = [{ search: 'false', filterBy: 'disabled' }];
        officeId !== 'all' && filterBy.push({ search: String(officeId), filterBy: 'office' });
        companyId !== 'all' && filterBy.push({ search: String(companyId), filterBy: 'company' });
        dispatch(getClients(filterBy, 0, 10000, false, 'logBook'));
    }, [dispatch, officeId, companyId]);

    useEffect(() => {
        if (clientId !== 'all') {
            const filterBy = [{ search: String(clientId), filterBy: 'client' }];
            companyId !== 'all' && filterBy.push({ search: String(companyId), filterBy: 'company' });
            officeId !== 'all' && filterBy.push({ search: String(officeId), filterBy: 'office' });
            dispatch(getUsers(filterBy, 0, 10000, false, "logBook"));
        }
    }, [dispatch, companyId, officeId, clientId]);

    const searchLog = () => {
        if (logId) {

            const filterBy = [];
            logId && filterBy.push({ search: 'true', filterBy: logId });
            clientId !== 'all' && filterBy.push({ search: String(clientId), filterBy: 'client' });
            userId !== 'all' && filterBy.push({ search: String(userId), filterBy: 'user' });
            companyId !== 'all' && filterBy.push({ search: String(companyId), filterBy: 'company' });
            officeId !== 'all' && filterBy.push({ search: String(officeId), filterBy: 'office' });
            initialDate !== '' && filterBy.push({ search: String(initialDate), filterBy: 'date1' });
            finalDate !== '' && filterBy.push({ search: String(finalDate), filterBy: 'date2' });
            dispatch(getLogs(filterBy, 0, 10000, true, 'logBook'));
            setIsSearch(true);
            setErrorSearch("");
        } else {
            setErrorSearch(t("logRequiredError"));
        }
    };

    const formatDate = () => {
        const today = new Date();
        const year = today.getFullYear();
        const month = today.getMonth() + 1;
        const day = today.getDate();

        return `${year}-${month > 9 ? month : ('0' + month)}-${day}`
    };

    return (
        <Container>
            <Title title={t("title")} style={{ marginLeft: 5 }} />
            <Grid item xs={12}>
                <div className="container-app-div">
                    <Typography className="app-form-title">{t("title")}</Typography>
                    <div>
                        <div style={{
                            display: 'flex', flexDirection: 'row', marginBottom: 30,
                            width: '100%', justifyContent: 'space-between'
                        }}>
                            <div style={{ width: '48%' }}>
                                <InputLabel className="label-app">{t("common:commonCompanyLbl")}</InputLabel>
                                <SelectFieldComponent name="companyId"
                                    valueInput={companyId}
                                    onChange={onChangeCompany}
                                    options={[
                                        { key: 'all', value: t("common:commonAllLbl") },
                                        ...companies
                                    ]}
                                />
                            </div>
                            <div style={{ width: '48%' }}>
                                <InputLabel className="label-app">{t("common:commonOfficeLbl")}</InputLabel>
                                <SelectFieldComponent name="officeId"
                                    valueInput={officeId}
                                    onChange={onChangeOffice}
                                    options={[
                                        { key: 'all', value: t("common:commonAllLbl") },
                                        ...offices
                                    ]}
                                />
                            </div>
                        </div>
                        <div style={{
                            display: 'flex', flexDirection: 'row', marginBottom: 30,
                            width: '100%', justifyContent: 'space-between'
                        }}>
                            <div style={{ width: '48%' }}>
                                <InputLabel className="label-app">{t("common:commonClientLbl")}</InputLabel>
                                <SelectFieldComponent name="clientId"
                                    valueInput={clientId}
                                    onChange={onChangeClient}
                                    options={[
                                        { key: 'all', value: t("common:commonAllLbl") },
                                        ...clients
                                    ]}
                                />
                            </div>
                            <div style={{ width: '48%' }} className="filters-logs">
                                <InputLabel className="label-app">{t("subtitle1")}</InputLabel>
                                <SelectFieldComponent name="logs"
                                    valueInput={logId}
                                    onChange={onChangeLog}
                                    options={logList}
                                    errorInput={errorSearch}
                                />
                            </div>
                        </div>
                        <Divider />
                        <Typography className="app-form-title" style={{ marginTop: 22 }}>{t("common:commonFilterLbl")}s</Typography>
                        <div style={{
                            display: 'flex', flexDirection: 'row',
                            width: '100%', justifyContent: 'space-between'
                        }}>
                            <div style={{ width: '30.5%' }}>
                                <InputLabel className="label-app">{t("log:headerTbl3")}</InputLabel>
                                <SelectFieldComponent name="userName"
                                    valueInput={userId}
                                    onChange={onChangeUser}
                                    options={[
                                        { key: 'all', value: t("common:commonAllLbl") },
                                        ...users
                                    ]}
                                />
                            </div>
                            <div style={{ width: '30.5%' }}>
                                <InputLabel className="label-app">{t("startDateLbl")}</InputLabel>
                                <TextFieldComponent name="initialDate"
                                    valueInput={initialDate}
                                    callback={onChangeInitialDate.bind(this)}
                                    type='date'
                                    inputProps={{
                                        max: formatDate()
                                    }}
                                />
                            </div>
                            <div style={{ width: '30.5%' }}>
                                <InputLabel className="label-app">{t("endDateLbl")}</InputLabel>
                                <TextFieldComponent name="finalDate"
                                    valueInput={finalDate}
                                    callback={onChangeFinalDate.bind(this)}
                                    type='date'
                                    inputProps={{
                                        max: formatDate()
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ display: 'flex' }}>
                    <Button variant="contained" className="button-app-save" style={{ marginRight: 18 }}
                        onClick={() => searchLog()}>
                        {t("common:commonSearchPlh")}
                    </Button>
                    <Button variant="contained" className="button-app-clear-filter"
                        onClick={() => clearFilters()}>
                        {t("cleanFilters")}
                    </Button>
                </div>
            </Grid>
            {isSearch &&
                <Results />
            }
        </Container>
    );
}
export default Logs;