import React from "react";
import { Grid, CardContent, Typography } from "@mui/material";
import { ReactComponent as FolderPlus } from '../../../assets/Icons/folderPlus.svg';
import FolderContainer from "./FolderContainer";
import { useSelector, useDispatch } from "react-redux";
import { changeFolderPage } from "../../../redux/DocumentDuck";
import PaginationCustom from "../../../components/Pagination";
import { useTranslation } from "react-i18next";
const Folders = ({ setOpenFile }) => {
    const { t } = useTranslation(['document','file']);
    const activePageFolder = useSelector(store => store.doc.activePageFolder);
    const pagesFolder = useSelector(store => store.doc.pagesFolder);
    const folders = useSelector(store => store.doc.folders);
    const dispatch = useDispatch();

    return (
        <Grid container>
            <Grid item xs={12} style={{ paddingRight: 10 }}>
                <div style={{ padding: '10px 20px' }}>
                    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                        {folders.map(item => (
                            <FolderContainer
                                key={item.id}
                                id={item.id}
                                texto={item.name}
                                counterText={`${item.filesIntoFolder} ${t('file:filesLbl')} `} 
                            />
                        ))}
                        <CardContent className='card-content-item-add-folder'
                            onClick={() => setOpenFile(true)}>
                            <div>
                                <FolderPlus className="icon-folder-add icon-svg-folder-add" />
                                <Typography className='card-text-add-folder'>
                                   {t('createFolderBtn')} 
                                </Typography>
                            </div>
                        </CardContent>
                    </div>
                </div>
                <br />
                <PaginationCustom pages={pagesFolder} activePage={activePageFolder}
                    onChange={page => dispatch(changeFolderPage(page))} />
            </Grid>
        </Grid>
    );
}

export default Folders;