import {
    getClientsURL, postClientURL, getClientByIdURL,getOrganizationDetailURL,getOrganizationIdURL,getOrganizationAdminURL,
    putClientDisableURL, putClientActiveURL, putClientURL
} from '../constants/apiUrls';
import { postAxios, getAxios, putAxios } from '../services/Axios';
import { logoutAction, renewTokenAction } from './AuthDuck'

// constantes
const initialState = {
    clients: [],
    company: {},
    companyAdmin: "",
    client: null,
    response: null,
    isCreating: true,
    isCreated: false,
    pages: 1,
    activePage: 0,
    loading: false,
    error: null,
};

// types
const CLIENTS_SUCCESS = 'CLIENTS_SUCCESS';
const CLIENT_ADMIN_SUCCESS = 'CLIENT_ADMIN_SUCCESS';
const CREATE_UPDATE_CLIENT_SUCCESS = 'CREATE_UPDATE_CLIENT_SUCCESS';
const CLIENT_GET_BY_ID = 'CLIENT_GET_BY_ID';
const CLIENT_STATUS_CHANGE = 'CLIENT_STATUS_CHANGE';
const CLIENT_CHANGE_PAGE = 'CLIENT_CHANGE_PAGE';
const IS_CREATING_CLIENT = 'IS_CREATING_CLIENT';
const CLEAR_RESPONSE = 'CLEAR_RESPONSE';
const CLIENT_ERROR = 'CLIENT_ERROR';
const CLIENT_LOADING = 'CLIENT_LOADING';
const LOADING_CLIENT = 'LOADING_CLIENT';
const GET_COMPANY_BY_ID_SUCCESS = 'GET_COMPANY_BY_ID_SUCCESS';
const CLEAR_CLIENTS = 'CLEAR_CLIENTS'
const LOG_OUT = 'LOG_OUT';
const INITIAL_STATE = 'INITIAL_STATE';

// reducer
export default function reducer(state = initialState, action) {
    switch (action.type) {
        case CLIENT_LOADING:
            return { ...state, loading: true, error: null };
        case LOADING_CLIENT:
            return { ...state, loading: true, error: null };
        case CLIENTS_SUCCESS:
            return {
                ...state, clients: action.payload, activePage: action.activePage,
                pages: action.pages, error: null, loading: false, isCreated: false
            };
        case CLIENT_ADMIN_SUCCESS:
            return {
                ...state, companyAdmin : action.payload
            };
        case CLEAR_CLIENTS:
            return {
                ...state, clients: [], activePage: 0,
                pages: 1, error: null, loading: false
            };
        case CREATE_UPDATE_CLIENT_SUCCESS:
            return {
                ...state, isCreated: true, response: action.response,
                clients: [], activePage: 0, pages: 1, loading: false, error: null
            };
        case CLIENT_GET_BY_ID:
            return {
                ...state, client: action.payload, error: null, loading: false,
                clients: [], pages: 1, activePage: 0
            };
        case GET_COMPANY_BY_ID_SUCCESS:
            return { ...state, company: action.payload, error: null, loading: false }
        case CLIENT_STATUS_CHANGE:
            return { ...state, response: action.response, loading: false, error: null };
        case IS_CREATING_CLIENT:
            return {
                ...state, isCreating: action.payload, client: null, isCreated: false,
                clients: [], activePage: 0, pages: 1
            };
        case CLIENT_CHANGE_PAGE:
            return { ...state, activePage: action.activePage };
        case CLEAR_RESPONSE:
            return { ...state, response: null };
        case CLIENT_ERROR:
            return {
                ...state, error: action.payload, loading: false,
                response: action.payload
            };
        case LOG_OUT:
            return { ...initialState };
        case INITIAL_STATE:
            return {
                ...state,
                clients: [],
                client: null,
                pages: 1,
                activePage: 0,
            };
        default:
            return state;
    }
};


export const getClients = (filterBy = [], page = 0, pageSize = 1000, loading = false, service = "") => async (dispatch, getState) => {
    loading && dispatch({ type: LOADING_CLIENT });
    try {
        const { user } = getState().auth;
        const res = await postAxios(getClientsURL, {
            "organizationId": user.organizationId,
            "page": page,
            "pageSize": pageSize,
            "filterBy": filterBy,
            "service": service,
            "orderBy": "",
            "orderDesc": true
        });
        if (res.status === 200) {
            const token = res.headers.refreshtoken;
            const expiration = res.headers.expirationToken;
            (token && expiration) && dispatch(renewTokenAction(token, expiration));
            return dispatch({
                type: CLIENTS_SUCCESS,
                payload: res.data.response,
                pages: res.data.totalPages,
                activePage: (res.data.totalPages < page + 1) ? 0 : page
            });
        }
        throw new Error();
    } catch (error) {
        if (error.response && error.response.status === 401)
            dispatch(logoutAction());
        dispatch({
            type: CLIENT_ERROR,
            payload: error.response ? error.response.data.message : "common:errorLoadingClients"
        });
    }
};

export const saveUpdateClient = (client, businesses, users) => async (dispatch, getState) => {
    dispatch({ type: LOADING_CLIENT });
    try {
        const userRedux = getState().auth.user;
        const { isCreating } = getState().client;
        const clientRedux = getState().client.client;
        const bodyFormData = new FormData();
        var countOffice = 0;
        businesses.forEach(business => {
            business.offices.forEach((item) => {
                if (item.active) {
                    bodyFormData.append(`clientOffices[${countOffice}][clientId]`, isCreating ? "0" : clientRedux.id);
                    bodyFormData.append(`clientOffices[${countOffice}][officeId]`, item.id);
                    countOffice++;
                }
            });
        });

        users.forEach((userId, index) => {
            bodyFormData.append(`clientUsers[${index}][userId]`, userId);
            bodyFormData.append(`clientUsers[${index}][clientId]`, !isCreating ? clientRedux.id : "0");
        });

        bodyFormData.append('organizationId', userRedux.organizationId);
        bodyFormData.append('name', client.name);
        bodyFormData.append('clientType', client.typeOfClient);
        bodyFormData.append('countryCodeId', client.countryCodeId ? client.countryCodeId : "");
        bodyFormData.append('phone', client.phoneNumber ? client.phoneNumber : "");
        bodyFormData.append('email', client.email ? client.email : "");
        bodyFormData.append('logo', client.image ? client.image : null);

        !isCreating && bodyFormData.append('id', clientRedux.id);
        (!isCreating && clientRedux.fileId) && bodyFormData.append('fileId', clientRedux.fileId);
        const res = isCreating
            ? await postAxios(postClientURL, bodyFormData)
            : await putAxios(putClientURL, bodyFormData);
        if (res.status === 200) {
            const token = res.headers.refreshtoken;
            const expiration = res.headers.expirationToken;
            (token && expiration) && dispatch(renewTokenAction(token, expiration));
            return dispatch({
                type: CREATE_UPDATE_CLIENT_SUCCESS,
                response: res.data.response
            });
        }
        throw new Error();
    } catch (error) {
        if (error.response && error.response.status === 401)
            dispatch(logoutAction());
        dispatch({
            type: CLIENT_ERROR,
            payload: error.response ? error.response.data.message : "common:errorSavingClient"
        });
    }
};
export const getAdmin = (id) => async (dispatch) => {
    //loading && dispatch({ type: LOADING_CLIENT });
    try {
        const res = await getAxios(getOrganizationAdminURL, { id: id });
        if (res.status === 200) {
            //console.log("res admin",res,res.data)
            const token = res.headers.refreshtoken;
            const expiration = res.headers.expirationToken;
            (token && expiration) && dispatch(renewTokenAction(token, expiration));
            return dispatch({
                type: CLIENT_ADMIN_SUCCESS,
                payload: res.data.response,
              
            });
        }
        throw new Error();
    } catch (error) {
        if (error.response && error.response.status === 401)
            dispatch(logoutAction());
        dispatch({
            type: CLIENT_ERROR,
            payload: error.response ? error.response.data.message : "common:errorLoadingClients"
        });
    }
};
export const getCompanyById = (id) => async (dispatch) => {
    dispatch({ type: CLIENT_LOADING });
    try {
        const res = await getAxios(getOrganizationIdURL, { id: id });
        const resDetail = await getAxios(getOrganizationDetailURL, { id: id });
        if (res.status === 200 && resDetail.status === 200) {
            return dispatch({
                type: GET_COMPANY_BY_ID_SUCCESS,
                payload: {
                    ...res.data.response,
                    'usersCount': resDetail.data.response.users,
                    'clientsCount': resDetail.data.response.clients,
                    'enabledClientsCount': resDetail.data.response.usersAvailable,
                    'clientStaffCount': resDetail.data.response.clientStaff,
                }
            });
        }
        throw new Error();
    } catch (error) {
        dispatch({
            type: CLIENT_ERROR,
            payload: error.response ? error.response.data.message : "common:errorLoadingClient"
        });
    }
};

export const getClientById = (id) => async (dispatch) => {
    dispatch({ type: LOADING_CLIENT });
    try {
        const res = await getAxios(getClientByIdURL, { id: id });
        if (res.status === 200) {
            const token = res.headers.refreshtoken;
            const expiration = res.headers.expirationToken;
            (token && expiration) && dispatch(renewTokenAction(token, expiration));
            return dispatch({
                type: CLIENT_GET_BY_ID,
                payload: res.data.response
            });
        }
        throw new Error();
    } catch (error) {
        if (error.response && error.response.status === 401)
            dispatch(logoutAction());
        dispatch({
            type: CLIENT_ERROR,
            payload: error.response ? error.response.data.message : "common:errorLoadingClient"
        });
    }
};

export const changeStatusClient = (id) => async (dispatch, getState) => {
    dispatch({ type: LOADING_CLIENT });
    try {
        const { client } = getState().client;
        const res = !client.disabled
            ? await putAxios(putClientDisableURL + id)
            : await putAxios(putClientActiveURL + id);

        if (res.status === 200) {
            const token = res.headers.refreshtoken;
            const expiration = res.headers.expirationToken;
            (token && expiration) && dispatch(renewTokenAction(token, expiration));
            dispatch({
                type: CLIENT_STATUS_CHANGE,
                response: res.data.response
            });
            return dispatch(getClientById(id));
        }
        throw new Error();
    } catch (error) {
        if (error.response && error.response.status === 401)
            dispatch(logoutAction());
        dispatch({
            type: CLIENT_ERROR,
            payload: error.response ? error.response.data.message : "common:errorChangingClientStatus"
        });
    }
}

export const changeClientPage = (page) => (dispatch) => {
    dispatch({
        type: CLIENT_CHANGE_PAGE,
        activePage: page - 1
    });
};

export const changeIsCreating = (state) => (dispatch) => {
    dispatch({
        type: IS_CREATING_CLIENT,
        payload: state
    });
};

export const clearClients = () => dispatch => {
    dispatch({
        type: CLEAR_CLIENTS
    })
}