import React, { useEffect, useState, createRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Grid, Typography, InputLabel,
    Button, Container, Divider
} from "@mui/material";
import TextFieldComponent from '../../components/TextField';
import Title from '../../components/Title';
import iOS from '../../assets/Images/iOS.png';
import Android from '../../assets/Images/Android.png';
import { ReactComponent as CheckIcon } from '../../assets/Icons/checkCircleGreen.svg';
import { getLenguages } from '../../redux/LenguageDuck';
import { getAppSettingById, saveAppSetting, logoChangeRequest } from '../../redux/AppConfigurationDuck';
import Icon from '../../components/Icon';
import { getFileURL } from '../../constants/apiUrls';
import './AppConfiguration.css';
import Checkbox from '../../components/Checkbox';
import { useTranslation } from 'react-i18next';

const AppConfiguration = () => {

    const { t } = useTranslation(['configApp', 'button']);
    const appSetting = useSelector(store => store.appSetting.appSetting);
    const system = useSelector(store => store.system.system);
    const logoRequest = useSelector(store => store.appSetting.logoRequest);
    const lenguages = useSelector(store => store.lenguage.lenguages);
    const [configuration, setConfiguration] = useState({
        name: '', languageId: null, image: '', imageUrl: ''
    });
    const [configurationError, setConfigurationError] = useState({});
    const [primaryLng, setPrimaryLng] = useState({});
    const [isAndroid, setIsAndroid] = useState(false);
    const imgRef = createRef();
    const dispatch = useDispatch();

    const onImageChange = event => {
        if (!!event.target.files[0]) {
            const { name } = event.target;
            const file = event.target.files[0];
            let img = new Image();
            img.src = window.URL.createObjectURL(file);
            img.onload = () => {
                if ('png' === file.type.split('/')[1]) {
                    if (img.width === 1024 && img.height === 1024) {
                        setConfiguration({
                            ...configuration,
                            [name]: file,
                            imageUrl: URL.createObjectURL(file)
                        });
                        setConfigurationError({ ...configurationError, image: '' });
                    } else {
                        setConfigurationError({ ...configurationError, image: t("errorDimensions") + ` (${img.width} x ${img.height})` });
                    }
                } else {
                    setConfigurationError({ ...configurationError, image: t("errorImgFormat") });
                }
            }
        }
    };

    const onChange = e => {
        const { name, value } = e.target;
        const tempValue = configuration.languageId === value ? null : value;
        setConfiguration({ ...configuration, [name]: tempValue });
    };

    const onSubmit = () => {
        if (validationAppConfig()) {
            dispatch(saveAppSetting(configuration));
        } else {
            document.querySelector('#principal').scrollIntoView({
                behavior: 'smooth'
            });
        }
    };

    const validationAppConfig = () => {
        var error = { languageId: '', name: '', image: '' };
        const allowedExtensions = /(.png)$/i;
        const maxSizeKB = 5000;

        if (configuration.name === null || configuration.name.length === 0
            || /^\s+$/.test(configuration.name))
            error.name = t("errorAppName");
        if (configuration.name.length > 30)
            error.name = t("errorNameLength");
        if (!configuration.imageUrl && (configuration.image === null || configuration.image.length === 0))
            error.image = t("errorImg");
        else if (!!configuration.image) {
            const size = configuration.image.size;
            const sizeKB = parseInt(size / 1024);
            if (imgRef.current.clientWidth === 120 || imgRef.current.clientHeight === 120)
                error.image = t("errorDimensions") + ` (${imgRef.current.clientWidth} x ${imgRef.current.clientHeight} px)`;
            if (!allowedExtensions.exec(configuration.image.name))
                error.image = t("errorImgExt");
            else if (sizeKB > maxSizeKB)
                error.image = t("errorImgSize");
        }
        setConfigurationError({ ...configurationError, ...error });
        return !error.languageId && !error.name && !error.image;
    };

    useEffect(() => {
        dispatch(getLenguages());
        dispatch(getAppSettingById());
    }, [dispatch]);

    const cancel = () => {
        if (appSetting) {
            document.querySelector('#principal').scrollIntoView({
                behavior: 'smooth'
            });
            //dispatch(showMessage());
            setConfiguration({
                id: appSetting.id,
                name: appSetting.name,
                languageId: appSetting.secundaryLanguageId,
                imageUrl: getFileURL + appSetting.fileId,
            });
        }
    }

    useEffect(() => {
        if (appSetting) {
            setConfiguration({
                id: appSetting.id,
                name: appSetting.name,
                languageId: appSetting.secundaryLanguageId,
                imageUrl: getFileURL + appSetting.fileId,
            });
        }
    }, [appSetting]);

    useEffect(() => {
        if (system) {
            if (lenguages.length > 0 && system) {
                setPrimaryLng(lenguages.find(item => item.key === parseInt(
                    system.defaultLanguageId
                )));
            }
        }
    }, [system, lenguages]);

    return (
        <Container>
            <Title title={t('configApp:title')} style={{ marginLeft: 26 }} />
            <Grid item xs={12}>
                <div className="container-app-div">
                    <Typography className="app-form-title">{t('configApp:subtitle1')}</Typography>
                    <Typography className="app-form-subtitle">
                        {t('configApp:subtitle2')}
                    </Typography>
                    <div style={{ display: 'flex' }}>
                        {(primaryLng && primaryLng.key) &&
                            <Checkbox
                                name='primaryLng'
                                value={primaryLng.key}
                                checkboxCheckedValue={parseInt(primaryLng.key)}
                                checkboxLabel={primaryLng.value}
                                disabled={true}
                                onChange={() => null}
                            />
                        }
                    </div>
                    <p className='app-error-text'>{configurationError.languageId}</p>
                    <Typography className="app-form-subtitle">
                        {t('configApp:subtitle10')}
                    </Typography>
                    <div style={{ display: 'flex' }}>
                        {lenguages.map(item => (
                            item.key !== parseInt(primaryLng.key) &&
                            <Checkbox
                                key={item.key}
                                name='languageId'
                                value={item.key}
                                onChange={onChange}
                                checkboxCheckedValue={parseInt(configuration.languageId)}
                                checkboxLabel={item.value}
                            />
                        ))}
                    </div>
                    <Typography className="config-app-description" style={{ marginTop: 25 }}>
                        {t('configApp:subtitle3')}
                    </Typography>
                </div>
                <div className="container-app-div" style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                    <div style={{ width: '100%' }}>
                        <Typography className="app-form-title">{t('configApp:subtitle4')}</Typography>
                        <Typography className="app-config-alert">
                            {t('configApp:alert3Lbl')}
                            {t('configApp:alert1Lbl')}
                        </Typography>
                        {!configuration.id &&
                            <Typography className="app-form-subtitle">
                                {t('configApp:subtitle5')}
                            </Typography>
                        }
                        <div style={{ width: '26%', marginTop: 17 }} className={configuration.id ? 'noBorderInput' : ''}>
                            <InputLabel className="label-app">{t('configApp:appNameLbl')}</InputLabel>
                            <TextFieldComponent name="name"
                                maxLength={30}
                                placeholder={t('configApp:appNamePlh')}
                                valueInput={configuration.name}
                                disabled={configuration.id ? true : false}
                                callback={onChange.bind(this)}
                                errorInput={configurationError.name}
                            />
                        </div>
                        <Typography className="config-app-description" style={{ marginTop: 15 }}>
                            {t('configApp:subtitle6')}
                        </Typography>
                    </div>
                    <div style={{ width: '50%' }}>
                        <Typography className="app-form-title">{t('configApp:subtitle7')}</Typography>
                        {!configuration.id ?
                            <>
                                <Typography className="app-form-subtitle">{t('configApp:subtitle8')}</Typography>
                                <Button variant="contained" className="button-app-save"
                                    component="label" >
                                    <input type="file" accept="image/png"
                                        name="image" onChange={onImageChange}
                                        style={{ display: "none" }}
                                    />
                                    {t('configApp:addImageBtn')}
                                </Button>
                                <Typography className="config-app-description" style={{ marginTop: 25 }}>
                                    {t('configApp:subtitle9')}
                                </Typography>
                                <p className='app-error-text'>{configurationError.image}</p>
                            </>
                            :
                            <>
                                <Button variant="contained" className="button-app-save"
                                    style={{ marginTop: 0, marginBottom: 10 }}
                                    disabled={logoRequest} onClick={() => dispatch(logoChangeRequest())}>
                                    {t('configApp:changeBtn')}
                                </Button>
                                {logoRequest &&
                                    <Typography className="app-config-alert-description" style={{ marginTop: 25 }}>
                                        <Icon Icon={CheckIcon} className='app-config-alert-icon' />
                                        {t('configApp:alert2Lbl')}
                                    </Typography>
                                }
                            </>
                        }
                    </div>
                    <div style={{
                        width: '40%', display: 'flex',
                        flexDirection: 'column', alignItems: 'center'
                    }}>
                        <Typography className="config-app-description" style={{ marginBottom: 15 }}>
                            {t('configApp:previewLbl')}
                        </Typography>
                        <div style={{ width: 368, height: 187, position: 'relative' }}>
                            <img src={isAndroid ? Android : iOS} alt="background-logo" />
                            {configuration.imageUrl &&
                                <div className={`${isAndroid ? 'config-app-logo-div-android' : 'config-app-logo-div-ios'}`}>
                                    <img src={configuration.imageUrl} alt="logo" className={`${isAndroid ? 'config-app-logo-android' : 'config-app-logo-ios'}`}
                                        ref={imgRef} />
                                    <label className={`${isAndroid ? 'app-name-android' : 'app-name-ios'}`}>{configuration.name}</label>
                                </div>
                            }
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', marginTop: 15 }}>
                            <label className={`device-name ${!isAndroid && 'device-name-active'}`}
                                onClick={() => setIsAndroid(false)}
                            >
                                iOS
                            </label>
                            <Divider orientation="vertical" style={{ borderColor: '#CED4DA', margin: '0 8px' }} />
                            <label className={`device-name ${isAndroid && 'device-name-active'}`}
                                onClick={() => setIsAndroid(true)}>
                                Android
                            </label>
                        </div>
                    </div>
                </div>
                <div style={{ display: 'flex' }}>
                    <Button variant="contained" className="button-app-save" style={{ marginRight: 18 }}
                        onClick={() => onSubmit()}>
                        {t('button:saveBtn')}
                    </Button>
                    <Button variant="contained" className="button-app-cancel"
                        onClick={() => cancel()}>
                        {t('button:cancelBtn')}
                    </Button>
                </div>
            </Grid>
        </Container>
    )
};

export default AppConfiguration;