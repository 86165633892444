import React from 'react';
import { TextField, MenuItem, InputAdornment } from '@mui/material';
import './SelectField.css';

const SelectField = ({ name, valueInput, errorInput, simple = false,
    onChange, placeholder = '', options = [], Icon = null, className }) => {

    return (
        <TextField
            variant='outlined'
            margin='normal'
            select
            name={name}
            value={(valueInput && options.length > 0) ? valueInput : (placeholder ? 'none' : '')}
            onChange={onChange}
            error={!!errorInput}
            helperText={errorInput}
            className={`select-text-field ${Icon ? 'icon' : ''} ${className}`}
            InputProps={
                Icon &&
                {
                    startAdornment: (
                        <InputAdornment position="start" style={{ cursor: 'pointer' }}>
                            <Icon />
                        </InputAdornment>
                    ),
                }
            }
        >
            {placeholder &&
                <MenuItem value='none' className='select-field-placeholder'>
                    {placeholder}
                </MenuItem>
            }
            {(simple && options.length > 0) ?
                options.map((option) => (
                    <MenuItem key={option} value={option} className='select-field-item'>
                        {option}
                    </MenuItem>
                ))
                :
                options.map((option) => (
                    <MenuItem key={option.key} value={option.key} className='select-field-item'>
                        {option.value}
                    </MenuItem>
                ))
            }
        </TextField>
    )
};

export default SelectField;