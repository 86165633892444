import React from "react";
import { withRouter } from "react-router";
import { Link } from 'react-router-dom';

import {
    Typography, Button, TableContainer, TableRow,
    TableCell, Table, TableHead, TableBody
} from "@mui/material";
import CircleIcon from '@mui/icons-material/Circle';
import * as ROUTES from '../../../constants/routes';
import './Table.css';
import { useTranslation } from 'react-i18next';
const TableUser = (props) => {
    const { t } = useTranslation(['user','common']);
    const { userList } = props;

    const status = [
        { status: t("common:commonStatusProcessing"), color: '#6C757D' },
        { status: t("common:commonStatusActive"), color: '#28A745' },
        { status: t("common:commonStatusInactive"), color: '#6C757D' },
    ];

    return (
        <TableContainer>
            <Table aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell style={{ width: '40%' }}
                            className='table-app-title-header'>{t("headerTbl1")}</TableCell>
                        <TableCell style={{ width: 'calc(100% / 4)' }}
                            className='table-app-title-header'>{t("headerTbl2")}</TableCell>
                        <TableCell style={{ width: 'calc(100% / 4)' }}
                            className='table-app-title-header'>{t("headerTbl3")}</TableCell>
                        <TableCell style={{ width: 'calc(100% / 4)' }}
                            className='table-app-title-header' align="right">{t("headerTbl4")}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {userList.length === 0 &&
                        <TableRow key='empty'>
                            <TableCell colSpan={4} style={{ textAlign: 'center' }}>
                            {t("common:commonNoResultsLbl")}
                                </TableCell>
                        </TableRow>
                    }
                    {userList.map(item => (
                        <TableRow key={item.id}>
                            <TableCell component="th" scope="row" className='table-row-user'>
                                {item.firstName + ' ' + item.lastName}
                            </TableCell>
                            <TableCell component="th" scope="row" className='table-row-user'>
                                {item.organizationUser && item.organizationUser.position}
                            </TableCell>
                            <TableCell component="th" scope="row" className='table-row-user'>
                                <div className='table-row-cell-user' style={{ color: status[item.status].color }}>
                                    <CircleIcon style={{ fontSize: 10, marginRight: 11 }} />
                                    <Typography>
                                        {status[item.status].status}
                                    </Typography>
                                </div>
                            </TableCell>
                            <TableCell component="th" scope="row" align="right">
                                <Button className='button-app-see-detail' component={Link}
                                    to={ROUTES.USERS_UPDATE.replace(':id', item.id)}
                                >
                                    {t("common:commonSeeLbl")}
                                </Button>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

export default withRouter(TableUser)