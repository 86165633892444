import React from "react";
import { Typography } from "@mui/material";
import IconSvg from '../../../components/Icon';

const AccessRow = ({ title, Icon = null }) => {

    return (
        <div style={{ display: 'flex', alignItems: 'center', margin: '8px 0px 11px 8px' }}>
            <IconSvg Icon={Icon} className="user-form-business-icon" />
            <Typography className="user-form-business-text">
                {title}
            </Typography>
        </div>
    );
}
export default (AccessRow);