import {
    getAuthorizationsURL, getAuthorizationByIdURL, postAuthorizationURL
} from '../constants/apiUrls';
import { postAxios, getAxios } from '../services/Axios';
import { logoutAction, renewTokenAction } from './AuthDuck'

// constantes
const initialState = {
    authorizations: [],
    authorization: null,
    pages: 1,
    activePage: 0,
    isCreated: false,
    response: null,
    loading: false,
    error: null,
};

// types
const AUTHORIZATION_SUCCESS = 'AUTHORIZATION_SUCCESS';
const CREATE_AUTHORIZATION_SUCCESS = 'CREATE_AUTHORIZATION_SUCCESS';
const AUTHORIZATION_GET_BY_ID = 'AUTHORIZATION_GET_BY_ID';
const AUTHORIZATION_ERROR = 'AUTHORIZATION_ERROR';
const LOADING_AUTHORIZATION = 'LOADING_AUTHORIZATION';
const CLEAR_RESPONSE = 'CLEAR_RESPONSE';
const AUTHORIZATION_CHANGE_PAGE = 'AUTHORIZATION_CHANGE_PAGE';
const LOG_OUT = 'LOG_OUT';
const INITIAL_STATE = 'INITIAL_STATE';

// reducer
export default function reducer(state = initialState, action) {
    switch (action.type) {
        case LOADING_AUTHORIZATION:
            return { ...state, loading: true, error: null };
        case AUTHORIZATION_SUCCESS:
            return {
                ...state, authorizations: action.payload, isCreated: false,
                pages: action.pages, error: null, loading: false
            };
        case CREATE_AUTHORIZATION_SUCCESS:
            return {
                ...state, isCreated: true, response: action.response,
                loading: false, error: null,
                authorizations: [], pages: 1, activePage: 0
            };
        case AUTHORIZATION_GET_BY_ID:
            return {
                ...state, authorization: action.payload,
                isCreated: false, error: null, loading: false,
                authorizations: [], pages: 1, activePage: 0
            };
        case AUTHORIZATION_CHANGE_PAGE:
            return { ...state, activePage: action.activePage };
        case CLEAR_RESPONSE:
            return { ...state, response: null };
        case AUTHORIZATION_ERROR:
            return {
                ...state, error: action.payload,
                response: action.payload, loading: false
            };
        case LOG_OUT:
            return { ...initialState };
        case INITIAL_STATE:
            return {
                ...state,
                authorizations: [],
                authorization: null,
                pages: 1,
                activePage: 0,
            };
        default:
            return state;
    }
};

export const getAuthorizations = (filterBy = [], page = 0, pageSize = 10, loading = false) => async (dispatch, getState) => {
    loading && dispatch({ type: LOADING_AUTHORIZATION });


    try {
        const { user } = getState().auth;
        const res = await postAxios(getAuthorizationsURL, {
            "organizationId": user.organizationId,
            "page": page,
            "pageSize": pageSize,
            "filterBy": filterBy,
            "orderBy": "",
            "orderDesc": true
        });
        if (res.status === 200) {
            const token = res.headers.refreshtoken;
            const expiration = res.headers.expirationToken;
            (token && expiration) && dispatch(renewTokenAction(token, expiration));
            return dispatch({
                type: AUTHORIZATION_SUCCESS,
                payload: res.data.response,
                pages: res.data.totalPages
            });
        }
        throw new Error();
    } catch (error) {
        if (error.response && ((error.response.data.message === "auth:errorUnauthDisabled" || error.response.data.message === "auth:errorUnauth") && error.response.status === 401))
            dispatch(logoutAction());
        dispatch({
            type: AUTHORIZATION_ERROR,
            payload: error.response ? error.response.data.message : "auth:errorLoadingAuths"
        });
    }
};

export const getAuthorizationById = (id) => async (dispatch) => {
    dispatch({ type: LOADING_AUTHORIZATION });

    try {
        const res = await getAxios(getAuthorizationByIdURL + id);
        if (res.status === 200) {
            const token = res.headers.refreshtoken;
            const expiration = res.headers.expirationToken;
            (token && expiration) && dispatch(renewTokenAction(token, expiration));
            return dispatch({
                type: AUTHORIZATION_GET_BY_ID,
                payload: res.data.response
            });
        }
        throw new Error();
    } catch (error) {
        if (error.response && ((error.response.data.message === "auth:errorUnauthDisabled" || error.response.data.message === "auth:errorUnauth") && error.response.status === 401))
            dispatch(logoutAction());
        dispatch({
            type: AUTHORIZATION_ERROR,
            payload: error.response ? error.response.data.message : "auth:errorLoadingAuth"
        });
    }
};

export const saveAuthorization = (authorization) => async (dispatch, getState) => {
    dispatch({ type: LOADING_AUTHORIZATION });

    try {
        const userRedux = getState().auth.user;
        const filesIds = [];
        const receiverIds = [];

        authorization.selectedFiles.forEach(doc => {
            filesIds.push(doc.id);
        });

        authorization.receivers.forEach(rec => {
            receiverIds.push(rec.id);
        });

        const res = await postAxios(postAuthorizationURL, {
            'userId': userRedux.id,
            'companyId': authorization.companyId,
            'officeId': authorization.officeId,
            'clientId': authorization.clientId,
            'title': authorization.title,
            'description': authorization.description,
            'filesIds': filesIds,
            'receiverIds': receiverIds
        });

        if (res.status === 200) {
            const token = res.headers.refreshtoken;
            const expiration = res.headers.expirationToken;
            (token && expiration) && dispatch(renewTokenAction(token, expiration));
            return dispatch({
                type: CREATE_AUTHORIZATION_SUCCESS,
                response: res.data.response
            });
        }
        throw new Error();
    } catch (error) {
        if (error.response && error.response.status === 401)
            dispatch(logoutAction());
        dispatch({
            type: AUTHORIZATION_ERROR,
            payload: error.response ? error.response.data.message : "auth:errorSavingAuth"
        });
    }
};

export const changeAuthorizationPage = (page) => (dispatch) => {
    dispatch({
        type: AUTHORIZATION_CHANGE_PAGE,
        activePage: page - 1
    });
};