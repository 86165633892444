import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';

const permissionList = [
    {
        route: ROUTES.SYSTEM_CONFIGURATION,
        permission: 'Permissions.Configuration'
    },
    {
        route: ROUTES.APP_CONFIGURATION,
        permission: 'Permissions.AppConfiguration'
    },
    {
        route: ROUTES.BUSINESS,
        permission: 'Permissions.Companies'
    },
    {
        route: ROUTES.MOBILE_CONTENT,
        permission: 'Permissions.Mobile'
    },
    {
        route: ROUTES.USERS,
        permission: 'Permissions.Users'
    },
    {
        route: ROUTES.PERMITS,
        permission: 'Permissions.Permissions'
    },
    {
        route: ROUTES.USER_ASSIGNMENT,
        permission: 'Permissions.AssignUsers'
    },
    {
        route: ROUTES.USER_PROFILE,
        permission: 'Permissions.Users'
    },
    {
        route: ROUTES.LOGS,
        permission: 'Permissions.Logbook'
    },
    {
        route: ROUTES.CLIENTS,
        permission: 'Permissions.Clients'
    },
    {
        route: ROUTES.CUSTOMER_STAFF,
        permission: 'Permissions.ClientStaff'
    },
    {
        route: ROUTES.MATTERS,
        permission: 'Permissions.Subject'
    },
    {
        route: ROUTES.NOTIFICATIONS,
        permission: 'Permissions.Notifications'
    },
    {
        route: ROUTES.AUTHORIZATIONS,
        permission: 'Permissions.Authorizations'
    },
    {
        route: ROUTES.DOCUMENTS,
        permission: 'Permissions.Documents'
    }
];

const permissionExceptions = [
    ROUTES.DASHBOARD,
    ROUTES.HOME,
    ROUTES.USER_PROFILE,
    ROUTES.HELP_SYSTEM_CONFIGURATION,
    ROUTES.HELP_APP_CONFIGURATION,
    ROUTES.HELP_BUSINESS,
    ROUTES.HELP_MOBILE_CONTENT,
    ROUTES.HELP_USERS,
    ROUTES.HELP_PERMITS,
    ROUTES.HELP_USER_ASSIGNMENT,
    ROUTES.HELP_LOGS,
    ROUTES.HELP_CLIENTS,
    ROUTES.HELP_CUSTOMER_STAFF,
    ROUTES.HELP_MATTERS,
    ROUTES.HELP_NOTIFICATIONS,
    ROUTES.HELP_AUTHORIZATIONS,
    ROUTES.HELP_DOCUMENTS,
];

/**
 * This method is the middleware that is valid if the user can access the path
 * @param {*} params Different parameters that make up a Route
 */
export const ProtectedRoute = ({ component, path, ...rest }) => {
    const reduxToken = useSelector(store => store.auth.token);
    const permission = useSelector(store => store.auth.permission);
    const token = localStorage.getItem('token');
    if ((token && reduxToken) && (token === reduxToken)) {
        const item = permissionList.find(p => path.includes(p.route));
        if (((item && permission) && permission.permissions.find(per => per.permissionName === item.permission)) ||
            permissionExceptions.includes(path)) {
            return <Route component={component} path={path} {...rest} />
        }
        return null;
    }
    return <Redirect to={ROUTES.LOGIN} {...rest} />
};