import React from 'react';
import { Typography } from '@mui/material';

const TitleModule = ({ title, style }) => {

    return (
        <div style={{ width: '100%', marginBottom: '2%', ...style }}>
            <Typography style={{
                color: '#231E60',
                fontWeight: 'normal',
                fontSize: '27.9915px',
                lineHeight: '39px',
            }} noWrap>
                {title}
            </Typography>
        </div>
    )
}

export default TitleModule;