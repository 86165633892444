//Just commented in case Carlos needs this import later, not added by JH 
//import { SignalCellularNullOutlined } from '@mui/icons-material';
import {
    getDocumentsURL, getFoldersURL, postFolderCreateURL,
    postFileCreateURL, deleteFileURL, deleteFolderURL,
    validateFolderURL, getURLDocumentURL
} from '../constants/apiUrls';
import { postAxios, deleteAxios, getAxios, getBlobAxios } from '../services/Axios';
import { logoutAction, renewTokenAction } from './AuthDuck'
import fileDownload from 'js-file-download';

// constantes
const initialState = {
    documents: [],
    folders: [],
    foldersFilters: [],
    folderDocs: [],
    folderId: null,
    folderValidation: '',
    selectedFolderId: '',

    updatedFileFolder: null,
    pagesDoc: 1,
    activePageDoc: 0,

    pagesFolder: 1,
    activePageFolder: 0,

    pagesFolderDoc: 1,
    activePageFolderDoc: 0,

    totalFolder: 0,
    totalFilterFolder: 0,
    totalFileFilter: 0,

    isCreatedFolder: false,
    isCreatedFile: false,

    deletedFolder: false,
    isDeletedFile: false,

    response: null,
    loading: false,
    error: null,
};

// types
const DOCUMENT_SUCCESS = 'DOCUMENT_SUCCESS';
const DOCUMENT_CHANGE_PAGE = 'DOCUMENT_CHANGE_PAGE';
const FOLDER_SUCCESS = 'FOLDER_SUCCESS';
const VALIDATE_FOLDER_SUCCESS = 'VALIDATE_FOLDER_SUCCESS';
const FOLDER_FILTER_SUCCESS = 'FOLDER_FILTER_SUCCESS';
const FOLDER_CHANGE_PAGE = 'FOLDER_CHANGE_PAGE';
const FOLDER_CREATE_SUCCESS = 'FOLDER_CREATE_SUCCESS';
const DOCUMENT_CREATE_SUCCESS = 'DOCUMENT_CREATE_SUCCESS';
const DOCUMENT_DOWNLOADABLE_SUCCESS = 'DOCUMENT_DOWNLOADABLE_SUCCESS';
const DOCUMENT_ERROR = 'DOCUMENT_ERROR';
const LOADING_DOCUMENT = 'LOADING_DOCUMENT';
const DOCUMENTS_BY_FOLDER_SUCCESS = 'DOCUMENTS_BY_FOLDER_SUCCESS';
const CLEAR_RESPONSE = 'CLEAR_RESPONSE';
const CLOSE_MODAL_DOC = 'CLOSE_MODAL_DOC';
const DOCUMENT_DELETE_SUCCESS = 'DOCUMENT_DELETE_SUCCESS';
const DOCUMENT_DELETE_BY_FOLDER_SUCCESS = 'DOCUMENT_DELETE_BY_FOLDER_SUCCESS';
const FOLDER_DOCUMENT_CHANGE_PAGE = 'FOLDER_DOCUMENT_CHANGE_PAGE';
const SET_FOLDER_ID = 'SET_FOLDER_ID';
const CLEAR_FOLDER_ID = 'CLEAR_FOLDER_ID';
const DELETE_FOLDER_BY_ID_SUCCESS = 'DELETE_FOLDER_BY_ID_SUCCESS';
const SET_IS_DELETED = 'SET_IS_DELETED';
const LOG_OUT = 'LOG_OUT';
const INITIAL_STATE = 'INITIAL_STATE';

// reducer
export default function reducer(state = initialState, action) {
    switch (action.type) {
        case LOADING_DOCUMENT:
            return { ...state, loading: true, error: null };
        case DOCUMENT_SUCCESS:
            return {
                ...state, documents: action.payload, activePageDoc: action.activePage, totalFileFilter: action.totalFilter,
                pagesDoc: action.pages, error: null, loading: false
            };
        case DOCUMENT_DOWNLOADABLE_SUCCESS:
            return {
                ...state, error: null, loading: false
            };
        case DOCUMENTS_BY_FOLDER_SUCCESS:
            return {
                ...state, folderDocs: action.payload, updatedFileFolder: null, activePageFolderDoc: action.activePage,
                pagesFolderDoc: action.pages, folderId: action.folderId, error: null, loading: false
            }
        case FOLDER_DOCUMENT_CHANGE_PAGE:
            return { ...state, activePageFolderDoc: action.activePage };
        case DOCUMENT_CHANGE_PAGE:
            return { ...state, activePageDoc: action.activePage };
        case FOLDER_SUCCESS:
            return {
                ...state, folders: action.payload, activePageFolder: action.activePage,
                pagesFolder: action.pages, error: null, loading: false,
                totalFolder: action.totalFolder, totalFilterFolder: action.totalFilterFolder,
                deletedFolder: false
            };
        case VALIDATE_FOLDER_SUCCESS:
            return { ...state, folderValidation: action.folderValidation };
        case FOLDER_FILTER_SUCCESS:
            return {
                ...state, foldersFilters: action.payload, error: null, loading: false
            };
        case FOLDER_CHANGE_PAGE:
            return { ...state, activePageFolder: action.activePage };

        case FOLDER_CREATE_SUCCESS:
            return {
                ...state, isCreatedFolder: true, response: action.response,
                selectedFolderId: action.id, loading: false, error: null
            };
        case DOCUMENT_CREATE_SUCCESS:
            return {
                ...state, isCreatedFile: true, updatedFileFolder: action.folderId, response: action.response,
                loading: false, error: null
            };
        case DOCUMENT_DELETE_SUCCESS:
            return {
                ...state, response: action.response,
                isDeletedFile: true, loading: false, error: null
            };
        case DOCUMENT_DELETE_BY_FOLDER_SUCCESS:
            return {
                ...state, response: action.response, updatedFileFolder: action.folderId,
                isDeletedFile: true, loading: false, error: null
            };
        case SET_IS_DELETED:
            return { ...state, isDeletedFile: false };
        case DELETE_FOLDER_BY_ID_SUCCESS:
            return {
                ...state, response: action.response, loading: false, error: null,
                deletedFolder: true
            };
        case DOCUMENT_ERROR:
            return {
                ...state, error: action.payload, loading: false,
                response: action.payload
            };
        case CLOSE_MODAL_DOC:
            return {
                ...state, isCreatedFolder: false, isCreatedFile: false,
                fileId: null
            };
        case SET_FOLDER_ID:
            return {
                ...state, selectedFolderId: ''
            };
        case CLEAR_FOLDER_ID:
            return {
                ...state, folderId: ''
            };
        case CLEAR_RESPONSE:
            return { ...state, response: null };
        case LOG_OUT:
            return { ...initialState };
        case INITIAL_STATE:
            return {
                ...state,
                documents: [],
                folders: [],
                foldersFilters: [],
                folderDocs: [],
                folderId: null,
                folderValidation: '',
                selectedFolderId: '',

                updatedFileFolder: null,
                pagesDoc: 1,
                activePageDoc: 0,

                pagesFolder: 1,
                activePageFolder: 0,

                pagesFolderDoc: 1,
                activePageFolderDoc: 0,

                totalFolder: 0,
                totalFilterFolder: 0,
                totalFileFilter: 0,
            };
        default:
            return state;
    }
};

export const getFolders = (filterBy = [], page = 0, pageSize = 10000, loading = false) =>
    async (dispatch, getState) => {
        loading && dispatch({ type: LOADING_DOCUMENT });
        try {
            const { user } = getState().auth;
            const res = await postAxios(getFoldersURL, {
                "organizationId": user.organizationId,
                "page": page,
                "pageSize": pageSize,
                "filterBy": filterBy,
                "orderBy": "",
                "orderDesc": true
            });
            if (res.status === 200) {
                const token = res.headers.refreshtoken;
                const expiration = res.headers.expirationToken;
                (token && expiration) && dispatch(renewTokenAction(token, expiration));
                return dispatch({
                    type: FOLDER_SUCCESS,
                    payload: res.data.response,
                    pages: res.data.totalPages,
                    activePage: (res.data.totalPages < page + 1) ? 0 : page,
                    totalFolder: res.data.total,
                    totalFilterFolder: res.data.totalFilter
                });
            }
            throw new Error();
        } catch (error) {
            if (error.response && error.response.status === 401)
                dispatch(logoutAction());
            dispatch({
                type: DOCUMENT_ERROR,
                payload: error.response ? error.response.data.message : "common:errorLoadingFolders"
            });
        }
    };

export const getFoldersFilter = (filterBy = [], page = 0, pageSize = 10000, loading = false) =>
    async (dispatch, getState) => {
        loading && dispatch({ type: LOADING_DOCUMENT });
        try {
            const { user } = getState().auth;
            const res = await postAxios(getFoldersURL, {
                "organizationId": user.organizationId,
                "page": page,
                "pageSize": pageSize,
                "filterBy": filterBy,
                "orderBy": "",
                "orderDesc": true
            });
            if (res.status === 200) {
                const token = res.headers.refreshtoken;
                const expiration = res.headers.expirationToken;
                (token && expiration) && dispatch(renewTokenAction(token, expiration));
                return dispatch({
                    type: FOLDER_FILTER_SUCCESS,
                    payload: res.data.response,
                });
            }
            throw new Error();
        } catch (error) {
            if (error.response && error.response.status === 401)
                dispatch(logoutAction());
            dispatch({
                type: DOCUMENT_ERROR,
                payload: error.response ? error.response.data.message : "common:errorLoadingFolders"
            });
        }
    };

export const getDocuments = (filterBy = [], orderBy, page = 0, pageSize = 10000, loading = false, service = '') =>
    async (dispatch, getState) => {
        loading && dispatch({ type: LOADING_DOCUMENT });
        try {
            const { user } = getState().auth;
            const res = await postAxios(getDocumentsURL, {
                "organizationId": user.organizationId,
                "page": page,
                "pageSize": pageSize,
                "filterBy": filterBy,
                "service": service,
                "orderBy": orderBy,
                "orderDesc": true
            });
            if (res.status === 200) {
                const token = res.headers.refreshtoken;
                const expiration = res.headers.expirationToken;
                (token && expiration) && dispatch(renewTokenAction(token, expiration));
                return dispatch({
                    type: DOCUMENT_SUCCESS,
                    payload: res.data.response,
                    totalFilter: res.data.totalFilter,
                    pages: res.data.totalPages,
                    activePage: (res.data.totalPages < page + 1) ? 0 : page
                });
            }
            throw new Error();
        } catch (error) {
            if (error.response && error.response.status === 401)
                dispatch(logoutAction());
            dispatch({
                type: DOCUMENT_ERROR,
                payload: error.response ? error.response.data.message : "common:errorLoadingFiles"
            });
        }
    };

export const getDocumentDownload = (fileId = "", fileName = "", loading = false) =>
    async (dispatch) => {
        loading && dispatch({ type: LOADING_DOCUMENT });
        try {
            const resUrl = await getAxios(getURLDocumentURL + fileId);
            if (resUrl.status === 200) {
                const res = await getBlobAxios(resUrl.data.response);
                if (res.status === 200) {
                    const file = new Blob([res.data], { type: 'image/png' });
                    fileDownload(file, fileName);
                    const token = resUrl.headers.refreshtoken;
                    const expiration = resUrl.headers.expirationToken;
                    (token && expiration) && dispatch(renewTokenAction(token, expiration));
                    return dispatch({ type: DOCUMENT_DOWNLOADABLE_SUCCESS });
                }
            }
            throw new Error();
        } catch (error) {
            if (error.response && error.response.status === 401)
                dispatch(logoutAction());
            dispatch({
                type: DOCUMENT_ERROR,
                payload: error.response ? error.response.data.message : "common:errorDownloadingFile"
            });
        }
    };

export const selectFileId = (folderId, page = 0, pageSize = 10000, loading = false) => async (dispatch, getState) => {
    loading && dispatch({ type: LOADING_DOCUMENT });
    try {
        if (folderId) {
            const { user } = getState().auth;
            const res = await postAxios(getDocumentsURL, {
                "organizationId": user.organizationId,
                "page": page,
                "pageSize": pageSize,
                "filterBy": [{ "search": String(folderId), "filterBy": "folder" }],
                "orderBy": "",
                "service": "documents",
                "orderDesc": true
            });
            if (res.status === 200) {
                const token = res.headers.refreshtoken;
                const expiration = res.headers.expirationToken;
                (token && expiration) && dispatch(renewTokenAction(token, expiration));
                return dispatch({
                    type: DOCUMENTS_BY_FOLDER_SUCCESS,
                    payload: res.data.response,
                    pages: res.data.totalPages,
                    activePage: (res.data.totalPages < page + 1) ? 0 : page,
                    folderId: folderId
                });
            }
            throw new Error();
        } else {
            return dispatch({
                type: DOCUMENTS_BY_FOLDER_SUCCESS,
                payload: [],
                pages: 1,
                activePage: page,
                folderId: null
            });
        }
    } catch (error) {
        if (error.response && error.response.status === 401)
            dispatch(logoutAction());
        dispatch({
            type: DOCUMENT_ERROR,
            payload: error.response ? error.response.data.message : "common:errorLoadingFiles"
        });
    }
};

export const createFolder = (name, clientId) => async (dispatch, getState) => {
    dispatch({ type: LOADING_DOCUMENT });
    try {
        const { activePageFolder } = getState().doc;
        const res = await postAxios(postFolderCreateURL, {
            name: name,
            clientId: clientId
        });
        if (res.status === 200) {
            const token = res.headers.refreshtoken;
            const expiration = res.headers.expirationToken;
            (token && expiration) && dispatch(renewTokenAction(token, expiration));
            dispatch(getFoldersFilter([{ search: String(clientId), filterBy: 'client' }]));
            dispatch(getFolders([{ search: String(clientId), filterBy: 'client' }],
                activePageFolder, 9
            ));
            return dispatch({
                type: FOLDER_CREATE_SUCCESS,
                response: res.data.response.message,
                id: res.data.response.folder
            });
        }
        throw new Error();
    } catch (error) {
        if (error.response && error.response.status === 401)
            dispatch(logoutAction());
        dispatch({
            type: DOCUMENT_ERROR,
            payload: error.response ? error.response.data.message : "common:errorLoadingFiles"
        });
    }
};

export const createFiles = (folderId, files, clientId) => async (dispatch, getState) => {
    dispatch({ type: LOADING_DOCUMENT });
    try {
        const { activePageFolder } = getState().doc;
        const updatedFileFolder = folderId;
        const bodyFormData = new FormData();
        bodyFormData.append('folderId', folderId);
        files.forEach(item => {
            bodyFormData.append('files', item.file);
        });
        const res = await postAxios(postFileCreateURL, bodyFormData);
        if (res.status === 200) {
            const token = res.headers.refreshtoken;
            const expiration = res.headers.expirationToken;
            (token && expiration) && dispatch(renewTokenAction(token, expiration));
            dispatch(getFolders([{ search: String(clientId), filterBy: 'client' }],
                activePageFolder, 9
            ));
            // dispatch(getDocuments([{ search: String(clientId), filterBy: 'client' }], "recent-files", 0, 5, true, 'documents'));
            return dispatch({
                type: DOCUMENT_CREATE_SUCCESS,
                response: res.data.response,
                folderId: updatedFileFolder
            });
        }
        throw new Error();
    } catch (error) {
        if (error.response && error.response.status === 401)
            dispatch(logoutAction());
        dispatch({
            type: DOCUMENT_ERROR,
            payload: error.response ? error.response.data.message : "common:errorLoadingFiles"
        });
    }
};

export const deleteDoc = (id, folderId = "", clientId) => async (dispatch) => {
    try {
        const res = await deleteAxios(deleteFileURL + id);
        if (res.status === 200) {
            const token = res.headers.refreshtoken;
            const expiration = res.headers.expirationToken;
            (token && expiration) && dispatch(renewTokenAction(token, expiration));
            if (!folderId) {
                dispatch(getFolders([{ search: String(clientId), filterBy: 'client' }]));
                // dispatch(getDocuments([{ search: String(clientId), filterBy: 'client' }], "recent-files", 0, 5, true, 'documents'));
                return dispatch({
                    type: DOCUMENT_DELETE_SUCCESS,
                    response: res.data.response,
                });
            }
            else {
                return dispatch({
                    type: DOCUMENT_DELETE_BY_FOLDER_SUCCESS,
                    response: res.data.response,
                    folderId: folderId
                });
            }
        }
        throw new Error();
    } catch (error) {
        if (error.response && error.response.status === 401)
            dispatch(logoutAction());
        dispatch({
            type: DOCUMENT_ERROR,
            payload: error.response ? error.response.data.message : "common:errorDeletingFiles"
        });
    }
};

export const validateFolder = (folderId) => async (dispatch) => {
    try {
        const res = await getAxios(validateFolderURL + folderId);
        if (res.status === 200) {
            const token = res.headers.refreshtoken;
            const expiration = res.headers.expirationToken;
            (token && expiration) && dispatch(renewTokenAction(token, expiration));
            return dispatch({
                type: VALIDATE_FOLDER_SUCCESS,
                folderValidation: res.data.response,
            });
        }
        throw new Error();
    } catch (error) {
        if (error.response && error.response.status === 401)
            dispatch(logoutAction());
        dispatch({
            type: DOCUMENT_ERROR,
            payload: error.response ? error.response.data.message : "common:errorValidatingFolder"
        });
    }
};

export const deleteFolder = (folderId, clientId) => async (dispatch) => {
    try {
        const res = await deleteAxios(deleteFolderURL + folderId);
        if (res.status === 200) {
            const token = res.headers.refreshtoken;
            const expiration = res.headers.expirationToken;
            (token && expiration) && dispatch(renewTokenAction(token, expiration));
            dispatch(selectFileId(null));
            return dispatch({
                type: DELETE_FOLDER_BY_ID_SUCCESS,
                response: res.data.response
            });
        }
        throw new Error();
    } catch (error) {
        if (error.response && error.response.status === 401)
            dispatch(logoutAction());
        dispatch({
            type: DOCUMENT_ERROR,
            payload: error.response ? error.response.data.message : "common:errorDeletingFile"
        });
    }
};

export const changeDocPage = (page) => (dispatch) => {
    dispatch({
        type: DOCUMENT_CHANGE_PAGE,
        activePage: page - 1
    });
};

export const changeFolderPage = (page) => (dispatch) => {
    dispatch({
        type: FOLDER_CHANGE_PAGE,
        activePage: page - 1
    });
};

export const changeFolderDocPage = (page) => (dispatch) => {
    dispatch({
        type: FOLDER_DOCUMENT_CHANGE_PAGE,
        activePage: page - 1
    });
};
export const clearFolderId = () => dispatch => {
    dispatch({
        type: CLEAR_FOLDER_ID
    });
};

export const closeModal = () => dispatch => {
    dispatch({
        type: CLOSE_MODAL_DOC
    });
};

export const setSelectedFolderId = () => dispatch => {
    dispatch({
        type: SET_FOLDER_ID
    });
};

export const changeIsDeleted = () => (dispatch) => {
    dispatch({
        type: SET_IS_DELETED
    });
};