import React from 'react';
import { Pagination } from '@mui/material';
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';
import './Pagination.css';

const PaginationCustom = ({ activePage, pages, onChange }) => {

    const pageChange = (page) => {
        const timer = setTimeout(() => {
            onChange(page);
        }, 600);
        return () => clearTimeout(timer);
    };

    return pages > 0 ? (
        <Pagination
            count={pages}
            page={activePage + 1}
            onChange={(e, page) => page ? pageChange(page) : null}
            padding={5}
            shape="rounded"
            translations={{
                previous: <ArrowBackIos fontSize='small' />,
                next: <ArrowForwardIos fontSize='small' />,
            }}
        />
    ) : null;
};

export default PaginationCustom;