import React, { useState, useEffect } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Typography, InputLabel, Button } from '@mui/material';
import Security from '../../components/Security';
import TextFieldComponent from '../../components/TextField';
import Logo from '../../assets/Images/Login.png';
import { loginAction, clearError } from '../../redux/AuthDuck';
import * as ROUTES from '../../constants/routes';
import { useTranslation } from 'react-i18next';
import './Login.css';

const Login = props => {

    const { t } = useTranslation(['login','validations','auth']);
    const isLogged = useSelector(store => store.auth.isLogged);
    const error = useSelector(store => store.auth.error);
    const [user, setUser] = useState({
        email: '', password: ''
    });
    const [userError, setUserError] = useState({});
    const dispatch = useDispatch();

    const login = (e) => {
        e.preventDefault();
        if (validationUser()) {
            dispatch(loginAction(user));
        }
    };

    const onChange = e => {
        const { name, value } = e.target;
        setUser({ ...user, [name]: value });
    };

    useEffect(() => {
        dispatch(clearError());
    }, [dispatch]);

    useEffect(() => {
        isLogged ? props.history.push(ROUTES.DASHBOARD) : props.history.push(ROUTES.LOGIN);
    }, [isLogged, props.history]);

    const validationUser = () => {
        var error = { email: '', password: '' };

        if (user.email === null || user.email.length === 0
            || /^\s+$/.test(user.email))
            error.email = t("validations:errorEmail");
        else if (!validateUserEmail(user.email))
            error.email = t("validations:errorEmailFormat");
        if (user.password === null || user.password.length === 0
            || /^\s+$/.test(user.password))
            error.password = t("validations:errorPassword");

        setUserError({ ...userError, ...error });
        return !error.email && !error.password;
    };

    const validateUserEmail = ((email) => {
        var regex = /^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
        return regex.test(email) ? true : false;
    });

    return (
        <Security logo={Logo}>
            <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                <form onSubmit={login} noValidate>
                    <Typography className="title-app" style={{ marginBottom: '8%' }}>
                        {t('title')}
                    </Typography>
                    <InputLabel className="label-app">{t('emailLabel')}</InputLabel>
                    <TextFieldComponent name="email"
                        type="email"
                        valueInput={user.email}
                        callback={onChange.bind(this)}
                        errorInput={userError.email}
                    />
                    <InputLabel className="label-app">{t('passwordLabel')}</InputLabel>
                    <TextFieldComponent name="password"
                        valueInput={user.password} type="password"
                        callback={onChange.bind(this)}
                        errorInput={userError.password || t(error)}
                    />
                    <Button variant="contained" className="button-login" disableElevation
                        type="submit">
                        {t('submitButton')}
                    </Button>
                </form>
                <Typography className="link-login" component={Link}
                    to={ROUTES.FORGET_PASSWORD}>
                    {t('forgotPassword')}
                </Typography>
            </div>
        </Security>
    );
}

export default withRouter(Login);