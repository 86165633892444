import React, { useState, useEffect } from "react";
import { withRouter } from "react-router";
import {
    TableContainer, Table, TableHead, TableCell,
    TableRow, TableBody, Button, Modal, Typography, Divider,
    Box, Tab
} from "@mui/material";
import { ReactComponent as CheckCircle } from '../../../assets/Icons/checkCircle.svg';
import { ReactComponent as DisableCircle } from '../../../assets/Icons/disableCircle.svg';
import { ReactComponent as Phone } from '../../../assets/Icons/phone.svg';
import { TabContext, TabList, TabPanel } from '@mui/lab';
// import { ReactComponent as Spam } from '../../../assets/Icons/spam.svg';
// import { ReactComponent as CheckCircle } from '../../../assets/Icons/checkCircle.svg';
import Envelope from '@mui/icons-material/Send'
import { ReactComponent as CloseCross } from '../../../assets/Icons/closeCross.svg';
import { ReactComponent as User } from '../../../assets/Icons/user.svg';
import { ReactComponent as BriefCase } from '../../../assets/Icons/briefcase.svg';
import { ReactComponent as Email } from '../../../assets/Icons/email.svg';
import TableClientAssigment from '../TableClientAssigments';
import TextFieldComponent from "../../../components/TextField";
import { ReactComponent as Search } from '../../../assets/Icons/search.svg';
import { useDispatch, useSelector } from "react-redux";
import {
    getClientStaff, getClientsOfClientStaff, changeClientPage, afterUpdate,
    changeUserAssignedStatusWithMobile, assignClientListToUser
} from "../../../redux/UserAssignDuck";
import PaginationCustom from "../../../components/Pagination";
import CircleIcon from '@mui/icons-material/Circle';
import { resendFirstLoginEmail,resendFirstLoginEmailMobile } from '../../../redux/AuthDuck';
import { useTranslation } from "react-i18next";
import DeleteModalUser from '../../../components/DeleteMessage';
import { getClientsName } from '../../../utils/functions'

const TableUserAssigned = ({ userList = [] }) => {
    const { t } = useTranslation(['customerStaff', 'customer', 'common', 'user']);
    const clientStaff = useSelector(store => store.assign.clientStaff);
    const activePageClient = useSelector(store => store.assign.activePageClient);
    const pagesClient = useSelector(store => store.assign.pagesClient);
    const clients = useSelector(store => store.client.clients);
    const isUpdated = useSelector(store => store.assign.isUpdated);
    const [open, setOpen] = useState(false);
    const [activatedClients, setActivatedClients] = useState([]);
    const [currentActivatedClients, setCurrentActivatedClients] = useState([]);
    const [value, setValue] = useState('1');
    const [search, setSearch] = useState('');
    const dispatch = useDispatch();
    const [/*Updated*/, setUpdated] = useState(false);
    const [/*refresh*/, setRefresh] = useState(false);
    const [openChangeStatus, setOpenChangeStatus] = useState(false);
    const [error, setError] = useState({});

    //componentDidMount
    useEffect(() => {
        dispatch(changeClientPage(1));
        //component will unmount
        return () => {
            dispatch(changeClientPage(1));
        }
    }, [dispatch]);

    const handleOpen = (userId) => {
        dispatch(getClientStaff(userId));
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setActivatedClients([]);
        setValue('1');
        dispatch(changeClientPage(1));
        setSearch('');
        setOpenChangeStatus(false);
    }

    const handleChange = (value, newValue) => {
        setValue(newValue);
    };

    const getStaffClientName = (clientsList) => {
        const list = [];
        clientsList.forEach(item => {
            const client = clients.find(cli => cli.id === item.clientId);
            (client && !list.includes(client.name)) && list.push(client.name);
        });
        return list.toString().replace(',', ', ');
    };

    useEffect(() => {
        let list = [];
        (clientStaff) &&
            clientStaff.clientUsers.forEach((item) => {
                let client = { clientId: item.clientId, UserId: clientStaff.id };
                list.push(client);

            });
        setCurrentActivatedClients(prev => ([...list]));
    }, [clientStaff]);

    useEffect(() => {
        let list = [];
        (clientStaff) &&
            clientStaff.clientUsers.forEach((item) => {
                let client = { clientId: item.clientId, UserId: clientStaff.id };
                list.push(client);

            });
        setCurrentActivatedClients(prev => ([...list]));
    }, [clientStaff]);

    useEffect(() => {
        const filterBy = [{ search: "false", filterBy: "disabled" }];
        search !== '' && filterBy.push({ search: search, filterBy: 'name' });
        const timer = setTimeout(() => dispatch(getClientsOfClientStaff(filterBy, activePageClient)), 1000);
        return () => clearTimeout(timer);
        // eslint-disable-next-line
    }, [dispatch, search, isUpdated]);

    useEffect(() => {
        const filterBy = [{ search: "false", filterBy: "disabled" }];
        search !== '' && filterBy.push({ search: search, filterBy: 'name' });
        dispatch(getClientsOfClientStaff(filterBy, activePageClient));
        // eslint-disable-next-line
    }, [dispatch, activePageClient]);

    const onChange = (e) => {
        setSearch(e.target.value);
    };

    useEffect(() => {
        isUpdated &&
            setRefresh(true);
        setOpen(false);
        dispatch(afterUpdate());
        //setUpdated(false);
    }, [isUpdated, dispatch]);

    const onSubmit = (e) => {
        let differences = 0;
        var error = {};
        if (activatedClients.length < 1) {
            error.customer = t("customer:errorClientRequired");
            setError(error);
        }

        if (activatedClients.length === currentActivatedClients.length) {

            currentActivatedClients.forEach((item, i) => {
                if (item.clientId !== activatedClients[i].clientId) { differences += 1 }
            })

            if (differences > 0) {
                if (!error.customer) {
                    dispatch(changeClientPage(1));
                    dispatch(assignClientListToUser(clientStaff.id, activatedClients));
                    setUpdated(true);
                }

            }
        } else {
            if (!error.customer) {
                dispatch(changeClientPage(1));
                dispatch(assignClientListToUser(clientStaff.id, activatedClients));
                setUpdated(true);
            }
        }
    };

    const status = [
        { status: t("statusProcessingLbl"), color: '#6C757D' },
        { status: t("statusActiveLbl"), color: '#28A745' },
        { status: t("statusArchivedLbl"), color: '#6C757D' },
    ];

    return (
        <TableContainer>
            <Table aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell className='table-app-title-header'>{t("headerTbl1")}</TableCell>
                        <TableCell className='table-app-title-header'>{t("headerTbl2")}</TableCell>
                        <TableCell className='table-app-title-header'>{t("headerTbl3") + "s"}</TableCell>
                        <TableCell className='table-app-title-header'>{t("headerTblStatus")}</TableCell>
                        <TableCell className='table-app-title-header' align="right">{t("headerTbl9")}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {userList.length === 0 &&
                        <TableRow key='empty'>
                            <TableCell colSpan={5} style={{ textAlign: 'center' }}>
                                {t("noResultsLbl")}
                            </TableCell>
                        </TableRow>
                    }
                    {userList.map(item => (
                        <TableRow key={item.id}>
                            <TableCell component="th" scope="row" className='app-form-subtitle'>
                                {item.firstName} {item.lastName}
                            </TableCell>
                            <TableCell component="th" scope="row" className='app-form-subtitle'>
                                {item.email}
                            </TableCell>
                            <TableCell component="th" scope="row" className='app-form-subtitle'>
                                {item.clients ? getClientsName(item.clients) : ''}
                            </TableCell>
                            <TableCell component="th" scope="row" className='app-form-subtitle'>
                                <div className='table-row-cell-user' style={{ color: status[item.statusMobile].color }}>
                                    <CircleIcon style={{ fontSize: 10, marginRight: 11 }} />
                                    <Typography>
                                        {status[item.statusMobile].status}
                                    </Typography>
                                </div>
                            </TableCell>
                            <TableCell align="right">
                                <Button className='button-app-see-detail'
                                    onClick={() => handleOpen(item.id)}>
                                    {t("seeLbl")}
                                </Button>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <div>
                <Modal
                    open={open}
                    onClose={handleClose}
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}
                >
                    <div className="container-div-modal">
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Typography className="modal-title-text">{t("subtitle3")}</Typography>
                            <Button className='button-close-cross' variant="text" onClick={handleClose} startIcon={<CloseCross />}></Button>
                        </div>
                        <div>
                            <TabContext value={value}>
                                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                    <TabList
                                        onChange={handleChange}
                                        aria-label="lab API tabs example">
                                        <Tab label={t("subtitle4")} value="1" />
                                        <Tab label={t("subtitle5")} value="2" />
                                    </TabList>
                                </Box>
                                <TabPanel value="1" className='tab-select-page-assigments-data-user'>
                                    <Typography className="subtitle-data-client" style={{ marginBottom: 32 }}>{t("subtitle4")}</Typography>
                                    <div style={{ display: 'flex', marginBottom: 39 }}>
                                        <div> <User /> </div>
                                        <div style={{ marginLeft: 19 }}>
                                            <Typography className="title-data-client">{t("headerTbl1")}</Typography>
                                            <Typography className="subtitle-data-client" style={{ marginTop: '4px' }}>
                                                {clientStaff && (clientStaff.firstName + ' ' + clientStaff.lastName)}
                                            </Typography>
                                        </div>
                                    </div>
                                    <div style={{ display: 'flex', marginBottom: 39 }}>
                                        <div className="icon-for-clients-text"> <Email /> </div>
                                        <div style={{ marginLeft: 19 }}>
                                            <Typography className="title-data-client">Email</Typography>
                                            <Typography className="subtitle-data-client" style={{ marginTop: '4px' }}>
                                                {clientStaff && clientStaff.email}
                                            </Typography>
                                        </div>
                                    </div>
                                    <div style={{ display: 'flex', marginBottom: 39 }}>
                                        <div className="icon-for-clients-text"> <BriefCase /> </div>
                                        <div style={{ marginLeft: 19 }}>
                                            <Typography className="title-data-client">{t("headerTbl3") + "s"}</Typography>
                                            <Typography className="subtitle-data-client" style={{ marginTop: '4px' }}>
                                                {clientStaff && getStaffClientName(clientStaff.clientUsers)}
                                            </Typography>
                                        </div>
                                    </div>
                                    {clientStaff && clientStaff.phone &&
                                        <div style={{ display: 'flex', marginBottom: 39 }}>
                                            <div className="icon-for-clients-text"> <Phone /> </div>
                                            <div style={{ marginLeft: 19 }}>
                                                <Typography className="title-data-client">{t("headerTblPhone")}</Typography>
                                                <Typography className="subtitle-data-client" style={{ marginTop: '4px' }}>
                                                    {clientStaff && (clientStaff.countryCode.code + ' ' + clientStaff.phone)}
                                                </Typography>
                                            </div>
                                        </div>
                                    }
                                    <Divider className="divider-style" />
                                    <div className="container-app-div">
                                        <Typography className="app-form-title">{t("optionsLbl")}</Typography>
                                        {(clientStaff) &&
                                            <>
                                                <DeleteModalUser
                                                    open={openChangeStatus}
                                                    closeModal={() => setOpenChangeStatus(false)}
                                                    handleClick={() => dispatch(changeUserAssignedStatusWithMobile(clientStaff))}
                                                    buttonMessage={clientStaff.statusMobile === 2 ? t("activateUserLbl") : t("suspendUserLbl")}
                                                    textMessage={`${t("common:textConfirmation")} ${clientStaff.statusMobile === 2 ? t("user:activateLbl") : t("user:suspendLbl")} ${t("common:textUserSure")} `}
                                                />
                                                <Button style={{ marginRight: 30 }} className='button-active-inactive' variant="text"
                                                    startIcon={clientStaff.statusMobile === 1 ? <CheckCircle /> : <DisableCircle />}
                                                    onClick={() => setOpenChangeStatus(true)}>
                                                    {clientStaff.statusMobile === 2 ? t("activateUserLbl") : t("suspendUserLbl")}
                                                </Button>
                                                {clientStaff.statusMobile !== 2 &&
                                                    <Button className='button-active-inactive' variant="text"
                                                        startIcon={<Envelope></Envelope>}
                                                        onClick={() => dispatch(resendFirstLoginEmailMobile(clientStaff))}>
                                                        {t("resendLoginLbl")}
                                                    </Button>
                                                }
                                            </>
                                        }
                                    </div>
                                </TabPanel>
                                <TabPanel value="2" className='tab-select-page-assigments-clients-assigment'>
                                    <Typography className="clients-assigment-title">{t("subtitle6")}</Typography>
                                    <div style={{ width: '35%' }}>
                                        <TextFieldComponent Icon={<Search />} position='start' placeholder={t("common:commonSearchPlh")}
                                            valueInput={search} callback={onChange.bind(this)}
                                        />
                                    </div>
                                    <TableClientAssigment activatedClients={activatedClients} setActivatedClients={setActivatedClients} />
                                    <br />
                                    <PaginationCustom pages={pagesClient} activePage={activePageClient}
                                        onChange={page => dispatch(changeClientPage(page))} />
                                    <p className='app-error-text'>{error.customer}</p>
                                    <div style={{ display: 'flex', paddingTop: 35 }}>
                                        <Button variant="contained" className="button-app-save" style={{ marginRight: 18 }}
                                            onClick={() => onSubmit()}>
                                            {t("submitBtn")}
                                        </Button>
                                        <Button variant="contained" className="button-app-cancel"
                                            onClick={handleClose}>
                                            {t("cancelBtn")}
                                        </Button>
                                    </div>
                                </TabPanel>
                            </TabContext>
                        </div>
                    </div>
                </Modal>
            </div>
        </TableContainer>
    );

}

export default withRouter(TableUserAssigned);