import React, { useState, useEffect } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
    AppBar, Toolbar, Typography,
    Box, Avatar, Menu, MenuItem, Divider,
    IconButton, Tooltip
} from '@mui/material';
import { ReactComponent as DoorIcon } from '../../assets/Icons/doorOpen.svg';
import { ReactComponent as HelpIcon } from '../../assets/Icons/help.svg';
import { ReactComponent as ArrowIcon } from '../../assets/Icons/navbarArrow.svg';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import Logo from '../../assets/Logos/navbarLogo.png';
import Icon from '../Icon';
import { logoutAction, getUser } from '../../redux/AuthDuck';
import * as ROUTES from '../../constants/routes';
import { useTranslation } from 'react-i18next';
import './Navbar.css';
import { getFileURL } from '../../constants/apiUrls';

const Navbar = (props) => {

    const { t } = useTranslation(['sidebar']);
    const user = useSelector(store => store.auth.user);
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const { location: { pathname } } = props;
    const dispatch = useDispatch();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => setAnchorEl(null);

    useEffect(() => {
        if (!user) { dispatch(getUser()); }
    }, [dispatch, user]);

    return (
        <AppBar position="fixed" style={{
            backgroundColor: '#F8F9FA',
            boxShadow: 'none',
            padding: (pathname.includes(ROUTES.DASHBOARD) || pathname === "/") ? '1% 7%' : '1% 6% 1% 1%',
            zIndex: 100
        }}>
            <Toolbar style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Link to={ROUTES.HOME}>
                    <img src={Logo} alt="Logo" />
                </Link>
                <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
                    <Typography sx={{ minWidth: 100 }} className="user-name-navbar">
                        {user && `${user.firstName + ' ' + user.lastName}`}
                    </Typography>
                    <Tooltip title="">
                        <IconButton onClick={handleClick} sx={{ ml: 2 }}>
                            <Avatar alt={user && user.firstName}
                                className="user-image-navbar"
                                src={(user && user.fileId) && getFileURL + user.fileId}
                            />
                        </IconButton>
                    </Tooltip>
                    <Icon Icon={ArrowIcon} />
                </Box>
                <Menu
                    className="menu-profile-navbar"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    onClick={handleClose}
                    PaperProps={{
                        elevation: 0,
                        sx: {
                            border: '1px solid #CED4DA',
                            overflow: 'visible',
                            borderRadius: '20px',
                            mt: 1.5,
                            ml: 5,
                            minWidth: 232,
                            '& .MuiAvatar-root': {
                                width: 32,
                                height: 32,
                                ml: -0.5,
                                mr: 1,
                            },
                            '&:before': {
                                content: '""',
                                display: 'block',
                                position: 'absolute',
                                top: -1,
                                right: 30,
                                width: 10,
                                height: 10,
                                bgcolor: 'background.paper',
                                transform: 'translateY(-50%) rotate(45deg)',
                                zIndex: 0,
                                borderRadius: '5px 0 0 0',
                                borderLeft: '1px solid #CED4DA',
                                borderTop: '1px solid #CED4DA',
                            },
                        },
                    }}
                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                >
                    <MenuItem className="menu-item-profile-navbar" component={Link} to={ROUTES.USER_PROFILE}>
                        <Icon Icon={PersonOutlineOutlinedIcon} />
                        {t('sidebar:userProfileLbl')}
                    </MenuItem>
                    <Divider style={{ borderColor: '#CED4DA' }} />
                    <MenuItem className="menu-item-profile-navbar" component={Link} to={ROUTES.HELP_SYSTEM_CONFIGURATION}>
                        <Icon Icon={HelpIcon} />
                        {t('sidebar:helpLbl')}
                    </MenuItem>
                    <Divider style={{ borderColor: '#CED4DA' }} />
                    <MenuItem className="menu-item-profile-navbar" onClick={() => dispatch(logoutAction())}>
                        <Icon Icon={DoorIcon} />
                        {t('sidebar:logOutLbl')}
                    </MenuItem>
                </Menu>
            </Toolbar>
        </AppBar>
    )
};

export default withRouter(Navbar);