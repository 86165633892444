import React, { useState, useEffect } from "react";
import { withRouter } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import {
    Container, Grid, Typography, Button
} from "@mui/material";
import Title from "../../../components/Title";
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { ReactComponent as Search } from '../../../assets/Icons/search.svg';
import { ReactComponent as Filter } from '../../../assets/Icons/filter.svg';
import TextFieldComponent from "../../../components/TextField";
import SelectFieldComponent from '../../../components/SelectField';
import TableBusiness from '../Table';
import { getBusinesses, changeBusinessPage } from "../../../redux/BusinessDuck";
import * as ROUTES from '../../../constants/routes';
import PaginationCustom from "../../../components/Pagination";
import { useTranslation } from 'react-i18next';
const BusinessRead = props => {
    const { t } = useTranslation(['business', 'common']);
    const businesses = useSelector(store => store.business.businesses);
    const activePage = useSelector(store => store.business.activePage);
    const pages = useSelector(store => store.business.pages);
    const [filter, setFilters] = useState('none');
    const [search, setSearch] = useState('');
    const [resetPage, setResetPage] = useState(false);
    const dispatch = useDispatch();

    //componentDidMount
    useEffect(() => {
        dispatch(changeBusinessPage(1));
        //component will unmount
        return () => {
            dispatch(changeBusinessPage(1));
        }
    }, [dispatch]);

    const handleChange = (event) => {
        setFilters(event.target.value);
        setResetPage(true);
    };

    const onChange = (event) => {
        setSearch(event.target.value);
        setResetPage(true);
    };

    useEffect(() => {
        const timer = setTimeout(() => {
            const page = resetPage ? 0 : activePage;
            dispatch(getBusinesses(search, filter, page, 10, true));
            setResetPage(false);
        }, 1000);
        return () => clearTimeout(timer);
        // eslint-disable-next-line
    }, [dispatch, search, filter]);

    useEffect(() => {
        const page = resetPage ? 0 : activePage;
        dispatch(getBusinesses(search, filter, page, 10, false));
        setResetPage(false);
        // eslint-disable-next-line
    }, [dispatch, activePage]);

    return (
        <Container>
            <Title title={t("title1")} style={{ marginLeft: 5 }} />
            <Grid item xs={12}>
                <div className="container-app-div">
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Typography className="app-form-title">{t("title1")}</Typography>
                        <Button className='button-app-create' variant="text"
                            startIcon={<AddRoundedIcon />}
                            onClick={() => props.history.push(ROUTES.BUSINESS_CREATE)}
                        >
                            {t("createBtn")}
                        </Button>
                    </div>
                    <Typography className="app-form-subtitle">
                        {t("subtitle1")}
                    </Typography>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div style={{ width: '35%' }}>
                            <TextFieldComponent
                                Icon={<Search />}
                                position='start'
                                placeholder={t("common:commonSearchPlh")}
                                valueInput={search}
                                callback={e => onChange(e)}
                            />
                        </div>
                        <div>
                            <SelectFieldComponent name="filter"
                                placeholder={t("filterByStatusPlh")}
                                valueInput={filter}
                                onChange={handleChange}
                                options={[
                                    { key: 'false', value: t("activeStatusLbl") },
                                    { key: 'true', value: t("archivedStatusLbl") }
                                ]}
                                Icon={Filter}
                            />
                        </div>
                    </div>
                    <TableBusiness businessList={businesses} />
                    <br />
                    <PaginationCustom pages={pages} activePage={activePage}
                        onChange={page => dispatch(changeBusinessPage(page))} />
                </div>
            </Grid>
        </Container>
    );
}

export default withRouter(BusinessRead);