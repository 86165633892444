import React from "react";
import { Container, Grid, Typography, Card, CardMedia } from "@mui/material";
import helpImage13 from '../../assets/Images/help13.png';
import { useTranslation } from "react-i18next";
import './Help.css';

const HelpUserAssignment = () => {

    const { t } = useTranslation(['help']);

    return (
        <Container>
                            <Typography className="admin-business-text" style={{ marginBottom: '20px' }}>
                            {t('help:userAssignment:category')+"|"+t('help:userAssignment:title')}
                        </Typography>

            <Grid item xs={12}>
                <div className="container-app-div help-screen header" style={{ marginTop: 10 }}>
                    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                        <p className="paragraph-text strong" style={{ marginBottom: '20px' }} >
                            {t('help:thisPageLbl')}
                        </p>
                        <label className="help-label-text">
                        <span className="enum">1</span>  {t('help:userAssignment:lbl1')}
                        </label>
                        <br />
                        </div>
                        </div>

                        <div className="container-app-div" style={{ marginTop: 10 }}>
                
                            <div style={{ width: '90%', display: 'flex', flexDirection: 'column' }}>
                                <p className="paragraph-text strong">
                                    {t('help:step1')}<span className="enum">1</span>  : &nbsp;   {t('help:userAssignment:lbl2')}
                                </p>
                                
                                <label className="help-label-text">
                                    {t('help:userAssignment:lbl3')}
                                </label>
                                <label className="help-label-text">
                                    {t('help:userAssignment:lbl4')}
                                </label>
                                <label className="help-label-text">
                                    {t('help:userAssignment:lbl5')}
                                </label>
                            </div>
                            <div style={{
                                width: '100%', display: 'flex', marginTop: 40,
                                justifyContent: 'center', alignItems: 'center'
                            }}>
                                <Card sx={{ width: 400, height: 330, boxShadow: 'none' }}>
                                    <CardMedia
                                        component="img"
                                        image={helpImage13}
                                        alt="img"
                                        style={{ objectFit: 'contain' }}
                                    />
                                </Card>
                            </div>
                        </div>
                  
            </Grid>
        </Container>
    );
}
export default HelpUserAssignment;