import {
    postAppSettingURL, getAppSettingByIdURL,
    putAppSettingURL, getOrganizationIdURL,
    postAppSettingChangeLogoURL
} from '../constants/apiUrls';
import { postAxios, getAxios, putAxios } from '../services/Axios';
import { logoutAction, renewTokenAction } from './AuthDuck'

// constantes
const initialState = {
    appSetting: null,
    loadedSetting: false,
    logoRequest: false,
    loading: false,
    error: null,
    response: null
};

// types
const APP_SUCCESS = 'APP_SUCCESS';
const APP_LOGO_SUCCESS = 'APP_LOGO_SUCCESS';
const APP_ERROR = 'APP_ERROR';
const APP_SUCCESS_SAVE = 'APP_SUCCESS_SAVE';
const LOADING_APP = 'LOADING_APP';
const LOG_OUT = 'LOG_OUT';
const CLEAR_RESPONSE = 'CLEAR_RESPONSE';
const APP_RESPONSE = 'APP_RESPONSE';

// reducer
export default function reducer(state = initialState, action) {
    switch (action.type) {
        case LOADING_APP:
            return { ...state, loading: true, error: null };
        case APP_SUCCESS:
            return {
                ...state, appSetting: action.payload,
                loadedSetting: true, error: null, loading: false
            };
        case APP_SUCCESS_SAVE:
            return { ...state, response: action.response, loadedSetting: false };
        case APP_LOGO_SUCCESS:
            return { ...state, response: action.response, loading: false, logoRequest: true };
        case APP_ERROR:
            return {
                ...state, error: action.payload, loading: false,
                response: action.payload
            };
        case CLEAR_RESPONSE:
            return { ...state, response: null };
        case APP_RESPONSE:
            return { ...state, response: action.response };
        case LOG_OUT:
            return { ...initialState };
        default:
            return state;
    }
};

// actions
export const saveAppSetting = (appSetting) => async (dispatch, getState) => {
    dispatch({ type: LOADING_APP });

    try {
        const { user } = getState().auth;
        const appSettingRedux = getState().appSetting.appSetting;
        var res;
        if (appSettingRedux && appSettingRedux.id) {
            res = await putAxios(putAppSettingURL, {
                'id': appSettingRedux.id,
                'name': appSetting.name,
                'secundaryLanguageId': appSetting.languageId ? parseInt(appSetting.languageId) : null,
                'file': null
            });
        } else {
            const bodyFormData = new FormData();
            bodyFormData.append('secundaryLanguageId', appSetting.languageId ? parseInt(appSetting.languageId) : 0);
            bodyFormData.append('name', appSetting.name);
            bodyFormData.append('organizationId', user.organizationId);
            bodyFormData.append('logo', appSetting.image);
            res = await postAxios(postAppSettingURL, bodyFormData);
        }
        if (res.status === 200) {
            const token = res.headers.refreshtoken;
            const expiration = res.headers.expirationtoken;

            (token && expiration) && dispatch(renewTokenAction(token, expiration));
            dispatch({
                type: APP_SUCCESS_SAVE,
                response: res.data.response
            })
            return dispatch(getAppSettingById());
        }
        throw new Error();
    } catch (error) {
        if (error.response && error.response.status === 401)
            dispatch(logoutAction());
        dispatch({
            type: APP_ERROR,
            payload: error.response ? error.response.data.message : "errorCreatingMobileConfig"
        });
    }
};

export const getAppSettingById = () => async (dispatch, getState) => {
    dispatch({ type: LOADING_APP });

    try {
        const { user } = getState().auth;
        const resSystem = await getAxios(getOrganizationIdURL, { id: user.organizationId });
        const res = await getAxios(getAppSettingByIdURL, { id: resSystem.data.response.appSettingId });
        if (res.status === 200) {
            const token = res.headers.refreshtoken;
            const expiration = res.headers.expirationToken;
            (token && expiration) && dispatch(renewTokenAction(token, expiration));
            return dispatch({
                type: APP_SUCCESS,
                payload: res.data.response
            });
        }
        throw new Error();
    } catch (error) {
        if (error.response && error.response.status === 401)
            dispatch(logoutAction());
        dispatch({
            type: APP_ERROR,
            payload: error.response ? error.response.data.message : "errorAcquiringConfig"
        });
    }
};

export const logoChangeRequest = () => async (dispatch, getState) => {
    dispatch({ type: LOADING_APP });

    try {
        const res = await postAxios(postAppSettingChangeLogoURL);
        if (res.status === 200) {
            const token = res.headers.refreshtoken;
            const expiration = res.headers.expirationToken;
            (token && expiration) && dispatch(renewTokenAction(token, expiration));
            return dispatch({
                type: APP_LOGO_SUCCESS,
                response: res.data.response
            });
        }
        throw new Error();
    } catch (error) {
        if (error.response && error.response.status === 401)
            dispatch(logoutAction());
        dispatch({
            type: APP_ERROR,
            payload: error.response ? error.response.data.message : "configAPP:errorRequestingLogoChange"
        });
    }
};

export const showMessage = () => async (dispatch) => {
    return dispatch({
        type: APP_RESPONSE,
        response: "common:commonRestoredConfigChanges"
    });
};