import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import {
    Typography, IconButton,
    Accordion, AccordionDetails, AccordionSummary, InputLabel,
} from "@mui/material";
import { ReactComponent as Edit } from '../../../assets/Icons/edit.svg';
import { ReactComponent as Drag } from '../../../assets/Icons/drag.svg';
import { ReactComponent as Files } from '../../../assets/Icons/files.svg';
import { ReactComponent as Trash } from '../../../assets/Icons/trash.svg';
//import { ReactComponent as Imagen } from '../../../assets/Icons/imagen.svg';
import { ReactComponent as Videoclip } from '../../../assets/Icons/video.svg';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import IconSvg from '../../../components/Icon';
import './Image.css';
import { useTranslation } from 'react-i18next';
import TextFieldComponent from '../../../components/TextField';
import { use } from 'i18next';

const VideoItem = ({ index, pageIndex, value = null, onURLChange, deleteContent, error = [] }) => {

    const { t } = useTranslation(['mobileContent', 'common']);
    const lenguages = useSelector(store => store.lenguage.lenguages);
    const defaultLanguageId = useSelector(store => store.system.system.defaultLanguageId);
    const [language] = useState(defaultLanguageId);
    const [linkError, setLinkError] = useState("");
    //const secondaryLanguageId = useSelector(store => store.appSetting.appSetting.secundaryLanguageId);
    const secondaryLanguageId = null;
    const [expanded, setExpanded] = useState(false);
    //console.log("props",index,value,value[defaultLanguageId]);
    //const [ytlink, setYTlink] = (useState(value && value[index]) ? value[index].url : null);
    const [ytlink, setYTlink] = useState(value && value[defaultLanguageId] && value[defaultLanguageId].url ? value[defaultLanguageId].url : "");
    function fixUrl(IDstring) {
        // eslint-disable-next-line
        const URLex = new RegExp(/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/);
        // only adds the url if the suffix is an actual youtube video id (or matching its apparent syntax)
        const IDRegex = new RegExp(/^(?!.*http)([A-Za-z0-9-_]{11,})$/g);

        if (URLex.test(IDstring)) {
            return IDstring;
        }
        else {
            return IDRegex.test(IDstring) ? "https://www.youtube.com/embed/" + IDstring : IDstring;
        }
    }

    const onChange = event => {
        const { value } = event.target;
        const embedRegex = new RegExp(/^<iframe[^>]+src=["']([^"']+)["'][^>]*>(?:<\/iframe>)?$/);
        const isIframe = embedRegex.test(value);
        let src = isIframe ? value.match(/src=["']([^"']+)["']/)[1] : value;
        const idPattern = /^.*(youtu\.be\/|youtube(?:-nocookie)?\.com\/(v\/|vi\/|e\/(?:(?:embed|watch)\/)?|shorts\/))?([\w\-]{11}).*/;
        const ID = src.match(idPattern);
        //console.log(value,src,ID);
    
        const linkview = /watch\?v=/;
        const replacement = "embed/";
        const isWatchLink = value.match(linkview) ? true : false;
        src = isWatchLink ? src.replace(linkview, replacement) : src;
        const catchId = /embed\/([^?]+)/;
        const youtubeId = (ID && ID.length>1 &&ID[3]) ? ID[3]:"";
         (!youtubeId ? setLinkError(t("linkError")):setLinkError(""));
        //console.log(linkError)
        //const youtubeId = src.match(catchId);
        setYTlink(value);
        lenguages.forEach(({ key }) => {
            onURLChange(pageIndex, index - 1, {
                id: (value && value[key]) ? value[key].id : null,
                //url: fixUrl(src),
                url: fixUrl(youtubeId),
                //url: "http://www.youtube.com/embed/"+youtubeId,
                //ytid: youtubeId ? youtubeId[1] : value
                ytid: youtubeId?youtubeId : value
            }, key);
        });
    };

    return (
        <Accordion className="accordion-sumary-row-title-mobile" expanded={expanded}
            style={{ width: '100%' }}>
            <AccordionSummary
                aria-controls="panel1bh-content"
                id="panel1bh-header"
            >
                <div style={{ width: '98%', display: 'flex', justifyContent: 'space-between' }}>
                    <div style={{
                        display: 'flex', alignItems: 'center',
                        margin: '29px 8px', flexShrink: 0, width: '50%'
                    }} onClick={() => setExpanded(!expanded)}>
                        <IconSvg Icon={Videoclip} className="icon-svg-mobile" />
                        <Typography className="accordion-title-text-mobile" >
                            {index}. {t("videoLbl")}
                        </Typography>
                    </div>
                    <div style={{
                        display: 'flex', alignItems: 'center',
                        width: '48%', justifyContent: 'flex-end'
                    }}>
                        <div style={{ display: 'flex', alignItems: 'center', marginTop: 5 }}>
                            <Edit style={{ margin: '0 20px' }} />
                            <Files style={{ marginRight: 20 }} />
                            <Drag />
                        </div>
                    </div>
                </div>
                {(error.length > 0 && error.find(err => language in err))
                    && error.find(err => language in err)[language] &&
                    <p className='app-error-text'>{error.find(err => language in err)[language]}</p>
                }
            </AccordionSummary>
            <AccordionDetails>
                <div style={{ margin: '15px 16px 0px 15px' }}>
                    <InputLabel className="label-app">{t("mobileContent:urlLbl")}</InputLabel>
                    <TextFieldComponent
                        name="urlVideo"
                        error={true && linkError}
                        maxLength={1000}
                        //  placeholder="https://www.fos.com"
                        valueInput={ytlink}
                        //valueInput={value[language] ? fixUrl(value[language].url) : ''}
                        callback={onChange.bind(this)}
                    />
                    { linkError && <Typography className='mobile-content-alert'>
                            <InfoOutlinedIcon fontSize='small' style={{ marginRight: 10 }} />
                           {linkError}
                        </Typography>}
                    {(value[language] && fixUrl(value[language].url).length > 11) &&
                        <iframe src={value[language] ? fixUrl(value[language].url) : 'youtube.com'}
                            width="100%"
                            height={250}
                            title="YouTube video player"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            allowFullScreen
                        />
                    }
                </div>
                <div style={{ display: 'flex', marginTop: 20, justifyContent: 'space-between' }}>
                    {secondaryLanguageId ?
                        <Typography className='mobile-content-alert'>
                            <InfoOutlinedIcon fontSize='small' style={{ marginRight: 10 }} />
                            {t("subtitle20")}
                        </Typography>
                        : <div></div>
                    }
                    <IconButton onClick={() => deleteContent(pageIndex, index - 1)}>
                        <Trash className="mobile-icon-svg-delete mobile-icon-delete" />
                    </IconButton>
                </div>
            </AccordionDetails>
        </Accordion >
    );
}

export default VideoItem;

