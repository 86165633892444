import React, { useEffect } from "react";
import {
    TableCell, TableHead, TableContainer, TableBody,
    Table, TableRow, Typography
} from "@mui/material";
import SwitchComponent from "../../../components/Switch";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
//import { assignClientToUser } from "../../redux/UserAssignDuck";

const TableClientAssigment = ({ isCreating = false, activatedClients, setActivatedClients }) => {

    const { t } = useTranslation(['customerStaff']);
    const clientsRedux = useSelector(store => store.assign.clients);
    const clientStaff = useSelector(store => store.assign.clientStaff);

    const onSwitch = event => {
        //if (isCreating) {
        // const { name, checked } = event.target;
        // const list = checked ? [] : activeClients;
        // if (checked) {
        //     list.push(String(name));
        // } else {
        //     const index = list.indexOf(String(name));
        //     index > -1 && list.splice(index, 1);
        // }
        // activatedClients(list);
        // setActiveClients(prev => ([...list]));

        const { name, checked } = event.target;
        const list = activatedClients;
        const client = {};

        if (checked) {
            client.clientId = parseInt(name);
            client.UserId = isCreating ? null : clientStaff.id;
            //client.userId = "";
            list.push(client);
        } else {
            let index = -1;
            activatedClients.forEach((item, i) => {
                if (item.clientId === parseInt(name)) {
                    index = i;
                }
            });
            //const index = list.indexOf(parseInt(name));
            index > -1 && list.splice(index, 1);
        }
        //activatedClients(list);
        setActivatedClients(prev => ([...list]));
/*
        if (checked) {
            client.clientId = parseInt(name);
            client.UserId = isCreating ? null : "";
            list.push(client);
        } else {
            let index = -1;
            activatedClients.forEach((item, i) => {
                if (item.clientId === parseInt(name)) {
                    index = i;
                }
            });
            //const index = list.indexOf(parseInt(name));
            index > -1 && list.splice(index, 1);
        }
        //activatedClients(list);
        setActivatedClients(prev => ([...list]));*/
    };

    useEffect(() => {
        if (clientStaff) {
            const clientList = [];
            const clientUsers = clientStaff.clientUsers ? clientStaff.clientUsers : [];
            clientUsers.forEach(item => {
                let userId = isCreating ? null : clientStaff.id;
                const client = { clientId: parseInt(item.clientId), userId: userId };
                clientList.push(client);
            });
            setActivatedClients(prev => ([...clientList]));
        }
        // eslint-disable-next-line
    }, [clientStaff, setActivatedClients]);

    function isChecked(id) {
        let isChecked = 0;
        (activatedClients && activatedClients.length > 0) &&
            activatedClients.forEach((item) => {
                if (item.clientId === id) {
                    isChecked += 1;
                }
            })
        return isChecked > 0 ? true : false;
    };

    useEffect(() => {
        if (isCreating) {
            setActivatedClients([]);
        }
    }, [isCreating, setActivatedClients]);

    return (
        <>
            <TableContainer>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell className='table-row-info'>{t("headerTbl5")}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {clientsRedux.length === 0 &&
                            <TableRow key='empty'>
                                <TableCell colSpan={1} style={{ textAlign: 'center' }}>
                                    {t("noResultsLbl")}
                                </TableCell>
                            </TableRow>
                        }
                        {clientsRedux.map((option) => (
                            <TableRow key={option.id}>
                                <TableCell className="table-row-clients-cell">
                                    <div className='align-divs-clients' >
                                        <Typography className={`access-text-clients ${activatedClients.includes(parseInt(option.id)) && "access-text-active-clients"}`} >
                                            {option.name}
                                        </Typography>
                                        <div style={{ marginRight: 18 }}>
                                            <SwitchComponent
                                                callback={onSwitch}
                                                name={option.id}
                                                checked={isChecked(parseInt(option.id))}
                                            />
                                        </div>
                                    </div>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
}
export default (TableClientAssigment);