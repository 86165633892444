import React, { useState, useCallback, useEffect } from "react";
import {
    Typography, TableContainer,
    TableRow, TableCell, Table, TableBody, TableHead, Button
} from "@mui/material";
import { ReactComponent as Edit } from '../../../../assets/Icons/edit.svg';
import { ReactComponent as Image } from '../../../../assets/Icons/image.svg';
import { ReactComponent as Document } from '../../../../assets/Icons/document.svg';
import { ReactComponent as Folder } from '../../../../assets/Icons/folder.svg';
import AccessRow from "./AccessRow";
import { useSelector } from "react-redux";
import Moment from "react-moment";
import PaginationCustom from "../../../../components/Pagination";
import './FourthStep.css';
import { useTranslation } from 'react-i18next';
import { getClientsName } from '../../../../utils/functions'

const FourthStep = ({ setStep, authorization }) => {
    const { t } = useTranslation(['authorization','common']);
    const businesses = useSelector(store => store.business.businesses);
    const clients = useSelector(store => store.client.clients);
    const offices = useSelector(store => store.business.offices);
    const [activePageSelectedDoc, setActivePageSelectedDoc] = useState(0);
    const [activePageReceivers, setActivePageReceivers] = useState(0);
    const [documents, setDocuments] = useState([]);
    const [receivers, setReceivers] = useState([]);

    const obj_per_page = 10;
    const imageExtensions = [".JPG", ".JPEG", ".GIF", ".PNG", ".TIFF",
        ".TIF", ".RAW", ".BMP", ".PSD", ".EPS", ".PIC",
        ".MOV", ".MKV", ".MP4", ".WMV", ".FLV"];

    const totalDocNumPages = () => {
        return Math.ceil(authorization.selectedFiles.length / obj_per_page);
    };

    const totalRecNumPages = () => {
        return Math.ceil(authorization.receivers.length / obj_per_page);
    };

    const changeSelectedDocPage = (page) => {
        setActivePageSelectedDoc(page - 1);
    };

    const changeReceiversPage = (page) => {
        setActivePageReceivers(page - 1);
    };

    const drawRowSelectedDocs = useCallback((page, selectedFiles) => {
        const list = [];
        for (let index = page * obj_per_page; index < (page * obj_per_page) + obj_per_page; index++) {
            if (selectedFiles.length > index) {
                list.push(selectedFiles[index]);
            }
        }
        setDocuments(prev => ([...list]));
    }, []);

    const drawRowReceivers = useCallback((page, receivers) => {
        const list = [];
        for (let index = page * obj_per_page; index < (page * obj_per_page) + obj_per_page; index++) {
            if (receivers.length > index) {
                list.push(receivers[index]);
            }
        }
        setReceivers(prev => ([...list]));
    }, []);

    useEffect(() => {
        drawRowSelectedDocs(activePageSelectedDoc, authorization.selectedFiles);
        // eslint-disable-next-line 
    }, [drawRowSelectedDocs, activePageSelectedDoc, authorization.selectedFiles.length]);

    useEffect(() => {
        drawRowReceivers(activePageReceivers, authorization.receivers);
        // eslint-disable-next-line 
    }, [drawRowReceivers, activePageReceivers, authorization.receivers.length]);

    return (
        <div>
            <div className="container-app-div">
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Typography className="app-form-title">{t("subtitle6")}</Typography>
                    <Button className='button-app-create' variant="text" startIcon={<Edit />}
                        onClick={() => setStep(0)}>
                        {t("editDetailsBtn")}
                    </Button>
                </div>
                <TableContainer>
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow className="header-cell-matters">
                                <TableCell className="detail-table-cell-notifications" align="right">{t("headerTbl12")}</TableCell>
                                <TableCell sx={{ width: '78%' }} className="detail-table-cell-notifications">{t("headerTbl10")}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow key={'id1'}>
                                <TableCell component="th" scope="row" className="description-table-cell-notifications" >
                                    {t("headerTbl11")}
                                </TableCell>
                                <TableCell className="detail-table-cell-notifications">
                                    {authorization.title}
                                </TableCell>
                            </TableRow>
                            <TableRow key={'id2'}>
                                <TableCell component="th" scope="row" className="description-table-cell-notifications">
                                  {t("headerTbl12")}
                                </TableCell>
                                <TableCell className="detail-table-cell-notifications">
                                    {authorization.description}
                                </TableCell>
                            </TableRow>
                            <TableRow key={'id3'}>
                                <TableCell component="th" scope="row" className="description-table-cell-notifications">
                                    {t("headerTbl13")}
                                </TableCell>
                                <TableCell className="detail-table-cell-notifications">
                                    {businesses.length > 0 && businesses.find(b => String(b.id) === String(authorization.companyId)).name}
                                </TableCell>
                            </TableRow>
                            <TableRow key={'id4'}>
                                <TableCell component="th" scope="row" className="description-table-cell-notifications">
                                {t("headerTbl14")}
                                </TableCell>
                                <TableCell className="detail-table-cell-notifications">
                                    {offices.length > 0 && offices.find(o => String(o.id) === String(authorization.officeId)).name}
                                </TableCell>
                            </TableRow>
                            <TableRow key={'id5'}>
                                <TableCell component="th" scope="row" className="description-table-cell-notifications">
                                {t("headerTbl15")}
                                </TableCell>
                                <TableCell className="detail-table-cell-notifications">
                                    {clients.length > 0 && clients.find(c => String(c.id) === String(authorization.clientId)).name}
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
            <div className="container-app-div">
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Typography className="app-form-title">{t("subitle10")}</Typography>
                    <Button className='button-app-create' variant="text" startIcon={<Edit />}
                        onClick={() => setStep(2)}>
                        {t("editRecipientsBtn")}
                    </Button>
                </div>
                <TableContainer>
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell className='table-app-title-header'>{t("headerTbl6")}</TableCell>
                                <TableCell className='table-app-title-header'>{t("headerTbl7")}</TableCell>
                                <TableCell className='table-app-title-header'>{t("headerTbl8")}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {receivers.map(item => (
                                <TableRow key={item.id} >
                                    <TableCell component="th" scope="row" className='authorization-text-active-clients'>
                                        {item.firstName} {item.lastName}
                                    </TableCell>
                                    <TableCell component="th" scope="row" className='authorization-text-active-clients'>
                                        {item.email}
                                    </TableCell>
                                    <TableCell component="th" scope="row" className='authorization-text-active-clients'>
                                        {item.clients ? getClientsName(item.clients) : ''}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <div style={{ marginTop: 24 }}>
                    <PaginationCustom pages={totalRecNumPages()} activePage={activePageReceivers}
                        onChange={page => changeReceiversPage(page)} />
                </div>
            </div>
            <div className="container-app-div">
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Typography className="app-form-title">{t("subtitle12")}</Typography>
                    <Button className='button-app-create' variant="text" startIcon={<Edit />}
                        onClick={() => setStep(1)}>
                        {t("adminDocBtn")}
                    </Button>
                </div>
                <TableContainer>
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell className='table-app-title-header'>{t("headerTbl1")}</TableCell>
                                <TableCell className='table-app-title-header'>{t("headerTbl2")}</TableCell>
                                <TableCell className='table-app-title-header'>{t("headerTbl3")} </TableCell>
                                <TableCell className='table-app-title-header'>{t("headerTbl4")}</TableCell>
                                <TableCell className='table-app-title-header'>{t("headerTbl5")}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {documents.map(item => (
                                <TableRow key={item.id}>
                                    <TableCell component="th" scope="row" className='app-form-subtitle'>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <AccessRow
                                                title={item.fileName}
                                                Icon={imageExtensions.includes(item.fileExtension.toUpperCase().trim())
                                                    ? Image : Document}
                                            />
                                        </div>
                                    </TableCell>
                                    <TableCell component="th" scope="row"  >
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <Folder style={{ marginRight: '10.14px' }} />
                                            <Typography className='folder-title-cel-text'>
                                                {item.folder.name}
                                            </Typography>
                                        </div>
                                    </TableCell>
                                    <TableCell component="th" scope="row" className='app-form-subtitle'>
                                        {item.uploadedByUser.firstName + ' ' + item.uploadedByUser.lastName}
                                    </TableCell>
                                    <TableCell component="th" scope="row" className='app-form-subtitle'>
                                        {item.fileExtension.replace(".", "").toUpperCase()}
                                    </TableCell>
                                    <TableCell component="th" scope="row" className='app-form-subtitle'>
                                        <div>
                                            <Moment className="app-form-subtitle" style={{ marginTop: '4px' }}
                                                format="DD/MM/YYYY">
                                                {item.created}
                                            </Moment>
                                        </div>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <div style={{ marginTop: 24 }}>
                    <PaginationCustom pages={totalDocNumPages()} activePage={activePageSelectedDoc}
                        onChange={page => changeSelectedDocPage(page)} />
                </div>
            </div>
        </div>
    );

}

export default (FourthStep);