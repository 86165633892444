import React from "react";
import './Switch.css';

const SwitchComponent = ({ callback, name, checked, disabled = false }) => {

    return (
        <label className="switch" aria-disabled={disabled}>
            <input type="checkbox"
                onChange={disabled ? null : callback}
                checked={checked}
                name={name}
            />
            <span className="slider round"></span>
        </label>
    );
}
export default SwitchComponent;