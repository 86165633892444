import React, { useState, useEffect } from 'react';
import {
    Typography, IconButton,
    Accordion, AccordionDetails, AccordionSummary,
} from "@mui/material";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { ReactComponent as Edit } from '../../../assets/Icons/edit.svg';
import { ReactComponent as Drag } from '../../../assets/Icons/drag.svg';
import { ReactComponent as Files } from '../../../assets/Icons/files.svg';
import { ReactComponent as Card } from '../../../assets/Icons/card-heading.svg';
import { ReactComponent as Trash } from '../../../assets/Icons/trash.svg';
import IconSvg from '../../../components/Icon';
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import SelectFieldComponent from "../../../components/SelectField";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useSelector } from 'react-redux';
import './Text.css';
import { useTranslation } from 'react-i18next';

const Text = ({ index, pageIndex, value = null, onHtmlChange, deleteContent, error = [] }) => {
    const { t } = useTranslation(['mobileContent', 'common']);
    const lenguages = useSelector(store => store.lenguage.lenguages);
    const defaultLanguageId = useSelector(store => store.system.system.defaultLanguageId);
    const secondaryLanguageId = useSelector(store => store.appSetting.appSetting.secundaryLanguageId);
    const [language, setLanguage] = useState(defaultLanguageId);
    const [languageList, setLanguageList] = useState([]);
    const [editorState, setEditorState] = useState();
    const [expanded, setExpanded] = useState(false);

    const onEditorStateChange = (state) => {
        const convertedToHtml = draftToHtml(convertToRaw(state.getCurrentContent()));
        ////console.log("text",convertedToHtml,state);
        onHtmlChange(pageIndex, index - 1, {
            id: value[language] ? value[language].id : null,
            text: convertedToHtml
        }, language);
        setEditorState(state);
    };

    useEffect(() => {
        const contentBlock = htmlToDraft(value[language] ? value[language].text : '');
        if (contentBlock) {
            const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
            const editorState = EditorState.createWithContent(contentState);
            setEditorState(editorState);
        }
        // eslint-disable-next-line
    }, [language]);

    useEffect(() => {
        const list = [];
        lenguages
            .filter(lng => [secondaryLanguageId, defaultLanguageId].includes(lng.key))
            .forEach(item => {
                list.push({
                    key: item.key,
                    value: <span style={{ color: '#5AB6DF' }}>{item.code.toUpperCase()}</span>
                });
            });
        setLanguageList(list);
    }, [lenguages, secondaryLanguageId, defaultLanguageId]);

    return (
        <Accordion className="accordion-sumary-row-title-mobile" expanded={expanded}
            style={{ width: '100%' }}>
            <AccordionSummary
                aria-controls="panel1bh-content"
                id="panel1bh-header"
            >
                <div style={{ width: '98%', display: 'flex', justifyContent: 'space-between' }}>
                    <div style={{
                        display: 'flex', alignItems: 'center',
                        margin: '29px 8px', flexShrink: 0, width: '50%'
                    }} onClick={() => setExpanded(!expanded)}>
                        <IconSvg Icon={Card} className="icon-svg-mobile" />
                        <Typography className="accordion-title-text-mobile" >
                            {index}. {t("textLbl")}
                        </Typography>
                    </div>
                    <div style={{
                        display: 'flex', alignItems: 'center',
                        width: '48%', justifyContent: 'flex-end'
                    }}>
                        {secondaryLanguageId &&
                            <SelectFieldComponent
                                name="language"
                                className="language-code"
                                valueInput={language}
                                options={[
                                    ...languageList
                                ]}
                                onChange={e => setLanguage(e.target.value)}
                            />
                        }
                        <div style={{ display: 'flex', alignItems: 'center', marginTop: 5 }}>
                            <Edit style={{ margin: '0 20px' }} />
                            <Files style={{ marginRight: 20 }} />
                            <Drag />
                        </div>
                    </div>
                </div>
                {(error.length > 0 && error.find(err => language in err))
                    && error.find(err => language in err)[language] &&
                    <p className='app-error-text'>{error.find(err => language in err)[language]}</p>
                }
            </AccordionSummary>
            <AccordionDetails className='panel-details'>
                <Typography className="app-form-title">{t("subtitle4")}</Typography>
                <div className="container">
                    <div className="row">
                        <div className="col align-self-center">
                            <form>
                                <Editor
                                    key={index}
                                    handlePastedText={() => false}
                                    localization={{
                                        locale: lenguages.length > 0 ? lenguages
                                            .find(lng => lng.key === defaultLanguageId).code : 'es'
                                    }}
                                    editorState={editorState}
                                    onEditorStateChange={onEditorStateChange}
                                    wrapperClassName="wrapper"
                                    editorClassName="editor"
                                    toolbar={{
                                        options: ['inline', 'colorPicker', 'image', 'embedded', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'link', 'remove', 'history'],
                                        inline: { inDropdown: false },
                                        blockType: {
                                            inDropdown: false,
                                            options: ['H1', 'H2', 'H3']
                                        },
                                        textAlign: {
                                            inDropdown: false,
                                            options: ['left', 'center', 'right'],
                                        },
                                        list: { inDropdown: false },
                                        link: { inDropdown: false },
                                        embedded: {
                                            defaultSize: {
                                                height: '250',
                                                width: '264',
                                            },
                                        },
                                        image: {
                                            defaultSize: {
                                                height: '250',
                                                width: '264',
                                            },
                                        }
                                    }}
                                />
                            </form>
                        </div>
                    </div>
                </div>
                <div style={{ display: 'flex', marginTop: 20, justifyContent: 'space-between' }}>
                    {secondaryLanguageId ?
                        <Typography className='mobile-content-alert'>
                            <InfoOutlinedIcon fontSize='small' style={{ marginRight: 10 }} />
                            {t("subtitle20")}
                        </Typography>
                        : <div></div>
                    }
                    <IconButton onClick={() => deleteContent(pageIndex, index - 1)}>
                        <Trash className="mobile-icon-svg-delete mobile-icon-delete" />
                    </IconButton>
                </div>
            </AccordionDetails>
        </Accordion >
    );
}

export default Text;




