import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { withRouter } from "react-router";
import { Link } from 'react-router-dom';
import {
    Typography, Button, TableContainer, TableRow,
    TableCell, Table, TableHead, TableBody, Container,
    Grid, InputLabel
} from "@mui/material";
import Title from '../../../components/Title';
import SelectFieldComponent from "../../../components/SelectField";
import TextFieldComponent from "../../../components/TextField";
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { ReactComponent as Search } from '../../../assets/Icons/search.svg';
import * as ROUTES from '../../../constants/routes';
import PaginationCustom from '../../../components/Pagination';
import { changeNotificationPage, getNotifications } from "../../../redux/NotificationDuck";
import { getClients } from "../../../redux/ClientDuck";
import Moment from "react-moment";
import { useTranslation } from "react-i18next";
const ReadNotification = () => {
    const { t } = useTranslation(['notification', 'common', 'label']);
    const activePage = useSelector(store => store.notification.activePage);
    const pages = useSelector(store => store.notification.pages);
    const notifications = useSelector(store => store.notification.notifications);
    const clientsRedux = useSelector(store => store.client.clients);
    const [clients, setClients] = useState([]);
    const [clientId, setClientId] = useState('none');
    const [search, setSearch] = useState('');
    const dispatch = useDispatch();

    //componentDidMount
    useEffect(() => {
        dispatch(changeNotificationPage(1));
        //component will unmount
        return () => {
            dispatch(changeNotificationPage(1));
        }
    }, [dispatch]);

    useEffect(() => {
        const filterBy = [];
        search !== '' && filterBy.push({ search: search, filterBy: 'title' });
        clientId !== 'none' && filterBy.push({ search: String(clientId), filterBy: 'client' });
        const timer = setTimeout(() => dispatch(getNotifications(filterBy, activePage, 10, true)), 1000);
        return () => clearTimeout(timer);
        // eslint-disable-next-line
    }, [dispatch, search, clientId]);

    useEffect(() => {
        const filterBy = [];
        search !== '' && filterBy.push({ search: search, filterBy: 'title' });
        clientId !== 'none' && filterBy.push({ search: String(clientId), filterBy: 'client' });
        dispatch(getNotifications(filterBy, activePage, 10));
        // eslint-disable-next-line
    }, [dispatch, activePage]);

    useEffect(() => {
        const list = [];
        clientsRedux.forEach(item => {
            list.push({ key: item.id, value: item.name });
        });
        setClients(list);
    }, [clientsRedux]);

    useEffect(() => {
        dispatch(getClients(
            [{ search: 'false', filterBy: 'disabled' }],
            0, 10000, false, 'notification'));
    }, [dispatch]);

    return (
        <Container>
            <Title title={t("notification:title")} style={{ marginLeft: 5 }} />
            <Grid item xs={12}>
                <div className="container-app-div">
                    <Typography className="app-form-title">{t("notification:title")}</Typography>
                    <InputLabel className="label-app">{t("notification:headerTbl2")}</InputLabel>
                    <SelectFieldComponent name="clientName"
                        valueInput={clientId}
                        options={[{ key: 'none', value: t("common:commonAllLbl") }, ...clients]}
                        onChange={e => setClientId(e.target.value)}
                    />
                </div>
                <div className="container-app-div">
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Typography className="app-form-title">{t("notification:title")}</Typography>
                        <Button className='button-app-create' component={Link}
                            to={ROUTES.NOTIFICATIONS_CREATE} variant="text" startIcon={<AddRoundedIcon />} >
                            {t("notification:createNotificationBtn")}
                        </Button>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div style={{ width: '35%' }}>
                            <TextFieldComponent Icon={<Search />} position='start' placeholder={t("common:commonSearchPlh")}
                                valueInput={search} callback={e => setSearch(e.target.value)} />
                        </div>

                    </div>
                    <TableContainer>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{ width: '42%' }} className='table-app-title-header'>{t("headerTbl1")}</TableCell>
                                    <TableCell sx={{ width: '30%' }} className='table-app-title-header'>{t("headerTbl2")}</TableCell>
                                    <TableCell className='table-app-title-header'>{t("headerTbl3")}</TableCell>
                                    <TableCell className='table-app-title-header' align="right">{t("headerTbl4")}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {notifications.length === 0 &&
                                    <TableRow key='empty'>
                                        <TableCell colSpan={4} style={{ textAlign: 'center' }}>
                                            {t("common:commonNoResultsLbl")}
                                        </TableCell>
                                    </TableRow>
                                }
                                {notifications.map(item => (
                                    <TableRow key={item.id}>
                                        <TableCell component="th" scope="row" className='app-form-subtitle'>
                                            {item.title}
                                        </TableCell>
                                        <TableCell component="th" scope="row" className='app-form-subtitle'>
                                            {item.client ? item.client.name : t('label:allsLbl')}
                                        </TableCell>
                                        <TableCell component="th" scope="row" className='app-form-subtitle'>
                                            <Moment style={{ marginTop: '4px' }}
                                                format="DD/MM/YYYY">
                                                {item.created}
                                            </Moment>
                                        </TableCell>
                                        <TableCell align="right">
                                            <Button className='button-app-see-detail' component={Link}
                                                to={ROUTES.NOTIFICATIONS_DETAIL.replace(':id', item.id)}>
                                                {t("common:commonSeeLbl")}
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <br />
                    <PaginationCustom pages={pages} activePage={activePage}
                        onChange={page => dispatch(changeNotificationPage(page))} />
                </div>
            </Grid>
        </Container>
    );

}
export default withRouter(ReadNotification);