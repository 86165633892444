import React, { useEffect, useState } from "react";
import { withRouter } from "react-router";
import { Link } from 'react-router-dom';
import { Container, Grid, Typography, CardMedia, Button } from "@mui/material";
import Title from "../../../components/Title";
import CircleIcon from '@mui/icons-material/Circle';
import { ReactComponent as SettingsOutline } from '../../../assets/Icons/settingsOutline.svg';
import { ReactComponent as Archive } from '../../../assets/Icons/archive.svg';
import * as ROUTES from '../../../constants/routes';
import TableDetails from "../TableDetail";
import { useDispatch, useSelector } from "react-redux";
import { getClientById, changeStatusClient } from '../../../redux/ClientDuck'
import { getFileURL } from "../../../constants/apiUrls";
import { getUsers, changeUserPage } from '../../../redux/UserDuck'
import { getAssignedClientStaff, changeUserAssignedPage } from "../../../redux/UserAssignDuck";
import PaginationCustom from "../../../components/Pagination";
import { getSubjects, changeSubjectPage } from '../../../redux/SubjectDuck'
import './Detail.css';
import { useTranslation } from "react-i18next";
import { ReactComponent as ArrowBack } from '../../../assets/Icons/arrowBack.svg';


const CustomerDetail = props => {
    const { t } = useTranslation(['customer', 'common']);
    const permission = useSelector(store => store.auth.permission);
    const activePageUser = useSelector(store => store.user.activePage);
    const activePageAuthorized = useSelector(store => store.assign.activePageAssigned);
    const pagesAuthorized = useSelector(store => store.assign.pagesAssigned);
    const activePageSubject = useSelector(store => store.subject.activePage);
    const pagesSubject = useSelector(store => store.subject.pages);

    const pagesUser = useSelector(store => store.user.pages);
    const client = useSelector(store => store.client.client);
    const [clientId, setClientId] = useState('');
    const dispatch = useDispatch();

    //componentDidMount
    useEffect(() => {
        dispatch(changeUserAssignedPage(1));
        dispatch(changeUserPage(1));
        dispatch(changeSubjectPage(1));
        //component will unmount
        return () => {
            dispatch(changeUserAssignedPage(1));
            dispatch(changeUserPage(1));
            dispatch(changeSubjectPage(1));
        }
    }, [dispatch]);

    const getOfficesName = (offices) => {
        const list = [];
        offices.forEach(item => {
            (item.office && !list.includes(item.office.name)) && list.push(item.office.name);
        });
        return list.toString().replace(',', ', ');
    };

    useEffect(() => {
        const { id } = props.match.params;
        setClientId(id);
        dispatch(getClientById(id));
    }, [dispatch, props.match.params]);

    useEffect(() => {
        const timer = setTimeout(() => dispatch(getUsers([{ search: clientId, filterBy: 'client' }],
            activePageUser, 3, false, 'client-detail')), 1000);
        return () => clearTimeout(timer);
        // eslint-disable-next-line
    }, [dispatch, clientId]);

    useEffect(() => {
        dispatch(getUsers([{ search: clientId, filterBy: 'client' }],
            activePageUser, 3, false, 'client-detail'));
        // eslint-disable-next-line
    }, [dispatch, activePageUser]);

    useEffect(() => {
        if (client)
            dispatch(getAssignedClientStaff([{
                search: String(client.id), filterBy: 'client'
            }], activePageAuthorized, 3));
    }, [dispatch, activePageAuthorized, client]);

    useEffect(() => {
        if (client)
            dispatch(getSubjects([{
                search: String(client.id), filterBy: 'client'
            }], activePageSubject, 3));
    }, [dispatch, activePageSubject, client]);

    return client && (
        <Container>
            <div style={{ display: 'flex', alignItems: 'center', marginLeft: 30 }}>
                <Button className="arrow-back-icon-svg"
                    style={{ marginBottom: '2%', minWidth: 30 }} variant="text"
                    component={Link}
                    to={ROUTES.CLIENTS} startIcon={<ArrowBack />}>
                </Button>
                <Title title={t("title")} style={{ marginLeft: 5 }} />
            </div>
            <Grid item xs={12}>
                <div className="container-app-div extra-padding-for-div">
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <div style={{ display: 'flex' }}>
                            {client.fileId &&
                                <CardMedia
                                    component="img"
                                    image={getFileURL + client.fileId}
                                    sx={{ width: 'auto' }}
                                />
                            }
                            <div style={{ marginLeft: 37 }}>
                                <div style={{ display: 'flex' }}>
                                    <Typography className="big-text-for-business-name"
                                        style={{ color: '#152536', maxWidth: "70%" }}>
                                        {client.name}
                                    </Typography>
                                    <Typography className="big-text-for-business-name"
                                        style={{ color: '#6C757D', marginLeft: '7px' }}>
                                        {client.clientType ? '(' + t("common:commonCompanyLbl") + ')' : '(' + t("common:commonPersonLbl") + ')'}
                                    </Typography>
                                </div>
                                <Typography className="office-direction-title" style={{ margin: '4px 0px' }}>
                                    {t("officeLbl")}s: {getOfficesName(client.clientOffices)}
                                </Typography>
                                <div className='status-row-client' style={{ color: client.disabled ? '#6C757D' : '#28A745', margin: '9px 0px' }}>
                                    <CircleIcon style={{ fontSize: 7, marginRight: 7 }} />
                                    <Typography>
                                        {client.disabled ? t("statusArchivedLbl") : t("statusActiveLbl")}
                                    </Typography>
                                </div>
                            </div>
                        </div>
                        <Button className="button-edit-client" component={Link}
                            to={ROUTES.CLIENT_UPDATE.replace(':id', client.id)}>
                            {t("common:commonEditBtn")}
                        </Button>
                    </div>
                </div>
                <div className="container-app-div">
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Typography className="app-form-title">{t("subtitle2")}</Typography>
                        {permission && permission.permissions.find(p => p.permissionName === 'Permissions.ClientStaff')
                            &&
                            <Button className='button-app-create' variant="text"
                                startIcon={<SettingsOutline />} component={Link}
                                to={ROUTES.CUSTOMER_STAFF}>
                                {t("adminCustomerStaffBtn")}
                            </Button>
                        }
                    </div>
                    <TableDetails
                        tableHeaders={[{ name: t("common:commonNameLbl") }, { name: 'Email' }, { name: t("common:commonPhoneLbl") },
                        ]} typeOfTable="CustomerStaff"
                    />
                    <br />
                    <PaginationCustom pages={pagesAuthorized} activePage={activePageAuthorized}
                        onChange={page => dispatch(changeUserAssignedPage(page))} />
                </div>
                <div className="container-app-div">
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Typography className="app-form-title">{t("subtitle3")}</Typography>
                        {permission && permission.permissions.find(p => p.permissionName === 'Permissions.Users')
                            &&
                            <Button className='button-app-create' variant="text"
                                startIcon={<SettingsOutline />} component={Link}
                                to={ROUTES.USERS}>
                                {t("adminUserBtn")}
                            </Button>
                        }
                    </div>
                    <TableDetails tableHeaders={[{ name: t("common:commonNameLbl") }]} typeOfTable="AssignedUsers" />
                    <br />
                    <PaginationCustom pages={pagesUser} activePage={activePageUser}
                        onChange={page => dispatch(changeUserPage(page))} />
                </div>
                <div className="container-app-div">
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Typography className="app-form-title">{t("subtitle4")}</Typography>
                        {permission && permission.permissions.find(p => p.permissionName === 'Permissions.Subject')
                            &&
                            <Button className='button-app-create' variant="text"
                                startIcon={<SettingsOutline />} component={Link}
                                to={ROUTES.MATTERS}>
                                {t("adminMatterBtn")}
                            </Button>
                        }
                    </div>
                    <Typography className="app-form-subtitle">{t("subtitle5")}</Typography>
                    <TableDetails
                        tableHeaders={[{ name: t("headerTbl8") }, { name: t("headerTbl9") }, { name: t("headerTbl10") }]}
                        typeOfTable="ClientActivity"
                    />
                    <br />
                    <PaginationCustom pages={pagesSubject} activePage={activePageSubject}
                        onChange={page => dispatch(changeSubjectPage(page))} />
                </div>
                <div className="container-app-div">
                    <Typography className="app-form-title">{t("optionsLbl")}</Typography>
                    <Button className='button-archive' variant="text"
                        startIcon={<Archive />}
                        onClick={() => dispatch(changeStatusClient(client.id))}>
                        {!client.disabled ? t("archiveBtn") : t("enableBtn")}
                    </Button>
                </div>
            </Grid>
        </Container>
    );

}

export default withRouter(CustomerDetail);