import {
    getAssignUser, getAssignedClientStaffURL,
    getPendingClientStaffURL, putAssignClientStaffURL,
    getClientStaffURL, getClientsURL, postClientStaffURL,
    pendingClientStaffByIdURL, noSpamClientURL, spamClientURL,
    putAssignedUserActiveURL, putAssignedUserDisableURL,putMobileAssignedUserDisableURL,putMobileAssignedUserActiveURL,
    updateUserAssignedClients
} from '../constants/apiUrls';
import { postAxios, putAxios, getAxios } from '../services/Axios';
import { getUserById } from './UserDuck';
import { logoutAction, renewTokenAction } from './AuthDuck'

// constantes
const initialState = {
    clients: [],
    clientStaff: null,
    pendingClient: null,
    isUpdated: false,
    phone: null,
    assignedClientStaff: [],
    pendingClientStaff: [],
    pagesAssigned: 1,
    activePageAssigned: 0,
    pagesPending: 1,
    activePagePending: 0,
    pagesClient: 1,
    activePageClient: 0,
    reload: false,
    response: null,
    loading: false,
    error: null,
    isCreated: false,
    makeRequest: false
};

// types
const USER_ASSIGN_SUCCESS = 'USER_ASSIGN_SUCCESS';
const AFTER_SUCESSFUL_UPDATE = 'AFTER_SUCESSFUL_UPDATE';
const USER_ASSIGNED_CLIENT_STAFF_SUCCESS = 'USER_ASSIGNED_CLIENT_STAFF_SUCCESS';
const USER_PENDING_CLIENT_STAFF_SUCCESS = 'USER_PENDING_CLIENT_STAFF_SUCCESS';
const USER_PENDING_CHANGE_PAGE = 'USER_PENDING_CHANGE_PAGE';
const USER_ASSIGNED_CHANGE_PAGE = 'USER_ASSIGNED_CHANGE_PAGE';
const USER_CLIENT_CHANGE_PAGE = 'USER_CLIENT_CHANGE_PAGE';
const USER_ASSIGN_CLIENT_STAFF_SUCCESS = 'USER_ASSIGN_CLIENT_STAFF_SUCCESS';
const USER_ASSIGN_CLIENTS_SUCCESS = 'USER_ASSIGN_CLIENTS_SUCCESS';
const USER_ASSIGN_STATUS_CHANGE = 'USER_ASSIGN_STATUS_CHANGE';
const GET_CLIENT_STAFF_SUCCESS = 'GET_CLIENT_STAFF_SUCCESS';
const CREATE_CLIENT_STAFF_SUCCESS = 'CREATE_CLIENT_STAFF_SUCCESS';
const SET_CREATED_CLIENT_STAFF = 'SET_CREATED_CLIENT_STAFF';
const USER_ASSIGN_ERROR = 'USER_ASSIGN_ERROR';
const LOADING_USER_ASSIGN = 'LOADING_USER_ASSIGN';
const CLIENT_STAFF_STATUS_CHANGE = 'CLIENT_STAFF_STATUS_CHANGE';
const GET_PENDING_CLIENT_STAFF_SUCCESS = 'GET_PENDING_CLIENT_STAFF_SUCCESS';
const CLEAR_RESPONSE = 'CLEAR_RESPONSE';
const LOG_OUT = 'LOG_OUT';
const INITIAL_STATE = 'INITIAL_STATE';

// reducer
export default function reducer(state = initialState, action) {
    switch (action.type) {
        case LOADING_USER_ASSIGN:
            return { ...state, loading: true, error: null };
        case AFTER_SUCESSFUL_UPDATE:
            return { ...state, clientStaff: null, isUpdated: false, isCreated: true };
        case USER_ASSIGN_CLIENTS_SUCCESS:
            return {
                ...state, clients: action.payload, activePageClient: action.activePage,
                reload: false, pagesClient: action.pages, error: null, loading: false
            };
        case USER_ASSIGN_SUCCESS:
            return {
                ...state, response: action.payload, isUpdated: true,
                reload: true, error: null, loading: false
            };
        case USER_ASSIGN_CLIENT_STAFF_SUCCESS:
            return {
                ...state, response: action.payload,
                reload: true, error: null, loading: false
            };
        case USER_ASSIGN_STATUS_CHANGE:
            return {
                ...state, response: action.response, loading: false, error: null,
                reload: true, makeRequest: true
            };
        case USER_ASSIGNED_CLIENT_STAFF_SUCCESS:
            return {
                ...state, assignedClientStaff: action.payload, activePageAssigned: action.activePage,
                pagesAssigned: action.pages, error: null, loading: false,
                makeRequest: false, reload: false
            };
        case USER_PENDING_CLIENT_STAFF_SUCCESS:
            return {
                ...state, pendingClientStaff: action.payload, activePagePending: action.activePage,
                pagesPending: action.pages, error: null, loading: false, reload: false
            };
        case CREATE_CLIENT_STAFF_SUCCESS:
            return {
                ...state, isCreated: true, response: action.response,
                reload: true, loading: false, error: null
            };
        case CLIENT_STAFF_STATUS_CHANGE:
            return {
                ...state, response: action.response,
                reload: true, loading: false, error: null
            };
        case SET_CREATED_CLIENT_STAFF:
            return { ...state, clientStaff: null, isCreated: false };
        case GET_CLIENT_STAFF_SUCCESS:
            return {
                ...state, clientStaff: action.payload,
                reload: false, error: null, loading: false
            };
        case GET_PENDING_CLIENT_STAFF_SUCCESS:
            return {
                ...state, pendingClient: action.payload,
                reload: false, error: null, loading: false
            };
        case USER_ASSIGN_ERROR:
            return {
                ...state, error: action.payload,
                response: action.payload, loading: false
            };
        case USER_ASSIGNED_CHANGE_PAGE:
            return { ...state, activePageAssigned: action.activePage };
        case USER_PENDING_CHANGE_PAGE:
            return { ...state, activePagePending: action.activePage };
        case USER_CLIENT_CHANGE_PAGE:
            return { ...state, activePageClient: action.activePage };
        case CLEAR_RESPONSE:
            return { ...state, response: null };
        case LOG_OUT:
            return { ...initialState };
        case INITIAL_STATE:
            return {
                ...state,
                clients: [],
                clientStaff: null,
                pendingClient: null,
                phone: null,
                assignedClientStaff: [],
                pendingClientStaff: [],
                pagesAssigned: 1,
                activePageAssigned: 0,
                pagesPending: 1,
                activePagePending: 0,
                pagesClient: 1,
                activePageClient: 0,
            };
        default:
            return state;
    }
};

export const assignClientListToUser = (userId, clientList) => async (dispatch) => {
    dispatch({ type: LOADING_USER_ASSIGN });
    try {
        const res = await postAxios(updateUserAssignedClients, {
            userId: userId,
            clientUser: clientList
        });
        if (res.status === 200) {
            const token = res.headers.refreshtoken;
            const expiration = res.headers.expirationToken;
            (token && expiration) && dispatch(renewTokenAction(token, expiration));
            dispatch(getUserById(userId, true));
            return dispatch({
                type: USER_ASSIGN_SUCCESS,
                payload: res.data.response
            });
        }
        throw new Error();
    } catch (error) {
        if (error.response && error.response.status === 401)
            dispatch(logoutAction());
        dispatch({
            type: USER_ASSIGN_ERROR,
            payload: error.response ? error.response.data.message : "common:errorAssigningClientToUser"
        });
    }
};

export const assignClientToUser = (userId, clients) => async (dispatch) => {
    dispatch({ type: LOADING_USER_ASSIGN });
    try {
        const clientList = [];
        clients.forEach(item => {
            clientList.push({ userId: userId, clientId: item });
        });
        const res = await postAxios(getAssignUser, {
            userId: userId,
            clientUser: clientList
        });
        if (res.status === 200) {
            const token = res.headers.refreshtoken;
            const expiration = res.headers.expirationToken;
            (token && expiration) && dispatch(renewTokenAction(token, expiration));
            dispatch(getUserById(userId, true));
            return dispatch({
                type: USER_ASSIGN_SUCCESS,
                payload: res.data.response
            });
        }
        throw new Error();
    } catch (error) {
        if (error.response && error.response.status === 401)
            dispatch(logoutAction());
        dispatch({
            type: USER_ASSIGN_ERROR,
            payload: error.response ? error.response.data.message : "common:errorAssigningClientToUser"
        });
    }
};

export const getAssignedClientStaff = (filterBy = [], page = 0, pageSize = 10, loading = false, service = '') =>
    async (dispatch, getState) => {
        loading && dispatch({ type: LOADING_USER_ASSIGN });
        try {
            const { user } = getState().auth;
            const res = await postAxios(getAssignedClientStaffURL, {
                "organizationId": user.organizationId,
                "page": page,
                "pageSize": pageSize,
                "filterBy": filterBy,
                "service": service,
                "orderBy": "",
                "orderDesc": true
            });
            if (res.status === 200) {
                const token = res.headers.refreshtoken;
                const expiration = res.headers.expirationToken;
                (token && expiration) && dispatch(renewTokenAction(token, expiration));
                return dispatch({
                    type: USER_ASSIGNED_CLIENT_STAFF_SUCCESS,
                    payload: res.data.response,
                    pages: res.data.totalPages,
                    activePage: (res.data.totalPages < page + 1) ? 0 : page
                });
            }
            throw new Error();
        } catch (error) {
            if (error.response && error.response.status === 401)
                dispatch(logoutAction());
            dispatch({
                type: USER_ASSIGN_ERROR,
                payload: error.response ? error.response.data.message : "common:errorLoadingUserAssignedClient"
            });
        }
    };

export const getPendingClientStaff = (filterBy = [], page = 0, pageSize = 10, loading = false) =>
    async (dispatch, getState) => {
        loading && dispatch({ type: LOADING_USER_ASSIGN });
        try {
            const { user } = getState().auth;
            const res = await postAxios(getPendingClientStaffURL, {
                "organizationId": user.organizationId,
                "page": page,
                "pageSize": pageSize,
                "filterBy": filterBy,
                "orderBy": "",
                "orderDesc": true
            });
            if (res.status === 200) {
                const token = res.headers.refreshtoken;
                const expiration = res.headers.expirationToken;
                (token && expiration) && dispatch(renewTokenAction(token, expiration));
                return dispatch({
                    type: USER_PENDING_CLIENT_STAFF_SUCCESS,
                    payload: res.data.response,
                    pages: res.data.totalPages,
                    activePage: (res.data.totalPages < page + 1) ? 0 : page
                });
            }
            throw new Error();
        } catch (error) {
            if (error.response && error.response.status === 401)
                dispatch(logoutAction());
            dispatch({
                type: USER_ASSIGN_ERROR,
                payload: error.response ? error.response.data.message : "common:errorLoadingUserAssignedClient"
            });
        }
    };

export const assignClientStaff = (userId) => async (dispatch) => {
    dispatch({ type: LOADING_USER_ASSIGN });
    try {
        const res = await putAxios(putAssignClientStaffURL + userId);
        if (res.status === 200) {
            const token = res.headers.refreshtoken;
            const expiration = res.headers.expirationToken;
            (token && expiration) && dispatch(renewTokenAction(token, expiration));
            return dispatch({
                type: USER_ASSIGN_CLIENT_STAFF_SUCCESS,
                payload: res.data.response
            });
        }
        throw new Error();
    } catch (error) {
        if (error.response && error.response.status === 401)
            dispatch(logoutAction());
        dispatch({
            type: USER_ASSIGN_ERROR,
            payload: error.response ? error.response.data.message : "common:errorAssigningClientStaff"
        });
    }
};

export const getClientStaff = (userId) => async (dispatch) => {
    dispatch({ type: LOADING_USER_ASSIGN });
    try {
        const res = await getAxios(getClientStaffURL.replace(':id', userId));
        if (res.status === 200) {
            const token = res.headers.refreshtoken;
            const expiration = res.headers.expirationToken;
            (token && expiration) && dispatch(renewTokenAction(token, expiration));
            return dispatch({
                type: GET_CLIENT_STAFF_SUCCESS,
                payload: res.data.response
            });
        }
        throw new Error();
    } catch (error) {
        if (error.response && error.response.status === 401)
            dispatch(logoutAction());
        dispatch({
            type: USER_ASSIGN_ERROR,
            payload: error.response ? error.response.data.message : "common:errorAssigningClientStaff"
        });
    }
};

export const getPendingClientStaffById = (userId) => async (dispatch) => {
    dispatch({ type: LOADING_USER_ASSIGN });
    try {
        const res = await getAxios(pendingClientStaffByIdURL + userId);
        if (res.status === 200) {
            const token = res.headers.refreshtoken;
            const expiration = res.headers.expirationToken;
            (token && expiration) && dispatch(renewTokenAction(token, expiration));
            return dispatch({
                type: GET_PENDING_CLIENT_STAFF_SUCCESS,
                payload: res.data.response
            });
        }
        throw new Error();
    } catch (error) {
        if (error.response && error.response.status === 401)
            dispatch(logoutAction());
        dispatch({
            type: USER_ASSIGN_ERROR,
            payload: error.response ? error.response.data.message : "common:errorAssigningClientStaff"
        });
    }
};

export const clientStaffSpam = (userId) => async (dispatch, getState) => {
    dispatch({ type: LOADING_USER_ASSIGN });
    try {
        const { pendingClient } = getState().assign;
        const res = !pendingClient.spam
            ? await putAxios(spamClientURL + userId)
            : await putAxios(noSpamClientURL + userId);
        if (res.status === 200) {
            const token = res.headers.refreshtoken;
            const expiration = res.headers.expirationToken;
            (token && expiration) && dispatch(renewTokenAction(token, expiration));
            dispatch({
                type: CLIENT_STAFF_STATUS_CHANGE,
                response: res.data.response
            });
            return dispatch(getPendingClientStaffById(userId));
        }
        throw new Error();
    } catch (error) {
        if (error.response && error.response.status === 401)
            dispatch(logoutAction());
        dispatch({
            type: USER_ASSIGN_ERROR,
            payload: error.response ? error.response.data.message : "common:errorChangingClientStaffStatus"
        });
    }
};

export const saveClientStaff = (clientStaff, clients = [], isPending = false) => async (dispatch, getState) => {
    dispatch({ type: LOADING_USER_ASSIGN });
    let clientList = [];
    clients.forEach((item) => {
        clientList.push(item.clientId);
    })
    try {
        const { user } = getState().auth;
        const res = await postAxios(postClientStaffURL, {
            "organizationId": user.organizationId,
            "clients": clientList,
            "email": clientStaff.email,
            "firstName": clientStaff.firstName,
            "lastName": clientStaff.lastName,
            "countryCodeId": clientStaff.countryCodeId ? clientStaff.countryCodeId : null,
            "phone": clientStaff.phoneNumber ? clientStaff.phoneNumber : null,
            "mobileUserRequestId": isPending ? clientStaff.id : 0
        });
        if (res.status === 200) {
            const token = res.headers.refreshtoken;
            const expiration = res.headers.expirationToken;
            (token && expiration) && dispatch(renewTokenAction(token, expiration));
            return dispatch({
                type: CREATE_CLIENT_STAFF_SUCCESS,
                response: res.data.response,
            });
        }
        throw new Error();
    } catch (error) {
        if (error.response && error.response.status === 401)
            dispatch(logoutAction());
        dispatch({
            type: USER_ASSIGN_ERROR,
            payload: error.response ? error.response.data.message : "errorCreatingClientStaff"
        });
    }
};

export const getClientsOfClientStaff = (filterBy = [], page = 0, pageSize = 10, loading = false) =>
    async (dispatch, getState) => {
        loading && dispatch({ type: LOADING_USER_ASSIGN });
        try {
            const { user } = getState().auth;
            const res = await postAxios(getClientsURL, {
                "organizationId": user.organizationId,
                "page": page,
                "pageSize": pageSize,
                "filterBy": filterBy,
                "service": "client-staff",
                "orderBy": "",
                "orderDesc": true
            });
            if (res.status === 200) {
                const token = res.headers.refreshtoken;
                const expiration = res.headers.expirationToken;
                (token && expiration) && dispatch(renewTokenAction(token, expiration));
                return dispatch({
                    type: USER_ASSIGN_CLIENTS_SUCCESS,
                    payload: res.data.response,
                    pages: res.data.totalPages,
                    activePage: (res.data.totalPages < page + 1) ? 0 : page
                });
            }
            throw new Error();
        } catch (error) {
            if (error.response && error.response.status === 401)
                dispatch(logoutAction());
            dispatch({
                type: USER_ASSIGN_ERROR,
                payload: error.response ? error.response.data.message : "common:errorLoadingClients"
            });
        }
    };

export const changeUserAssignedPage = (page) => async (dispatch) => {
    await dispatch({
        type: USER_ASSIGNED_CHANGE_PAGE,
        activePage: page - 1
    });
};

export const changeUserAssignedStatusWithMobile = (clientStaff) => async (dispatch, getState) => {
    dispatch({ type: LOADING_USER_ASSIGN });
    try {
        const res = (clientStaff.statusMobile === 1 || clientStaff.statusMobile === 0)
            ? await putAxios(putMobileAssignedUserDisableURL + clientStaff.id)
            : await putAxios(putMobileAssignedUserActiveURL + clientStaff.id);

        if (res.status === 200) {
            const token = res.headers.refreshtoken;
            const expiration = res.headers.expirationToken;
            (token && expiration) && dispatch(renewTokenAction(token, expiration));
            dispatch({
                type: USER_ASSIGN_STATUS_CHANGE,
                response: res.data.response
            });
            // dispatch(getAssignedClientStaff([], 0, 4, true));
            return dispatch(getClientStaff(clientStaff.id));
        }
        throw new Error();
    } catch (error) {
        if (error.response && error.response.status === 401)
            dispatch(logoutAction());
        dispatch({
            type: USER_ASSIGN_ERROR,
            payload: error.response ? error.response.data.message : "common:errorOnArchivalStatus"
        });
    }
};
export const changeUserAssignedStatus = (clientStaff) => async (dispatch, getState) => {
    dispatch({ type: LOADING_USER_ASSIGN });
    try {
        const res = (clientStaff.status === 1 || clientStaff.status === 0)
            ? await putAxios(putAssignedUserDisableURL + clientStaff.id)
            : await putAxios(putAssignedUserActiveURL + clientStaff.id);

        if (res.status === 200) {
            const token = res.headers.refreshtoken;
            const expiration = res.headers.expirationToken;
            (token && expiration) && dispatch(renewTokenAction(token, expiration));
            dispatch({
                type: USER_ASSIGN_STATUS_CHANGE,
                response: res.data.response
            });
            // dispatch(getAssignedClientStaff([], 0, 4, true));
            return dispatch(getClientStaff(clientStaff.id));
        }
        throw new Error();
    } catch (error) {
        if (error.response && error.response.status === 401)
            dispatch(logoutAction());
        dispatch({
            type: USER_ASSIGN_ERROR,
            payload: error.response ? error.response.data.message : "common:errorOnArchivalStatus"
        });
    }
};

export const changeUserPendingPage = (page) => (dispatch) => {
    dispatch({
        type: USER_PENDING_CHANGE_PAGE,
        activePage: page - 1
    });
};

export const changeClientPage = (page) => (dispatch) => {
    dispatch({
        type: USER_CLIENT_CHANGE_PAGE,
        activePage: page - 1
    });
};

export const afterUpdate = () => (dispatch) => {
    dispatch({ type: AFTER_SUCESSFUL_UPDATE, isUpdate: false });
}

export const setCreatedClientStaff = () => (dispatch) => {
    dispatch({ type: SET_CREATED_CLIENT_STAFF });
};