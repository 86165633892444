import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
    Typography, IconButton,
    Accordion, AccordionDetails, AccordionSummary,
} from "@mui/material";
import { ReactComponent as Edit } from '../../../assets/Icons/edit.svg';
import { ReactComponent as Drag } from '../../../assets/Icons/drag.svg';
import { ReactComponent as Files } from '../../../assets/Icons/files.svg';
import { ReactComponent as Trash } from '../../../assets/Icons/trash.svg';
import { ReactComponent as Imagen } from '../../../assets/Icons/imagen.svg';
import { ReactComponent as Cloud } from '../../../assets/Icons/cloudUpload2.svg';
import Checkbox from '../../../components/Checkbox';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import SelectFieldComponent from "../../../components/SelectField";
import IconSvg from '../../../components/Icon';
import './Image.css';
import { useTranslation } from 'react-i18next';

const ImageItem = ({ index, pageIndex, value = null, onImageChange, deleteContent, error = [] }) => {
    const { t } = useTranslation(['mobileContent', 'common']);
    const lenguages = useSelector(store => store.lenguage.lenguages);
    const defaultLanguageId = useSelector(store => store.system.system.defaultLanguageId);
    const secondaryLanguageId = useSelector(store => store.appSetting.appSetting.secundaryLanguageId);
    const [language, setLanguage] = useState(defaultLanguageId);
    const [languageList, setLanguageList] = useState([]);
    const [expanded, setExpanded] = useState(false);
    const [duplicateContent, setDuplicateContent] = useState(false);
    const [file, setFile] = useState(null);
    const [isChanged, setIsChanged] = useState(false);

    const onChange = async event => {
        if (!!event.target.files[0]) {
            const file = event.target.files[0];
            setFile({
                id: null,
                file: file,
                width: 0,
                height: 0,
                imageUrl: URL.createObjectURL(file),
                fileId: 0,
                check: duplicateContent
            });
            setIsChanged(true);
        }
    };

    useEffect(() => {
        if (file && isChanged) {
            const imgTemp = file;
            const img = new Image();

            img.src = URL.createObjectURL(imgTemp.file);
            img.onload = () => {
                imgTemp.width = img.width;
                imgTemp.height = img.height;
            };

            const timer = setTimeout(() => {
                duplicateContent ?
                    lenguages.forEach(({ key }) => {
                        onImageChange(pageIndex, index - 1, imgTemp, key);
                    })
                    :
                    onImageChange(pageIndex, index - 1, imgTemp, language);
                setIsChanged(false);
            }, 1000);

            return () => clearTimeout(timer);
        }

        // eslint-disable-next-line
    }, [file, isChanged]);

    const deleteImage = () => {
        onImageChange(pageIndex, index - 1, null, language);
    };

    useEffect(() => {
        const list = [];
        lenguages
            .filter(lng => [secondaryLanguageId, defaultLanguageId].includes(lng.key))
            .forEach(item => {
                list.push({
                    key: item.key,
                    value: <span style={{ color: '#5AB6DF' }}>{item.code.toUpperCase()}</span>
                });
            });
        setLanguageList(list);
    }, [lenguages, secondaryLanguageId, defaultLanguageId]);

    useEffect(() => {
        if (value[language]) {
            setDuplicateContent(value[language].check);
        }
    }, [value, language]);

    const scaleImage = (maxWidth = 294, image) => {
        const aspectRatio = image.width / image.height;
        const imageWidth = Math.min(maxWidth, image.width);
        const imageHeight = imageWidth / aspectRatio;

        return {
            width: imageWidth,
            height: imageHeight,
        };
    };

    return (
        <Accordion className="accordion-sumary-row-title-mobile" expanded={expanded}
            style={{ width: '100%' }}>
            <AccordionSummary
                aria-controls="panel1bh-content"
                id="panel1bh-header"
            >
                <div style={{ width: '98%', display: 'flex', justifyContent: 'space-between' }}>
                    <div style={{
                        display: 'flex', alignItems: 'center',
                        margin: '29px 8px', flexShrink: 0, width: '50%'
                    }} onClick={() => setExpanded(!expanded)}>
                        <IconSvg Icon={Imagen} className="icon-svg-mobile" />
                        <Typography className="accordion-title-text-mobile" >
                            {index}. {t("imageLbl")}
                        </Typography>
                    </div>
                    <div style={{
                        display: 'flex', alignItems: 'center',
                        width: '48%', justifyContent: 'flex-end'
                    }}>
                        {secondaryLanguageId &&
                            <SelectFieldComponent
                                name="language"
                                className="language-code"
                                valueInput={language}
                                options={[
                                    ...languageList
                                ]}
                                onChange={e => setLanguage(e.target.value)}
                            />
                        }
                        <div style={{ display: 'flex', alignItems: 'center', marginTop: 5 }}>
                            <Edit style={{ margin: '0 20px' }} />
                            <Files style={{ marginRight: 20 }} />
                            <Drag />
                        </div>
                    </div>
                </div>
                {(error.length > 0 && error.find(err => language in err))
                    && error.find(err => language in err)[language] &&
                    <p className='app-error-text'>{error.find(err => language in err)[language]}</p>
                }
            </AccordionSummary>
            <AccordionDetails>
                <div style={{ margin: '15px 16px 0px 15px' }}>
                    {secondaryLanguageId &&
                        <>
                            <Typography className="app-form-title">{t("subtitle5")}</Typography>
                            <Checkbox
                                name='primaryLng'
                                value={duplicateContent}
                                checkboxCheckedValue={true}
                                checkboxLabel={t("subtitle22")}
                                onChange={() => !value[language] ? setDuplicateContent(!duplicateContent) : null}
                                disabled={!!value[language]}
                            />
                        </>
                    }
                    <div className='div-image'>
                        {!!value[language] ?
                            <div style={{
                                display: 'flex', padding: 10, flexDirection: 'column',
                                justifyContent: 'center', position: 'relative', width: "100%",
                                alignItems: "center"
                            }}>
                                <div className='preview-image-div-mob'>
                                    <img src={value[language].imageUrl} alt="logo"
                                        className='preview-image-mob'
                                        style={{
                                            ...scaleImage(294, value[language])
                                        }}
                                    />
                                    <IconButton style={{
                                        position: 'absolute', top: '0%',
                                        right: '0%', cursor: 'pointer'
                                    }} onClick={() => deleteImage()}>
                                        <Trash className="mobile-form-icon-svg-delete mobile-form-icon-delete" />
                                    </IconButton>
                                </div>
                            </div>
                            :
                            <div style={{
                                padding: "10px 30px", textAlign: "center",
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}>
                                <input type="file" accept=".jpg, image/png"
                                    name="image" onChange={onChange} />
                                <Cloud style={{
                                    width: '100%', height: 40, paddingTop: 15
                                }} />
                                <p className='drag-image-text'>{t("subtitle6")}</p>
                                <p className="image-description-mobile"> {t("subtitle7")}</p>
                            </div>
                        }
                    </div>
                </div>
                <div style={{ display: 'flex', marginTop: 20, justifyContent: 'space-between' }}>
                    {secondaryLanguageId ?
                        <Typography className='mobile-content-alert'>
                            <InfoOutlinedIcon fontSize='small' style={{ marginRight: 10 }} />
                            {t("subtitle20")}
                        </Typography>
                        : <div></div>
                    }
                    <IconButton onClick={() => deleteContent(pageIndex, index - 1)}>
                        <Trash className="mobile-icon-svg-delete mobile-icon-delete" />
                    </IconButton>
                </div>
            </AccordionDetails>
        </Accordion >
    );
}

export default ImageItem;

