import React, { useState, useEffect } from "react";
import { withRouter } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import {
    Container, Grid, Typography, Button, InputLabel
} from "@mui/material";
import Title from "../../../components/Title";
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { ReactComponent as Search } from '../../../assets/Icons/search.svg';
import { ReactComponent as Filter } from '../../../assets/Icons/filter.svg';
import TextFieldComponent from "../../../components/TextField";
import SelectFieldComponent from '../../../components/SelectField';
import TableUser from '../Table';
import { getUsers, changeUserPage } from '../../../redux/UserDuck';
import { getBusinessesFilter, getOfficesFilter } from "../../../redux/BusinessDuck";
import * as ROUTES from '../../../constants/routes';
import PaginationCustom from "../../../components/Pagination";
import { useTranslation } from 'react-i18next';

const UserRead = props => {

    const { t } = useTranslation(['user', 'common']);
    const users = useSelector(store => store.user.users);
    const officesRedux = useSelector(store => store.business.offices);
    const activePage = useSelector(store => store.user.activePage);
    const pages = useSelector(store => store.user.pages);
    const [filter, setFilters] = useState('none');
    const [search, setSearch] = useState('');
    const [resetPage, setResetPage] = useState(false);
    const dispatch = useDispatch();
    const [offices, setOffices] = useState([]);
    const [businessList, setBusinessList] = useState([]);
    const businesses = useSelector(store => store.business.businesses);

    const [businessId, setBusinessId] = useState('all');
    const [officeId, setOfficeId] = useState('all');
    const changeBusinessId = e => {
        setBusinessId(e.target.value);
        setOfficeId('all');
    };
    useEffect(() => {
        const list = [];
        businesses.forEach(item => {
            list.push({
                key: item.id, value: item.name,
            });
        });
        setBusinessList(list);
    }, [businesses]);

    useEffect(() => {
        dispatch(getOfficesFilter(businessId !== 'all' ? [{
            "search": String(businessId),
            "filterBy": "company"
        }] : [], false));
    }, [dispatch, businessId]);
    
    //componentDidMount
    useEffect(() => {
        dispatch(changeUserPage(1));
        //component will unmount
        return () => {
            dispatch(changeUserPage(1));
        }
    }, [dispatch]);

    useEffect(() => {
        dispatch(getBusinessesFilter([], false));
    }, [dispatch]);
    useEffect(() => {
        const officesTemp = [];
        officesRedux.forEach(item => {
            officesTemp.push({ key: item.id, value: item.name });
        });
        setOffices(officesTemp);
        setOfficeId('all');
    }, [businessId, officesRedux]);

    useEffect(() => {
        const page = resetPage ? 0 : activePage;
        const filterBy = [];
        search !== '' && filterBy.push({ search: search, filterBy: 'search-bar' });
        filter !== 'none' && filterBy.push({ search: filter, filterBy: 'status' });
        businessId !== 'all' && filterBy.push({ search: String(businessId), filterBy: 'company' });
        officeId !== 'all' && filterBy.push({ search: String(officeId), filterBy: 'office' });

        const timer = setTimeout(() => dispatch(getUsers(filterBy, page, 10, true, "users")), 1000);
        setResetPage(false);
        return () => clearTimeout(timer);
        // eslint-disable-next-line
    }, [dispatch, businessId, officeId, search, filter]);

    useEffect(() => {
        const page = resetPage ? 0 : activePage;
        const filterBy = [];
        search !== '' && filterBy.push({ search: search, filterBy: 'search-bar' });
        filter !== 'none' && filterBy.push({ search: filter, filterBy: 'status' });
        businessId !== 'all' && filterBy.push({ search: String(businessId), filterBy: 'company' });
        officeId !== 'all' && filterBy.push({ search: String(officeId), filterBy: 'office' });

        dispatch(getUsers(filterBy, page, 10, false, "users"));
        setResetPage(false);
        // eslint-disable-next-line
    }, [dispatch, activePage]);

    const handleChange = (event) => {
        setFilters(event.target.value);
        setResetPage(true);
    };

    const onChange = (event) => {
        setSearch(event.target.value);
        setResetPage(true);
    };

    return (
        <Container>
            <Title title={t("title")} />
            <Grid item xs={12}>
                <div className="container-app-div">
                    <Typography className="app-form-title">{t("common:commonFilterLbl") + "s"}</Typography>
                    <div style={{
                        display: 'flex', flexDirection: 'row',
                        width: '100%', justifyContent: 'space-between',
                    }}>
                        <div style={{ width: '48%' }}>
                            <InputLabel className="label-app">{t("common:commonCompanyLbl")}</InputLabel>
                            <SelectFieldComponent name="businessName"
                                valueInput={businessId}
                                options={[{ key: 'all', value: t("common:commonAllLbl") }, ...businessList]}
                                onChange={changeBusinessId}
                            />
                        </div>
                        <div style={{ width: '48%' }}>
                            <InputLabel className="label-app">{t("common:commonOfficeLbl")}</InputLabel>
                            <SelectFieldComponent name="officesName"
                                valueInput={officeId}
                                options={businessId !== 'all'
                                    ? [{ key: 'all', value: t("common:commonAllLbl") }, ...offices]
                                    : [{ key: 'all', value: t("common:commonAllLbl") }]}
                                onChange={e => setOfficeId(e.target.value)}
                            />
                        </div>
                    </div>
                </div>
                <div className="container-app-div">
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Typography className="app-form-title">{t("title")} </Typography>
                        <Button className='button-app-create' variant="text"
                            startIcon={<AddRoundedIcon />}
                            onClick={() => props.history.push(ROUTES.USERS_CREATE)}
                        >
                            {t("createUserBtn")}
                        </Button>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div style={{ width: '35%' }}>
                            <TextFieldComponent
                                Icon={<Search />}
                                position='start'
                                placeholder={t("common:commonSearchPlh")}
                                valueInput={search}
                                callback={onChange}
                            />
                        </div>
                        <div>
                            <SelectFieldComponent name="filter"
                                placeholder={t("common:commonFilterByStatusPlh")}
                                valueInput={filter}
                                onChange={handleChange}
                                options={[
                                    { key: '0', value: t("common:commonStatusProcessing") },
                                    { key: '1', value: t("common:commonStatusActive") },
                                    { key: '2', value: t("common:commonStatusInactive") }
                                ]}
                                Icon={Filter}
                            />
                        </div>
                    </div>
                    <TableUser userList={users} />
                    <br />
                    <PaginationCustom pages={pages} activePage={activePage}
                        onChange={page => dispatch(changeUserPage(page))} />
                </div>
            </Grid>
        </Container>);

}

export default withRouter(UserRead);