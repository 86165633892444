import React, { useState, useEffect } from 'react';
import { Snackbar } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { clearResponse } from '../../redux/AuthDuck';
import { useTranslation } from 'react-i18next';

const SnackbarResponse = () => {
    const { t } = useTranslation(['auth','configApp','error','common']);

    const authResponse = useSelector(store => store.auth.response);
    const appSettingResponse = useSelector(store => store.appSetting.response);
    const businessResponse = useSelector(store => store.business.response);
    const systemResponse = useSelector(store => store.system.response);
    const userResponse = useSelector(store => store.user.response);
    const permissionResponse = useSelector(store => store.permission.response);
    const clientResponse = useSelector(store => store.client.response);
    const assignResponse = useSelector(store => store.assign.response);
    const subjectResponse = useSelector(store => store.subject.response);
    const docResponse = useSelector(store => store.doc.response);
    const authorizationResponse = useSelector(store => store.authorization.response);
    const subscriptionResponse = useSelector(store => store.subscription.response);
    const notificationResponse = useSelector(store => store.notification.response);
    const logResponse = useSelector(store => store.log.response);
    const mobileResponse = useSelector(store => store.mobile.response);

    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState('');
    const dispatch = useDispatch();

    const handleClose = () => setOpen(false);

    useEffect(() => {
        if (!!(authResponse || appSettingResponse || businessResponse ||
            systemResponse || userResponse || permissionResponse ||
            clientResponse || assignResponse || subjectResponse ||
            docResponse || authorizationResponse || notificationResponse || subscriptionResponse ||
            logResponse || mobileResponse)) {
            setOpen(true);
            setMessage(authResponse || appSettingResponse || businessResponse ||
                systemResponse || userResponse || permissionResponse ||
                clientResponse || assignResponse || subjectResponse ||
                docResponse || authorizationResponse || notificationResponse || subscriptionResponse ||
                logResponse || mobileResponse);
            setTimeout(() => {
                dispatch(clearResponse());
                setOpen(false);
            }, 5000);
        }
    }, [
        authResponse,
        appSettingResponse,
        businessResponse,
        systemResponse,
        userResponse,
        permissionResponse,
        clientResponse,
        assignResponse,
        subjectResponse,
        docResponse,
        authorizationResponse,
        subscriptionResponse,
        notificationResponse,
        logResponse,
        mobileResponse,
        dispatch
    ]);

    return (
        <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={open}
            autoHideDuration={5000}
            onClose={handleClose}
            message={t(message)}
        />
    );
};

export default SnackbarResponse;