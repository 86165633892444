import React, { useState, useEffect, createRef } from "react";
import {
    Container, Grid, Typography, CardMedia, Button, InputLabel,
    Divider, TableContainer, TableRow,
    TableCell, Table, TableHead, TableBody
} from "@mui/material";
import Title from "../../../components/Title";
import TextFieldComponent from "../../../components/TextField";
import SelectFieldComponent from "../../../components/SelectField";
import Checkbox from '../../../components/Checkbox';
import PaginationCustom from "../../../components/Pagination";
import { ReactComponent as Less } from '../../../assets/Icons/less.svg';
import { ReactComponent as More } from '../../../assets/Icons/more.svg';
import { ReactComponent as Search } from '../../../assets/Icons/search.svg';
import SwitchComponent from "../../../components/Switch";
import AccessRow from "./AccessRow";
import { useDispatch, useSelector } from "react-redux";
import { getCountries } from "../../../redux/CountryDuck";
import { saveUpdateClient } from "../../../redux/ClientDuck";
import { clientValidation } from "./FormValidation";
import { withRouter } from "react-router-dom";
import { getBusinesses } from "../../../redux/BusinessDuck";
import * as ROUTES from '../../../constants/routes';
import { getFileURL } from "../../../constants/apiUrls";
import { useTranslation } from "react-i18next";
import { changeUserPage, getUsers } from "../../../redux/UserDuck";
import './Form.css';

const Form = props => {

    const { t } = useTranslation(['customer', 'common', 'error', 'validations', 'label', 'userProfile']);
    const clientRedux = useSelector(store => store.client.client);
    const isCreating = useSelector(store => store.client.isCreating);
    const isCreated = useSelector(store => store.client.isCreated);
    const countries = useSelector(store => store.country.countries);
    const businesses = useSelector(store => store.business.businesses);

    const users = useSelector(store => store.user.users);
    const activePageUser = useSelector(store => store.user.activePage);
    const pagesUser = useSelector(store => store.user.pages);

    const [client, setClient] = useState({
        name: '', typeOfClient: 'true', email: '',
        countryCodeId: '', phoneNumber: '',
        image: '', imageUrl: ''
    });
    const [business, setBusiness] = useState([]);
    const [usersId, setUsersId] = useState([]);
    const [error, setError] = useState({});
    const [searchUser, setSearchUser] = useState('');
    const [resetPageUser, setResetPageUser] = useState(false);

    const imgRef = createRef();
    const dispatch = useDispatch();

    const onSubmit = () => {
        const errorForm = clientValidation(client, business, imgRef, usersId, t);
        setError(errorForm);
        if (Object.entries(errorForm).length === 0) {
            dispatch(saveUpdateClient(client, business, usersId));
        }
    };

    const onImageChange = event => {
        if (!!event.target.files[0]) {
            const value = event.target.files[0];
            let img = new Image();
            img.src = window.URL.createObjectURL(value);
            img.onload = () => {
                if ((img.width <= 400 && img.height <= 400) || (img.width <= 200 && img.height <= 200)) {
                    setClient({
                        ...client, image: value,
                        imageUrl: URL.createObjectURL(value)
                    });
                    setError({ ...error, image: '' });
                } else {
                    setError({ ...error, image: t("dimensionsErrTxt") + `(${img.width} x ${img.height})` });
                }
            }
        }
    };

    const onChange = e => {
        const { name, value } = e.target;
        setClient({ ...client, [name]: value });
    };

    const onSwitch = (id, event) => {
        const { checked } = event.target;
        var businessTemp = business;
        var businessObj = businessTemp.find(item => item.id === id);
        const index = businessTemp.indexOf(businessObj);
        businessObj.active = checked;
        businessTemp[index] = businessObj;

        if (!checked) {
            businessObj.offices.forEach(item => {
                item.active = false;
            });
        }

        setBusiness(prev => ([...businessTemp]));
    };

    const onCheckbox = (id, officeId, event) => {
        const { checked } = event.target;
        var businessTemp = business;
        var businessObj = businessTemp.find(item => item.id === id);
        const indexBusiness = businessTemp.indexOf(businessObj);
        var officeObj = businessObj.offices.find(item => item.id === officeId);
        const indexOffice = businessObj.offices.indexOf(officeObj);
        officeObj.active = checked;
        businessObj.offices[indexOffice] = officeObj;
        businessTemp[indexBusiness] = businessObj;
        setBusiness(prev => ([...businessTemp]));
    };

    const onSwitchUser = (id, event) => {
        const { checked } = event.target;
        const list = usersId;
        if (!checked) {
            const index = list.indexOf(String(id));
            index > -1 && list.splice(index, 1);
        } else {
            list.push(String(id));
        }
        setUsersId(prev => ([...list]));
    };

    const onChangeSearchUser = (e) => {
        setSearchUser(e.target.value);
        setResetPageUser(true);
    };

    //componentDidMount
    useEffect(() => {
        dispatch(changeUserPage(1));
        //component will unmount
        return () => {
            dispatch(changeUserPage(1));
        }
    }, [dispatch]);

    useEffect(() => {
        const page = resetPageUser ? 0 : activePageUser;
        const filterBy = [{ search: "2", filterBy: 'not-status' }];
        searchUser !== '' && filterBy.push({ search: searchUser, filterBy: 'search-bar' });

        const timer = setTimeout(() => dispatch(getUsers(filterBy, page, 6, true, "users")), 1000);
        setResetPageUser(false);
        return () => clearTimeout(timer);
        // eslint-disable-next-line
    }, [dispatch, searchUser]);

    useEffect(() => {
        const page = resetPageUser ? 0 : activePageUser;
        const filterBy = [{ search: "2", filterBy: 'not-status' }];
        searchUser !== '' && filterBy.push({ search: searchUser, filterBy: 'search-bar' });

        dispatch(getUsers(filterBy, page, 6, false, "users"));
        setResetPageUser(false);
        // eslint-disable-next-line
    }, [dispatch, activePageUser]);

    useEffect(() => {
        const list = [];
        businesses.forEach(item => {
            const offices = [];
            var businessActive = false;
            item.offices.forEach(office => {
                if (!office.disabled) {
                    const officeActive = clientRedux && clientRedux.clientOffices
                        .find(of => of.officeId === office.id);
                    if (officeActive) businessActive = true;
                    offices.push({
                        active: !!officeActive,
                        ...office
                    })
                }
            });
            if (offices.length > 0) {
                list.push({
                    id: item.id,
                    name: item.name,
                    offices: offices,
                    active: businessActive
                });
            }
        });
        setBusiness(list);
    }, [businesses, clientRedux]);

    useEffect(() => {
        if (clientRedux) {
            const list = [];
            clientRedux.clientUsers.forEach(u => {
                list.push(String(u.userId))
            });
            setUsersId(prev => ([...list]));
        }
    }, [clientRedux]);

    useEffect(() => {
        dispatch(getCountries());
        dispatch(getBusinesses('', 'false', 0, 10000, false, 'client-detail'));
        // dispatch(getBusinesses('', 'false'));
    }, [dispatch]);

    useEffect(() => {
        if (!isCreating && clientRedux) {
            setClient({
                name: clientRedux.name,
                typeOfClient: String(clientRedux.clientType),
                email: clientRedux.email,
                countryCodeId: clientRedux.countryCodeId,
                phoneNumber: clientRedux.phone,
                image: '',
                imageUrl: clientRedux.fileId ? getFileURL + clientRedux.fileId : ''
            });
        }
    }, [clientRedux, isCreating]);

    useEffect(() => {
        (isCreated) && props.history.push(ROUTES.CLIENTS);
    }, [isCreated, props.history]);

    return (
        <Container>
            <Title title={isCreating ? t("subtitle6") : t("subtitle7")} style={{ marginLeft: 5 }} />
            <Grid item xs={12}>
                <div className="container-app-div">
                    <Typography className="app-form-title">{t("subtitle7b")}</Typography>
                    <Typography className="app-form-subtitle">
                        {t("subtitle8")}</Typography>
                    <InputLabel className="label-app">{t("nameLbl")}</InputLabel>
                    <TextFieldComponent name="name"
                        valueInput={client.name}
                        callback={onChange.bind(this)}
                        placeholder={t("namePlh")}
                        errorInput={error.name}
                    />
                    <Typography className="app-form-subtitle">{t("typeLbl")}</Typography>
                    <div style={{ display: 'flex' }}>
                        <label className="type-of-client-form">{t("companyLbl")}
                            <input type="radio" name="typeOfClient" value="true" onChange={onChange}
                                checked={client.typeOfClient === "true"} />
                            <span className="checkmark"></span>
                        </label>
                        <label className="type-of-client-form">{t("personLbl")}
                            <input type="radio" name="typeOfClient" value="false" onChange={onChange}
                                checked={client.typeOfClient === "false"} />
                            <span className="checkmark"></span>
                        </label>
                    </div>
                    <p className='app-error-text'>{error.typeOfClient}</p>
                    <div style={{
                        display: 'flex', flexDirection: 'row', marginTop: 32,
                        width: '100%', justifyContent: 'space-between'
                    }}>
                        <div style={{ width: '50%' }}>
                            <InputLabel className="label-app">Email ({t("optionalLbl")}):</InputLabel>
                            <TextFieldComponent name="email"
                                valueInput={client.email}
                                callback={onChange.bind(this)}
                                errorInput={error.email}
                            />
                        </div>
                        <div style={{ width: '15%' }}>
                            <InputLabel className="label-app">{t("label:countyCodeLbl")}</InputLabel>
                            <SelectFieldComponent
                                name="countryCodeId"
                                onChange={onChange.bind(this)}
                                valueInput={countries.length > 0 ? client.countryCodeId : ''}
                                options={countries.length > 0 ? [{ key: '', value: 'Opcional' }, ...countries] : []}
                                errorInput={error.countryCodeId}
                            />
                        </div>
                        <div style={{ width: '30%' }}>
                            <InputLabel className="label-app">{t("label:phoneNumberLbl")} ({t("optionalLbl")}):</InputLabel>
                            <TextFieldComponent name="phoneNumber"
                                maxLength={20}
                                valueInput={client.phoneNumber}
                                callback={onChange.bind(this)}
                                errorInput={error.phoneNumber}
                            />
                        </div>
                    </div>
                </div>
                <div className="container-app-div">
                    <Typography className="app-form-title">{t("subtitle9")}</Typography>
                    <Typography className="app-form-subtitle">
                        {t("subtitle10")}
                    </Typography>
                    {business.map(item => (
                        <div key={item.id}>
                            <Divider />
                            <div className='business-user-form' >
                                <AccessRow
                                    title={item.name}
                                    Icon={item.active ? Less : More}
                                />
                                <div style={{ marginRight: 18 }}>
                                    <SwitchComponent
                                        callback={onSwitch.bind(this, item.id)}
                                        checked={item.active}
                                        name='business'
                                    />
                                </div>
                            </div>
                            <Divider />
                            {item.active &&
                                <div className='business-form-offices'>
                                    <Typography className="business-form-offices-title">{t("officeLbl")}s</Typography>
                                    {item.offices.map(office => (
                                        <div className='business-user-form' key={office.id}>
                                            <Typography className="business-form-offices-name">
                                                {office.name}
                                            </Typography>
                                            <Checkbox
                                                name='office'
                                                onChange={onCheckbox.bind(this, item.id, office.id)}
                                                value={office.active}
                                                checkboxCheckedValue={true}
                                            />
                                        </div>
                                    ))}
                                </div>
                            }
                        </div>
                    ))}
                    <p className='app-error-text'>{error.office}</p>
                </div>
                <div className="container-app-div">
                    <Typography className="app-form-title">{t("subtitle11")}</Typography>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div style={{ width: '35%' }}>
                            <TextFieldComponent Icon={<Search />}
                                position='start' placeholder={t("common:commonSearchPlh")}
                                valueInput={searchUser}
                                callback={onChangeSearchUser}
                            />
                        </div>
                    </div>
                    <TableContainer>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell className='table-app-title-header'>{t("headerTbl5")}</TableCell>
                                    <TableCell className='table-app-title-header' align="right"></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {users.length === 0 &&
                                    <TableRow key='empty'>
                                        <TableCell colSpan={4} style={{ textAlign: 'center' }}>
                                            {t("common:commonNoResultsLbl")}
                                        </TableCell>
                                    </TableRow>
                                }
                                {users.map(item => (
                                    <TableRow key={item.id} className={
                                        !usersId.find(u => String(u) === String(item.id))
                                        && 'dest-disabled'
                                    }>
                                        <TableCell component="th" scope="row" className='notifications-text-active-clients'>
                                            {item.firstName} {item.lastName}
                                        </TableCell>
                                        <TableCell align="right">
                                            <SwitchComponent
                                                callback={onSwitchUser.bind(this, item.id)}
                                                checked={!!usersId.find(u => String(u) === String(item.id))}
                                                name='users'
                                            />
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <br />
                    <PaginationCustom pages={pagesUser} activePage={activePageUser}
                        onChange={page => dispatch(changeUserPage(page))} />
                    {error.users &&
                        <p className='app-error-text'>{error.users}</p>
                    }
                </div>
                <div className="container-app-div">
                    <Typography className="app-form-title">{t("logoLbl")} ({t("optionalLbl")})</Typography>
                    <div className='image-div'>
                        {!!client.imageUrl &&
                            <CardMedia component="img"
                                image={client.imageUrl}
                                className="card-img-form"
                                ref={imgRef}
                            />
                        }
                        <br />
                        <div style={{ textAlign: 'center' }}>
                            <Button component="label" className='button-add-image'>
                                <input type="file" accept="image/*"
                                    name="image" onChange={onImageChange}
                                    style={{ display: "none" }}
                                />
                                {t("addImgLbl")}
                            </Button>
                            <br />
                            <Typography className="add-image-title">{t("common:commonValidDimensionsLbl")}: 200 x 200 px / 400 x 400 px</Typography>
                        </div>
                    </div>
                    <p className='app-error-text'>{error.image}</p>
                </div>
                <div style={{ display: 'flex' }}>
                    <Button variant="contained" className="button-app-save" style={{ marginRight: 18 }}
                        onClick={() => onSubmit()}>
                        {t("submitBtn")}
                    </Button>
                    <Button variant="contained" className="button-app-cancel"
                        onClick={() => props.history.push(ROUTES.CLIENTS)}>
                        {t("cancelBtn")}
                    </Button>
                </div>
            </Grid>
        </Container>
    );
}

export default withRouter(Form);